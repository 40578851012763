import React, { useState, useEffect } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';

const LivePerformanceChart = () => {
  const [timeframe, setTimeframe] = useState('day');
  const [data, setData] = useState([]);

  // Sample data generation for different timeframes
  const generateData = (period) => {
    const dataPoints = {
      day: 24,
      week: 7,
      month: 30
    };

    const baseMetrics = {
      day: { open: 45, click: 22, conversion: 8 },
      week: { open: 42, click: 20, conversion: 7 },
      month: { open: 40, click: 18, conversion: 6 }
    };

    return Array.from({ length: dataPoints[period] }, (_, i) => {
      const variance = Math.sin(i) * 5;
      return {
        name: period === 'day' ? `${i}h` : period === 'week' ? `Day ${i + 1}` : `Day ${i + 1}`,
        "Open Rate": baseMetrics[period].open + variance + Math.random() * 5,
        "Click Rate": baseMetrics[period].click + variance + Math.random() * 3,
        "Conversion": baseMetrics[period].conversion + variance/2 + Math.random() * 2
      };
    });
  };

  useEffect(() => {
    setData(generateData(timeframe));
  }, [timeframe]);

  const chartColors = {
    "Open Rate": "#60A5FA",
    "Click Rate": "#34D399",
    "Conversion": "#A78BFA"
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-4">
          {Object.entries(chartColors).map(([metric, color]) => (
            <div key={metric} className="flex items-center">
              <div 
                className="w-3 h-3 rounded-full mr-2"
                style={{ backgroundColor: color }}
              />
              <span className="text-sm text-gray-300">{metric}</span>
            </div>
          ))}
        </div>
        <div className="flex gap-4">
          {['day', 'week', 'month'].map((period) => (
            <button
              key={period}
              onClick={() => setTimeframe(period)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                timeframe === period 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div className="h-64 w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis 
              dataKey="name" 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
            />
            <YAxis 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: '#1F2937',
                border: 'none',
                borderRadius: '0.5rem',
                color: '#F3F4F6'
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="Open Rate"
              stroke={chartColors["Open Rate"]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Click Rate"
              stroke={chartColors["Click Rate"]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Conversion"
              stroke={chartColors["Conversion"]}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LivePerformanceChart;