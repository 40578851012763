import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowRight, 
  Info, 
  Loader2, 
  Type, 
  Search, 
  Heart, 
  TrendingUp,
  AlertCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';

const HeadlineAnalyzer = () => {
  const [headline, setHeadline] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const analyzeHeadline = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const analysis = {
        overallScore: calculateOverallScore(headline),
        length: analyzeLength(headline),
        emotion: analyzeEmotion(headline),
        clarity: analyzeClarity(headline),
        seo: analyzeSEO(headline),
        powerWords: findPowerWords(headline),
        suggestions: generateSuggestions(headline)
      };
      
      setResult(analysis);
      setIsAnalyzing(false);
    }, 1000);
  }, [headline]);

  // Analysis helper functions
  const calculateOverallScore = (text) => {
    const metrics = {
      length: getScoreForLength(text),
      powerWords: getScoreForPowerWords(text),
      clarity: getScoreForClarity(text),
      seo: getScoreForSEO(text)
    };
    
    return Math.min(Math.round(
      (metrics.length + metrics.powerWords + metrics.clarity + metrics.seo) / 4
    ), 100);
  };

  const getScoreForLength = (text) => {
    const words = text.split(' ').length;
    if (words >= 5 && words <= 9) return 100;
    if (words >= 3 && words <= 12) return 80;
    return 60;
  };

  const getScoreForPowerWords = (text) => {
    const powerWordsFound = findPowerWords(text).length;
    return Math.min(powerWordsFound * 20, 100);
  };

  const getScoreForClarity = (text) => {
    const words = text.split(' ');
    const complexWords = words.filter(w => w.length > 12).length;
    return Math.max(100 - (complexWords * 20), 0);
  };

  const getScoreForSEO = (text) => {
    const words = text.split(' ').length;
    const containsNumber = /\d/.test(text);
    const hasOptimalLength = words >= 5 && words <= 9;
    
    let score = 60;
    if (hasOptimalLength) score += 20;
    if (containsNumber) score += 20;
    return score;
  };

  const analyzeLength = (text) => {
    const words = text.split(' ').length;
    return {
      count: words,
      optimal: words >= 5 && words <= 9,
      message: words >= 5 && words <= 9 
        ? "Perfect length for engagement"
        : "Aim for 5-9 words for optimal engagement"
    };
  };

  const analyzeEmotion = (text) => {
    const emotionalWords = findPowerWords(text);
    return {
      found: emotionalWords,
      score: Math.min(emotionalWords.length * 20, 100),
      message: emotionalWords.length > 0 
        ? "Good use of emotional triggers"
        : "Consider adding emotional words for more impact"
    };
  };

  const analyzeClarity = (text) => {
    const words = text.split(' ');
    const complexWords = words.filter(w => w.length > 12);
    return {
      score: Math.max(100 - (complexWords.length * 20), 0),
      complex: complexWords,
      message: complexWords.length === 0 
        ? "Clear and easy to understand"
        : "Consider simplifying some words for better clarity"
    };
  };

  const analyzeSEO = (text) => {
    const words = text.split(' ').length;
    const containsNumber = /\d/.test(text);
    
    return {
      optimal: words >= 5 && words <= 9,
      hasNumber: containsNumber,
      score: getScoreForSEO(text),
      message: `${words >= 5 && words <= 9 ? '✓' : '•'} Length is ${words >= 5 && words <= 9 ? 'optimal' : 'not optimal'} for SEO\n${containsNumber ? '✓' : '•'} ${containsNumber ? 'Contains' : 'Consider adding'} numbers for better CTR`
    };
  };

  const findPowerWords = (text) => {
    const powerWords = [
      'free', 'new', 'now', 'amazing', 'essential', 'easy', 'discover',
      'proven', 'exclusive', 'guaranteed', 'powerful', 'ultimate', 'best',
      'secret', 'limited', 'unique', 'special', 'instantly', 'revealed'
    ];
    
    return powerWords.filter(word => 
      text.toLowerCase().includes(word.toLowerCase())
    );
  };

  const generateSuggestions = (text) => {
    const suggestions = [];
    const words = text.split(' ');
    
    if (words.length < 5) {
      suggestions.push("Add more detail to reach the optimal length of 5-9 words");
    }
    if (words.length > 9) {
      suggestions.push("Consider shortening to improve readability");
    }
    if (!findPowerWords(text).length) {
      suggestions.push("Include power words to increase emotional impact");
    }
    if (!/\d/.test(text)) {
      suggestions.push("Add numbers to improve click-through rates");
    }
    
    return suggestions;
  };

  const ScoreGauge = memo(({ score }) => (
    <div className="relative w-32 h-32">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-700"
          strokeWidth="10"
          stroke="currentColor"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
        <circle
          className="text-blue-500"
          strokeWidth="10"
          strokeDasharray={251.2}
          strokeDashoffset={251.2 - (251.2 * score) / 100}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="40"
          cx="50"
          cy="50"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-2xl font-bold">{score}</span>
      </div>
    </div>
  ));

  ScoreGauge.displayName = 'ScoreGauge';

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <input
          type="text"
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          placeholder="Enter your headline to analyze..."
          className="w-full p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {headline.split(' ').filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeHeadline}
            disabled={!headline.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Type className="h-4 w-4" />
                <span>Analyze Headline</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h3 className="text-xl font-semibold">Overall Score</h3>
              <p className="text-sm text-gray-400">
                Based on length, emotion, clarity, and SEO factors
              </p>
            </div>
            <ScoreGauge score={result.overallScore} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-800 rounded-lg p-4 space-y-2">
              <div className="flex items-center space-x-2 text-blue-400">
                <Type className="h-5 w-5" />
                <h4 className="font-medium">Length Analysis</h4>
              </div>
              <p className="text-sm">{result.length.message}</p>
            </div>

            <div className="bg-gray-800 rounded-lg p-4 space-y-2">
              <div className="flex items-center space-x-2 text-pink-400">
                <Heart className="h-5 w-5" />
                <h4 className="font-medium">Emotional Impact</h4>
              </div>
              <p className="text-sm">{result.emotion.message}</p>
            </div>

            <div className="bg-gray-800 rounded-lg p-4 space-y-2">
              <div className="flex items-center space-x-2 text-purple-400">
                <AlertCircle className="h-5 w-5" />
                <h4 className="font-medium">Clarity Score</h4>
              </div>
              <p className="text-sm">{result.clarity.message}</p>
            </div>

            <div className="bg-gray-800 rounded-lg p-4 space-y-2">
              <div className="flex items-center space-x-2 text-green-400">
                <Search className="h-5 w-5" />
                <h4 className="font-medium">SEO Analysis</h4>
              </div>
              <p className="text-sm whitespace-pre-line">{result.seo.message}</p>
            </div>
          </div>

          {result.suggestions.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium">Suggestions for Improvement:</h4>
              <ul className="space-y-2">
                {result.suggestions.map((suggestion, index) => (
                  <li key={index} className="flex items-start space-x-2">
                    <TrendingUp className="h-5 w-5 text-blue-400 mt-0.5" />
                    <span className="text-sm">{suggestion}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              This analysis is based on proven headline writing principles and best practices.
              Results should be used as a guide alongside your creative judgment.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Content Creation Tools?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to advanced features like A/B headline testing, competitor analysis, and personalized recommendations. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HeadlineAnalyzer;