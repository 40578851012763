import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Copy, Info, ArrowRight, Layout } from 'lucide-react';
import { Link } from 'react-router-dom';

const CSSFlexboxGenerator = () => {
  const [containerProps, setContainerProps] = useState({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    gap: '0',
    padding: '1rem'
  });

  const [items, setItems] = useState([
    { id: 1, flexGrow: '0', flexShrink: '1', flexBasis: 'auto', alignSelf: 'auto' },
    { id: 2, flexGrow: '0', flexShrink: '1', flexBasis: 'auto', alignSelf: 'auto' },
    { id: 3, flexGrow: '0', flexShrink: '1', flexBasis: 'auto', alignSelf: 'auto' }
  ]);

  const [copied, setCopied] = useState(false);

  const flexDirectionOptions = ['row', 'row-reverse', 'column', 'column-reverse'];
  const justifyContentOptions = ['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly'];
  const alignItemsOptions = ['flex-start', 'flex-end', 'center', 'stretch', 'baseline'];
  const flexWrapOptions = ['nowrap', 'wrap', 'wrap-reverse'];
  const alignSelfOptions = ['auto', 'flex-start', 'flex-end', 'center', 'stretch', 'baseline'];

  const generateCSS = () => {
    let css = `.container {\n`;
    Object.entries(containerProps).forEach(([key, value]) => {
      css += `  ${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};\n`;
    });
    css += `}\n\n`;

    items.forEach((item, index) => {
      css += `.item-${index + 1} {\n`;
      Object.entries(item).forEach(([key, value]) => {
        if (key !== 'id') {
          css += `  ${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};\n`;
        }
      });
      css += `}\n`;
    });

    return css;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generateCSS());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        id: items.length + 1,
        flexGrow: '0',
        flexShrink: '1',
        flexBasis: 'auto',
        alignSelf: 'auto'
      }
    ]);
  };

  const removeItem = (id) => {
    if (items.length > 1) {
      setItems(items.filter(item => item.id !== id));
    }
  };

  const updateItemProperty = (id, property, value) => {
    setItems(items.map(item => 
      item.id === id ? { ...item, [property]: value } : item
    ));
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Controls */}
        <div className="space-y-4">
          <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
            <h3 className="text-lg font-medium mb-4">Container Properties</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm mb-2">Display</label>
                <select
                  value={containerProps.display}
                  onChange={(e) => setContainerProps({ ...containerProps, display: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                >
                  <option value="flex">flex</option>
                  <option value="inline-flex">inline-flex</option>
                </select>
              </div>
              
              <div>
                <label className="block text-sm mb-2">Flex Direction</label>
                <select
                  value={containerProps.flexDirection}
                  onChange={(e) => setContainerProps({ ...containerProps, flexDirection: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                >
                  {flexDirectionOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm mb-2">Justify Content</label>
                <select
                  value={containerProps.justifyContent}
                  onChange={(e) => setContainerProps({ ...containerProps, justifyContent: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                >
                  {justifyContentOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm mb-2">Align Items</label>
                <select
                  value={containerProps.alignItems}
                  onChange={(e) => setContainerProps({ ...containerProps, alignItems: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                >
                  {alignItemsOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm mb-2">Flex Wrap</label>
                <select
                  value={containerProps.flexWrap}
                  onChange={(e) => setContainerProps({ ...containerProps, flexWrap: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                >
                  {flexWrapOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm mb-2">Gap</label>
                <input
                  type="text"
                  value={containerProps.gap}
                  onChange={(e) => setContainerProps({ ...containerProps, gap: e.target.value })}
                  className="w-full bg-gray-700 rounded-lg p-2 text-sm"
                  placeholder="e.g., 1rem"
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Flex Items</h3>
              <button
                onClick={addItem}
                className="px-3 py-1 bg-blue-500 rounded-lg text-sm hover:bg-blue-600 transition-colors"
              >
                Add Item
              </button>
            </div>
            
            <div className="space-y-6">
              {items.map((item) => (
                <div key={item.id} className="p-4 bg-gray-700 rounded-lg space-y-3">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium">Item {item.id}</span>
                    {items.length > 1 && (
                      <button
                        onClick={() => removeItem(item.id)}
                        className="text-red-400 hover:text-red-300 text-sm"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-xs mb-1">Flex Grow</label>
                      <input
                        type="number"
                        value={item.flexGrow}
                        onChange={(e) => updateItemProperty(item.id, 'flexGrow', e.target.value)}
                        className="w-full bg-gray-600 rounded p-1 text-sm"
                        min="0"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-xs mb-1">Flex Shrink</label>
                      <input
                        type="number"
                        value={item.flexShrink}
                        onChange={(e) => updateItemProperty(item.id, 'flexShrink', e.target.value)}
                        className="w-full bg-gray-600 rounded p-1 text-sm"
                        min="0"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-xs mb-1">Flex Basis</label>
                    <input
                      type="text"
                      value={item.flexBasis}
                      onChange={(e) => updateItemProperty(item.id, 'flexBasis', e.target.value)}
                      className="w-full bg-gray-600 rounded p-1 text-sm"
                      placeholder="auto, 100px, 50%, etc."
                    />
                  </div>

                  <div>
                    <label className="block text-xs mb-1">Align Self</label>
                    <select
                      value={item.alignSelf}
                      onChange={(e) => updateItemProperty(item.id, 'alignSelf', e.target.value)}
                      className="w-full bg-gray-600 rounded p-1 text-sm"
                    >
                      {alignSelfOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Preview and Code */}
        <div className="space-y-4">
          <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
            <h3 className="text-lg font-medium mb-4">Preview</h3>
            <div
              className="bg-gray-700 rounded-lg p-4 min-h-[300px] resize-y overflow-auto"
              style={containerProps}
            >
              {items.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    flexGrow: item.flexGrow,
                    flexShrink: item.flexShrink,
                    flexBasis: item.flexBasis,
                    alignSelf: item.alignSelf,
                  }}
                  className="bg-blue-500 p-4 rounded text-center"
                >
                  Item {index + 1}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Generated CSS</h3>
              <button
                onClick={copyToClipboard}
                className="flex items-center space-x-2 px-3 py-1 bg-blue-500 rounded-lg text-sm hover:bg-blue-600 transition-colors"
              >
                <Copy className="h-4 w-4" />
                <span>{copied ? 'Copied!' : 'Copy'}</span>
              </button>
            </div>
            <pre className="bg-gray-700 rounded-lg p-4 text-sm overflow-x-auto">
              {generateCSS()}
            </pre>
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              The preview above shows how your flex container and items will look. 
              Adjust the properties to see real-time changes and copy the generated CSS when you're satisfied.
            </p>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Elevate Your Content Creation Game!
              </h2>
              <p className="text-purple-100 mb-6">
                Love our free tools? Unlock premium features for content creation, AI-powered writing assistance, and advanced formatting options. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get Started Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CSSFlexboxGenerator;