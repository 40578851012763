import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Copy, RefreshCcw, CheckCircle2, Settings2, ArrowRight, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const LoremIpsumGenerator = () => {
  const [paragraphCount, setParagraphCount] = useState(3);
  const [wordsPerParagraph, setWordsPerParagraph] = useState(50);
  const [includeHeaders, setIncludeHeaders] = useState(false);
  const [format, setFormat] = useState('normal'); // normal, html, markdown
  const [output, setOutput] = useState('');
  const [copied, setCopied] = useState(false);

  // Word banks
  const words = [
    "lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit",
    "sed", "do", "eiusmod", "tempor", "incididunt", "ut", "labore", "et", "dolore",
    "magna", "aliqua", "enim", "ad", "minim", "veniam", "quis", "nostrud",
    "exercitation", "ullamco", "laboris", "nisi", "ut", "aliquip", "ex", "ea",
    "commodo", "consequat", "duis", "aute", "irure", "dolor", "in", "reprehenderit",
    "voluptate", "velit", "esse", "cillum", "dolore", "eu", "fugiat", "nulla",
    "pariatur", "excepteur", "sint", "occaecat", "cupidatat", "non", "proident",
    "sunt", "in", "culpa", "qui", "officia", "deserunt", "mollit", "anim", "id",
    "est", "laborum"
  ];

  const headers = [
    "De Finibus Bonorum et Malorum",
    "The Purpose of Lorem Ipsum",
    "Origins and Discovery",
    "Classical Literature",
    "Modern Applications",
    "Typography and Design",
    "Digital Implementation",
    "Creative Inspiration"
  ];

  const generateSentence = useCallback((minWords = 5, maxWords = 15) => {
    const length = Math.floor(Math.random() * (maxWords - minWords + 1)) + minWords;
    const sentence = Array(length).fill()
      .map(() => words[Math.floor(Math.random() * words.length)])
      .join(' ');
    return sentence.charAt(0).toUpperCase() + sentence.slice(1) + '.';
  }, []);

  const generateParagraph = useCallback((wordCount) => {
    let paragraph = '';
    while (paragraph.split(' ').length < wordCount) {
      paragraph += ' ' + generateSentence();
    }
    return paragraph.trim();
  }, [generateSentence]);

  const formatOutput = useCallback((paragraphs) => {
    switch (format) {
      case 'html':
        return paragraphs.map((p, i) => 
          `${includeHeaders && i === 0 ? `<h2>${headers[Math.floor(Math.random() * headers.length)]}</h2>\n` : ''}<p>${p}</p>`
        ).join('\n');
      case 'markdown':
        return paragraphs.map((p, i) => 
          `${includeHeaders && i === 0 ? `## ${headers[Math.floor(Math.random() * headers.length)]}\n\n` : ''}${p}`
        ).join('\n\n');
      default:
        return paragraphs.join('\n\n');
    }
  }, [format, includeHeaders]);

  const generateText = useCallback(() => {
    const paragraphs = Array(paragraphCount).fill()
      .map(() => generateParagraph(wordsPerParagraph));
    const formattedOutput = formatOutput(paragraphs);
    setOutput(formattedOutput);
  }, [paragraphCount, wordsPerParagraph, generateParagraph, formatOutput]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(output);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [output]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Generate professional-looking placeholder text for your designs and layouts.
        Customize length, format, and style to match your needs.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">
              Number of Paragraphs
            </label>
            <input
              type="number"
              min="1"
              max="10"
              value={paragraphCount}
              onChange={(e) => setParagraphCount(Number(e.target.value))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">
              Words per Paragraph
            </label>
            <input
              type="number"
              min="10"
              max="200"
              value={wordsPerParagraph}
              onChange={(e) => setWordsPerParagraph(Number(e.target.value))}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="headers"
              checked={includeHeaders}
              onChange={(e) => setIncludeHeaders(e.target.checked)}
              className="rounded border-gray-700 bg-gray-800 text-blue-500 focus:ring-blue-500"
            />
            <label htmlFor="headers" className="text-sm text-gray-200">
              Include Headers
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <label className="text-sm text-gray-200">Format:</label>
            <select
              value={format}
              onChange={(e) => setFormat(e.target.value)}
              className="bg-gray-800 border border-gray-700 rounded-lg px-3 py-1 text-sm text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="normal">Plain Text</option>
              <option value="html">HTML</option>
              <option value="markdown">Markdown</option>
            </select>
          </div>
        </div>

        <button
          onClick={generateText}
          className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity flex items-center justify-center space-x-2"
        >
          <Settings2 className="h-4 w-4" />
          <span>Generate Lorem Ipsum</span>
        </button>

        {output && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="relative"
          >
            <div className="absolute top-4 right-4 flex space-x-2">
              <button
                onClick={generateText}
                className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                title="Regenerate"
              >
                <RefreshCcw className="h-4 w-4" />
              </button>
              <button
                onClick={copyToClipboard}
                className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                title="Copy to clipboard"
              >
                {copied ? (
                  <CheckCircle2 className="h-4 w-4 text-green-500" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
              </button>
            </div>
            <div className="w-full h-64 p-4 bg-gray-800 border border-gray-700 rounded-xl text-gray-300 overflow-auto whitespace-pre-wrap">
              {output}
            </div>
          </motion.div>
        )}

        <InfoMessage />
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Create Professional Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Go beyond Lorem Ipsum with our AI-powered content creation tools. Generate engaging, SEO-optimized content in seconds. Try it now with 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LoremIpsumGenerator;