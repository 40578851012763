import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { FileCode, Info, Loader2, ArrowRight, Copy, CheckCircle2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const HTMLMinifier = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [stats, setStats] = useState(null);
  const [isMinifying, setIsMinifying] = useState(false);
  const [copied, setCopied] = useState(false);

  const minifyHTML = useCallback((html) => {
    if (!html.trim()) return '';
    
    // Basic minification rules
    return html
      // Remove comments
      .replace(/<!--[\s\S]*?-->/g, '')
      // Remove whitespace between tags
      .replace(/>\s+</g, '><')
      // Remove line breaks and tabs
      .replace(/(\r\n|\n|\r|\t)/gm, '')
      // Remove spaces between attributes
      .replace(/\s+/g, ' ')
      // Remove spaces around equals signs in attributes
      .replace(/\s*=\s*/g, '=')
      // Remove spaces inside tags
      .replace(/\s+>/g, '>')
      // Collapse boolean attributes
      .replace(/(\w+)\s*=\s*['"](true|false)['"]/g, (match, attr, value) => 
        value === 'true' ? attr : '')
      // Remove quotes from numeric attributes
      .replace(/=["'](\d+)["']/g, '=$1')
      .trim();
  }, []);

  const handleMinify = useCallback(() => {
    setIsMinifying(true);
    
    setTimeout(() => {
      const minified = minifyHTML(input);
      setOutput(minified);
      
      // Calculate statistics
      const originalSize = new Blob([input]).size;
      const minifiedSize = new Blob([minified]).size;
      const savings = originalSize - minifiedSize;
      const percentage = ((savings / originalSize) * 100).toFixed(2);
      
      setStats({
        originalSize,
        minifiedSize,
        savings,
        percentage
      });
      
      setIsMinifying(false);
    }, 800);
  }, [input, minifyHTML]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(output);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [output]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        This tool minifies HTML by removing unnecessary whitespace, comments, and optimizing attributes.
        The original structure and functionality remain unchanged.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm text-gray-300">Original HTML</label>
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Paste your HTML code here..."
            className="w-full h-64 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono text-sm"
          />
        </div>
        
        <div className="space-y-2">
          <label className="text-sm text-gray-300">Minified Output</label>
          <textarea
            value={output}
            readOnly
            placeholder="Minified code will appear here..."
            className="w-full h-64 p-4 bg-gray-800/50 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none resize-none font-mono text-sm"
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <button
          onClick={handleMinify}
          disabled={!input.trim() || isMinifying}
          className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
        >
          {isMinifying ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Minifying...</span>
            </>
          ) : (
            <>
              <FileCode className="h-4 w-4" />
              <span>Minify HTML</span>
            </>
          )}
        </button>

        {output && (
          <button
            onClick={handleCopy}
            className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors flex items-center space-x-2"
          >
            {copied ? (
              <>
                <CheckCircle2 className="h-4 w-4 text-green-500" />
                <span>Copied!</span>
              </>
            ) : (
              <>
                <Copy className="h-4 w-4" />
                <span>Copy</span>
              </>
            )}
          </button>
        )}
      </div>

      {stats && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-4"
        >
          <h4 className="font-medium">Optimization Results:</h4>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-gray-700/50 rounded-lg p-4">
              <div className="text-sm text-gray-400">Original Size</div>
              <div className="text-lg font-medium">{(stats.originalSize / 1024).toFixed(2)} KB</div>
            </div>
            <div className="bg-gray-700/50 rounded-lg p-4">
              <div className="text-sm text-gray-400">Minified Size</div>
              <div className="text-lg font-medium">{(stats.minifiedSize / 1024).toFixed(2)} KB</div>
            </div>
            <div className="bg-gray-700/50 rounded-lg p-4">
              <div className="text-sm text-gray-400">Size Reduction</div>
              <div className="text-lg font-medium">{(stats.savings / 1024).toFixed(2)} KB</div>
            </div>
            <div className="bg-gray-700/50 rounded-lg p-4">
              <div className="text-sm text-gray-400">Savings</div>
              <div className="text-lg font-medium text-green-500">{stats.percentage}%</div>
            </div>
          </div>
        </motion.div>
      )}

      <InfoMessage />

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Enhance Your Content Creation
              </h2>
              <p className="text-purple-100 mb-6">
                Access advanced content optimization tools, AI writing assistants, and premium features. 
                Sign up now and get 1000 free points to explore our full suite of content creation tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HTMLMinifier;