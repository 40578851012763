import React, { useRef } from 'react';
import { FileText, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const executiveSummaryConfig = {
  id: 'executive-summary',
  label: 'Executive Summary',
  icon: <FileText className="w-4 h-4" />,
  description: 'Generate comprehensive business insights',
};

export const createPrompt = (data, columns) => {
  // Format columns and prepare sample data
  const formattedColumns = columns.map(col => {
    const accessor = col.accessor || col.id;
    const sampleValue = data[0]?.[accessor];
    
    let type = 'string';
    if (typeof sampleValue === 'number') {
      type = 'number';
    } else if (!isNaN(Date.parse(sampleValue))) {
      type = 'date';
    }
    
    return {
      header: col.Header || col.id || accessor,
      accessor: accessor,
      type: type
    };
  });

  const tablePreview = data.map(row => {
    return formattedColumns.map(col => `${col.header}: ${row[col.accessor]}`).join(', ');
  }).join('\n');

  return `Generate an executive summary report analyzing this dataset. Provide the analysis in this exact JSON format:

{
  "executiveSummary": {
    "title": "string",
    "overview": "string",
    "dateRange": "string"
  },
  "keyMetrics": {
    "metricName": {
      "value": number,
      "change": number,
      "trend": "up|down|stable",
      "insight": "string"
    }
  },
  "performanceHighlights": {
    "top": {
      "category": "string",
      "value": number,
      "change": number
    },
    "bottom": {
      "category": "string",
      "value": number,
      "change": number
    }
  },
  "segments": {
    "segmentName": {
      "performance": "string",
      "contribution": number,
      "growth": number
    }
  },
  "recommendations": [
    {
      "title": "string",
      "description": "string",
      "impact": "high|medium|low",
      "timeframe": "immediate|short-term|long-term"
    }
  ],
  "visualData": {
    "labels": ["string"],
    "series": {
      "metricName": [number]
    }
  }
}

Table Structure:
${formattedColumns.map(col => `${col.header} (${col.type})`).join('\n')}

Complete Dataset:
${tablePreview}

${JSON.stringify(data)}`;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);

    return {
      executiveSummary: parsedJson.executiveSummary || {},
      keyMetrics: parsedJson.keyMetrics || {},
      performanceHighlights: parsedJson.performanceHighlights || {},
      segments: parsedJson.segments || {},
      recommendations: parsedJson.recommendations || [],
      visualData: parsedJson.visualData || { labels: [], series: {} }
    };
  } catch (error) {
    return {
      executiveSummary: {},
      keyMetrics: {},
      performanceHighlights: {},
      segments: {},
      recommendations: [],
      visualData: { labels: [], series: {} }
    };
  }
};

export const ExecutiveSummaryResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('executive-summary.pdf');
  };

  // Transform data for visualization
  const chartData = results.visualData.labels.map((label, index) => {
    const dataPoint = { name: label };
    Object.entries(results.visualData.series).forEach(([metric, values]) => {
      dataPoint[metric] = values[index];
    });
    return dataPoint;
  });

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef} className="space-y-6">
        {/* Executive Summary Header */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
            {results.executiveSummary.title}
          </h2>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
            {results.executiveSummary.dateRange}
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            {results.executiveSummary.overview}
          </p>
        </div>

        {/* Key Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(results.keyMetrics).map(([metric, data]) => (
            <div key={metric} className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                {metric}
              </h3>
              <div className="flex items-center gap-2 mb-2">
                <span className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                  {data.value}
                </span>
                <span className={`text-sm ${
                  data.trend === 'up' ? 'text-green-500' :
                  data.trend === 'down' ? 'text-red-500' :
                  'text-gray-500'
                }`}>
                  {data.change >= 0 ? '+' : ''}{data.change}%
                </span>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400">{data.insight}</p>
            </div>
          ))}
        </div>

        {/* Performance Chart */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Performance Overview
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {Object.keys(results.visualData.series).map((metric, index) => (
                  <Bar
                    key={metric}
                    dataKey={metric}
                    fill={`hsl(${index * 137.5}, 70%, 50%)`}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Segment Analysis */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Segment Analysis
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(results.segments).map(([segment, data]) => (
              <div key={segment} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <h4 className="font-medium text-gray-900 dark:text-gray-100 mb-2">
                  {segment}
                </h4>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">Performance:</span>
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {data.performance}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">Contribution:</span>
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {data.contribution}%
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">Growth:</span>
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {data.growth}%
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Recommendations */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Strategic Recommendations
          </h3>
          <div className="space-y-4">
            {results.recommendations.map((rec, index) => (
              <div key={index} className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <h4 className="font-medium text-gray-900 dark:text-gray-100">
                    {rec.title}
                  </h4>
                  <div className="flex items-center gap-2">
                    <span className={`px-2 py-1 rounded text-xs ${
                      rec.impact === 'high' ? 'bg-red-100 dark:bg-red-900/30 text-red-600 dark:text-red-400' :
                      rec.impact === 'medium' ? 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-600 dark:text-yellow-400' :
                      'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400'
                    }`}>
                      {rec.impact} impact
                    </span>
                    <span className="px-2 py-1 bg-gray-100 dark:bg-gray-600 rounded text-xs text-gray-600 dark:text-gray-400">
                      {rec.timeframe}
                    </span>
                  </div>
                </div>
                <p className="text-gray-600 dark:text-gray-400">
                  {rec.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default {
  config: executiveSummaryConfig,
  createPrompt,
  parseResults,
  ResultsComponent: ExecutiveSummaryResults
};