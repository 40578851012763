import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import { Check } from 'lucide-react';


import {
  LayoutTemplate,
  Wand2,
  Target,
  ArrowRight,
  Star,
  Users,
  Blocks,
  Eye,
  Palette,
  Brain,
  ChevronDown,
  LineChart,
  Rocket,
  Code,
  Shuffle,
  Sparkles,
  Globe,
  Zap,
  Lightning,
  MousePointer,
  Monitor,
  Laptop,
  Smartphone,
  Share2
} from 'lucide-react';

const LandingPageWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeTab, setActiveTab] = useState('conversion');
  const { scrollYProgress } = useScroll();
  const [isVisible, setIsVisible] = useState(false);

  // Parallax and scroll-based animations
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <>
      <SEOHead 
        title="AI Landing Page Generator | Create Converting Pages in Minutes"
        description="Transform your ideas into high-converting landing pages with our AI-powered page builder. Get 2x more conversions with smart layouts and optimized content."
        keywords="AI landing page builder, landing page generator, conversion optimization, AI website builder"
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white relative overflow-hidden">
        {/* Hero Section with 3D Animation */}
        <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
          {/* Animated background gradients */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 animate-gradient-x" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          </div>

          {/* Floating elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            {[...Array(6)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ 
                  opacity: 0.1,
                  scale: 1,
                  x: Math.random() * window.innerWidth,
                  y: Math.random() * window.innerHeight
                }}
                transition={{
                  duration: 2,
                  delay: i * 0.2,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
              >
                <Sparkles className="h-12 w-12 text-blue-500" />
              </motion.div>
            ))}
          </div>

          {/* Main Hero Content */}
          <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-7xl font-bold mb-6">
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400">
                  Next-Gen Landing Pages
                </span>
                <span className="block mt-2">
                  Powered by AI
                </span>
              </h1>
              
              <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
                Create stunning, high-converting landing pages in minutes with our AI-powered builder.
                Transform your ideas into reality with smart layouts and optimized content.
              </p>

              <div className="flex justify-center gap-6">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link
                    to="/register"
                    className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full font-medium hover:from-blue-700 hover:to-purple-700 transition-all flex items-center group"
                  >
                    Start Creating Free
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <button
                    className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all flex items-center"
                  >
                    Watch Demo
                    <Eye className="ml-2 h-5 w-5" />
                  </button>
                </motion.div>
              </div>

              {/* Trust Indicators */}
              <div className="mt-16 grid grid-cols-3 gap-8 max-w-3xl mx-auto">
                <div className="text-center">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="text-3xl font-bold text-blue-400 mb-2"
                  >
                    2M+
                  </motion.div>
                  <p className="text-gray-400">Pages Created</p>
                </div>
                <div className="text-center">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2 }}
                    className="text-3xl font-bold text-purple-400 mb-2"
                  >
                    150K+
                  </motion.div>
                  <p className="text-gray-400">Active Users</p>
                </div>
                <div className="text-center">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.4 }}
                    className="text-3xl font-bold text-pink-400 mb-2"
                  >
                    89%
                  </motion.div>
                  <p className="text-gray-400">Conversion Rate</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Scroll Indicator */}
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: [0, 10, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
            className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
          >
            <MousePointer className="h-8 w-8 text-gray-400" />
          </motion.div>
        </div>
        <section className="py-24 relative overflow-hidden">
          {/* Animated background gradients */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Future of Page Building
              </h2>
              <p className="text-xl text-gray-400 max-w-2xl mx-auto">
                Experience AI-powered features that transform how you create landing pages
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: Brain,
                  title: "AI Content Generation",
                  description: "Generate compelling copy and headlines that convert visitors into customers",
                  color: "blue"
                },
                {
                  icon: Wand2,
                  title: "Smart Layouts",
                  description: "AI-powered layouts that automatically adjust based on your content and goals",
                  color: "purple"
                },
                {
                  icon: Target,
                  title: "Conversion Optimization",
                  description: "Built-in conversion optimization with real-time suggestions",
                  color: "pink"
                },
                {
                  icon: Globe,
                  title: "Multi-Language Support",
                  description: "Create pages in 100+ languages with AI-powered translation",
                  color: "blue"
                },
                {
                  icon: Zap,
                  title: "Instant Publishing",
                  description: "Go live instantly with optimized code and hosting",
                  color: "purple"
                },
                {
                  icon: Share2,
                  title: "Integration Ready",
                  description: "Connect with your favorite tools and platforms seamlessly",
                  color: "pink"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative"
                >
                  <div className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 hover:bg-gray-800/80 transition-all border border-gray-700/50 hover:border-gray-600">
                    <div className={`w-12 h-12 rounded-xl bg-${feature.color}-500/20 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform`}>
                      <feature.icon className={`h-6 w-6 text-${feature.color}-400`} />
                    </div>
                    <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Interactive Demo Section */}
        <section className="py-24 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-500/5 to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-16 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                <h2 className="text-4xl font-bold mb-6">
                  Create Pages in
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400"> Minutes</span>
                  , Not Days
                </h2>
                <p className="text-xl text-gray-400 mb-8">
                  Watch how our AI transforms your ideas into stunning landing pages instantly
                </p>
                
                <div className="space-y-6">
                  {[
                    {
                      icon: Brain,
                      title: "Input Your Idea",
                      description: "Describe your product or service"
                    },
                    {
                      icon: Wand2,
                      title: "AI Generation",
                      description: "Watch AI create your perfect page"
                    },
                    {
                      icon: Eye,
                      title: "Preview & Customize",
                      description: "Fine-tune your page instantly"
                    }
                  ].map((step, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-start space-x-4"
                    >
                      <div className="w-10 h-10 rounded-lg bg-blue-500/20 flex items-center justify-center flex-shrink-0">
                        <step.icon className="h-5 w-5 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="font-semibold mb-1">{step.title}</h3>
                        <p className="text-gray-400">{step.description}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="mt-8 px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 rounded-xl font-medium hover:from-blue-700 hover:to-purple-700 transition-all flex items-center"
                >
                  Try Live Demo
                  <Rocket className="ml-2 h-5 w-5" />
                </motion.button>
              </motion.div>

              {/* Interactive Preview */}
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="relative"
              >
                <div className="aspect-w-16 aspect-h-9 bg-gray-800 rounded-2xl overflow-hidden border border-gray-700/50">
                  {/* Add your interactive demo component here */}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-gray-400">Interactive Demo</span>
                  </div>
                </div>

                {/* Floating Device Frames */}
                <motion.div
                  animate={{ y: [-10, 10, -10] }}
                  transition={{ repeat: Infinity, duration: 3 }}
                  className="absolute -right-16 -top-16 bg-gray-800 p-2 rounded-xl border border-gray-700/50"
                >
                  <Monitor className="h-8 w-8 text-gray-400" />
                </motion.div>

                <motion.div
                  animate={{ y: [10, -10, 10] }}
                  transition={{ repeat: Infinity, duration: 3 }}
                  className="absolute -left-16 -bottom-16 bg-gray-800 p-2 rounded-xl border border-gray-700/50"
                >
                  <Smartphone className="h-8 w-8 text-gray-400" />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>

       {/* AI Capabilities Visualization */}
       <section className="py-24 relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                AI-Powered Magic
              </h2>
              <p className="text-xl text-gray-400 max-w-2xl mx-auto">
                Watch our AI transform basic inputs into conversion-optimized landing pages
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-12">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50"
              >
                <h3 className="text-2xl font-bold mb-4">Basic Input</h3>
                <div className="space-y-4">
                  <div className="bg-gray-900/50 rounded-lg p-4">
                    <p className="text-gray-400">Company: Tech Startup</p>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-4">
                    <p className="text-gray-400">Product: AI Analytics Platform</p>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-4">
                    <p className="text-gray-400">Target: B2B Software Companies</p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="bg-gradient-to-br from-purple-500/20 to-pink-500/20 backdrop-blur-xl rounded-2xl p-8 border border-purple-700/50"
              >
                <h3 className="text-2xl font-bold mb-4">AI Enhanced Output</h3>
                <div className="space-y-4">
                  <div className="bg-purple-900/30 rounded-lg p-4">
                    <p className="text-purple-200">Headline: "Transform Your Data into Actionable Insights"</p>
                  </div>
                  <div className="bg-purple-900/30 rounded-lg p-4">
                    <p className="text-purple-200">Subheadline: "AI-Powered Analytics for Modern Software Teams"</p>
                  </div>
                  <div className="bg-purple-900/30 rounded-lg p-4">
                    <p className="text-purple-200">CTA: "Get Started with Smart Analytics"</p>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* AI Processing Visualization */}
            <div className="mt-12 relative">
              <div className="absolute left-1/2 top-0 transform -translate-x-1/2 w-px h-16 bg-gradient-to-b from-transparent via-purple-500 to-transparent" />
              
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50 max-w-3xl mx-auto"
              >
                <div className="grid grid-cols-3 gap-8">
                  {[
                    {
                      icon: Brain,
                      title: "Natural Language Processing",
                      description: "Understands context and intent"
                    },
                    {
                      icon: Target,
                      title: "Conversion Analysis",
                      description: "Optimizes for maximum impact"
                    },
                    {
                      icon: Sparkles,
                      title: "Style Enhancement",
                      description: "Adds persuasive elements"
                    }
                  ].map((process, index) => (
                    <motion.div
                      key={index}
                      className="text-center"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <div className="w-12 h-12 rounded-xl bg-purple-500/20 flex items-center justify-center mx-auto mb-4">
                        <process.icon className="h-6 w-6 text-purple-400" />
                      </div>
                      <h4 className="font-semibold mb-2">{process.title}</h4>
                      <p className="text-gray-400 text-sm">{process.description}</p>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Results & Stats Section */}
        <section className="py-24 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Proven Results
              </h2>
              <p className="text-xl text-gray-400 max-w-2xl mx-auto">
                Real metrics from businesses using our AI landing page builder
              </p>
            </motion.div>

            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  metric: "2.5x",
                  label: "Conversion Rate",
                  description: "Average increase in conversions",
                  color: "blue"
                },
                {
                  metric: "85%",
                  label: "Time Saved",
                  description: "Reduction in page creation time",
                  color: "purple"
                },
                {
                  metric: "100K+",
                  label: "Pages Created",
                  description: "Using our AI builder",
                  color: "pink"
                },
                {
                  metric: "95%",
                  label: "User Satisfaction",
                  description: "From verified customers",
                  color: "blue"
                }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="relative group"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-800/50 to-gray-900/50 rounded-2xl blur-xl group-hover:blur-2xl transition-all" />
                  
                  <div className="relative bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50">
                    <div className={`text-4xl font-bold text-${stat.color}-400 mb-2`}>
                      {stat.metric}
                    </div>
                    <div className="text-xl font-semibold mb-2">{stat.label}</div>
                    <p className="text-gray-400">{stat.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
        

        {/* Testimonials */}
        <section className="py-24 relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Customer Success Stories
              </h2>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  name: "Sarah Johnson",
                  role: "Marketing Director",
                  company: "TechCorp",
                  content: "The AI suggestions are incredible. We've seen a 200% increase in conversions since using this platform."
                },
                {
                  name: "Michael Chen",
                  role: "Startup Founder",
                  company: "GrowthLabs",
                  content: "Created our entire website in hours instead of weeks. The AI understands exactly what we need."
                },
                {
                  name: "Emma Davis",
                  role: "Product Manager",
                  company: "ScaleUp Inc",
                  content: "The most intuitive landing page builder I've ever used. The AI makes everything so much easier."
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50"
                >
                  <div className="flex items-center mb-6">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mr-4">
                      <span className="text-lg font-bold">{testimonial.name[0]}</span>
                    </div>
                    <div>
                      <h4 className="font-semibold">{testimonial.name}</h4>
                      <p className="text-sm text-gray-400">{testimonial.role} at {testimonial.company}</p>
                    </div>
                  </div>
                  <p className="text-gray-300">{testimonial.content}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="py-32 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/20 via-purple-500/20 to-transparent" />
          
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-5xl mx-auto px-4 text-center relative z-10"
          >
            <h2 className="text-5xl md:text-6xl font-bold mb-8">
              Start Building Your
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                Perfect Landing Page
              </span>
            </h2>
            <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
              Join thousands of businesses creating high-converting landing pages with AI.
              Get started free today.
            </p>
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block"
            >
              <Link
                to="/register"
                className="px-12 py-6 bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl font-medium text-lg hover:from-blue-700 hover:to-purple-700 transition-all flex items-center justify-center group"
              >
                Create Your First Page Free
                <ArrowRight className="ml-2 h-6 w-6 group-hover:translate-x-1 transition-transform" />
              </Link>
            </motion.div>

            <p className="mt-6 text-gray-400">
              No credit card required • 14-day free trial • Cancel anytime
            </p>
          </motion.div>

          {/* Final floating elements */}
          <motion.div
            animate={{ 
              y: [-10, 10, -10],
              rotate: [0, 5, -5, 0]
            }}
            transition={{ repeat: Infinity, duration: 5 }}
            className="absolute bottom-20 left-20 w-24 h-24 bg-blue-500/10 rounded-full blur-xl"
          />
          <motion.div
            animate={{ 
              y: [10, -10, 10],
              rotate: [0, -5, 5, 0]
            }}
            transition={{ repeat: Infinity, duration: 5 }}
            className="absolute bottom-40 right-20 w-32 h-32 bg-purple-500/10 rounded-full blur-xl"
          />
        </section>
      </div>
    </>
  );
};

export default LandingPageWriterLanding;