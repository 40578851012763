import React, { useRef } from 'react';
import { TrendingUp, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const timeSeriesConfig = {
  id: 'time-series',
  label: 'Time Series Analysis',
  icon: <TrendingUp className="w-4 h-4" />,
  description: 'Analyze trends and patterns over time',
};

export const createPrompt = (data, columns) => {
  const dateColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return !isNaN(Date.parse(sampleValue));
  });

  const numericColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return typeof sampleValue === 'number';
  });

  const tablePreview = data.map(row => {
    return columns.map(col => `${col.Header || col.id}: ${row[col.accessor || col.id]}`).join(', ');
  }).join('\n');

  const prompt = `Analyze this time series data and provide analysis in this exact JSON format:

{
  "trends": {
    "overall": "description of overall trend",
    "seasonal": "description of seasonal patterns if any",
    "cyclical": "description of cyclical patterns if any"
  },
  "statistics": {
    "columnName": {
      "growthRate": number,
      "volatility": number,
      "minMaxRange": number,
      "trendDirection": "up|down|stable"
    }
  },
  "timeSeriesData": {
    "labels": ["date1", "date2", ...],
    "series": {
      "metricName": [value1, value2, ...]
    }
  },
  "forecasts": {
    "nextPeriod": {
      "point": number,
      "range": [lower, upper]
    }
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ]
}

Available Date Columns:
${dateColumns.map(col => col.Header || col.id).join(', ')}

Available Numeric Columns:
${numericColumns.map(col => col.Header || col.id).join(', ')}

Sample Data:
${tablePreview}`;

  return prompt;
};

export const parseResults = (apiResponse) => {
    try {
        // Extract everything between the first set of ```json tags
        const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
        
        if (!jsonMatch || !jsonMatch[1]) {
            
            throw new Error('Invalid API response format');
        }

        const parsedJson = JSON.parse(jsonMatch[1]);
        

        // Return the parsed structure directly
        return {
            trends: parsedJson.trends || {},
            statistics: parsedJson.statistics || {},
            timeSeriesData: parsedJson.timeSeriesData || { labels: [], series: {} },
            forecasts: parsedJson.forecasts || {},
            insights: parsedJson.insights || []
        };

    } catch (error) {
        
        return {
            trends: {},
            statistics: {},
            timeSeriesData: { labels: [], series: {} },
            forecasts: {},
            insights: ['Failed to parse analysis results: ' + error.message]
        };
    }
};

export const TimeSeriesResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const { timeSeriesData, trends, statistics, forecasts, insights } = results;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('time-series-analysis.pdf');
  };

  // Transform data for Recharts
  const chartData = timeSeriesData.labels.map((label, index) => {
    const dataPoint = { date: label };
    Object.entries(timeSeriesData.series).forEach(([metric, values]) => {
      dataPoint[metric] = values[index];
    });
    return dataPoint;
  });

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>

      {/* Time Series Chart */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
        <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Time Series Visualization</h4>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(timeSeriesData.series).map((metric, index) => (
                <Line
                  key={metric}
                  type="monotone"
                  dataKey={metric}
                  stroke={`hsl(${index * 137.5}, 70%, 50%)`}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Trends Analysis */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Trend Analysis</h4>
          <div className="space-y-4">
            {Object.entries(trends).map(([type, description]) => (
              <div key={type} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1 capitalize">{type}</div>
                <div className="text-gray-900 dark:text-gray-100">{description}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Statistics */}
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Key Statistics</h4>
          <div className="space-y-4">
            {Object.entries(statistics).map(([column, stats]) => (
              <div key={column} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3">{column}</h5>
                <div className="grid grid-cols-2 gap-3">
                  {Object.entries(stats).map(([key, value]) => (
                    <div key={key} className="text-sm">
                      <span className="text-gray-500 dark:text-gray-400 capitalize">{key}: </span>
                      <span className="text-gray-900 dark:text-gray-100 font-medium">
                        {typeof value === 'number' ? value.toFixed(2) : value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Forecasts */}
      <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
        <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Forecasts</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {Object.entries(forecasts).map(([period, forecast]) => (
            <div key={period} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
              <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 capitalize">{period}</h5>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Point Forecast:</span>
                  <span className="font-medium dark:text-purple-400">{forecast.point.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500 dark:text-gray-400">Range:</span>
                  <span className="font-medium dark:text-purple-400">
                    [{forecast.range[0].toFixed(2)} - {forecast.range[1].toFixed(2)}]
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Insights */}
      {insights?.length > 0 && (
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Key Insights</h4>
          <ul className="space-y-3">
            {insights.map((insight, index) => (
              <li 
                key={index}
                className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
              >
                <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                  {index + 1}
                </span>
                <span>{insight}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
    </div>
  );
};

export default {
  config: timeSeriesConfig,
  createPrompt,
  parseResults,
  ResultsComponent: TimeSeriesResults
};