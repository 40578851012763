import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Copy, ArrowRight, Image, Globe, Info, Check, AlertCircle, Loader2 } from 'lucide-react';

const OpenGraphTagsGenerator = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    url: '',
    image: '',
    type: 'website',
    siteName: ''
  });

  const [imagePreview, setImagePreview] = useState({
    isValid: false,
    loading: false,
    error: null
  });

  const [copied, setCopied] = useState(false);

  const types = [
    'website',
    'article',
    'blog',
    'product',
    'profile'
  ];

  const validateImageUrl = useCallback(async (url) => {
    if (!url) return false;
    
    try {
      setImagePreview(prev => ({ ...prev, loading: true, error: null }));
      
      // Simple client-side URL validation
      new URL(url);
      
      // Instead of directly fetching the image, we'll just verify it's a valid URL format
      // and let the img tag handle the loading
      setImagePreview({
        isValid: true,
        loading: false,
        error: null
      });
      
      return true;
    } catch (error) {
      setImagePreview({
        isValid: false,
        loading: false,
        error: 'Please enter a valid URL'
      });
      return false;
    }
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'image') {
      await validateImageUrl(value);
    }
  };

  const generateMetaTags = useCallback(() => {
    const tags = [];
    
    if (formData.title) {
      tags.push(`<meta property="og:title" content="${formData.title}" />`);
    }
    if (formData.description) {
      tags.push(`<meta property="og:description" content="${formData.description}" />`);
    }
    if (formData.url) {
      tags.push(`<meta property="og:url" content="${formData.url}" />`);
    }
    if (formData.image) {
      tags.push(`<meta property="og:image" content="${formData.image}" />`);
    }
    if (formData.type) {
      tags.push(`<meta property="og:type" content="${formData.type}" />`);
    }
    if (formData.siteName) {
      tags.push(`<meta property="og:site_name" content="${formData.siteName}" />`);
    }

    return tags.join('\n');
  }, [formData]);

  const handleCopy = async () => {
    const tags = generateMetaTags();
    await navigator.clipboard.writeText(tags);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const PreviewCard = () => (
    <div className="border border-gray-700 rounded-lg overflow-hidden max-w-md">
      <div className="aspect-video bg-gray-800 relative">
        {imagePreview.loading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader2 className="h-8 w-8 text-gray-500 animate-spin" />
          </div>
        ) : formData.image ? (
          <img 
            src={formData.image} 
            alt="Preview"
            className="w-full h-full object-cover"
            onError={() => {
              setImagePreview(prev => ({
                ...prev,
                isValid: false,
                error: 'Unable to load image'
              }));
            }}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center">
            <Image className="h-8 w-8 text-gray-500" />
          </div>
        )}
      </div>
      <div className="p-4 space-y-2">
        <h3 className="font-medium text-lg text-white">
          {formData.title || 'Your Title Here'}
        </h3>
        <p className="text-sm text-gray-400">
          {formData.description || 'Your description will appear here...'}
        </p>
        <div className="flex items-center text-xs text-gray-500 space-x-2">
          <Globe className="h-3 w-3" />
          <span>{formData.siteName || 'your-website.com'}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto space-y-8">
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-4">
          {/* Form inputs remain the same */}
          <div>
            <label className="block text-sm font-medium mb-2">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter your page title"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter your page description"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">URL</label>
            <input
              type="url"
              name="url"
              value={formData.url}
              onChange={handleInputChange}
              placeholder="https://your-website.com"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Image URL</label>
            <div className="space-y-2">
              <input
                type="url"
                name="image"
                value={formData.image}
                onChange={handleInputChange}
                placeholder="https://your-website.com/image.jpg"
                className={`w-full p-3 bg-gray-800 border rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  imagePreview.error ? 'border-red-500' : 'border-gray-700'
                }`}
              />
              {imagePreview.error && (
                <div className="flex items-center space-x-2 text-red-500 text-sm">
                  <AlertCircle className="h-4 w-4" />
                  <span>{imagePreview.error}</span>
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {types.map(type => (
                <option key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Site Name</label>
            <input
              type="text"
              name="siteName"
              value={formData.siteName}
              onChange={handleInputChange}
              placeholder="Your Website Name"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4">Preview</h3>
            <PreviewCard />
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Generated Tags</h3>
            <div className="relative">
              <pre className="bg-gray-800 p-4 rounded-lg text-sm text-gray-300 overflow-x-auto">
                {generateMetaTags() || 'Fill in the form to generate meta tags...'}
              </pre>
              <button
                onClick={handleCopy}
                disabled={!generateMetaTags()}
                className="absolute top-2 right-2 p-2 text-gray-400 hover:text-white disabled:opacity-50"
              >
                {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
              </button>
            </div>
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Add these meta tags to your HTML {'<head>'} section to optimize how your content appears when shared on social media platforms.
            </p>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden">
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Generate SEO-Optimized Content in Seconds
              </h2>
              <p className="text-purple-100 mb-6">
                Create engaging, SEO-friendly content with our AI-powered writing tools. Get meta descriptions, blog posts, and social media content that ranks higher and converts better.
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenGraphTagsGenerator;