import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { FileText, Loader2, ArrowRight, RefreshCw, Copy, Check } from 'lucide-react';
import { Link } from 'react-router-dom';

const TextCleaner = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    removeExtraSpaces: true,
    fixPunctuation: true,
    fixCapitalization: true,
    removeDuplicateLines: true,
    removeEmptyLines: true,
    fixCommonTypos: true,
    removeUrls: false,
    removeEmails: false,
    removePhoneNumbers: false,
  });

  const cleanText = useCallback(() => {
    setIsProcessing(true);
    
    setTimeout(() => {
      let cleaned = inputText;
      
      if (selectedOptions.removeExtraSpaces) {
        cleaned = cleaned.replace(/\s+/g, ' ').trim();
      }
      
      if (selectedOptions.fixPunctuation) {
        cleaned = cleaned
          .replace(/\s+([.,!?;:])/g, '$1')
          .replace(/([.,!?;:])\s*([A-Za-z])/g, '$1 $2')
          .replace(/\s*([()])\s*/g, '$1')
          .replace(/,{2,}/g, ',')
          .replace(/\.{2,}/g, '...')
          .replace(/\s+\.\.\./g, '...')
          .replace(/\.\.\.\s+/g, '... ');
      }
      
      if (selectedOptions.fixCapitalization) {
        cleaned = cleaned
          .replace(/(^\w|[.!?]\s+\w)/g, letter => letter.toUpperCase())
          .replace(/\si\s/g, ' I ');
      }
      
      if (selectedOptions.removeDuplicateLines) {
        cleaned = [...new Set(cleaned.split('\n'))].join('\n');
      }
      
      if (selectedOptions.removeEmptyLines) {
        cleaned = cleaned.replace(/^\s*[\r\n]/gm, '');
      }
      
      if (selectedOptions.fixCommonTypos) {
        const typos = {
          'teh': 'the',
          'dont': "don't",
          'cant': "can't",
          'wont': "won't",
          'im': "I'm",
          'Id': "I'd",
          'Ill': "I'll",
          'theyre': "they're",
          'thier': 'their',
          'recieve': 'receive',
          'wierd': 'weird',
        };
        
        Object.entries(typos).forEach(([typo, correction]) => {
          cleaned = cleaned.replace(new RegExp(`\\b${typo}\\b`, 'gi'), correction);
        });
      }
      
      if (selectedOptions.removeUrls) {
        cleaned = cleaned.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
      }
      
      if (selectedOptions.removeEmails) {
        cleaned = cleaned.replace(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g, '');
      }
      
      if (selectedOptions.removePhoneNumbers) {
        cleaned = cleaned.replace(/(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})/g, '');
      }
      
      setOutputText(cleaned);
      setIsProcessing(false);
    }, 800);
  }, [inputText, selectedOptions]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(outputText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [outputText]);

  const toggleOption = useCallback((option) => {
    setSelectedOptions(prev => ({
      ...prev,
      [option]: !prev[option]
    }));
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Paste your text here to clean and format..."
            className="w-full h-64 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          />
          <div className="flex flex-wrap gap-2">
            {Object.entries(selectedOptions).map(([option, isSelected]) => (
              <button
                key={option}
                onClick={() => toggleOption(option)}
                className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-colors ${
                  isSelected
                    ? 'bg-blue-500/20 text-blue-400 border border-blue-500/30'
                    : 'bg-gray-800 text-gray-400 border border-gray-700 hover:border-gray-600'
                }`}
              >
                {option.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <textarea
            value={outputText}
            readOnly
            placeholder="Cleaned text will appear here..."
            className="w-full h-64 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none resize-none"
          />
          <div className="flex justify-between">
            <button
              onClick={cleanText}
              disabled={!inputText.trim() || isProcessing}
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
            >
              {isProcessing ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Processing...</span>
                </>
              ) : (
                <>
                  <RefreshCw className="h-4 w-4" />
                  <span>Clean Text</span>
                </>
              )}
            </button>

            {outputText && (
              <button
                onClick={handleCopy}
                className="px-6 py-2 bg-gray-800 border border-gray-700 rounded-lg font-medium hover:border-gray-600 transition-colors flex items-center space-x-2"
              >
                {isCopied ? (
                  <>
                    <Check className="h-4 w-4 text-green-500" />
                    <span>Copied!</span>
                  </>
                ) : (
                  <>
                    <Copy className="h-4 w-4" />
                    <span>Copy</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Create Perfect Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Transform your writing with our advanced AI tools. Get access to our premium features including article generation, content optimization, and more. Sign up now and receive 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TextCleaner;