import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight, Copy, Check, Settings, RefreshCw, Info } from 'lucide-react';

const URLSlugGenerator = () => {
  const [text, setText] = useState('');
  const [slug, setSlug] = useState('');
  const [copied, setCopied] = useState(false);
  const [settings, setSettings] = useState({
    lowercase: true,
    removeStopWords: true,
    maxLength: 60,
    separator: '-',
  });

  const stopWords = new Set([
    'a', 'an', 'and', 'are', 'as', 'at', 'be', 'by', 'for', 'from', 'has', 
    'he', 'in', 'is', 'it', 'its', 'of', 'on', 'that', 'the', 'to', 'was', 
    'were', 'will', 'with'
  ]);

  const generateSlug = useCallback(() => {
    let processedText = text;

    // Convert to lowercase if setting is enabled
    if (settings.lowercase) {
      processedText = processedText.toLowerCase();
    }

    // Remove stop words if setting is enabled
    if (settings.removeStopWords) {
      processedText = processedText
        .split(' ')
        .filter(word => !stopWords.has(word.toLowerCase()))
        .join(' ');
    }

    // Generate slug
    const slug = processedText
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters
      .trim()
      .replace(/\s+/g, settings.separator) // Replace spaces with separator
      .slice(0, settings.maxLength); // Apply max length

    setSlug(slug);
  }, [text, settings]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(slug);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Generate SEO-friendly URLs from your content titles. Customize the output with
        advanced settings for perfect URLs every time.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter your title or text to generate a URL slug..."
          className="w-full h-32 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        
        {/* Settings Panel */}
        <div className="bg-gray-800 border border-gray-700 rounded-xl p-4 space-y-3">
          <div className="flex items-center space-x-2 text-sm">
            <Settings className="h-4 w-4" />
            <span>Settings</span>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={settings.lowercase}
                onChange={(e) => setSettings(prev => ({...prev, lowercase: e.target.checked}))}
                className="rounded bg-gray-700 border-gray-600"
              />
              <span className="text-sm">Convert to lowercase</span>
            </label>
            
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={settings.removeStopWords}
                onChange={(e) => setSettings(prev => ({...prev, removeStopWords: e.target.checked}))}
                className="rounded bg-gray-700 border-gray-600"
              />
              <span className="text-sm">Remove stop words</span>
            </label>
            
            <div className="flex items-center space-x-2">
              <span className="text-sm">Max length:</span>
              <input
                type="number"
                value={settings.maxLength}
                onChange={(e) => setSettings(prev => ({...prev, maxLength: parseInt(e.target.value)}))}
                className="w-20 px-2 py-1 bg-gray-700 border border-gray-600 rounded"
              />
            </div>
            
            <div className="flex items-center space-x-2">
              <span className="text-sm">Separator:</span>
              <select
                value={settings.separator}
                onChange={(e) => setSettings(prev => ({...prev, separator: e.target.value}))}
                className="bg-gray-700 border border-gray-600 rounded px-2 py-1"
              >
                <option value="-">Hyphen (-)</option>
                <option value="_">Underscore (_)</option>
                <option value="+">Plus (+)</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <InfoMessage />
          <button
            onClick={generateSlug}
            disabled={!text.trim()}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <RefreshCw className="h-4 w-4" />
            <span>Generate Slug</span>
          </button>
        </div>
      </div>

      {slug && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6"
        >
          <div className="flex justify-between items-center">
            <h4 className="font-medium">Generated URL Slug:</h4>
            <button
              onClick={copyToClipboard}
              className="flex items-center space-x-1 text-sm text-gray-400 hover:text-white transition-colors"
            >
              {copied ? (
                <Check className="h-4 w-4 text-green-500" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </button>
          </div>
          <p className="mt-2 p-3 bg-gray-900 rounded-lg font-mono text-sm break-all">
            {slug}
          </p>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Create Better Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to our premium AI content creation tools, including article writing, SEO optimization, and content analysis. Start with 1000 free points today!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default URLSlugGenerator;