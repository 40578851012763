import React, { useState, useEffect } from 'react';
import SEOHead from '../../components/SEOHead';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Mail, 
  Lock, 
  EyeOff, 
  Eye, 
  ArrowRight, 
  User,
  CheckCircle2,
  AlertCircle,
  X,
  Check 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { getSelectedPlan, clearSelectedPlan } from '../../utils/pricingUtils';

const VALID_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'thecontentgpt.com',
  'icloud.com'
];

const PASSWORD_REQUIREMENTS = [
  { id: 'length', label: 'At least 8 characters', test: (pass) => pass.length >= 8 },
  { id: 'capital', label: 'One uppercase letter', test: (pass) => /[A-Z]/.test(pass) },
  { id: 'number', label: 'One number', test: (pass) => /[0-9]/.test(pass) },
  { id: 'special', label: 'One special character', test: (pass) => /[!@#$%^&*]/.test(pass) }
];

const AuthPages = ({ defaultView }) => {
  const navigate = useNavigate();
  const { login, register } = useAuth();
  const [isLogin, setIsLogin] = useState(defaultView === 'login');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showDomainList, setShowDomainList] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [passwordRequirements, setPasswordRequirements] = useState(
    PASSWORD_REQUIREMENTS.reduce((acc, req) => ({ ...acc, [req.id]: false }), {})
  );

  useEffect(() => {
    const plan = getSelectedPlan();
    if (plan) {
      setSelectedPlan(plan);
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!isLogin && !formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!emailValid) {
      newErrors.email = 'Please use a valid email domain';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!Object.values(passwordRequirements).every(Boolean)) {
      newErrors.password = 'Password does not meet requirements';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }

    try {
      if (isLogin) {
        const result = await login(formData.email, formData.password);
        if (result.redirectUrl) {
          navigate(result.redirectUrl);
        } else {
          navigate('/dashboard');
        }
      } else {
        await register(formData.name, formData.email, formData.password);
        if (selectedPlan && selectedPlan.type && selectedPlan.type.toLowerCase() !== 'free') {
          clearSelectedPlan();
        }
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Auth error:', error);
      setErrors({ 
        submit: error.message || 'An error occurred. Please try again.' 
      });
    } finally {
      setLoading(false);
    }
  };

  const checkEmailDomain = (email) => {
    const domain = email.split('@')[1];
    return VALID_EMAIL_DOMAINS.includes(domain);
  };

  const handleEmailFocus = () => {
    if (!emailValid) {
      setShowDomainList(true);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, email: value }));
    
    if (value.includes('@')) {
      const isValidDomain = checkEmailDomain(value);
      setEmailValid(isValidDomain);
      setShowPasswordField(isValidDomain);
      if (isValidDomain) {
        setTimeout(() => {
          setShowDomainList(false);
        }, 1000);
      }
    } else {
      setEmailValid(false);
      setShowPasswordField(false);
    }

    if (errors.email) {
      setErrors(prev => ({ ...prev, email: '' }));
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, password: value }));
    
    const newRequirements = {};
    PASSWORD_REQUIREMENTS.forEach(req => {
      newRequirements[req.id] = req.test(value);
    });
    setPasswordRequirements(newRequirements);

    if (errors.password) {
      setErrors(prev => ({ ...prev, password: '' }));
    }
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, name: value }));
    if (errors.name) {
      setErrors(prev => ({ ...prev, name: '' }));
    }
  };

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
    setErrors({});
    setFormData({
      name: '',
      email: '',
      password: ''
    });
    setEmailValid(false);
    setShowPasswordField(false);
    setShowDomainList(false);
    setPasswordRequirements(
      PASSWORD_REQUIREMENTS.reduce((acc, req) => ({ ...acc, [req.id]: false }), {})
    );
  };

  return (
    <>
      <SEOHead
        title={defaultView === 'login' ? 'Login' : 'Register'}
        description={defaultView === 'login' ? 'Login to your account' : 'Create your account'}
        noindex={true}
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/20 rounded-full filter blur-3xl animate-pulse" />
          <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full filter blur-3xl animate-pulse delay-1000" />
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="sm:mx-auto sm:w-full sm:max-w-md relative z-10"
        >
          <motion.div 
            className="flex justify-center"
            whileHover={{ scale: 1.05 }}
          >
            <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center">
              <span className="text-white font-bold text-xl">C</span>
            </div>
          </motion.div>

          {selectedPlan && !isLogin && selectedPlan.type && (
            <motion.div 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-6 bg-blue-900/50 border border-blue-500/50 rounded-lg p-4 backdrop-blur-sm"
            >
              <div className="flex items-center">
                <CheckCircle2 className="h-5 w-5 text-blue-400 mr-2" />
                <p className="text-sm text-blue-200">
                  You're signing up for the {selectedPlan.type.toUpperCase()} plan
                </p>
              </div>
            </motion.div>
          )}

          <h2 className="mt-6 text-center text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            {isLogin ? 'Welcome Back' : 'Join the Future'}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            {isLogin ? "Don't have an account? " : "Already have an account? "}
            <button
              onClick={toggleAuthMode}
              className="font-medium text-blue-400 hover:text-blue-300 transition-colors"
            >
              {isLogin ? 'Sign up' : 'Sign in'}
            </button>
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-8 sm:mx-auto sm:w-full sm:max-w-md relative z-10"
        >
          <div className="bg-gray-800/50 py-8 px-4 shadow-xl backdrop-blur-sm sm:rounded-lg sm:px-10 border border-gray-700">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name field (for registration only) */}
              {!isLogin && (
                <div className="group">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                    Full Name
                  </label>
                  <div className="mt-1 relative">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleNameChange}
                      className="appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-700/50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                      placeholder="John Doe"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      <User className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                  {errors.name && (
                    <p className="mt-2 text-sm text-red-400">{errors.name}</p>
                  )}
                </div>
              )}

              {/* Email field with domain validation */}
              <div className="group">
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                  Email address
                </label>
                <div className="mt-1 relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleEmailChange}
                    onFocus={handleEmailFocus}
                    className="appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-700/50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                    placeholder="you@example.com"
                  />
                  <AnimatePresence>
                    {showDomainList && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute mt-2 w-full bg-gray-800 rounded-md shadow-lg border border-gray-700 z-10"
                      >
                        {VALID_EMAIL_DOMAINS.map((domain) => (
                          <div
                            key={domain}
                            className="flex items-center px-4 py-2 hover:bg-gray-700 transition-colors"
                          >
                            {emailValid && domain === formData.email.split('@')[1] ? (
                              <CheckCircle2 className="h-5 w-5 text-green-400 mr-2" />
                            ) : (
                              <Mail className="h-5 w-5 text-red-400 mr-2" />
                            )}
                            <span className="text-sm text-gray-300">@{domain}</span>
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                {errors.email && (
                  <p className="mt-2 text-sm text-red-400">{errors.email}</p>
                )}
              </div>

              {/* Password field with requirements */}
              <AnimatePresence>
                {showPasswordField && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="group"
                  >
                    <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                      Password
                    </label>
                    <div className="mt-1 relative">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handlePasswordChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md bg-gray-700/50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                        placeholder="••••••••"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>

                    <AnimatePresence>
                      {formData.password && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="mt-2 space-y-2"
                        >
                          {PASSWORD_REQUIREMENTS.map(req => (
                            <motion.div
                              key={req.id}
                              className="flex items-center text-sm"
                              animate={{
                                color: passwordRequirements[req.id] ? '#68D391' : '#FC8181'
                              }}
                            >
                              {passwordRequirements[req.id] ? (
                                <Check className="h-4 w-4 mr-2 text-green-400" />
                              ) : (
                                <X className="h-4 w-4 mr-2 text-red-400" />
                              )}
                              {req.label}
                            </motion.div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {errors.password && (
                      <p className="mt-2 text-sm text-red-400">{errors.password}</p>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Submit button */}
              <motion.button
                type="submit"
                disabled={loading || !emailValid || !Object.values(passwordRequirements).every(Boolean)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all ${
                  (loading || !emailValid || !Object.values(passwordRequirements).every(Boolean)) ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span>{isLogin ? 'Sign in' : 'Create account'}</span>
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </div>
                )}
              </motion.button>

              {/* Error Messages */}
              <AnimatePresence>
                {errors.submit && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="rounded-md bg-red-900/50 border border-red-500/50 p-4 backdrop-blur-sm"
                  >
                    <div className="flex">
                      <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                      <p className="text-sm text-red-300">{errors.submit}</p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Remember Me & Forgot Password - Only show for login */}
              {isLogin && (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-600 bg-gray-700 rounded"
                    />
                    <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-300">
                      Remember me
                    </label>
                  </div>
                
                  <div className="text-sm">
                    <Link 
                      to="/forgot-password" 
                      className="font-medium text-blue-400 hover:text-blue-300 transition-colors"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              )}
            </form>
          </div>
        </motion.div>

        {/* Additional decorative elements */}
        <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-500 to-transparent opacity-20" />
        <div className="absolute top-0 right-0 w-px h-full bg-gradient-to-b from-transparent via-purple-500 to-transparent opacity-20" />
      </div>
    </>
  );
};

export default AuthPages;