import SEOHead from '../../components/SEOHead';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  HelpCircle,
  Sparkles,
  Brain,
  MessageSquare,
  Settings,
  Search,
  ChevronDown,
  CheckCircle,
  Database,
  Globe,
  Zap,
  Target,
  Share2,
  Layout,
  PieChart
} from 'lucide-react';

const FaqGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeTab, setActiveTab] = useState('ecommerce');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const sampleFaqs = {
    ecommerce: {
      before: [
        "What is your return policy?",
        "How long does shipping take?",
      ],
      after: [
        "What is your hassle-free 30-day return policy?",
        "How quickly will I receive my order with your express shipping?",
      ]
    },
    saas: {
      before: [
        "Do you offer a free trial?",
        "What payment methods do you accept?",
      ],
      after: [
        "How can I start my 14-day free trial with all premium features?",
        "Which secure payment methods do you support for subscriptions?",
      ]
    },
    service: {
      before: [
        "What are your business hours?",
        "Do you offer consultations?",
      ],
      after: [
        "When can I reach your 24/7 customer support team?",
        "How do I book my free 30-minute consultation call?",
      ]
    }
  };

  const features = [
    {
      title: "AI Question Prediction",
      description: "Automatically predict customer questions based on your content",
      icon: Brain,
      color: "blue"
    },
    {
      title: "Smart Answering",
      description: "Generate comprehensive, engaging answers that boost conversion",
      icon: MessageSquare,
      color: "purple"
    },
    {
      title: "SEO Optimization",
      description: "Schema-ready FAQs that improve search rankings",
      icon: Search,
      color: "green"
    },
    {
      title: "Industry Templates",
      description: "Pre-built FAQ templates for various business types",
      icon: Layout,
      color: "orange"
    }
  ];

  const analytics = [
    {
      metric: "Customer Satisfaction",
      improvement: "+45%",
      description: "Higher satisfaction scores with comprehensive FAQs"
    },
    {
      metric: "Support Tickets",
      improvement: "-60%",
      description: "Reduction in basic support queries"
    },
    {
      metric: "Search Rankings",
      improvement: "+35%",
      description: "Improvement in FAQ page visibility"
    },
    {
      metric: "Conversion Rate",
      improvement: "+25%",
      description: "Increase in conversion with targeted FAQs"
    }
  ];

  const industries = {
    ecommerce: [
      "Returns & Shipping",
      "Product Details",
      "Payment Options",
      "Order Tracking"
    ],
    saas: [
      "Pricing & Plans",
      "Technical Support",
      "Integration Help",
      "Account Management"
    ],
    service: [
      "Booking & Scheduling",
      "Service Coverage",
      "Pricing Questions",
      "Expertise & Experience"
    ]
  };

  const toolFeatures = [
    {
      title: "Real-time Generation",
      description: "Create comprehensive FAQs in seconds using advanced AI",
      benefits: ["Instant Q&A pairs", "Context-aware responses", "Natural language"]
    },
    {
      title: "Smart Organization",
      description: "Automatically categorize and structure your FAQs",
      benefits: ["Topic clustering", "Priority sorting", "Hierarchical structure"]
    },
    {
      title: "SEO Enhancement",
      description: "Optimize your FAQs for search engines",
      benefits: ["Schema markup", "Keyword optimization", "Rich snippets"]
    }
  ];

  const faqs = [
    {
      question: "How does the FAQ Generator create relevant questions?",
      answer: "Our AI analyzes your website content, customer interactions, and industry patterns to generate highly relevant questions. It uses machine learning to identify common customer pain points and information needs specific to your business."
    },
    {
      question: "Can I customize the generated FAQs?",
      answer: "Yes! Every generated FAQ can be edited, reworded, or refined to match your brand voice. You can also manually add questions, rearrange the order, and organize them into categories."
    },
    {
      question: "Will the FAQs help with SEO?",
      answer: "Absolutely. All generated FAQs come with schema markup, are keyword-optimized, and follow SEO best practices. This helps your content rank better in search results and appear in Google's FAQ rich snippets."
    },
    {
      question: "How often should I update my FAQs?",
      answer: "We recommend reviewing and updating your FAQs quarterly or whenever you make significant changes to your products/services. Our tool can help identify new common questions based on customer interaction data."
    }
  ];

  return (
    <>
      <SEOHead />
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Transform Customer Support with</span>
          <span className="block text-blue-600">AI-Powered FAQs</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Generate comprehensive, SEO-friendly FAQs that answer customer questions before they ask. Reduce support tickets by 60%.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-all flex items-center"
          >
            Generate FAQs Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-all"
          >
            See Examples
          </a>
        </div>
      </div>

      {/* Interactive Demo Section */}
      <div id="demo" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">See the Transformation</h2>
        <div className="flex justify-center gap-4 mb-8">
          {Object.keys(sampleFaqs).map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-6 py-2 rounded-full ${
                activeTab === tab
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Standard FAQs</h3>
              <span className="text-orange-500 text-sm">Before</span>
            </div>
            <div className="space-y-4">
              {sampleFaqs[activeTab].before.map((q, i) => (
                <div key={i} className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-gray-800">{q}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">AI-Enhanced FAQs</h3>
              <span className="text-green-500 text-sm">After</span>
            </div>
            <div className="space-y-4">
              {sampleFaqs[activeTab].after.map((q, i) => (
                <div key={i} className="p-4 bg-green-50 rounded-lg">
                  <p className="text-gray-800">{q}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Analytics Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-white mb-12">Real Results</h2>
          <div className="grid md:grid-cols-4 gap-6">
            {analytics.map((stat, index) => (
              <div key={index} className="bg-white rounded-xl p-6 text-center">
                <div className="text-3xl font-bold text-blue-600 mb-2">
                  {stat.improvement}
                </div>
                <div className="text-lg font-semibold mb-2">{stat.metric}</div>
                <p className="text-gray-600 text-sm">{stat.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Powerful Features</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className={`h-12 w-12 text-${feature.color}-600 mb-4`} />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Industry Solutions */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Industry-Specific Solutions</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {Object.entries(industries).map(([industry, topics]) => (
              <div key={industry} className="bg-white rounded-xl shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4 capitalize">{industry}</h3>
                <ul className="space-y-3">
                  {topics.map((topic, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Advanced Features */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Advanced Capabilities</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {toolFeatures.map((feature, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.description}</p>
                <ul className="space-y-2">
                  {feature.benefits.map((benefit, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-blue-500 mr-2" />
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your FAQs?</h2>
          <p className="text-xl mb-8">Join thousands of businesses providing better customer support</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Generating FAQs
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
    </>
  );
};

export default FaqGeneratorLanding;