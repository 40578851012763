import React, { useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

import { 
  Users, 
  Target, 
  Globe, 
  Shield, 
  Award,
  MessageCircle,
  Brain,
  Trophy,
  Medal,
  Star,
HeartHandshake,
Book,

  Mail,
  MapPin,
  Rocket,
  Code,
  Heart,
  BarChart,
  Zap,
  Clock,
  Coffee,
  Lightbulb
} from 'lucide-react';

const AboutUs = () => {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 200], [1, 0]);
  
  const stats = [
    { label: 'Active Users', value: '50k+', icon: <Users /> },
    { label: 'Content Generated', value: '1M+', icon: <Code /> },
    { label: 'Success Rate', value: '99%', icon: <Target /> },
    { label: 'Global Reach', value: '150+', icon: <Globe /> }
  ];

  const values = [
    {
      title: "Innovation First",
      icon: <Lightbulb className="h-6 w-6 text-blue-400" />,
      description: "Pushing the boundaries of AI technology to revolutionize content creation"
    },
    {
      title: "User-Centric",
      icon: <Heart className="h-6 w-6 text-blue-400" />,
      description: "Every feature and update is designed with our users in mind"
    },
    {
      title: "Ethical AI",
      icon: <Shield className="h-6 w-6 text-blue-400" />,
      description: "Committed to responsible AI development and usage"
    }
  ];

  const timeline = [
    {
      year: "2024",
      title: "Platform Launch",
      description: "Revolutionizing content creation with advanced AI technology"
    },
    {
      year: "2024 Q2",
      title: "Global Expansion",
      description: "Extending our reach to serve creators worldwide"
    },
    {
      year: "2024 Q3",
      title: "Advanced Features",
      description: "Introducing cutting-edge AI capabilities"
    }
  ];


  const awards = [
    {
      year: "2024",
      title: "Best AI Innovation",
      organization: "Tech Excellence Awards",
      icon: <Trophy className="h-6 w-6" />
    },
    {
      year: "2024",
      title: "Top Content Platform",
      organization: "Digital Impact Awards",
      icon: <Medal className="h-6 w-6" />
    },
    {
      year: "2024",
      title: "Innovation Leader",
      organization: "AI Breakthrough Awards",
      icon: <Star className="h-6 w-6" />
    }
  ];

  const technologies = [
    "Advanced Neural Networks",
    "Natural Language Processing",
    "Machine Learning",
    "Deep Learning",
    "Transformer Architecture",
    "Cloud Computing"
  ];

  const roadmap = [
    {
      quarter: "Q 2025",
      title: "Enhanced Multilingual Support",
      description: "Expanding language capabilities to 150+ languages"
    },
    {
      quarter: "Q3 2025",
      title: "Advanced Analytics Dashboard",
      description: "Providing deeper insights into content performance"
    },
    {
      quarter: "Q4 2025",
      title: "AI Model Upgrades",
      description: "Implementing next-generation language models"
    }
  ];

  const partners = [
    {
      category: "Technology",
      companies: ["Cloud Leaders", "AI Innovators", "Security Pioneers"]
    },
    {
      category: "Content",
      companies: ["Media Giants", "Publishing Houses", "Digital Agencies"]
    },
    {
      category: "Education",
      companies: ["Universities", "Online Learning", "Research Institutes"]
    }
  ];

  const workCulture = [
    {
      title: "Innovation-First",
      description: "We encourage creative thinking and bold ideas",
      icon: <Lightbulb />
    },
    {
      title: "Remote-First",
      description: "Work from anywhere, collaborate everywhere",
      icon: <Globe />
    },
    {
      title: "Continuous Learning",
      description: "Regular upskilling and knowledge sharing",
      icon: <Brain />
    }
  ];

  return (
    <>
      <SEOHead 
        title="About The Content GPT - Leading AI Content Creation Platform"
        description="Learn about The Content GPT's mission to revolutionize content creation through ethical AI technology. Discover our journey, values, and commitment to empowering creators worldwide."
        keywords="AI content creation, content technology, AI writing assistant, content automation, ethical AI, content generation platform"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative h-screen flex items-center justify-center overflow-hidden"
        >
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
            <motion.h1 
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
            >
              Shaping the Future
            </motion.h1>
            
            <motion.p 
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto"
            >
              We're on a mission to democratize content creation through ethical AI technology,
              empowering creators worldwide to express their ideas effectively.
            </motion.p>
          </div>

          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
            <motion.div
              animate={{ y: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className="text-gray-400"
            >
              <span className="sr-only">Scroll down</span>
              <svg 
                className="w-6 h-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </motion.div>
          </div>
        </motion.section>

        {/* Vision Section */}
        <section className="py-20 bg-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-12 items-center"
            >
              <div>
                <h2 className="text-4xl font-bold mb-6">Our Vision</h2>
                <p className="text-gray-300 text-lg leading-relaxed">
                  We envision a world where quality content creation is accessible to everyone,
                  regardless of their technical expertise or resources. Through ethical AI implementation,
                  we're building tools that amplify human creativity rather than replace it.
                </p>
              </div>
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl transform rotate-3" />
                <div className="bg-gray-800 p-8 rounded-xl relative">
                  <Brain className="h-12 w-12 text-blue-400 mb-4" />
                  <h3 className="text-2xl font-bold mb-4">AI for Humans</h3>
                  <p className="text-gray-300">
                    Our technology is designed to enhance human capabilities,
                    not replace them. We believe in augmented intelligence that
                    empowers creators to achieve more.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
 
      {/* Technology Stack Section */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Our Technology
            </h2>
            <p className="text-gray-400 text-xl">
              Powered by cutting-edge AI technologies
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {technologies.map((tech, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-6 hover:bg-gray-800 transition-all"
              >
                <Code className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{tech}</h3>
                <div className="h-1 w-20 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Impact & Social Responsibility */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="grid md:grid-cols-2 gap-12 items-center"
          >
            <div>
              <h2 className="text-4xl font-bold mb-6">Making an Impact</h2>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="bg-blue-500/20 p-3 rounded-lg">
                    <Heart className="h-6 w-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Community Support</h3>
                    <p className="text-gray-400">
                      Providing free access to educational institutions and non-profits
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="bg-blue-500/20 p-3 rounded-lg">
                    <Globe className="h-6 w-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Environmental Impact</h3>
                    <p className="text-gray-400">
                      Carbon-neutral operations and sustainable technology practices
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="bg-blue-500/20 p-3 rounded-lg">
                    <Users className="h-6 w-6 text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Digital Inclusion</h3>
                    <p className="text-gray-400">
                      Making content creation accessible to underserved communities
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative">
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                className="bg-gradient-to-br from-blue-500/10 to-purple-500/10 p-8 rounded-2xl"
              >
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-800 p-6 rounded-xl">
                    <h4 className="text-3xl font-bold text-blue-400 mb-2">50K+</h4>
                    <p className="text-gray-300">Students Supported</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-xl">
                    <h4 className="text-3xl font-bold text-purple-400 mb-2">100+</h4>
                    <p className="text-gray-300">NGO Partners</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-xl">
                    <h4 className="text-3xl font-bold text-blue-400 mb-2">0</h4>
                    <p className="text-gray-300">Carbon Footprint</p>
                  </div>
                  <div className="bg-gray-800 p-6 rounded-xl">
                    <h4 className="text-3xl font-bold text-purple-400 mb-2">25+</h4>
                    <p className="text-gray-300">Impact Projects</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Global Presence */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Global Presence
            </h2>
            <p className="text-gray-400 text-xl">
              Serving creators across continents
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              { region: "North America", users: "20K+", cities: "50+" },
              { region: "Europe", users: "15K+", cities: "40+" },
              { region: "Asia Pacific", users: "10K+", cities: "30+" },
              { region: "Global", users: "50K+", cities: "150+" }
            ].map((region, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800 rounded-xl p-6 hover:bg-gray-750 transition-all"
              >
                <MapPin className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{region.region}</h3>
                <p className="text-3xl font-bold text-blue-400 mb-1">{region.users}</p>
                <p className="text-gray-400">Active Users</p>
                <p className="text-gray-400 mt-2">{region.cities} Cities</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

 

      {/* Awards & Recognition */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Awards & Recognition
            </h2>
            <p className="text-gray-400 text-xl">
              Recognized for excellence in AI innovation
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {awards.map((award, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="relative group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-2xl transform group-hover:scale-105 transition-transform" />
                <div className="bg-gray-800 p-8 rounded-2xl relative">
                  <div className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-3 rounded-lg w-fit mb-4">
                    {award.icon}
                  </div>
                  <p className="text-blue-400 mb-2">{award.year}</p>
                  <h3 className="text-xl font-bold mb-2">{award.title}</h3>
                  <p className="text-gray-400">{award.organization}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Working Culture */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Our Culture
            </h2>
            <p className="text-gray-400 text-xl">
              Building the future of content creation together
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {workCulture.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gray-800/50 backdrop-blur-lg p-8 rounded-2xl hover:bg-gray-800 transition-all"
              >
                <div className="bg-blue-500/20 p-3 rounded-lg w-fit mb-6">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold mb-4">{item.title}</h3>
                <p className="text-gray-400">{item.description}</p>
              </motion.div>
            ))}
          </div>

        
        </div>
      </section>

      {/* Partnership Ecosystem */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Partnership Ecosystem
            </h2>
            <p className="text-gray-400 text-xl">
              Collaborating with industry leaders
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {partners.map((category, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gray-800 rounded-2xl p-8"
              >
                <HeartHandshake className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">{category.category}</h3>
                <ul className="space-y-3">
                  {category.companies.map((company, idx) => (
                    <li key={idx} className="text-gray-400 flex items-center">
                      <ChevronRight className="h-4 w-4 mr-2 text-blue-400" />
                      {company}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Future Roadmap */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Future Roadmap
            </h2>
            <p className="text-gray-400 text-xl">
              Building tomorrow's content creation platform
            </p>
          </motion.div>

          <div className="space-y-8">
            {roadmap.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gray-800 rounded-2xl p-8 relative"
              >
                <div className="absolute top-0 left-0 w-2 h-full bg-gradient-to-b from-blue-500 to-purple-500 rounded-l-2xl" />
                <div className="ml-4">
                  <p className="text-blue-400 mb-2">{item.quarter}</p>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-400">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact & Connect */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Connect With Us
            </h2>
            <p className="text-gray-400 text-xl">
              Let's shape the future of content creation together
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <h3 className="text-2xl font-bold mb-4">Get in Touch</h3>
              <div className="flex items-center space-x-4">
                <Mail className="h-6 w-6 text-blue-400" />
                <p className="text-gray-300">support@thecontentgpt.com</p>
              </div>
              <div className="flex items-center space-x-4">
                <MapPin className="h-6 w-6 text-blue-400" />
                <p className="text-gray-300">Global Headquarters - San Francisco</p>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800 rounded-2xl p-8"
            >
              <h3 className="text-2xl font-bold mb-6">Stay Updated</h3>
              <div className="flex gap-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-1 bg-gray-700 rounded-lg px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="px-6 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-all">
                  Subscribe
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Community Section */}
      <section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Join Our Community
            </h2>
            <p className="text-gray-400 text-xl">
              Connect with fellow creators and innovators
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Discord Community",
                description: "Join discussions, share ideas, and get instant support",
                icon: <MessageCircle className="h-6 w-6" />,
                members: "10K+"
              },
              {
                title: "Developer Hub",
                description: "Access APIs, documentation, and development resources",
                icon: <Code className="h-6 w-6" />,
                members: "5K+"
              },
              {
                title: "Knowledge Base",
                description: "Explore tutorials, guides, and best practices",
                icon: <Book className="h-6 w-6" />,
                members: "15K+"
              }
            ].map((community, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gray-800/50 backdrop-blur-lg p-8 rounded-2xl hover:bg-gray-800 transition-all"
              >
                <div className="bg-blue-500/20 p-3 rounded-lg w-fit mb-4">
                  {community.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{community.title}</h3>
                <p className="text-gray-400 mb-4">{community.description}</p>
                <p className="text-blue-400 font-semibold">{community.members} Members</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-4xl md:text-6xl font-bold mb-6">
              Ready to Transform Your Content?
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join thousands of creators who are already using our AI to create extraordinary content
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105"
              >
                Get Started Free
              </Link>
              <Link
                to="/contact"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                Contact Sales
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Social Proof Footer */}
      <section className="py-12 bg-black border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: "Trustpilot Rating", value: "4.9/5" },
              { label: "G2 Rating", value: "4.8/5" },
              { label: "Customer Success Rate", value: "99%" },
              { label: "Enterprise Clients", value: "500+" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-center"
              >
                <p className="text-2xl md:text-3xl font-bold text-blue-400 mb-2">
                  {stat.value}
                </p>
                <p className="text-gray-400">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Schema.org structured data for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "The Content GPT",
          "description": "AI-powered content creation platform revolutionizing how creators work",
          "url": "https://thecontentgpt.ai",
          "logo": "https://thecontentgpt.ai/logo.png",
          "sameAs": [
            "https://twitter.com/thecontentgpt",
            "https://linkedin.com/company/thecontentgpt",
            "https://github.com/thecontentgpt"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "San Francisco",
            "addressRegion": "CA",
            "addressCountry": "US"
          }
        })}
      </script>
      </div>
    </>
  );
};

export default AboutUs;