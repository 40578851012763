import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import { motion } from 'framer-motion';
import LivePerformanceChart from '../../components/LivePerformanceChart';
import {
  Mail,
  ArrowRight,
  Star,
  Zap,
  Target,
  BarChart,
  Layout,
  Users,
  Globe,
  ChevronDown,
  MessageCircle,
  Brain,
  Sparkles,
  Wand2,
  Check,
  RefreshCw,
  Lightning,
  Eye,
  Clock,
  Shield,
  Code,
  Rocket,
  Palette,
  Settings,
  Send
} from 'lucide-react';

const EmailNewsletterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [activePreview, setActivePreview] = useState('standard');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    setIsVisible(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const emailPreviewTemplates = {
    standard: {
      subject: "Welcome to Our Newsletter",
      preview: "Discover the latest updates and insights...",
      engagement: "32% open rate"
    },
    enhanced: {
      subject: "🚀 Your Exclusive Weekly Insights Are Here",
      preview: "AI-crafted content tailored just for you...",
      engagement: "48% open rate"
    },
    optimized: {
      subject: "Breaking: Industry News You Can't Miss",
      preview: "Data-driven insights and analysis...",
      engagement: "52% open rate"
    }
  };

  return (
    <>
      <SEOHead 
        title="AI Email Newsletter Generator | Create Engaging Newsletters Instantly"
        description="Transform your email marketing with AI-powered newsletter generation. Create compelling, personalized newsletters that drive engagement. Try free today!"
        keywords="email newsletter generator, AI newsletter creator, email marketing tool, newsletter templates, email automation"
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />

          {/* Animated email particles */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(15)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute"
                initial={{ 
                  x: Math.random() * window.innerWidth,
                  y: -20,
                  opacity: 0 
                }}
                animate={{
                  y: window.innerHeight + 20,
                  opacity: [0, 1, 0],
                  rotate: [0, 360]
                }}
                transition={{
                  duration: Math.random() * 5 + 3,
                  repeat: Infinity,
                  ease: "linear",
                  delay: Math.random() * 2
                }}
              >
                <Mail className="h-6 w-6 text-blue-400/30" />
              </motion.div>
            ))}
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Next-Gen
              <span className="block">Newsletter AI</span>
            </motion.h1>

            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Create hyperpersonalized newsletters that captivate your audience using advanced AI technology
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => {
                  document.querySelector('#demo')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See It in Action
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">10M+</div>
                <div className="text-gray-400">Newsletters Sent</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">45%</div>
                <div className="text-gray-400">Higher Engagement</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </motion.div>
          </motion.div>
        </section>

      {/* Interactive Demo Section */}
      <section id="demo" className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Experience the
                <span className="text-blue-400"> Evolution</span>
              </h2>
              <p className="text-xl text-gray-400">
                Watch your newsletters transform from ordinary to extraordinary
              </p>
            </motion.div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8">
              <div className="flex justify-center gap-4 mb-8">
                {Object.keys(emailPreviewTemplates).map((template) => (
                  <button
                    key={template}
                    onClick={() => setActivePreview(template)}
                    className={`px-6 py-3 rounded-lg capitalize transition-all ${
                      activePreview === template
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    {template}
                  </button>
                ))}
              </div>

              <motion.div
                key={activePreview}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-gray-900 rounded-xl p-8"
              >
                <div className="max-w-2xl mx-auto">
                  <div className="flex items-center mb-4">
                    <div className="w-3 h-3 rounded-full bg-red-500 mr-2" />
                    <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2" />
                    <div className="w-3 h-3 rounded-full bg-green-500" />
                  </div>
                  <div className="border border-gray-700 rounded-lg p-6">
                    <div className="mb-4">
                      <label className="text-gray-400 text-sm">Subject Line</label>
                      <p className="text-lg font-semibold text-white">
                        {emailPreviewTemplates[activePreview].subject}
                      </p>
                    </div>
                    <div className="mb-4">
                      <label className="text-gray-400 text-sm">Preview Text</label>
                      <p className="text-white">
                        {emailPreviewTemplates[activePreview].preview}
                      </p>
                    </div>
                    <div className="flex items-center text-green-400">
                      <Target className="h-4 w-4 mr-2" />
                      <span>{emailPreviewTemplates[activePreview].engagement}</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* AI Features Showcase */}
        <section className="py-24 bg-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-xl text-gray-400">
                Next-generation technology for unparalleled results
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: Brain,
                  title: "Neural Content Generation",
                  description: "Advanced algorithms create engaging content tailored to your audience",
                  color: "blue"
                },
                {
                  icon: Target,
                  title: "Smart Personalization",
                  description: "Dynamic content adaptation based on subscriber behavior",
                  color: "purple"
                },
                {
                  icon: BarChart,
                  title: "Predictive Analytics",
                  description: "AI-powered insights to optimize engagement and conversions",
                  color: "green"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl group hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className={`w-16 h-16 rounded-full bg-${feature.color}-500/20 flex items-center justify-center mb-6 group-hover:bg-${feature.color}-500/30 transition-all`}>
                    <feature.icon className={`h-8 w-8 text-${feature.color}-400`} />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Performance Metrics Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
            <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent" />
            <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-purple-500/50 to-transparent" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Metrics that Matter
              </h2>
              <p className="text-xl text-gray-400">
                Real-time insights for data-driven decisions
              </p>
            </motion.div>

            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  metric: "45%",
                  label: "Higher Open Rates",
                  icon: Eye,
                  color: "blue"
                },
                {
                  metric: "3.2x",
                  label: "Click-Through Rate",
                  icon: Target,
                  color: "green"
                },
                {
                  metric: "28%",
                  label: "Conversion Boost",
                  icon: Rocket,
                  color: "purple"
                },
                {
                  metric: "87%",
                  label: "Time Saved",
                  icon: Clock,
                  color: "pink"
                }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 text-center"
                >
                  <div className={`w-12 h-12 rounded-full bg-${stat.color}-500/20 flex items-center justify-center mx-auto mb-4`}>
                    <stat.icon className={`h-6 w-6 text-${stat.color}-400`} />
                  </div>
                  <div className="text-3xl font-bold text-white mb-2">{stat.metric}</div>
                  <div className="text-gray-400">{stat.label}</div>
                </motion.div>
              ))}
            </div>

            {/* Live Performance Graph */}
            <LivePerformanceChart />
          </div>
        </section>

        {/* Template Showcase Section */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Premium Templates
              </h2>
              <p className="text-xl text-gray-400">
                Professional designs for every occasion
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  name: "Welcome Series",
                  description: "Onboard new subscribers with style",
                  features: ["Personalized greeting", "Product showcase", "Getting started guide"],
                  popular: true
                },
                {
                  name: "Product Launch",
                  description: "Announce new products with impact",
                  features: ["Countdown timer", "Feature highlights", "Early bird offers"],
                  popular: false
                },
                {
                  name: "Content Digest",
                  description: "Curate and share valuable content",
                  features: ["Smart content curation", "Reading time estimates", "Category sorting"],
                  popular: false
                }
              ].map((template, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className={`bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-8 relative ${
                    template.popular ? 'ring-2 ring-blue-500' : ''
                  }`}
                >
                  {template.popular && (
                    <div className="absolute -top-3 -right-3 bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
                      Popular
                    </div>
                  )}
                  <h3 className="text-xl font-bold mb-4">{template.name}</h3>
                  <p className="text-gray-400 mb-6">{template.description}</p>
                  <ul className="space-y-3">
                    {template.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <Check className="h-5 w-5 text-blue-400 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

{/* Integration Features */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Seamless Integrations
              </h2>
              <p className="text-xl text-gray-400">
                Connect with your favorite tools
              </p>
            </motion.div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                "Mailchimp", "Campaign Monitor", "Klaviyo", "SendGrid",
                "HubSpot", "Salesforce", "Zapier", "ActiveCampaign"
              ].map((platform, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-800/70 transition-all text-center"
                >
                  <Code className="h-8 w-8 text-blue-400 mx-auto mb-3" />
                  <span className="text-gray-300">{platform}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Success Stories
              </h2>
              <p className="text-xl text-gray-400">
                From our satisfied customers
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "Revolutionized our email marketing strategy. Engagement is up 300%.",
                  author: "Sarah Chen",
                  role: "Marketing Director",
                  company: "TechGrowth"
                },
                {
                  quote: "The AI suggestions are spot-on. Our newsletters now convert better than ever.",
                  author: "Mike Johnson",
                  role: "Content Manager",
                  company: "EcomPro"
                },
                {
                  quote: "Finally, an email tool that understands our audience and delivers results.",
                  author: "Emma Wilson",
                  role: "Newsletter Editor",
                  company: "MediaPulse"
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-8 rounded-2xl"
                >
                  <div className="flex mb-6">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                    ))}
                  </div>
                  <p className="text-gray-300 mb-6">"{testimonial.quote}"</p>
                  <div>
                    <div className="font-semibold">{testimonial.author}</div>
                    <div className="text-sm text-gray-400">{testimonial.role}</div>
                    <div className="text-sm text-gray-400">{testimonial.company}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
            />
          </div>
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your Email Marketing?
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of marketers creating engaging newsletters with AI
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Get Started Free
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Pricing
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">
                No credit card required • 14-day free trial
              </p>
            </motion.div>
          </div>
        </section>

        {/* Floating Stats Display */}
        <motion.div
          className="fixed bottom-8 right-8 bg-gray-800/90 backdrop-blur-sm rounded-2xl p-4 shadow-lg z-50"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2 }}
        >
          <div className="flex items-center space-x-6">
            <div>
              <div className="text-sm text-gray-400">Newsletters Created</div>
              <div className="text-xl font-bold text-blue-400">
                {Math.floor(scrollY * 5).toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Active Users</div>
              <div className="text-xl font-bold text-green-400">2,847</div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default EmailNewsletterLanding;