import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import SMLiveDemo from '../../components/SMLiveDemo';
import { motion } from 'framer-motion';
import { 
  MessageSquare, 
  Instagram, 
  Twitter, 
  Facebook, 
  Linkedin,
  ArrowRight,
  Star,
  ChevronDown,
  Sparkles,
  Target,
  Repeat,
  Image,
  Calendar,
  Hash,
  TrendingUp,
  BookOpen,
  Check,
  Clock,
  Brain,
  Globe,
  Zap,
  Users,
  Rocket,
  Shield,
  Eye,
  Settings,
  Layout
} from 'lucide-react';

const SocialMediaWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('instagram');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const platforms = {
    instagram: {
      icon: Instagram,
      name: "Instagram",
      examples: [
        "✨ Living my best life one adventure at a time! 🌎 #wanderlust #travel",
        "🔥 Transform your body, transform your life. New fitness program dropping soon! 💪",
        "🌟 The perfect blend of style and comfort. Shop our new collection now! 🛍️"
      ]
    },
    twitter: {
      icon: Twitter,
      name: "Twitter",
      examples: [
        "Breaking: Our latest feature just dropped! Here's what you need to know...",
        "Pro tip: The best time to post on Twitter is when your audience is most active 📊",
        "Hot take: AI is not replacing creativity, it's enhancing it. Here's why..."
      ]
    },
    facebook: {
      icon: Facebook,
      name: "Facebook",
      examples: [
        "🎉 Big announcement! We're excited to share our latest product launch...",
        "Looking for expert tips on digital marketing? Join our free webinar!",
        "Customer spotlight: See how Sarah transformed her business using our platform"
      ]
    },
    linkedin: {
      icon: Linkedin,
      name: "LinkedIn",
      examples: [
        "Excited to announce that our team has reached a major milestone...",
        "Three key trends that are reshaping the future of work...",
        "Leadership lesson: The importance of emotional intelligence in management"
      ]
    }
  };

  return (
    <>
      <SEOHead 
        title="AI Social Media Content Generator | Write Engaging Posts Instantly"
        description="Create engaging social media content in seconds with our AI-powered content generator. Perfect for Instagram, Twitter, Facebook, and LinkedIn. Try free now!"
        keywords="social media content generator, AI content writer, social media posts, Instagram captions, Twitter posts, Facebook content, LinkedIn posts"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Next-Gen Social
              <span className="block">Content Creation</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform your social media presence with AI-powered content that engages, converts, and grows your audience
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full font-medium hover:from-blue-700 hover:to-purple-700 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <Link
                to="#platforms"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('platforms')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
              >
                Explore Platforms
              </Link>
            </motion.div>

            {/* Platform Icons */}
            <motion.div 
              className="mt-12 flex justify-center space-x-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.2 }}
            >
              {Object.entries(platforms).map(([key, platform], index) => (
                <motion.button
                  key={key}
                  onClick={() => setSelectedPlatform(key)}
                  className={`p-4 rounded-lg transition-all ${
                    selectedPlatform === key 
                      ? 'bg-blue-500/20 text-blue-400' 
                      : 'text-gray-400 hover:text-gray-300 hover:bg-white/5'
                  }`}
                  whileHover={{ scale: 1.1 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.2 + index * 0.1 }}
                >
                  <platform.icon className="h-8 w-8" />
                </motion.button>
              ))}
            </motion.div>
          </motion.div>

          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
            <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
          </div>
        </section>


        {/* Would you like me to continue with the next sections? */}

        {/* Continuing from previous sections */}

        {/* Stats Counter Section */}
        <section className="py-20 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                { label: 'Posts Generated', value: '5M+', icon: MessageSquare },
                { label: 'Active Users', value: '50K+', icon: Users },
                { label: 'Engagement Rate', value: '47%', icon: TrendingUp },
                { label: 'Time Saved', value: '85%', icon: Clock }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="text-center"
                >
                  <div className="flex justify-center mb-4">
                    <stat.icon className="h-8 w-8 text-blue-400" />
                  </div>
                  <div className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                    {stat.value}
                  </div>
                  <p className="text-gray-400 mt-2">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Interactive Platform Preview */}
        <section id="platforms" className="py-20 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                One AI, All Platforms
              </h2>
              <p className="text-xl text-gray-400">
                Create platform-perfect content with a single click
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-12">
              {/* Platform Selector */}
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="space-y-6"
              >
                {Object.entries(platforms).map(([key, platform]) => (
                  <motion.button
                    key={key}
                    onClick={() => setSelectedPlatform(key)}
                    className={`w-full p-6 rounded-xl transition-all flex items-center space-x-4 ${
                      selectedPlatform === key
                        ? 'bg-gradient-to-r from-blue-500/20 to-purple-500/20 border border-blue-500/50'
                        : 'bg-gray-800/50 hover:bg-gray-800'
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <platform.icon className="h-8 w-8" />
                    <span className="text-xl font-medium">{platform.name}</span>
                  </motion.button>
                ))}
              </motion.div>

              {/* Content Preview */}
<motion.div
  initial={{ opacity: 0, x: 50 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
  className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8"
>
  <div className="flex items-center space-x-4 mb-6">
    {platforms[selectedPlatform].icon && (
      <div className="text-blue-400">
        {React.createElement(platforms[selectedPlatform].icon, { className: "h-8 w-8" })}
      </div>
    )}
    <h3 className="text-2xl font-bold">
      {platforms[selectedPlatform].name} Content
    </h3>
  </div>

  <div className="space-y-4">
    {platforms[selectedPlatform].examples.map((example, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        className="bg-black/50 p-6 rounded-xl border border-gray-800"
      >
        <p className="text-gray-300">{example}</p>
      </motion.div>
    ))}
  </div>
</motion.div>
            </div>
          </div>
        </section>

        {/* AI Capabilities Showcase */}
        <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-xl text-gray-400">
                Create engaging content with cutting-edge artificial intelligence
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: Brain,
                  title: "Smart Analysis",
                  description: "AI analyzes top-performing content to understand what drives engagement"
                },
                {
                  icon: Target,
                  title: "Audience Targeting",
                  description: "Create content that resonates with your specific audience demographics"
                },
                {
                  icon: Globe,
                  title: "Multi-Language Support",
                  description: "Generate content in 30+ languages while maintaining context and tone"
                },
                {
                  icon: Sparkles,
                  title: "Trend Detection",
                  description: "Stay ahead with AI-powered trend and hashtag recommendations"
                },
                {
                  icon: Calendar,
                  title: "Smart Scheduling",
                  description: "Post at optimal times based on audience activity patterns"
                },
                {
                  icon: Eye,
                  title: "Performance Prediction",
                  description: "AI predicts content performance before you post"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  <feature.icon className="h-12 w-12 text-blue-400 mb-6" />
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Would you like me to continue with the next sections? */}

        {/* Content Types Grid with Interactive Preview */}
        <section className="py-20 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_30%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                All Content Types Covered
              </h2>
              <p className="text-xl text-gray-400">
                Create any type of social content with AI precision
              </p>
            </motion.div>

            <div className="grid md:grid-cols-4 gap-6">
              {[
                {
                  title: "Brand Stories",
                  description: "Build compelling brand narratives",
                  icon: Layout,
                  examples: ["Behind the scenes", "Company culture", "Product showcases"]
                },
                {
                  title: "Promotional Posts",
                  description: "Drive sales and conversions",
                  icon: Target,
                  examples: ["Product launches", "Special offers", "Limited-time deals"]
                },
                {
                  title: "Engagement Content",
                  description: "Spark conversations and interactions",
                  icon: MessageSquare,
                  examples: ["Polls", "Questions", "User-generated content"]
                },
                {
                  title: "Thought Leadership",
                  description: "Establish industry authority",
                  icon: Brain,
                  examples: ["Industry insights", "Expert tips", "Market trends"]
                },
                {
                  title: "Event Content",
                  description: "Promote and cover live events",
                  icon: Calendar,
                  examples: ["Event announcements", "Live coverage", "Recap posts"]
                },
                {
                  title: "Educational Content",
                  description: "Share knowledge and expertise",
                  icon: BookOpen,
                  examples: ["How-to guides", "Tips & tricks", "Industry facts"]
                },
                {
                  title: "Visual Content",
                  description: "Create eye-catching visuals",
                  icon: Image,
                  examples: ["Infographics", "Quote cards", "Product showcases"]
                },
                {
                  title: "Community Building",
                  description: "Foster audience connections",
                  icon: Users,
                  examples: ["Community spotlights", "Member features", "Group discussions"]
                }
              ].map((type, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  
                  <type.icon className="h-8 w-8 text-blue-400 mb-4" />
                  <h3 className="text-xl font-bold mb-2">{type.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{type.description}</p>
                  
                  <div className="space-y-2">
                    {type.examples.map((example, i) => (
                      <div 
                        key={i}
                        className="text-sm text-gray-500 flex items-center"
                      >
                        <Check className="h-4 w-4 text-blue-400 mr-2" />
                        {example}
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Workflow Visualization */}
        <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Streamlined Creation Process
              </h2>
              <p className="text-xl text-gray-400">
                From idea to viral post in minutes
              </p>
            </motion.div>

            <div className="relative">
              {/* Connection line */}
              <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 to-purple-500 transform -translate-y-1/2 hidden md:block" />

              <div className="grid md:grid-cols-4 gap-8">
                {[
                  {
                    step: "01",
                    title: "Choose Platform",
                    description: "Select your target social network",
                    icon: Globe
                  },
                  {
                    step: "02",
                    title: "Define Goals",
                    description: "Set your content objectives",
                    icon: Target
                  },
                  {
                    step: "03",
                    title: "Generate Content",
                    description: "Let AI craft perfect posts",
                    icon: Sparkles
                  },
                  {
                    step: "04",
                    title: "Schedule & Post",
                    description: "Publish at optimal times",
                    icon: Rocket
                  }
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 }}
                    className="relative z-10"
                  >
                    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 hover:transform hover:scale-105 transition-all">
                      <div className="bg-blue-500/20 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                        <step.icon className="h-8 w-8 text-blue-400" />
                      </div>
                      <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-xl font-bold">
                        {step.step}
                      </div>
                      <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
                      <p className="text-gray-400">{step.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Would you like me to continue with the remaining sections? */}

        {/* Success Stories */}
        <section className="py-20 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_70%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Success Stories
              </h2>
              <p className="text-xl text-gray-400">
                Real results from real businesses
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  metric: "400%",
                  label: "Engagement Increase",
                  company: "GrowthLabs",
                  description: "Quadrupled their social media engagement within 3 months"
                },
                {
                  metric: "10x",
                  label: "Content Output",
                  company: "TechStart",
                  description: "Increased content production while maintaining quality"
                },
                {
                  metric: "75%",
                  label: "Time Saved",
                  company: "MediaCorp",
                  description: "Reduced content creation time significantly"
                }
              ].map((story, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl relative group hover:transform hover:scale-105 transition-all"
                >
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  <h3 className="text-4xl font-bold mb-2 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                    {story.metric}
                  </h3>
                  <p className="text-xl font-semibold mb-4">{story.label}</p>
                  <p className="text-gray-400 mb-2">{story.company}</p>
                  <p className="text-gray-300">{story.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Live Demo Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  Try It Yourself
                </h2>
                <p className="text-xl text-gray-400 mb-8">
                  Experience the power of AI-driven social media content creation
                </p>
                <div className="space-y-4">
                  {[
                    "Select your platform",
                    "Choose your content type",
                    "Customize tone and style",
                    "Generate engaging content"
                  ].map((step, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-center space-x-3"
                    >
                      <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center">
                        <Check className="h-4 w-4" />
                      </div>
                      <span className="text-gray-300">{step}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              <motion.div
  initial={{ opacity: 0, x: 50 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
  className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 shadow-2xl"
>
  <SMLiveDemo />
</motion.div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-20 bg-black">
          <div className="max-w-3xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Common Questions
              </h2>
              <p className="text-xl text-gray-400">
                Everything you need to know
              </p>
            </motion.div>

            <div className="space-y-4">
              {[
                {
                  question: "How does the AI understand my brand voice?",
                  answer: "Our AI analyzes your existing content and brand guidelines to maintain consistent messaging across all platforms."
                },
                {
                  question: "Can I schedule posts directly from the platform?",
                  answer: "Yes! Our platform integrates with major social media platforms for direct scheduling and posting."
                },
                {
                  question: "Is the content unique and original?",
                  answer: "Absolutely. Each piece of content is generated uniquely for your brand, passing AI detection and plagiarism checks."
                },
                {
                  question: "How many variations can I generate?",
                  answer: "You can generate unlimited variations for each post to find the perfect fit for your audience."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => toggleFaq(index)}
                    className="w-full p-6 text-left flex items-center justify-between hover:bg-blue-500/10 transition-all"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown className={`h-5 w-5 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`} />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-6 text-gray-400">
                      {faq.answer}
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your Social Media?
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of creators who are already using AI to create viral content
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Get Started Free
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Pricing
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">No credit card required • Free plan available</p>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SocialMediaWriterLanding;