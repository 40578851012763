import React, { useRef, useEffect } from 'react';

import { 
  Save, FileText, Edit3, Eye, Table, Layout, Tools, Settings,
  Upload, Download, ChevronDown, Plus, Search, X, Copy,
  Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight,
  DollarSign, Percent, Calculator, Undo, Redo, Trash2,
  PaintBucket, Filter, SortAsc, LockIcon, Scissors, ChevronRight,
  Layers, Grid, Type, MoreHorizontal, AlertCircle,
  Calendar, Clock, Hash, RefreshCw, ArrowDownWideNarrow, Printer,
  Paintbrush, ArrowDownUp, ShieldAlert, PanelLeftClose
} from 'lucide-react';
import { Button } from '../ui/button';
import { Separator } from '../ui/separator';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/Tooltip';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Toolbar = ({
    handleUndo,
    handleRedo,
    handleSave,
    handleUpload,
    handleDownload,
    handleNew,
    handleTextFormat,
    handleAlignment,
    handleNumberFormat,
    data,
    columns,
    tableName,
    handleCut,
    handleCopy,
    handlePaste,
    handleDelete,
    handleFormatPainter,
    handleClearFormatting,
    handleSort,
    handleFilter,
    handleMergeCells,
    handleUnmergeCells,
    handleConditionalFormatting,
    handleAddSheet,
    handleDeleteSheet,
    handleRenameSheet,
    handleProtectSheet,
    // New handlers
    handleExportPDF,
    handleImportData,
    handlePrint,
    handlePasteSpecial,
    handleFindReplace,
    handleGoTo,
    handleFreezePanes,
    handleFullScreen,
    handleZoom,
    handleInsertChart,
    handleInsertFunction,
    handleInsertTable,
    handleInsertPivotTable,
    handleInsertLink,
    handleInsertImage,
    handleInsertComment,
    handleCellStyles,
    handleTextWrap,
    handleCustomSort,
    handleRemoveDuplicates,
    handleDataValidation,
    handleTextToColumns,
    handleConsolidate,
    handleSpellCheck,
    handleShare,
    handleAnalyzeData,
    handleGenerateChart,
    handleCreateDashboard,
    handleSuggestFormulas,
    handleSettings,
    // State props
    formulaValue,
    setFormulaValue,
    canUndo,
    canRedo,
    isBold,
    setIsBold,
    isItalic,
    setIsItalic,
    isUnderline,
    setIsUnderline,
    textAlignment,
    setTextAlignment,
    numberFormat,
    setNumberFormat,
    isProtected,
    setIsProtected,
    showFormulas,
    setShowFormulas,
    showGridLines,
    setShowGridLines,
    showHeaders,
    setShowHeaders,
    selectedCell,
    cellFormats,
    activeCells,
    isEditing,
    sheetName
  }) => {
    
    const fileInputRef = useRef(null);
    // Add this function at the top of Toolbar component
  const getSelectedCellFormat = () => {
    if (!selectedCell) return {};
    return cellFormats[selectedCell] || {};
  };

  // Update button states based on selected cell
  const selectedFormat = getSelectedCellFormat();
  useEffect(() => {
    if (selectedCell) {
      setIsBold(!!selectedFormat.bold);
      setIsItalic(!!selectedFormat.italic);
      setIsUnderline(!!selectedFormat.underline);
      setTextAlignment(selectedFormat.alignment || 'left');
    } else {
      setIsBold(false);
      setIsItalic(false);
      setIsUnderline(false);
      setTextAlignment('left');
    }
  }, [selectedCell, selectedFormat]);
  
  const fileMenuItems = [
    { label: 'New', action: handleNew, shortcut: '⌘N' },
    { label: 'Open...', action: () => document.getElementById('fileInput').click(), shortcut: '⌘O' },
    { label: 'Save', action: handleSave, shortcut: '⌘S' },
    { 
      label: 'Save As...',
      shortcut: '⌘⇧S',
      submenu: [
        { label: 'Excel (.xlsx)', action: () => handleSave('xlsx') },
        { label: 'CSV (.csv)', action: () => handleSave('csv') },
        
      ]
    },
    { label: 'Print', action: handlePrint, shortcut: '⌘P' }
  ];
  const editMenuItems = [
    { label: 'Cut', action: handleCut, shortcut: '⌘X' },
    { label: 'Copy', action: handleCopy, shortcut: '⌘C' },
    { label: 'Paste', action: handlePaste, shortcut: '⌘V' },
    { label: 'Paste Special...', action: handlePasteSpecial, shortcut: '⌘⌥V' },
    { label: 'Clear Contents', action: handleDelete, shortcut: 'Delete' },
    { label: 'Clear Formatting', action: handleClearFormatting },
    { label: 'Find and Replace', action: handleFindReplace, shortcut: '⌘F' },
    { label: 'Go To...', action: handleGoTo, shortcut: '⌘G' }
  ];
  
  const viewMenuItems = [
    { label: 'Show Formulas', action: () => setShowFormulas(!showFormulas), shortcut: '⌘`' },
    { label: 'Freeze Panes', submenu: [
      { label: 'Freeze Top Row', action: () => handleFreezePanes('topRow') },
      { label: 'Freeze First Column', action: () => handleFreezePanes('firstColumn') },
      { label: 'Freeze Current Selection', action: handleFreezePanes }
    ]},
    { label: 'Grid Lines', action: () => setShowGridLines(!showGridLines) },
    { label: 'Headers', action: () => setShowHeaders(!showHeaders) },
    { label: 'Full Screen', action: handleFullScreen, shortcut: 'F11' },
    { label: 'Zoom', submenu: [
      { label: '50%', action: () => handleZoom(0.5) },
      { label: '75%', action: () => handleZoom(0.75) },
      { label: '100%', action: () => handleZoom(1) },
      { label: '125%', action: () => handleZoom(1.25) },
      { label: '150%', action: () => handleZoom(1.5) }
    ]}
  ];
  
  const insertMenuItems = [
    { label: 'New Sheet', action: handleAddSheet, shortcut: '⇧F11' },
    { label: 'Chart', submenu: [
      { label: 'Bar Chart', action: () => handleInsertChart('bar') },
      { label: 'Line Chart', action: () => handleInsertChart('line') },
      { label: 'Pie Chart', action: () => handleInsertChart('pie') },
      { label: 'Scatter Plot', action: () => handleInsertChart('scatter') }
    ]},
    { label: 'Function', action: handleInsertFunction },
    { label: 'Table', action: handleInsertTable },
    { label: 'Pivot Table', action: handleInsertPivotTable },
    { label: 'Link', action: handleInsertLink, shortcut: '⌘K' },
    { label: 'Image', action: handleInsertImage },
    { label: 'Comment', action: handleInsertComment, shortcut: '⌘⇧M' }
  ];
  
  const formatMenuItems = [
    { label: 'Number Format', submenu: [
      { label: 'General', action: () => handleNumberFormat('general') },
      { label: 'Number', action: () => handleNumberFormat('number') },
      { label: 'Currency', action: () => handleNumberFormat('currency') },
      { label: 'Percentage', action: () => handleNumberFormat('percentage') },
      { label: 'Date', action: () => handleNumberFormat('date') }
    ]},
    { label: 'Conditional Formatting', action: handleConditionalFormatting },
    { label: 'Cell Styles', action: handleCellStyles },
    { label: 'Format Painter', action: handleFormatPainter },
    { label: 'Merge Cells', action: handleMergeCells },
    { label: 'Text Wrapping', action: handleTextWrap }
  ];
  
  const dataMenuItems = [
    { label: 'Sort', submenu: [
      { label: 'Sort A to Z', action: () => handleSort('asc') },
      { label: 'Sort Z to A', action: () => handleSort('desc') },
      { label: 'Custom Sort...', action: handleCustomSort }
    ]},
    { label: 'Filter', action: handleFilter },
    { label: 'Remove Duplicates', action: handleRemoveDuplicates },
    { label: 'Data Validation', action: handleDataValidation },
    { label: 'Text to Columns', action: handleTextToColumns },
    { label: 'Consolidate', action: handleConsolidate }
  ];
  
  const toolsMenuItems = [
    { label: 'Spell Check', action: handleSpellCheck },
    { label: 'Protect Sheet', action: handleProtectSheet },
    { label: 'Share', action: handleShare },
    { label: 'AI Analysis', submenu: [
      { label: 'Analyze Data', action: handleAnalyzeData },
      { label: 'Generate Chart', action: handleGenerateChart },
      { label: 'Create Dashboard', action: handleCreateDashboard },
      { label: 'Suggest Formulas', action: handleSuggestFormulas }
    ]},
    { label: 'Settings', action: handleSettings }
  ];

  return (
    <div className="flex flex-col border-b border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900">
      {/* Main Toolbar */}
      <div className="flex items-center h-12 px-2 space-x-2 border-b border-gray-200 dark:border-gray-800">


{/* File Menu */}
<DropdownMenu>
  <DropdownMenuTrigger asChild>
  <Button variant="ghost" size="sm" className="text-sm font-medium text-gray-700 dark:text-gray-300">
      File <ChevronDown className="h-3 w-3 ml-1" />
    </Button>
  </DropdownMenuTrigger>
  <DropdownMenuContent align="start" className="w-48">
    <DropdownMenuItem className="text-gray-900 dark:text-gray-100" onClick={() => handleSave('xlsx')}>
      <span>Save</span>
      <span className="text-gray-500 dark:text-gray-400 text-xs ml-auto">⌘S</span>
    </DropdownMenuItem>
    
    <DropdownMenu>
  <DropdownMenuTrigger className="w-full px-2 py-1.5 text-sm flex items-center justify-between text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-gray-800 cursor-default select-none">
    <span>Save As...</span>
    <ChevronRight className="h-4 w-4" />
  </DropdownMenuTrigger>
  <DropdownMenuContent side="right" className="w-48">
    <DropdownMenuItem 
      onClick={() => handleSave('xlsx')}
      className="text-gray-900 dark:text-gray-100"
    >
      Excel Workbook (.xlsx)
    </DropdownMenuItem>
    <DropdownMenuItem 
      onClick={() => handleSave('csv')}
      className="text-gray-900 dark:text-gray-100"
    >
      CSV File (.csv)
    </DropdownMenuItem>
    <DropdownMenuItem 
      onClick={async () => {
        const element = document.querySelector('table');
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
        });
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width/2, canvas.height/2]
        });
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
        pdf.save('spreadsheet.pdf');
      }}
      className="text-gray-900 dark:text-gray-100"
    >
      PDF File (.pdf)
    </DropdownMenuItem>
  </DropdownMenuContent>
</DropdownMenu>

    <DropdownMenuItem className="text-gray-900 dark:text-gray-100" onClick={() => document.getElementById('fileInput').click()}>
      <span>Open...</span>
      <span className="text-gray-500 dark:text-gray-400 text-xs ml-auto">⌘O</span>
    </DropdownMenuItem>
    
    <DropdownMenuItem className="text-gray-900 dark:text-gray-100" onClick={handlePrint}>
      <span>Print</span>
      <span className="text-gray-500 dark:text-gray-400 text-xs ml-auto">⌘P</span>
    </DropdownMenuItem>
  </DropdownMenuContent>
</DropdownMenu>

{/* Add Print Button */}
<TooltipProvider>
  <Tooltip>
    <TooltipTrigger asChild>
      <Button 
        variant="ghost" 
        size="icon" 
        className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
        onClick={handlePrint}
      >
        <Printer className="h-4 w-4" />
      </Button>
    </TooltipTrigger>
    <TooltipContent>Print Spreadsheet (⌘P)</TooltipContent>
  </Tooltip>
</TooltipProvider>

<Separator orientation="vertical" className="h-6" />

        {/* Clipboard Group */}
        <div className="flex items-center space-x-1">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  className="hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300"
                  onClick={handleCut}
                >
                  <Scissors className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Cut (⌘X)</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  className="hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300"
                  onClick={handleCopy}
                >
                  <Copy className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Copy (⌘C)</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          
        </div>

        <Separator orientation="vertical" className="h-6" />

        {/* Font Formatting Group */}
        <div className="flex items-center space-x-1">
          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              isBold ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setIsBold(!isBold);
              handleTextFormat('bold');
            }}
          >
            <Bold className="h-4 w-4" />
          </Button>

          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              isItalic ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setIsItalic(!isItalic);
              handleTextFormat('italic');
            }}
          >
            <Italic className="h-4 w-4" />
          </Button>

          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              isUnderline ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setIsUnderline(!isUnderline);
              handleTextFormat('underline');
            }}
          >
            <Underline className="h-4 w-4" />
          </Button>
        </div>

        <Separator orientation="vertical" className="h-6" />

        {/* Alignment Group */}
        <div className="flex items-center space-x-1">
          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              textAlignment === 'left' ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setTextAlignment('left');
              handleAlignment('left');
            }}
          >
            <AlignLeft className="h-4 w-4" />
          </Button>

          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              textAlignment === 'center' ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setTextAlignment('center');
              handleAlignment('center');
            }}
          >
            <AlignCenter className="h-4 w-4" />
          </Button>

          <Button 
            variant="ghost" 
            size="sm" 
            className={`text-sm hover:bg-gray-100 dark:hover:bg-gray-800 ${
              textAlignment === 'right' ? 'bg-gray-200 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => {
              setTextAlignment('right');
              handleAlignment('right');
            }}
          >
            <AlignRight className="h-4 w-4" />
          </Button>
        </div>

        <Separator orientation="vertical" className="h-6" />


        {/* Menu Bar */}
<div className="flex-1 flex justify-center space-x-1">
  {/* File Menu */}
  

          
        </div>

        {/* Right Side Tools */}
        <div className="flex items-center space-x-2">
          {/* File Operations */}
          {/* File Operations */}
{/* File Operations */}
<div>
  <input
    type="file"
    id="fileInput"
    accept=".xlsx,.xls,.csv"
    onChange={handleUpload}
    style={{ display: 'none' }}
    ref={fileInputRef}
  />
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Button 
          variant="ghost" 
          size="icon" 
          className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
          onClick={() => fileInputRef.current?.click()}
        >
          <Upload className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Upload Spreadsheet</TooltipContent>
    </Tooltip>
  </TooltipProvider>
</div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  className="text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
                  onClick={handleDownload}
                >
                  <Download className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Download Spreadsheet</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <Separator orientation="vertical" className="h-6" />

        
        </div>
      </div>

      
    </div>
  );
};

export default Toolbar;