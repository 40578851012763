import React, { useState } from 'react';
import { 
  Wand2,
  Settings,
  Languages,
  Palette,
  Type,
  RotateCcw,
  Copy,
  Save,
  Download,
  CheckCircle2,
  AlertCircle
} from 'lucide-react';

const ContentGenerator = () => {
  const [formData, setFormData] = useState({
    topic: '',
    keywords: '',
    toneOfVoice: 'professional',
    language: 'english',
    wordCount: '1000',
    contentType: 'blog'
  });

  const [generatedContent, setGeneratedContent] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // In handleGenerate function in ContentGenerator.js
const handleGenerate = async (e) => {
  e.preventDefault();
  setIsGenerating(true);
  setError('');
  
  try {
    const token = localStorage.getItem('token'); // Add this line
    const response = await fetch('https://api.thecontentgpt.com/api/proxy/anthropic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add this line
        'anthropic-version': '2023-06-01'
      },
      body: JSON.stringify(/* your request body */)
    });

    // Rest of your code remains the same...
  } catch (error) {
    setError('Failed to generate content. Please try again.');
  } finally {
    setIsGenerating(false);
  }
};

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedContent);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Input Form */}
          <div>
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Create New Content</h2>
              
              <form onSubmit={handleGenerate} className="space-y-6">
                {/* Topic Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    What would you like to write about?
                  </label>
                  <textarea
                    name="topic"
                    rows={3}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your topic or paste your brief here..."
                    value={formData.topic}
                    onChange={handleChange}
                  />
                </div>

                {/* Keywords */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Target Keywords (optional)
                  </label>
                  <input
                    type="text"
                    name="keywords"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="e.g., SEO, content marketing, digital strategy"
                    value={formData.keywords}
                    onChange={handleChange}
                  />
                </div>

                {/* Content Type */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Content Type
                  </label>
                  <select
                    name="contentType"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    value={formData.contentType}
                    onChange={handleChange}
                  >
                    <option value="blog">Blog Post</option>
                    <option value="article">Article</option>
                    <option value="social">Social Media Post</option>
                    <option value="email">Email Newsletter</option>
                    <option value="product">Product Description</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* Tone of Voice */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Tone of Voice
                    </label>
                    <select
                      name="toneOfVoice"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      value={formData.toneOfVoice}
                      onChange={handleChange}
                    >
                      <option value="professional">Professional</option>
                      <option value="casual">Casual</option>
                      <option value="friendly">Friendly</option>
                      <option value="authoritative">Authoritative</option>
                      <option value="humorous">Humorous</option>
                    </select>
                  </div>

                  {/* Language */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Language
                    </label>
                    <select
                      name="language"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      value={formData.language}
                      onChange={handleChange}
                    >
                      <option value="english">English</option>
                      <option value="spanish">Spanish</option>
                      <option value="french">French</option>
                      <option value="german">German</option>
                      <option value="italian">Italian</option>
                    </select>
                  </div>

                  {/* Word Count */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Word Count
                    </label>
                    <select
                      name="wordCount"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      value={formData.wordCount}
                      onChange={handleChange}
                    >
                      <option value="500">500 words</option>
                      <option value="1000">1000 words</option>
                      <option value="1500">1500 words</option>
                      <option value="2000">2000 words</option>
                      <option value="3000">3000 words</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isGenerating}
                  className={`w-full flex items-center justify-center px-4 py-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    isGenerating ? 'opacity-75 cursor-not-allowed' : ''
                  }`}
                >
                  <Wand2 className="h-5 w-5 mr-2" />
                  {isGenerating ? 'Generating...' : 'Generate Content'}
                </button>
              </form>
            </div>
          </div>

          {/* Right Column - Generated Content */}
          <div>
            <div className="bg-white rounded-xl shadow-sm h-full">
              {/* Toolbar */}
              <div className="border-b border-gray-200 p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-900">Generated Content</h3>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => setGeneratedContent('')}
                      className="p-2 text-gray-400 hover:text-gray-600"
                      title="Reset"
                    >
                      <RotateCcw className="h-5 w-5" />
                    </button>
                    <button
                      onClick={handleCopy}
                      className="p-2 text-gray-400 hover:text-gray-600"
                      title="Copy to clipboard"
                    >
                      {copySuccess ? (
                        <CheckCircle2 className="h-5 w-5 text-green-500" />
                      ) : (
                        <Copy className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      className="p-2 text-gray-400 hover:text-gray-600"
                      title="Save draft"
                    >
                      <Save className="h-5 w-5" />
                    </button>
                    <button
                      className="p-2 text-gray-400 hover:text-gray-600"
                      title="Download"
                    >
                      <Download className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Content Area */}
              <div className="p-6">
                {error ? (
                  <div className="flex items-center text-red-600 mb-4">
                    <AlertCircle className="h-5 w-5 mr-2" />
                    {error}
                  </div>
                ) : null}
                
                {generatedContent ? (
                  <div className="prose max-w-none">
                    {generatedContent}
                  </div>
                ) : (
                  <div className="text-center text-gray-500 py-12">
                    <Wand2 className="h-12 w-12 mx-auto mb-4 opacity-50" />
                    <p>Your generated content will appear here</p>
                    <p className="text-sm">Configure your options and click "Generate Content" to begin</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentGenerator;