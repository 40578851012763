import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Code2, Copy, Check, Info, ArrowRight, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const JavaScriptBeautifier = () => {
  const [code, setCode] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [mode, setMode] = useState('beautify');
  const [copied, setCopied] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  // Beautification settings
  const [settings, setSettings] = useState({
    indentSize: 2,
    useTabs: false,
    bracketSpacing: true,
    singleQuotes: true,
  });

  const beautifyCode = (code) => {
    try {
      let result = code;
      
      // Basic formatting
      result = result
        // Remove multiple empty lines
        .replace(/\n\s*\n\s*\n/g, '\n\n')
        // Add spacing around operators
        .replace(/([+\-*/%=&|<>!])?=(?!=)/g, ' $1= ')
        // Add spacing around binary operators
        .replace(/([+\-*/%<>])/g, ' $1 ')
        // Fix spacing after commas
        .replace(/,([^\s])/g, ', $1')
        // Fix spacing after semicolons
        .replace(/;([^\s\n])/g, '; $1')
        // Add newline after semicolons in appropriate contexts
        .replace(/;(?!\s*[\]\}\)])/g, ';\n')
        // Indent within brackets
        .replace(/\{(?!\s*\n)/g, '{\n')
        .replace(/([^\s])\}/g, '$1\n}');

      // Apply indentation
      const lines = result.split('\n');
      let indent = 0;
      result = lines
        .map(line => {
          line = line.trim();
          if (line.match(/[}\]\)]/)) indent--;
          const spacing = settings.useTabs 
            ? '\t'.repeat(Math.max(0, indent))
            : ' '.repeat(Math.max(0, indent * settings.indentSize));
          if (line.match(/[{\[\(]/)) indent++;
          return spacing + line;
        })
        .join('\n');

      // Handle quotes
      if (settings.singleQuotes) {
        result = result.replace(/"/g, "'");
      }

      // Handle bracket spacing
      if (settings.bracketSpacing) {
        result = result
          .replace(/\{(\S)/g, '{ $1')
          .replace(/(\S)\}/g, '$1 }');
      }

      return result;
    } catch (err) {
      throw new Error('Invalid JavaScript code');
    }
  };

  const minifyCode = (code) => {
    try {
      return code
        .replace(/\/\*.*?\*\/|\/\/.*/g, '') // Remove comments
        .replace(/\s+/g, ' ') // Collapse whitespace
        .replace(/;\s+/g, ';') // Remove space after semicolon
        .replace(/{\s+/g, '{') // Remove space after bracket
        .replace(/}\s+/g, '}') // Remove space before bracket
        .replace(/,\s+/g, ',') // Remove space after comma
        .replace(/\s+=/g, '=') // Remove space before equals
        .replace(/=\s+/g, '=') // Remove space after equals
        .trim();
    } catch (err) {
      throw new Error('Invalid JavaScript code');
    }
  };

  const handleProcess = useCallback(() => {
    if (!code.trim()) return;

    setIsProcessing(true);
    setError('');

    setTimeout(() => {
      try {
        const processed = mode === 'beautify' 
          ? beautifyCode(code)
          : minifyCode(code);
        setOutputCode(processed);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsProcessing(false);
      }
    }, 500);
  }, [code, mode, settings]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(outputCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [outputCode]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Beautify or minify your JavaScript code instantly. No data is sent to any server - 
        all processing happens right in your browser.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => setMode('beautify')}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              mode === 'beautify'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
          >
            Beautify
          </button>
          <button
            onClick={() => setMode('minify')}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              mode === 'minify'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
          >
            Minify
          </button>
        </div>

        {mode === 'beautify' && (
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={settings.useTabs}
                  onChange={(e) => setSettings({...settings, useTabs: e.target.checked})}
                  className="rounded border-gray-700 bg-gray-800"
                />
                <span className="text-sm">Use Tabs</span>
              </label>
            </div>
            <div>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={settings.bracketSpacing}
                  onChange={(e) => setSettings({...settings, bracketSpacing: e.target.checked})}
                  className="rounded border-gray-700 bg-gray-800"
                />
                <span className="text-sm">Bracket Spacing</span>
              </label>
            </div>
            <div>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={settings.singleQuotes}
                  onChange={(e) => setSettings({...settings, singleQuotes: e.target.checked})}
                  className="rounded border-gray-700 bg-gray-800"
                />
                <span className="text-sm">Use Single Quotes</span>
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <label className="text-sm">Indent Size:</label>
              <select
                value={settings.indentSize}
                onChange={(e) => setSettings({...settings, indentSize: Number(e.target.value)})}
                className="bg-gray-800 border border-gray-700 rounded p-1"
              >
                {[2, 4, 8].map(size => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </select>
            </div>
          </div>
        )}

        <textarea
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Paste your JavaScript code here..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono"
        />
        
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {code.length} characters
          </span>
          <button
            onClick={handleProcess}
            disabled={!code.trim() || isProcessing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isProcessing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Processing...</span>
              </>
            ) : (
              <>
                <Code2 className="h-4 w-4" />
                <span>{mode === 'beautify' ? 'Beautify' : 'Minify'}</span>
              </>
            )}
          </button>
        </div>
      </div>

      {error && (
        <div className="text-red-500 bg-red-500/10 border border-red-500/20 rounded-lg p-4">
          {error}
        </div>
      )}

      {outputCode && !error && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative"
        >
          <pre className="w-full p-4 bg-gray-800 border border-gray-700 rounded-xl text-white overflow-x-auto font-mono">
            {outputCode}
          </pre>
          <button
            onClick={handleCopy}
            className="absolute top-4 right-4 p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
          >
            {copied ? (
              <Check className="h-4 w-4 text-green-500" />
            ) : (
              <Copy className="h-4 w-4" />
            )}
          </button>
        </motion.div>
      )}

      <InfoMessage />

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Level Up Your Content Creation
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to advanced content creation tools, AI-powered writing assistance, and premium features. Sign up now and receive 1000 free points to explore our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get Started Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default JavaScriptBeautifier;