import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiService from '../services/apiService';
import SEOHead from '../components/SEOHead';
import { 
  Calendar,
  Clock, 
  Tag,
  ChevronUp,
  Facebook,
  Twitter,
  Linkedin,
  Link as LinkIcon,
  ArrowRight
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [tableOfContents, setTableOfContents] = useState([]);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        setError('');
        const response = await fetch(`${apiService.getUrl('/api/blog/posts')}/${slug}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
          mode: 'cors'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch post');
        }

        const data = await response.json();
        if (!data) {
          throw new Error('Post not found');
        }

        setPost(data);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Failed to load post. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  useEffect(() => {
    if (post?.content) {
      const headings = post.content.match(/#{1,3} .+/g) || [];
      const toc = headings.map(heading => {
        const level = (heading.match(/^#+/) || [''])[0].length;
        const title = heading.replace(/^#+\s*/, '');
        const id = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return { level, title, id };
      });
      setTableOfContents(toc);
    }
  }, [post?.content]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('h1, h2, h3').forEach(
      heading => observer.observe(heading)
    );

    return () => observer.disconnect();
  }, [post]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const calculateReadTime = (content) => {
    const wordsPerMinute = 200;
    const words = content.split(/\s+/).length;
    return Math.ceil(words / wordsPerMinute);
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-black">
        <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        <div className="max-w-3xl mx-auto px-4 py-8">
          <div className="text-center text-red-400">{error}</div>
        </div>
      </div>
    );
  }

  if (!post) return null;

  const currentUrl = window.location.href;
  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(post.title)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`
  };

  return (
    <>
      <SEOHead post={post} />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        {/* Hero Section */}
        {/* Hero Section - Adjusted sizing */}
<div className="bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
  <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
  <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 relative z-10">
    <div className="text-center max-w-4xl mx-auto">
      {post.tags[0] && (
        <div className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-blue-500/10 text-blue-400 mb-4">
          <Tag className="h-4 w-4 mr-2" />
          {post.tags[0]}
        </div>
      )}
      
      <h1 className="text-3xl font-bold text-white sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 mb-4">
        {post.title}
      </h1>

      {post.excerpt && (
        <p className="mt-2 max-w-2xl mx-auto text-base text-gray-300 sm:text-lg md:text-xl mb-6">
          {post.excerpt}
        </p>
      )}

      <div className="flex items-center justify-center space-x-6 text-gray-300 text-sm md:text-base">
        <div className="flex items-center">
          <Calendar className="h-4 w-4 mr-2" />
          <span>{formatDate(post.publishDate || post.createdAt)}</span>
        </div>
        <span className="text-gray-600">•</span>
        <div className="flex items-center">
          <Clock className="h-4 w-4 mr-2" />
          <span>{calculateReadTime(post.content)} min read</span>
        </div>
      </div>

      {post.featuredImage && (
        <div className="mt-8 relative">
          <div className="relative rounded-xl overflow-hidden shadow-2xl max-h-[50vh]">
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-30" />
            <img
              src={post.featuredImage}
              alt={post.title}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}
    </div>
  </div>
</div>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Table of Contents - Desktop */}
            <div className="hidden lg:block">
              <div className="sticky top-8">
                <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg shadow-lg p-6">
                  <h3 className="font-bold text-white mb-4">
                    Table of Contents
                  </h3>
                  <nav className="space-y-2">
                    {tableOfContents.map(({ title, id, level }) => (
                      <button
                        key={id}
                        onClick={() => scrollToSection(id)}
                        className={`block text-left w-full px-2 py-1 text-sm rounded transition-colors ${
                          activeSection === id
                            ? 'text-blue-400 bg-blue-500/10'
                            : 'text-gray-300 hover:text-blue-400 hover:bg-gray-700/50'
                        }`}
                        style={{ marginLeft: `${(level - 1) * 1}rem` }}
                      >
                        {title}
                      </button>
                    ))}
                  </nav>
                </div>

                {/* Social Share */}
                <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg shadow-lg p-6 mt-6">
                  <h3 className="font-bold text-white mb-4">Share</h3>
                  <div className="flex space-x-4">
                    <a
                      href={shareUrls.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-blue-400 transition-colors"
                    >
                      <Facebook className="h-5 w-5" />
                    </a>
                    <a
                      href={shareUrls.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-blue-400 transition-colors"
                    >
                      <Twitter className="h-5 w-5" />
                    </a>
                    <a
                      href={shareUrls.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-blue-400 transition-colors"
                    >
                      <Linkedin className="h-5 w-5" />
                    </a>
                    <button 
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        alert('Link copied to clipboard!');
                      }}
                      className="text-gray-400 hover:text-blue-400 transition-colors"
                    >
                      <LinkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-3">
              <article className="bg-gray-800/50 backdrop-blur-sm rounded-xl shadow-lg p-8">
                <div className="prose prose-lg prose-invert max-w-none prose-headings:scroll-mt-20">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      h1: ({node, ...props}) => (
                        <h1 
                          id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                          className="text-3xl font-bold mt-8 mb-4 text-white" 
                          {...props} 
                        />
                      ),
                      h2: ({node, ...props}) => (
                        <h2 
                          id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                          className="text-2xl font-bold mt-6 mb-3 text-white" 
                          {...props} 
                        />
                      ),
                      h3: ({node, ...props}) => (
                        <h3 
                          id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                          className="text-xl font-bold mt-4 mb-2 text-white" 
                          {...props} 
                        />
                      ),
                      p: ({node, ...props}) => (
                        <p className="text-gray-300 leading-relaxed mb-4" {...props} />
                      ),
                      a: ({node, ...props}) => (
                        <a className="text-blue-400 hover:text-blue-300 transition-colors" {...props} />
                      ),
                      ul: ({node, ...props}) => (
                        <ul className="list-disc pl-6 my-4 space-y-2 text-gray-300" {...props} />
                      ),
                      ol: ({node, ...props}) => (
                        <ol className="list-decimal pl-6 my-4 space-y-2 text-gray-300" {...props} />
                      ),
                      blockquote: ({node, ...props}) => (
                        <blockquote className="border-l-4 border-blue-500 pl-4 my-4 italic text-gray-300" {...props} />
                      ),
                      code: ({node, ...props}) => (
                        <code className="bg-gray-900/50 rounded px-1 py-0.5 text-sm font-mono text-gray-300" {...props} />
                      ),
                      pre: ({node, ...props}) => (
                        <pre className="bg-gray-900/50 text-gray-300 rounded-lg p-4 my-4 overflow-x-auto" {...props} />
                      ),
                    }}
                  >
                    {post.content}
                  </ReactMarkdown>
                </div>

                {/* Tags */}
                <div className="mt-8 pt-8 border-t border-gray-700">
                  <div className="flex flex-wrap gap-2">
                    {post.tags.map((tag) => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-500/10 text-blue-400"
                      >
                        <Tag className="h-4 w-4 mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </article>

              {/* CTA Section */}
              <div className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden">
                <div className="relative p-8 md:p-12">
                  <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
                  <div className="relative z-10">
                    <div className="max-w-xl">
                      <h2 className="text-2xl font-bold text-white mb-4">
                        Ready to Create Amazing Content?
                      </h2>
                      <p className="text-purple-100 mb-6">
                        Get started with our AI-powered content creation tools and transform your ideas into engaging content. Sign up now and receive 1000 free points!
                      </p>
                      <Link
                        to="/register"
                        className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
                      >
                        Get 1000 Free Points
                        <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;