import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Tag, Eye, Copy, ArrowRight, Info, Check } from 'lucide-react';
import { Link } from 'react-router-dom';

const MetaTagsGenerator = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    keywords: '',
    author: '',
    imageUrl: '',
    type: 'website',
    siteName: '',
    twitterHandle: '',
  });

  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('form');

  const generateMetaTags = useCallback(() => {
    const tags = `<!-- Primary Meta Tags -->
<title>${formData.title}</title>
<meta name="title" content="${formData.title}">
<meta name="description" content="${formData.description}">
${formData.keywords ? `<meta name="keywords" content="${formData.keywords}">` : ''}
${formData.author ? `<meta name="author" content="${formData.author}">` : ''}

<!-- Open Graph / Facebook -->
<meta property="og:type" content="${formData.type}">
<meta property="og:title" content="${formData.title}">
<meta property="og:description" content="${formData.description}">
${formData.imageUrl ? `<meta property="og:image" content="${formData.imageUrl}">` : ''}
${formData.siteName ? `<meta property="og:site_name" content="${formData.siteName}">` : ''}

<!-- Twitter -->
<meta property="twitter:card" content="summary_large_image">
<meta property="twitter:title" content="${formData.title}">
<meta property="twitter:description" content="${formData.description}">
${formData.imageUrl ? `<meta property="twitter:image" content="${formData.imageUrl}">` : ''}
${formData.twitterHandle ? `<meta property="twitter:creator" content="${formData.twitterHandle}">` : ''}`;

    return tags.trim();
  }, [formData]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(generateMetaTags());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [generateMetaTags]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('form')}
            className={`px-4 py-2 rounded-lg font-medium flex items-center space-x-2 ${
              activeTab === 'form' ? 'bg-blue-500 text-white' : 'text-gray-400 hover:bg-gray-700'
            }`}
          >
            <Tag className="h-4 w-4" />
            <span>Generate Tags</span>
          </button>
          <button
            onClick={() => setActiveTab('preview')}
            className={`px-4 py-2 rounded-lg font-medium flex items-center space-x-2 ${
              activeTab === 'preview' ? 'bg-blue-500 text-white' : 'text-gray-400 hover:bg-gray-700'
            }`}
          >
            <Eye className="h-4 w-4" />
            <span>Preview</span>
          </button>
        </div>

        {activeTab === 'form' ? (
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Title*</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="Page Title"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Type</label>
                <select
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                >
                  <option value="website">Website</option>
                  <option value="article">Article</option>
                  <option value="product">Product</option>
                  <option value="profile">Profile</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Description*</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none h-24 resize-none"
                placeholder="Page description (150-160 characters recommended)"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Keywords</label>
                <input
                  type="text"
                  name="keywords"
                  value={formData.keywords}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="Comma-separated keywords"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Author</label>
                <input
                  type="text"
                  name="author"
                  value={formData.author}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="Content author"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Image URL</label>
              <input
                type="url"
                name="imageUrl"
                value={formData.imageUrl}
                onChange={handleInputChange}
                className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="https://example.com/image.jpg"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Site Name</label>
                <input
                  type="text"
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="Your website name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Twitter Handle</label>
                <input
                  type="text"
                  name="twitterHandle"
                  value={formData.twitterHandle}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="@username"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="bg-gray-900 rounded-lg p-4">
              <pre className="text-sm overflow-x-auto whitespace-pre-wrap">
                {generateMetaTags()}
              </pre>
            </div>
            <button
              onClick={handleCopy}
              className="px-4 py-2 bg-blue-500 rounded-lg font-medium hover:bg-blue-600 transition-colors flex items-center space-x-2"
            >
              {copied ? (
                <>
                  <Check className="h-4 w-4" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4" />
                  <span>Copy to Clipboard</span>
                </>
              )}
            </button>
          </div>
        )}

        <div className="mt-6 flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
          <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
          <p>
            Meta tags help search engines and social media platforms better understand and display your content. 
            For best results, ensure your title is under 60 characters and description is between 150-160 characters.
          </p>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Supercharge Your Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Generate SEO-optimized content, meta tags, and more with our AI-powered tools. Sign up now and get 1000 free points to try our premium content generation features!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MetaTagsGenerator;