import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import ReadabilityCalculator from '../../components/calculateFleschKincaid';
import {
  BookOpen,
  Type,
  Edit3,
  Layers,
  MessageCircle,
  Users,
  Compass,
  ChevronDown,
  CheckCircle,
  BookMarked,
  Feather,
  AlignLeft,
  Scissors,
  PenTool,
  FileText,
  Search,
  Smile,
  Zap,
  Eye,
  ArrowRight,
  Brain,
  Star,
  Globe,
  ScrollText,
  BarChart,
  Wand2
} from 'lucide-react';

const ReadabilityImproverLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    setIsVisible(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const readabilityMetrics = {
    before: {
      fleschScore: 43,
      comprehension: "25%",
      engagement: "Low",
      readingTime: "8.5 min"
    },
    after: {
      fleschScore: 82,
      comprehension: "95%",
      engagement: "High",
      readingTime: "3.2 min"
    }
  };
  const faqs = [
    {
      question: "How does the AI readability analyzer work?",
      answer: "Our AI uses advanced natural language processing to analyze text structure, complexity, and coherence. It considers factors like sentence length, word choice, and paragraph flow to provide comprehensive readability metrics and suggestions for improvement."
    },
    {
      question: "Will it maintain my writing style?",
      answer: "Yes! Our AI is designed to preserve your unique voice and style while improving clarity. It focuses on enhancing readability without altering your intended message or tone."
    },
    {
      question: "What readability metrics are used?",
      answer: "We analyze multiple metrics including Flesch-Kincaid Grade Level, SMOG Index, Coleman-Liau Index, and more. Each piece of content receives a comprehensive readability score based on these industry-standard measurements."
    },
    {
      question: "Can I use it for different types of content?",
      answer: "Absolutely! Whether you're writing technical documentation, marketing copy, educational content, or any other type of text, our AI adapts its analysis and suggestions to match your specific content type and target audience."
    },
    {
      question: "What languages are supported?",
      answer: "Currently, we support 30+ languages including English, Spanish, French, German, Chinese, Japanese, and more. Each language has specialized algorithms optimized for its unique linguistic characteristics."
    },
    {
      question: "How accurate is the AI analysis?",
      answer: "Our AI has been trained on millions of documents and achieves a 95%+ accuracy rate in readability assessment, verified against human expert evaluations. The system continuously learns and improves from user feedback."
    }
  ];
  return (
    <>
      <SEOHead 
        title="AI Readability Checker & Optimizer | Improve Text Clarity Instantly"
        description="Transform complex text into clear, engaging content with our AI readability checker. Get instant scores, suggestions, and improvements. Try free today!"
        keywords="readability checker, text optimizer, content clarity, readability score, AI writing assistant, Flesch-Kincaid score"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          {/* Animated particles */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-blue-500/20 rounded-full"
                style={{
                  width: Math.random() * 50 + 10,
                  height: Math.random() * 50 + 10,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            ))}
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              AI Readability
              <span className="block">Revolution</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform complex content into crystal-clear communication with AI-powered readability optimization
            </motion.p>

            <motion.div 
            className="flex flex-col sm:flex-row gap-4 justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <Link 
              to="/register" 
              className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
            >
              Start Optimizing Free
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Link>
            
            <button
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('#live-demo-section')?.scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }}
              className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
            >
              Try Live Demo
            </button>
          </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">50M+</div>
                <div className="text-gray-400">Words Optimized</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">89%</div>
                <div className="text-gray-400">Clarity Improvement</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </motion.div>
          </motion.div>

          {/* Scroll Indicator */}
          <motion.div 
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
            animate={{ y: [0, 10, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <div className="w-6 h-10 border-2 border-white/30 rounded-full flex justify-center">
              <div className="w-1 h-3 bg-white/60 rounded-full mt-2" />
            </div>
          </motion.div>
        </section>

{/* Interactive Demo Section */}
<section id="live-demo-section" className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
  <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
  
  <div className="max-w-7xl mx-auto px-4 relative z-10">
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="text-center mb-16"
    >
      <h2 className="text-4xl md:text-5xl font-bold mb-4">
        Experience the
        <span className="text-blue-400"> Transformation</span>
      </h2>
      <p className="text-xl text-gray-400">
        Watch your text transform from complex to crystal clear in real-time
      </p>
    </motion.div>

    <ReadabilityCalculator />
  </div>
</section>

        {/* AI Features Showcase */}
        <section className="py-24 bg-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-xl text-gray-400">
                Experience the future of content optimization
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: Brain,
                  title: "Neural Text Analysis",
                  description: "Advanced AI algorithms analyze your text's structure, complexity, and coherence in real-time",
                  color: "blue"
                },
                {
                  icon: Wand2,
                  title: "Smart Suggestions",
                  description: "Get intelligent recommendations for improving clarity while maintaining meaning",
                  color: "purple"
                },
                {
                  icon: BarChart,
                  title: "Readability Metrics",
                  description: "Comprehensive scoring using multiple readability formulas and engagement metrics",
                  color: "green"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl group hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className={`w-16 h-16 rounded-full bg-${feature.color}-500/20 flex items-center justify-center mb-6 group-hover:bg-${feature.color}-500/30 transition-all`}>
                    <feature.icon className={`h-8 w-8 text-${feature.color}-400`} />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Floating Statistics */}
        <motion.div
          className="fixed bottom-8 right-8 bg-gray-800/90 backdrop-blur-sm rounded-2xl p-4 shadow-lg z-50"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2 }}
        >
          <div className="flex items-center space-x-6">
            <div>
              <div className="text-sm text-gray-400">Words Processed</div>
              <div className="text-xl font-bold text-blue-400">
                {Math.floor(scrollY * 10).toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Active Users</div>
              <div className="text-xl font-bold text-green-400">1,234</div>
            </div>
          </div>
        </motion.div>
{/* Use Cases Section */}
<section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Transforming Content Across Industries
              </h2>
              <p className="text-xl text-gray-400">
                See how different sectors achieve clarity with AI
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  industry: "Technical Documentation",
                  icon: FileText,
                  metrics: ["47% better comprehension", "62% faster documentation"],
                  description: "Transform complex technical specs into clear documentation"
                },
                {
                  industry: "Educational Content",
                  icon: BookOpen,
                  metrics: ["85% student engagement", "33% better retention"],
                  description: "Create accessible learning materials for all levels"
                },
                {
                  industry: "Marketing Copy",
                  icon: MessageCircle,
                  metrics: ["58% higher conversion", "41% longer engagement"],
                  description: "Craft compelling copy that converts and engages"
                }
              ].map((useCase, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="bg-blue-500/20 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                    <useCase.icon className="h-8 w-8 text-blue-400" />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{useCase.industry}</h3>
                  <p className="text-gray-400 mb-6">{useCase.description}</p>
                  <div className="space-y-3">
                    {useCase.metrics.map((metric, i) => (
                      <div key={i} className="flex items-center text-green-400">
                        <CheckCircle className="h-5 w-5 mr-2" />
                        <span>{metric}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Advanced Features Showcase */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
            <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent" />
            <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-purple-500/50 to-transparent" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Advanced Capabilities
              </h2>
              <p className="text-xl text-gray-400">
                Cutting-edge features powered by next-gen AI
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Real-time Analysis",
                  description: "Instant feedback as you type with live suggestions",
                  icon: Zap,
                  features: ["Live scoring", "Instant suggestions", "Dynamic feedback"]
                },
                {
                  title: "Multi-language Support",
                  description: "Optimize content across 30+ languages",
                  icon: Globe,
                  features: ["Native analysis", "Cultural context", "Local idioms"]
                },
                {
                  title: "Style Maintenance",
                  description: "Preserve your voice while improving clarity",
                  icon: PenTool,
                  features: ["Tone consistency", "Brand voice", "Style guides"]
                },
                {
                  title: "SEO Integration",
                  description: "Optimize for both readers and search engines",
                  icon: Search,
                  features: ["Keyword balance", "Readability SEO", "Rich snippets"]
                },
                {
                  title: "Team Collaboration",
                  description: "Work together on content optimization",
                  icon: Users,
                  features: ["Shared projects", "Team analytics", "Style sharing"]
                },
                {
                  title: "Advanced Analytics",
                  description: "Deep insights into content performance",
                  icon: BarChart,
                  features: ["Engagement metrics", "Improvement tracking", "ROI analysis"]
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 hover:bg-gray-800/70 transition-all group"
                >
                  <feature.icon className="h-8 w-8 text-blue-400 mb-4 group-hover:text-blue-300 transition-colors" />
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400 mb-6">{feature.description}</p>
                  <ul className="space-y-2">
                    {feature.features.map((item, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mr-2" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
{/* Integration Showcase */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Seamless Integrations
              </h2>
              <p className="text-xl text-gray-400">
                Works with your favorite tools and platforms
              </p>
            </motion.div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                "Google Docs", "WordPress", "Microsoft Word", "Notion",
                "Medium", "Ghost", "Webflow", "VS Code"
              ].map((platform, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-800/70 transition-all text-center"
                >
                  <ScrollText className="h-8 w-8 text-blue-400 mx-auto mb-3" />
                  <span className="text-gray-300">{platform}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Loved by Content Teams
              </h2>
              <p className="text-xl text-gray-400">
                See what our users have to say
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "Transformed our technical documentation. Our docs are now more accessible than ever.",
                  author: "Sarah Chen",
                  role: "Technical Writer",
                  company: "TechCorp"
                },
                {
                  quote: "The AI suggestions are incredible. Our content engagement has improved by 300%.",
                  author: "Mike Johnson",
                  role: "Content Manager",
                  company: "Growth Labs"
                },
                {
                  quote: "Perfect for ensuring consistent clarity across our global content team.",
                  author: "Emma Wilson",
                  role: "Editorial Director",
                  company: "Global Media"
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-8 rounded-2xl"
                >
                  <div className="flex items-center mb-6">
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                      ))}
                    </div>
                  </div>
                  <p className="text-gray-300 mb-6">"{testimonial.quote}"</p>
                  <div>
                    <div className="font-semibold">{testimonial.author}</div>
                    <div className="text-sm text-gray-400">{testimonial.role}</div>
                    <div className="text-sm text-gray-400">{testimonial.company}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-3xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Common Questions
              </h2>
              <p className="text-xl text-gray-400">
                Everything you need to know
              </p>
            </motion.div>

            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => toggleFaq(index)}
                    className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-800/70 transition-all"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown 
                      className={`h-5 w-5 transform transition-transform ${
                        openFaq === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-6">
                      <p className="text-gray-400">{faq.answer}</p>
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
            />
          </div>
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your Content?
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of writers and content teams creating clearer, more engaging content
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Start Improving Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Pricing
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">
                No credit card required · 14-day free trial
              </p>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReadabilityImproverLanding;