import React, { useRef } from 'react';
import { TrendingUp, Download, Calendar, Zap } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, ComposedChart } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const aiForecastingConfig = {
  id: 'ai-forecasting',
  label: 'AI Forecasting',
  icon: <TrendingUp className="w-4 h-4" />,
  description: 'Advanced AI-powered forecasting and predictions',
};

export const createPrompt = (data, columns) => {
  // Get date and numeric columns for analysis
  const dateColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return !isNaN(Date.parse(sampleValue));
  });

  const numericColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return typeof sampleValue === 'number';
  });

  const tablePreview = JSON.stringify(data);

  const prompt = `Analyze this dataset and provide comprehensive AI-powered forecasting analysis in this exact JSON format:

{
  "timeSeriesForecasts": {
    "short_term": {
      "next_30_days": [
        {"date": "YYYY-MM-DD", "value": number, "lower_bound": number, "upper_bound": number}
      ],
      "confidence": number
    },
    "medium_term": {
      "next_90_days": [
        {"date": "YYYY-MM-DD", "value": number, "lower_bound": number, "upper_bound": number}
      ],
      "confidence": number
    },
    "long_term": {
      "next_180_days": [
        {"date": "YYYY-MM-DD", "value": number, "lower_bound": number, "upper_bound": number}
      ],
      "confidence": number
    }
  },
  "seasonalityAnalysis": {
    "yearly": {
      "pattern": "description",
      "strength": number,
      "peaks": ["Month1", "Month2"],
      "troughs": ["Month1", "Month2"]
    },
    "monthly": {
      "pattern": "description",
      "strength": number,
      "peaks": ["Day1", "Day2"],
      "troughs": ["Day1", "Day2"]
    }
  },
  "growthMetrics": {
    "historical": {
      "cagr": number,
      "volatility": number,
      "trend_strength": number
    },
    "projected": {
      "cagr": number,
      "volatility": number,
      "trend_strength": number
    }
  },
  "anomalyDetection": {
    "historical": [
      {"date": "YYYY-MM-DD", "value": number, "significance": "high|medium|low"}
    ],
    "projected": [
      {"date": "YYYY-MM-DD", "value": number, "significance": "high|medium|low"}
    ]
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ],
  "recommendations": [
    "actionable recommendation 1",
    "actionable recommendation 2"
  ]
}

Available Date Columns:
${dateColumns.map(col => col.Header || col.id).join(', ')}

Available Numeric Columns:
${numericColumns.map(col => col.Header || col.id).join(', ')}

Full Dataset:
${tablePreview}

Provide sophisticated forecasting analysis using machine learning techniques. Account for seasonality, trends, and cyclical patterns. Detect anomalies and provide actionable insights.`;

  return prompt;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);

    return {
      timeSeriesForecasts: parsedJson.timeSeriesForecasts || {},
      seasonalityAnalysis: parsedJson.seasonalityAnalysis || {},
      growthMetrics: parsedJson.growthMetrics || {},
      anomalyDetection: parsedJson.anomalyDetection || {},
      insights: parsedJson.insights || [],
      recommendations: parsedJson.recommendations || []
    };

  } catch (error) {
    return {
      timeSeriesForecasts: {},
      seasonalityAnalysis: {},
      growthMetrics: {},
      anomalyDetection: {},
      insights: ['Failed to parse analysis results: ' + error.message],
      recommendations: []
    };
  }
};

export const AIForecastingResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const { timeSeriesForecasts, seasonalityAnalysis, growthMetrics, anomalyDetection, insights, recommendations } = results;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('ai-forecasting-analysis.pdf');
  };

  // Transform forecast data for charts
  const chartData = timeSeriesForecasts.short_term?.next_30_days.map(point => ({
    date: point.date,
    forecast: point.value,
    lowerBound: point.lower_bound,
    upperBound: point.upper_bound
  })) || [];

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Forecast Visualization */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg mb-6">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Forecast Visualization</h4>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="upperBound"
                  fill="#4ade8050"
                  stroke="none"
                />
                <Area
                  type="monotone"
                  dataKey="lowerBound"
                  fill="#4ade8030"
                  stroke="none"
                />
                <Line
                  type="monotone"
                  dataKey="forecast"
                  stroke="#4ade80"
                  strokeWidth={2}
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Forecast Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {Object.entries(timeSeriesForecasts).map(([term, data]) => (
            <div key={term} className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
              <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 capitalize">
                {term.replace('_', ' ')} Forecast
              </h5>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-gray-500 dark:text-gray-400">Confidence:</span>
                  <span className="font-medium text-green-600 dark:text-green-400">
                    {data.confidence}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Growth Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {Object.entries(growthMetrics).map(([period, metrics]) => (
            <div key={period} className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
              <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 capitalize">
                {period} Growth Metrics
              </h5>
              <div className="space-y-3">
                {Object.entries(metrics).map(([metric, value]) => (
                  <div key={metric} className="flex justify-between items-center">
                    <span className="text-gray-500 dark:text-gray-400 capitalize">
                      {metric.replace('_', ' ')}:
                    </span>
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {typeof value === 'number' ? `${value.toFixed(2)}%` : value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Seasonality Analysis */}
        <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Seasonality Analysis
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Object.entries(seasonalityAnalysis).map(([period, analysis]) => (
              <div key={period} className="bg-white dark:bg-gray-900 p-4 rounded-lg">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 capitalize">
                  {period} Patterns
                </h5>
                <div className="space-y-3">
                  <div className="text-gray-700 dark:text-gray-300">
                    <span className="font-medium">Pattern:</span> {analysis.pattern}
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-500 dark:text-gray-400">Strength:</span>
                    <span className="font-medium text-green-600 dark:text-green-400">
                      {analysis.strength}%
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-500 dark:text-gray-400">Peak Periods:</span>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {analysis.peaks.map((peak, i) => (
                        <span key={i} className="px-2 py-1 bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full text-sm">
                          {peak}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Insights and Recommendations */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Insights */}
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
              Key Insights
            </h4>
            <ul className="space-y-3">
              {insights.map((insight, index) => (
                <li 
                  key={index}
                  className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 font-medium">
                    {index + 1}
                  </span>
                  <span>{insight}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Recommendations */}
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
              Recommendations
            </h4>
            <ul className="space-y-3">
              {recommendations.map((recommendation, index) => (
                <li 
                  key={index}
                  className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                    {index + 1}
                  </span>
                  <span>{recommendation}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default {
  config: aiForecastingConfig,
  createPrompt,
  parseResults,
  ResultsComponent: AIForecastingResults
};