import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  ChevronDown, 
  FileText, 
  Layout, 
  Image, 
  MessageSquare,
  RefreshCcw,
  Wand2,
  Menu,
  X,
  Settings,
  Shield,
  Sparkle
} from 'lucide-react';

const PublicNavbar = () => {


  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(null);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const menuCategories = {
    'Content Creation': {
      icon: FileText,
      tools: [
        { name: 'Blog Post Generator', path: '/landing/blog-writer' },
        { name: 'Social Media Writer', path: '/landing/social-media-writer' },
        { name: 'Email Newsletter Creator', path: '/landing/email-newsletter' },
        { name: 'Product Description Writer', path: '/landing/product-description-writer' }
      ]
    },
    'Content Enhancement': {
      icon: Wand2,
      tools: [
        { name: 'AI Detection Bypass', path: '/landing/human-writer' },
        { name: 'SEO Optimizer', path: '/landing/seo-optimizer' },
        { name: 'Content Rewriter', path: '/landing/content-rewriter' },
        { name: 'Readability Improver', path: '/landing/readability-improver' }
      ]
    },
    'Marketing Copy': {
      icon: MessageSquare,
      tools: [
        { name: 'Ad Copy Generator', path: '/landing/ad-copy-writer' },
        { name: 'Landing Page Writer', path: '/landing/landing-page-writer' },
        { name: 'Sales Copy Creator', path: '/landing/sales-copy-writer' },
        { name: 'CTAs Generator', path: '/landing/cta-generator' }
      ]
    },
    'Visual Content': {
      icon: Image,
      tools: [
        { name: 'Blog Images Creator', path: '/landing/image-generator' },
        { name: 'Social Media Graphics', path: '/landing/image-generator' },
        { name: 'Banner Designer', path: '/landing/image-generator' },
        { name: 'Logo Generator', path: '/landing/image-generator' }
      ]
    }
  };

  const handleDropdownEnter = (dropdownName) => {
    setOpenDropdown(dropdownName);
  };

  const handleDropdownLeave = () => {
    setOpenDropdown(null);
  };

  const toggleMobileDropdown = (category) => {
    setMobileDropdown(prevState => {
      if (category === 'use-cases') {
        if (prevState === null || (Array.isArray(prevState) && !prevState.includes('use-cases'))) {
          return ['use-cases'];
        }
        return null;
      }
      
      if (Array.isArray(prevState)) {
        if (prevState.includes(category)) {
          return ['use-cases'];
        }
        return ['use-cases', category];
      }
      
      return ['use-cases', category];
    });
  };

  const isOpen = (section) => {
    return Array.isArray(mobileDropdown) ? mobileDropdown.includes(section) : mobileDropdown === section;
  };

  return (
    <nav className="bg-gray-900 relative z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo - Reduced width */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg flex items-center justify-center">
                <span className="text-white font-bold text-xl">C</span>
              </div>
              <div className="hidden sm:flex flex-col">
                <span className="text-lg font-bold text-white">The Content GPT</span>
                <span className="text-xs text-gray-400">AI-Powered Content Solutions</span>
              </div>
            </Link>
          </div>

          {/* Desktop Navigation Links - Centered with flex-grow */}
          <div className="hidden md:flex flex-grow items-center justify-center space-x-6 whitespace-nowrap">
            {/* Free Tools Button */}
            <Link
              to="/free-tools"
              className="group relative inline-flex items-center bg-gray-900 hover:bg-gray-800 transition-colors"
            >
              <div className="absolute -inset-[1px] rounded-lg bg-gradient-to-r from-yellow-400 via-purple-500 to-pink-500" />
              <div className="relative flex items-center space-x-2 px-4 py-2 rounded-[7px] bg-gray-900">
                <Sparkle className="h-4 w-4 text-yellow-400" />
                <span className="text-white font-medium">Free Tools</span>
              </div>
            </Link>

            {/* Use Cases */}
            <div className="relative">
              <button
                onMouseEnter={() => handleDropdownEnter('use-cases')}
                className="flex items-center space-x-1 px-3 py-2 text-gray-300 hover:text-white whitespace-nowrap"
              >
                <span>Use Cases</span>
                <ChevronDown className="h-4 w-4" />
              </button>

              {openDropdown === 'use-cases' && (
                <div
                  onMouseEnter={() => handleDropdownEnter('use-cases')}
                  onMouseLeave={handleDropdownLeave}
                  className="absolute top-full -left-2 w-[600px] bg-gray-900 shadow-xl rounded-lg mt-2 p-6 grid grid-cols-2 gap-8 border border-gray-800"
                >
                  {Object.entries(menuCategories).map(([category, { icon: Icon, tools }]) => (
                    <div key={category}>
                      <div className="flex items-center space-x-2 mb-4">
                        <Icon className="h-5 w-5 text-purple-400" />
                        <h3 className="font-semibold text-white">{category}</h3>
                      </div>
                      <ul className="space-y-2">
                        {tools.map((tool) => (
                          <li key={tool.name}>
                            <Link
                              to={tool.path}
                              className="text-gray-300 hover:text-white block py-1"
                            >
                              {tool.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Link
              to="/landing/human-writer"
              className="text-gray-300 hover:text-white"
            >
              Human Writer
            </Link>

            <Link
              to="/pricing"
              className="text-gray-300 hover:text-white"
            >
              Pricing
            </Link>

            <Link
              to="/blog"
              className="text-gray-300 hover:text-white"
            >
              Blog
            </Link>
          </div>

          {/* Auth Buttons */}
          <div className="flex-1 hidden md:flex items-center justify-end space-x-4">
            <Link
              to="/login"
              className="text-gray-300 hover:text-white"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-lg hover:opacity-90"
            >
              Sign Up
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
          <button
  onClick={() => setIsMobileMenuOpen(true)}
  className="text-gray-300 hover:text-white p-2"
  aria-label="Open mobile menu"
>
  <Menu className="h-6 w-6" />
</button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-900 z-50">
          {/* Header with close button */}
          <div className="flex items-center justify-between p-4 border-b border-gray-800">
            <Link to="/" className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg flex items-center justify-center">
                <span className="text-white font-bold text-xl">C</span>
              </div>
              <div className="flex flex-col">
                <span className="text-lg font-bold text-white">The Content GPT</span>
                <span className="text-xs text-gray-400">AI-Powered Content Solutions</span>
              </div>
            </Link>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 text-gray-400 hover:text-white"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Mobile Menu Content */}
          <div className="px-4 py-6">
          <div className="space-y-6">
              {/* Free Tools Button in mobile menu */}
              <Link
                to="/free-tools"
                className="relative inline-flex w-full bg-gray-900"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <div className="absolute -inset-[1px] rounded-lg bg-gradient-to-r from-yellow-400 via-purple-500 to-pink-500" />
                <div className="relative flex items-center space-x-2 px-4 py-2 rounded-[7px] bg-gray-900 w-full">
                  <Sparkle className="h-5 w-5 text-yellow-400" />
                  <span className="text-white font-medium">Free Tools</span>
                </div>
              </Link>
              <button
                onClick={() => toggleMobileDropdown('use-cases')}
                className="w-full text-left text-lg font-medium text-white flex items-center justify-between"
              >
                <span>Use Cases</span>
                <ChevronDown className={`h-5 w-5 transform transition-transform ${
                  isOpen('use-cases') ? 'rotate-180' : ''
                }`} />
              </button>

              {isOpen('use-cases') && (
                <div className="ml-4 space-y-6">
                  {Object.entries(menuCategories).map(([category, { icon: Icon, tools }]) => (
                    <div key={category}>
                      <button
                        onClick={() => toggleMobileDropdown(category)}
                        className="flex items-center justify-between w-full text-gray-300 mb-2"
                      >
                        <div className="flex items-center">
                          <Icon className="h-5 w-5 mr-2 text-purple-400" />
                          <span>{category}</span>
                        </div>
                        <ChevronDown className={`h-4 w-4 transform transition-transform ${
                          isOpen(category) ? 'rotate-180' : ''
                        }`} />
                      </button>

                      {isOpen(category) && (
                        <ul className="ml-7 mt-2 space-y-3">
                          {tools.map((tool) => (
                            <li key={tool.name}>
                              <Link
                                to={tool.path}
                                className="text-gray-400 hover:text-white block"
                                onClick={() => setIsMobileMenuOpen(false)}
                              >
                                {tool.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div className="space-y-6 py-4">
                <Link
                  to="/landing/human-writer"
                  className="block text-lg text-gray-300 hover:text-white"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Human Writer
                </Link>

                <Link
                  to="/pricing"
                  className="block text-lg text-gray-300 hover:text-white"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Pricing
                </Link>

                <Link
                  to="/blog"
                  className="block text-lg text-gray-300 hover:text-white"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Blog
                </Link>
              </div>
            </div>

            {/* Mobile Auth Buttons */}
            <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-800 bg-gray-900">
              <div className="space-y-3">
                <Link
                  to="/login"
                  className="block w-full text-center py-3 text-gray-300 hover:text-white"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="block w-full text-center bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default PublicNavbar;