import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import {
  MousePointer,
  TrendingUp,
  ArrowRight,
  ChevronDown,
  Zap,
  Target,
  RefreshCw,
  Layout,
  Maximize2,
  Minimize2,
  Copy,
  Palette,
  Shuffle,
  CheckCircle,
  Star,
  Globe,
  Users,
  MessageCircle,
  BarChart,
  Award,
  Brain,
  Sparkles,
  Eye,
  Click
} from 'lucide-react';

const CTAGeneratorLanding = () => {
  const [scrollY, setScrollY] = useState(0);
  const [activeDemo, setActiveDemo] = useState('classic');
  const [openFaq, setOpenFaq] = useState(null);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const ctaVariants = {
    classic: {
      text: "Start Your Free Trial",
      style: "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
    },
    modern: {
      text: "Experience the Future →",
      style: "bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600"
    },
    minimal: {
      text: "Get Started",
      style: "bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black"
    }
  };

  // New state for interactive demo
  const [selectedIndustry, setSelectedIndustry] = useState('ecommerce');
  const [selectedStyle, setSelectedStyle] = useState('modern');
  
  const demoExamples = {
    ecommerce: {
      modern: {
        text: "Shop the Collection →",
        style: "bg-gradient-to-r from-purple-600 to-pink-600"
      },
      minimal: {
        text: "View Products",
        style: "bg-black border border-white/20"
      },
      bold: {
        text: "Explore Latest Drops",
        style: "bg-gradient-to-r from-yellow-500 to-orange-500"
      }
    },
    saas: {
      modern: {
        text: "Start Free Trial",
        style: "bg-gradient-to-r from-blue-600 to-cyan-600"
      },
      minimal: {
        text: "Try Now",
        style: "bg-gray-900 border border-white/20"
      },
      bold: {
        text: "Power Up Your Workflow",
        style: "bg-gradient-to-r from-green-500 to-emerald-500"
      }
    },
    // Add more industries...
  };

  const pricingPlans = [
    {
      name: "Starter",
      price: "0",
      features: ["5 CTAs per month", "Basic analytics", "3 style variations"],
      recommended: false
    },
    {
      name: "Pro",
      price: "29",
      features: ["Unlimited CTAs", "Advanced analytics", "A/B testing", "Priority support"],
      recommended: true
    },
    {
      name: "Enterprise",
      price: "99",
      features: ["Custom solutions", "Dedicated support", "API access", "Custom branding"],
      recommended: false
    }
  ];

  return (
    <>
      <SEOHead 
        title="AI CTA Generator | Create High-Converting Call-to-Action Buttons"
        description="Transform your website's conversion rate with AI-powered call-to-action buttons. Generate, test, and optimize CTAs that drive results. Try free today!"
        keywords="CTA generator, call to action, conversion optimization, AI button generator, website optimization"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          {/* Animated particles */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-blue-500/20 rounded-full"
                style={{
                  width: Math.random() * 50 + 10,
                  height: Math.random() * 50 + 10,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            ))}
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              AI-Powered CTAs
              <span className="block">That Convert</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Create high-converting call-to-action buttons with the power of AI. Transform clicks into customers.
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => document.querySelector('#live-demo')?.scrollIntoView({ behavior: 'smooth' })}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See It In Action
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">2M+</div>
                <div className="text-gray-400">CTAs Generated</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">47%</div>
                <div className="text-gray-400">Conversion Boost</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </motion.div>
          </motion.div>
        </section>

       

  
        {/* Interactive Demo Section */}
        <section id="live-demo" className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_-20%,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Create the Perfect CTA in
                <span className="text-blue-400"> Seconds</span>
              </h2>
              <p className="text-xl text-gray-400">
                Watch AI transform your ideas into converting buttons
              </p>
            </motion.div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 mb-12">
              <div className="grid md:grid-cols-2 gap-8">
                {/* Controls */}
                <div className="space-y-6">
                  <div>
                    <label className="text-sm text-gray-400 mb-2 block">Industry</label>
                    <div className="flex gap-4">
                      {Object.keys(demoExamples).map((industry) => (
                        <button
                          key={industry}
                          onClick={() => setSelectedIndustry(industry)}
                          className={`px-4 py-2 rounded-full capitalize transition-all ${
                            selectedIndustry === industry
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          }`}
                        >
                          {industry}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="text-sm text-gray-400 mb-2 block">Style</label>
                    <div className="flex gap-4">
                      {Object.keys(demoExamples[selectedIndustry]).map((style) => (
                        <button
                          key={style}
                          onClick={() => setSelectedStyle(style)}
                          className={`px-4 py-2 rounded-full capitalize transition-all ${
                            selectedStyle === style
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          }`}
                        >
                          {style}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Preview */}
                <div className="bg-gray-900 rounded-xl p-8 flex items-center justify-center">
                  <motion.button
                    key={`${selectedIndustry}-${selectedStyle}`}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    className={`px-8 py-3 rounded-lg text-white transition-all hover:scale-105 ${
                      demoExamples[selectedIndustry][selectedStyle].style
                    }`}
                  >
                    {demoExamples[selectedIndustry][selectedStyle].text}
                  </motion.button>
                </div>
              </div>
            </div>

            {/* AI Features Grid */}
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: Brain,
                  title: "AI Copy Generation",
                  description: "Create compelling CTA text that resonates with your audience"
                },
                {
                  icon: Palette,
                  title: "Smart Design",
                  description: "Auto-generated color schemes and styling that match your brand"
                },
                {
                  icon: BarChart,
                  title: "Performance Analytics",
                  description: "Track clicks, conversions, and optimize for better results"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl hover:bg-gray-800/70 transition-all"
                >
                  <feature.icon className="h-8 w-8 text-blue-400 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Features Showcase */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Cutting-Edge Features
              </h2>
              <p className="text-xl text-gray-400">
                Everything you need to create CTAs that convert
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Smart A/B Testing",
                  description: "Automatically generate variants and track their performance",
                  icon: Shuffle,
                  features: ["Multiple variations", "Performance tracking", "Auto-optimization"]
                },
                {
                  title: "Real-time Analytics",
                  description: "Monitor CTA performance with detailed insights",
                  icon: BarChart,
                  features: ["Click-through rates", "Heat maps", "Conversion tracking"]
                },
                {
                  title: "Brand Integration",
                  description: "Maintain consistent branding across all CTAs",
                  icon: Palette,
                  features: ["Color matching", "Style consistency", "Brand guidelines"]
                }
                // ... more features to be added in the next continuation
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 hover:bg-gray-800/70 transition-all group"
                >
                  <feature.icon className="h-8 w-8 text-blue-400 mb-4 group-hover:text-blue-300 transition-colors" />
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400 mb-6">{feature.description}</p>
                  <ul className="space-y-2">
                    {feature.features.map((item, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mr-2" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* AI Capabilities Section */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/10 to-blue-500/10 animate-pulse" />
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
            />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Advanced AI
                <span className="text-blue-400"> Capabilities</span>
              </h2>
              <p className="text-xl text-gray-400">
                Powered by next-generation language models and conversion optimization
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Natural Language Processing",
                  description: "Understands context and generates human-like copy",
                  icon: Brain,
                  features: [
                    "Context awareness",
                    "Tone matching",
                    "Multilingual support"
                  ]
                },
                {
                  title: "Conversion Optimization",
                  description: "AI-driven insights for maximum click-through rates",
                  icon: TrendingUp,
                  features: [
                    "Behavioral analysis",
                    "Psychological triggers",
                    "Action optimization"
                  ]
                },
                {
                  title: "Dynamic Personalization",
                  description: "Adapts CTAs based on user behavior and preferences",
                  icon: Users,
                  features: [
                    "User segmentation",
                    "Real-time adaptation",
                    "Context-aware messaging"
                  ]
                },
                {
                  title: "Smart Color Theory",
                  description: "Automatic color selection based on psychology and brand",
                  icon: Palette,
                  features: [
                    "Brand matching",
                    "Contrast optimization",
                    "Accessibility compliance"
                  ]
                },
                {
                  title: "Performance Learning",
                  description: "Continuously improves based on real-world data",
                  icon: RefreshCw,
                  features: [
                    "A/B test analysis",
                    "Success pattern recognition",
                    "Automated optimization"
                  ]
                },
                {
                  title: "Semantic Analysis",
                  description: "Deep understanding of intent and context",
                  icon: Eye,
                  features: [
                    "Intent recognition",
                    "Emotional analysis",
                    "Context mapping"
                  ]
                }
              ].map((capability, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 hover:bg-gray-800/70 transition-all group"
                >
                  <capability.icon className="h-8 w-8 text-blue-400 mb-4 group-hover:text-blue-300 transition-colors" />
                  <h3 className="text-xl font-bold mb-4">{capability.title}</h3>
                  <p className="text-gray-400 mb-6">{capability.description}</p>
                  <ul className="space-y-2">
                    {capability.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Use Cases Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Transform CTAs Across
                <span className="text-purple-400"> Industries</span>
              </h2>
              <p className="text-xl text-gray-400">
                See how different sectors achieve higher conversions
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  industry: "E-commerce",
                  icon: MousePointer,
                  metrics: ["67% higher add-to-cart", "43% more checkouts"],
                  description: "Drive sales with compelling product CTAs",
                  example: "Shop Now → Get It Today"
                },
                {
                  industry: "SaaS",
                  icon: Zap,
                  metrics: ["89% trial signups", "52% demo bookings"],
                  description: "Convert visitors into active users",
                  example: "Start Free → Power Up Your Work"
                },
                {
                  industry: "Lead Generation",
                  icon: Target,
                  metrics: ["73% form completion", "45% qualified leads"],
                  description: "Capture and convert quality leads",
                  example: "Learn More → Get Expert Insights"
                }
              ].map((useCase, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-2xl p-8 hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="bg-purple-500/20 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                    <useCase.icon className="h-8 w-8 text-purple-400" />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{useCase.industry}</h3>
                  <p className="text-gray-400 mb-6">{useCase.description}</p>
                  <div className="space-y-3">
                    {useCase.metrics.map((metric, i) => (
                      <div key={i} className="flex items-center text-green-400">
                        <CheckCircle className="h-5 w-5 mr-2" />
                        <span>{metric}</span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-6 p-4 bg-gray-800/50 rounded-lg">
                    <p className="text-sm text-gray-400">Example Transformation</p>
                    <p className="text-purple-400 font-medium">{useCase.example}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

       
  
        {/* Integrations Section */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-green-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Seamless
                <span className="text-blue-400"> Integrations</span>
              </h2>
              <p className="text-xl text-gray-400">
                Works with your favorite tools and platforms
              </p>
            </motion.div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                "WordPress",
                "Shopify",
                "Webflow",
                "Wix",
                "React",
                "Next.js",
                "HTML/CSS",
                "API"
              ].map((platform, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-800/70 transition-all text-center"
                >
                  <Globe className="h-8 w-8 text-blue-400 mx-auto mb-3" />
                  <span className="text-gray-300">{platform}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Trusted by Industry Leaders
              </h2>
              <p className="text-xl text-gray-400">
                See what our users say about their experience
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "Increased our conversion rate by 47% in just two weeks.",
                  author: "Sarah Chen",
                  role: "Growth Lead",
                  company: "TechStart"
                },
                {
                  quote: "The AI suggestions are spot-on. It's like having a conversion expert on the team.",
                  author: "Michael Rodriguez",
                  role: "Marketing Director",
                  company: "E-commerce Plus"
                },
                {
                  quote: "Perfect for our multi-language campaigns. The results speak for themselves.",
                  author: "Emma Watson",
                  role: "CRO Specialist",
                  company: "Global Solutions"
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-8 rounded-2xl"
                >
                  <div className="flex mb-6">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                    ))}
                  </div>
                  <p className="text-gray-300 mb-6">"{testimonial.quote}"</p>
                  <div>
                    <div className="font-semibold">{testimonial.author}</div>
                    <div className="text-sm text-gray-400">{testimonial.role}</div>
                    <div className="text-sm text-gray-400">{testimonial.company}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        

        {/* Final CTA */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
            />
          </div>
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your CTAs?
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of marketers creating high-converting CTAs with AI
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Start Creating Free
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link
                  to="/demo"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  Request Demo
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">
                No credit card required · 14-day free trial · Cancel anytime
              </p>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CTAGeneratorLanding;