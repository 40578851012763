import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check } from "lucide-react"

const Checkbox = React.forwardRef(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={`peer h-4 w-4 shrink-0 rounded-sm border border-gray-200 shadow 
    focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 
    disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-900 
    data-[state=checked]:text-gray-50 dark:border-gray-800 dark:focus-visible:ring-gray-300 
    dark:data-[state=checked]:bg-gray-50 dark:data-[state=checked]:text-gray-900 ${className}`}
    {...props}
  >
    <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }