import SEOHead from '../../components/SEOHead';
import React from 'react';
import { 
  RefreshCw, 
  Clock, 
  CheckCircle, 
  XCircle, 
  AlertCircle,
  CreditCard,
  HelpCircle,
  Mail
} from 'lucide-react';

const RefundPolicy = () => {
  const lastUpdated = "October 26, 2024";

  const eligibleCases = [
    {
      title: "Technical Issues",
      description: "Service was unavailable for extended periods or had significant technical problems"
    },
    {
      title: "Billing Errors",
      description: "Multiple charges or incorrect billing amount"
    },
    {
      title: "New Accounts",
      description: "Within 14 days of first subscription purchase"
    }
  ];

  const nonEligibleCases = [
    {
      title: "Used Credits",
      description: "Refunds are not available for already used content generation credits"
    },
    {
      title: "Account Violations",
      description: "Accounts terminated due to terms of service violations"
    },
    {
      title: "Extended Usage",
      description: "Requests after the 14-day trial period"
    }
  ];

  const refundSteps = [
    {
      title: "Contact Support",
      description: "Send an email to support@thecontentgpt.com with your account details"
    },
    {
      title: "Provide Information",
      description: "Include your order number and reason for refund request"
    },
    {
      title: "Review Process",
      description: "Our team will review your request within 24-48 hours"
    },
    {
      title: "Refund Processing",
      description: "Approved refunds are processed within 5-7 business days"
    }
  ];

  return (
    <>
      <SEOHead />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        {/* Hero Section */}
        <div className="relative bg-gradient-to-b from-gray-900 to-black py-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
            <div className="text-center">
              <RefreshCw className="h-12 w-12 text-blue-500 mx-auto mb-4" />
              <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Refund Policy</h1>
              <p className="mt-4 text-lg text-gray-400">
                Last Updated: {lastUpdated}
              </p>
              <div className="mt-6 bg-gray-800/50 rounded-lg p-4 border border-gray-700">
                <p className="text-gray-300 text-sm">
                  We want you to be completely satisfied with our service. Learn about our refund process below.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-gray-800/50 rounded-xl border border-gray-700 p-8">
            {/* Overview Section */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Overview</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  The Content GPT offers a 14-day money-back guarantee for new subscriptions. We process refund requests on a case-by-case basis, considering various factors to ensure fair treatment of all users.
                </p>
                <div className="bg-blue-500/10 border border-blue-500/20 p-4 rounded-lg">
                  <div className="flex items-start">
                    <Clock className="h-5 w-5 text-blue-400 mt-0.5 mr-2" />
                    <p className="text-sm text-blue-400">
                      Refund requests must be submitted within 14 days of your initial purchase.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Eligible Cases */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Eligible for Refund</h2>
              <div className="grid gap-6">
                {eligibleCases.map((item, index) => (
                  <div key={index} className="flex items-start bg-gray-900/50 border border-gray-700 p-4 rounded-lg">
                    <CheckCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-3" />
                    <div>
                      <h3 className="font-semibold text-white">{item.title}</h3>
                      <p className="text-gray-400 text-sm mt-1">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Non-Eligible Cases */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Not Eligible for Refund</h2>
              <div className="grid gap-6">
                {nonEligibleCases.map((item, index) => (
                  <div key={index} className="flex items-start bg-gray-900/50 border border-gray-700 p-4 rounded-lg">
                    <XCircle className="h-5 w-5 text-red-500 mt-0.5 mr-3" />
                    <div>
                      <h3 className="font-semibold text-white">{item.title}</h3>
                      <p className="text-gray-400 text-sm mt-1">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Refund Process */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Refund Process</h2>
              <div className="grid gap-6">
                {refundSteps.map((step, index) => (
                  <div key={index} className="relative">
                    <div className="flex items-start">
                      <div className="flex items-center justify-center bg-blue-500/20 rounded-full h-8 w-8 flex-shrink-0 mr-3">
                        <span className="text-blue-400 font-semibold">{index + 1}</span>
                      </div>
                      <div>
                        <h3 className="font-semibold text-white">{step.title}</h3>
                        <p className="text-gray-400 text-sm mt-1">{step.description}</p>
                      </div>
                    </div>
                    {index < refundSteps.length - 1 && (
                      <div className="absolute left-4 top-8 h-full border-l-2 border-blue-500/20" />
                    )}
                  </div>
                ))}
              </div>
            </section>

            {/* Payment Processing */}
            <section className="mb-12">
              <div className="flex items-center mb-4">
                <CreditCard className="h-6 w-6 text-blue-500 mr-2" />
                <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Refund Processing</h2>
              </div>
              <div className="space-y-4 text-gray-300">
                <p>
                  Refunds are processed back to the original payment method used for the purchase.
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-400">
                  <li>Credit card refunds: 5-7 business days</li>
                  <li>PayPal refunds: 2-3 business days</li>
                  <li>Bank transfers: 7-10 business days</li>
                </ul>
                <div className="bg-yellow-500/10 border border-yellow-500/20 p-4 rounded-lg mt-4">
                  <div className="flex items-start">
                    <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5 mr-2" />
                    <p className="text-sm text-yellow-500">
                      Processing times may vary depending on your payment provider and location.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Contact Section */}
            <section className="mt-12 pt-8 border-t border-gray-700">
              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <Mail className="h-8 w-8 text-blue-500" />
                </div>
                <h2 className="text-xl font-semibold mb-4 text-white">Need Help?</h2>
                <p className="text-gray-400 mb-4">
                  If you have any questions about our refund policy or need to request a refund, please contact our support team at:
                </p>
                <a href="mailto:support@thecontentgpt.com" className="text-blue-400 hover:text-blue-300 font-medium inline-block mb-6">
                  support@thecontentgpt.com
                </a>
                <div className="mt-4 p-4 bg-gray-900/50 border border-gray-700 rounded-lg inline-block">
                  <div className="flex items-center text-sm text-gray-400">
                    <HelpCircle className="h-4 w-4 mr-2" />
                    <span>Average response time: 24-48 hours</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;