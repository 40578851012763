import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import {
  Shield,
  Lock,
  ArrowRight,
  ChevronDown,
  Sparkles,
  Zap,
  Brain,
  FileText,
  CheckCircle,
  AlertCircle,
  Eye,
  Clock,
  Heart,
  Target,
  RefreshCw,
  Star,
  BarChart2,
  Globe,
  Layout,
  MessageSquare,
  Settings,
  Lightning,
  Search
} from 'lucide-react';

const AIDetectionBypassLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [activeFeature, setActiveFeature] = useState(0);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // SEO configuration
  const seoData = {
    title: "AI Content Detection Bypass Tool | Undetectable AI Writing",
    description: "Transform AI-generated content into 100% human-like text that bypasses all AI detectors. Use our advanced AI technology to create undetectable content while maintaining quality and meaning.",
    keywords: "AI detection bypass, undetectable AI content, AI writing detection, bypass AI detectors, human-like AI content"
  };

  const features = [
    {
      title: "Quantum Bypass Technology",
      description: "Advanced algorithms that transform AI content beyond detection",
      icon: Brain,
      stats: "99.9% Success Rate"
    },
    {
      title: "Real-time Processing",
      description: "Transform content instantly with zero waiting time",
      icon: Zap,
      stats: "Process 10k+ words/sec"
    },
    {
      title: "Meaning Preservation",
      description: "Maintain original message while restructuring content",
      icon: Target,
      stats: "100% Semantic Match"
    },
    {
      title: "Multi-Platform Support",
      description: "Bypass all major AI detection tools",
      icon: Globe,
      stats: "Works on 50+ platforms"
    }
  ];

  return (
    <>
      <SEOHead
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Dynamic Animation */}
        <section className="relative h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <div className="inline-block mb-6">
              <span className="bg-blue-500/20 text-blue-400 px-4 py-2 rounded-full text-sm font-medium border border-blue-500/50">
                Next-Gen AI Technology
              </span>
            </div>

            <motion.h1
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Undetectable AI Content
            </motion.h1>

            <motion.p
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform AI-generated text into perfectly human-like content that bypasses all detection tools while preserving your message.
            </motion.p>

            <motion.div
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Bypassing Now
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>

              <a
                href="#demo"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all flex items-center justify-center"
              >
                See How It Works
                <ChevronDown className="ml-2 h-5 w-5 animate-bounce" />
              </a>
            </motion.div>
          </motion.div>

          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
            <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
          </div>
        </section>

        {/* Stats Counter Section */}
        <section className="py-20 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                { value: "100%", label: "Bypass Success Rate" },
                { value: "50K+", label: "Active Users" },
                { value: "10M+", label: "Documents Processed" },
                { value: "0.001s", label: "Processing Time" }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="text-center"
                >
                  <h3 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                    {stat.value}
                  </h3>
                  <p className="text-gray-400 mt-2">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Features Grid with Hover Effects */}
        <section className="py-20 bg-black relative">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Quantum AI Technology
              </h2>
              <p className="text-gray-400 text-xl">
                Next-generation features that redefine AI content
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl hover:transform hover:scale-105 transition-all group cursor-pointer"
                >
                  <div className="bg-blue-500/20 p-3 rounded-lg w-fit mb-4 group-hover:bg-blue-500/30 transition-all">
                    <feature.icon className="h-8 w-8" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-400 mb-4">{feature.description}</p>
                  <div className="bg-blue-500/10 px-3 py-1 rounded-full text-blue-400 text-sm inline-block">
                    {feature.stats}
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        {/* Interactive Demo Section */}
        <section id="demo" className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                See It In Action
              </h2>
              <p className="text-gray-400 text-xl">
                Experience real-time AI detection bypass
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-gray-800 rounded-2xl p-6"
              >
                <div className="mb-4 flex items-center justify-between">
                  <h3 className="text-xl font-bold">Original AI Text</h3>
                  <span className="text-red-500 bg-red-500/20 px-3 py-1 rounded-full text-sm">
                    Detectable as AI
                  </span>
                </div>
                <div className="bg-gray-900 p-4 rounded-xl mb-4">
                  <p className="text-gray-300">
                  Purchasing followers might seem like a quick fix to boost your brand’s numbers, but it’s crucial to consider the long-term impact on your brand’s reputation. While a higher follower count can create the illusion of popularity, true success comes from genuine engagement. What matters most is that real people interact with your content, share it, and connect with your brand’s message. However, relying on fake followers can damage your credibility and lead to stagnant engagement rates. Instead, focus on sustainable growth strategies like influencer partnerships or targeted advertising. 
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-red-500/10 p-3 rounded-lg">
                    <span className="block text-sm text-gray-400">AI Score</span>
                    <span className="text-xl font-bold text-red-500">99.8%</span>
                  </div>
                  <div className="bg-red-500/10 p-3 rounded-lg">
                    <span className="block text-sm text-gray-400">Detection Risk</span>
                    <span className="text-xl font-bold text-red-500">High</span>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-gray-800 rounded-2xl p-6"
              >
                <div className="mb-4 flex items-center justify-between">
                  <h3 className="text-xl font-bold">After Bypass</h3>
                  <span className="text-green-500 bg-green-500/20 px-3 py-1 rounded-full text-sm">
                    100% Human-Like
                  </span>
                </div>
                <div className="bg-gray-900 p-4 rounded-xl mb-4">
                  <p className="text-gray-300">
                  Buy the followers, if you think about the long-term effects of it on your brand. The amount of followers does increase things immediately. Still, the amount of engagement you get on a post is not necessarily a bad thing – so long as real people are engaging with you and they are reacting to your content. But it is essential to grasp that long term brand health hinges on real connections. Suppose you are investing in organic strategies like doing influencer partnerships, or targeted ads. In that case, those are going to be much more sustainable growth while not taking any of the risks potentially coming from buying followers.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-green-500/10 p-3 rounded-lg">
                    <span className="block text-sm text-gray-400">AI Score</span>
                    <span className="text-xl font-bold text-green-500">0.1%</span>
                  </div>
                  <div className="bg-green-500/10 p-3 rounded-lg">
                    <span className="block text-sm text-gray-400">Detection Risk</span>
                    <span className="text-xl font-bold text-green-500">None</span>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Processing Animation */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="mt-12 flex justify-center"
            >
              <div className="flex items-center space-x-8">
                <div className="flex items-center space-x-2">
                  <Brain className="h-6 w-6 text-blue-400" />
                  <span>Analyzing Patterns</span>
                </div>
                <div className="h-1 w-24 bg-gray-700 rounded-full overflow-hidden">
                  <div className="h-full w-full bg-gradient-to-r from-blue-500 to-purple-500 animate-[loading_1.5s_ease-in-out_infinite]" />
                </div>
                <div className="flex items-center space-x-2">
                  <Zap className="h-6 w-6 text-purple-400" />
                  <span>Transforming Content</span>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Support Matrix */}
        <section className="py-24 bg-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Comprehensive Coverage
              </h2>
              <p className="text-gray-400 text-xl">
                Bypass all major AI detection platforms
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  category: "Academic Detectors",
                  tools: ["Turnitin", "GPTZero", "Copyleaks"],
                  successRate: "99.9%"
                },
                {
                  category: "Commercial Tools",
                  tools: ["Originality.AI", "Winston AI", "Content at Scale"],
                  successRate: "99.8%"
                },
                {
                  category: "Enterprise Solutions",
                  tools: ["OpenAI Classifier", "Crossplag", "Scribbr"],
                  successRate: "99.9%"
                }
              ].map((category, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl"
                >
                  <h3 className="text-2xl font-bold mb-4">{category.category}</h3>
                  <div className="space-y-3 mb-6">
                    {category.tools.map((tool, i) => (
                      <div key={i} className="flex items-center space-x-2">
                        <CheckCircle className="h-5 w-5 text-green-500" />
                        <span>{tool}</span>
                      </div>
                    ))}
                  </div>
                  <div className="bg-blue-500/10 p-4 rounded-xl">
                    <div className="text-sm text-gray-400 mb-2">Success Rate</div>
                    <div className="text-2xl font-bold text-blue-400">
                      {category.successRate}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          
        </div>
      </section>

      {/* Process Workflow */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Quantum Transformation Process
              </h2>
              <p className="text-gray-400 text-xl">
                Advanced AI processing in three simple steps
              </p>
            </motion.div>

            <div className="relative">
              {/* Connection line */}
              <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 to-purple-500 transform -translate-y-1/2 hidden md:block" />

              <div className="grid md:grid-cols-3 gap-12 relative">
                {[
                  {
                    step: "01",
                    title: "Input Content",
                    description: "Paste your AI-generated text or upload document",
                    icon: FileText,
                    detail: "Supports all formats"
                  },
                  {
                    step: "02",
                    title: "Quantum Processing",
                    description: "Advanced AI transforms content patterns",
                    icon: Brain,
                    detail: "0.001s processing time"
                  },
                  {
                    step: "03",
                    title: "Verification",
                    description: "Confirm undetectability with built-in checker",
                    icon: Shield,
                    detail: "100% pass rate"
                  }
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.3 }}
                    className="relative z-10"
                  >
                    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 hover:transform hover:scale-105 transition-all">
                      <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-xl font-bold">
                        {step.step}
                      </div>
                      <div className="bg-blue-500/20 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                        <step.icon className="h-8 w-8" />
                      </div>
                      <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
                      <p className="text-gray-400 mb-4">{step.description}</p>
                      <div className="bg-blue-500/10 px-4 py-2 rounded-full text-blue-400 text-sm inline-block">
                        {step.detail}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Performance Metrics */}
        <section className="py-24 bg-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Unprecedented Performance
              </h2>
              <p className="text-gray-400 text-xl">
                Setting new standards in AI detection bypass
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  metric: "Processing Speed",
                  value: "0.001s",
                  change: "+300%",
                  icon: Clock
                },
                {
                  metric: "Success Rate",
                  value: "99.9%",
                  change: "+40%",
                  icon: Target
                },
                {
                  metric: "Content Quality",
                  value: "100%",
                  change: "+25%",
                  icon: Star
                },
                {
                  metric: "User Satisfaction",
                  value: "4.9/5",
                  change: "+45%",
                  icon: Heart
                }
              ].map((metric, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-2xl"
                >
                  <div className="flex items-center justify-between mb-4">
                    <metric.icon className="h-6 w-6 text-blue-400" />
                    <span className="text-green-400 bg-green-500/10 px-2 py-1 rounded-full text-sm">
                      {metric.change}
                    </span>
                  </div>
                  <div className="text-3xl font-bold mb-2">{metric.value}</div>
                  <div className="text-gray-400">{metric.metric}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Security and Compliance */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  Enterprise-Grade Security
                </h2>
                <p className="text-gray-400 text-xl mb-8">
                  Your content is protected with military-grade encryption and security measures
                </p>
                <div className="space-y-4">
                  {[
                    "256-bit AES Encryption",
                    "SOC 2 Type II Compliant",
                    "GDPR Ready",
                    "24/7 Security Monitoring"
                  ].map((feature, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-center space-x-3"
                    >
                      <Shield className="h-6 w-6 text-blue-400" />
                      <span>{feature}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8"
              >
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl" />
                  <div className="relative z-10 space-y-6">
                    <div className="flex items-center space-x-4">
                      <Lock className="h-8 w-8 text-blue-400" />
                      <div>
                        <h3 className="text-xl font-bold">Real-time Protection</h3>
                        <p className="text-gray-400">Continuous security monitoring</p>
                      </div>
                    </div>
                    {/* Add more security features here */}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

{/* User Testimonials */}
<section className="py-24 bg-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Trusted by Industry Leaders
              </h2>
              <p className="text-gray-400 text-xl">
                Join thousands of satisfied users worldwide
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  name: "Sarah Chen",
                  role: "Content Director",
                  company: "TechGrowth Inc",
                  content: "This tool has revolutionized our content strategy. We can now confidently use AI-generated content without any concerns.",
                  metric: "3x Content Output"
                },
                {
                  name: "James Wilson",
                  role: "Academic Researcher",
                  company: "Research Institute",
                  content: "A game-changer for academic writing. The transformed content maintains perfect academic integrity while being completely undetectable.",
                  metric: "100% Pass Rate"
                },
                {
                  name: "Maria Rodriguez",
                  role: "Marketing Lead",
                  company: "Digital Solutions",
                  content: "The quantum processing is incredible. What used to take hours of manual rewriting now happens in seconds with perfect results.",
                  metric: "47x Faster"
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl relative group hover:transform hover:scale-105 transition-all"
                >
                  <div className="absolute top-0 right-0 -translate-y-4 translate-x-4">
                    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-2 rounded-full">
                      <Star className="h-6 w-6" />
                    </div>
                  </div>
                  <p className="text-gray-300 italic mb-6">"{testimonial.content}"</p>
                  <div className="flex items-center mb-4">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mr-4">
                      <span className="font-bold">{testimonial.name.charAt(0)}</span>
                    </div>
                    <div>
                      <h4 className="font-bold">{testimonial.name}</h4>
                      <p className="text-sm text-gray-400">{testimonial.role}</p>
                      <p className="text-sm text-gray-400">{testimonial.company}</p>
                    </div>
                  </div>
                  <div className="bg-blue-500/10 px-4 py-2 rounded-full text-blue-400 text-sm inline-block">
                    {testimonial.metric}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-32 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-5xl md:text-7xl font-bold mb-8">
                Ready to Transform Your
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                  AI Content?
                </span>
              </h2>
              <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
                Join the future of content creation with our quantum AI technology
              </p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Start Transforming Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>

                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Plans & Pricing
                </Link>
              </motion.div>

              <div className="mt-8 flex justify-center items-center space-x-4">
                <Shield className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">256-bit Encryption</span>
                <span className="text-gray-600">•</span>
                <Clock className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">24/7 Support</span>
                <span className="text-gray-600">•</span>
                <Target className="h-5 w-5 text-gray-400" />
                <span className="text-gray-400">100% Success Rate</span>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AIDetectionBypassLanding;