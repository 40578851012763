// src/services/configService.js

class ConfigService {
    static getBaseUrl() {
      return process.env.NODE_ENV === 'production'
        ? 'https://thecontentgpt-api.onrender.com'
        : 'http://localhost:5001';
    }
  
    static getFrontendUrl() {
      return process.env.NODE_ENV === 'production'
        ? 'https://thecontentgpt.com'
        : 'http://localhost:3000';
    }
  
    static getCorsOrigins() {
      return process.env.NODE_ENV === 'production'
        ? ['https://thecontentgpt.com', 'https://www.thecontentgpt.com']
        : ['http://localhost:3000'];
    }
  
    static getApiConfig() {
      return {
        baseUrl: this.getBaseUrl(),
        frontendUrl: this.getFrontendUrl(),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        timeout: 30000,
        withCredentials: true
      };
    }
  
    static getWebhookConfig() {
      const baseUrl = this.getBaseUrl();
      return {
        successUrl: `${this.getFrontendUrl()}/dashboard?payment=success`,
        cancelUrl: `${this.getFrontendUrl()}/pricing?payment=cancelled`,
        webhookUrl: `${baseUrl}/api/payments/webhook`,
        corsOrigin: this.getCorsOrigins()
      };
    }
  
    static getGoogleAuthConfig() {
      return {
        callbackUrl: `${this.getBaseUrl()}/api/auth/google/callback`,
        successRedirect: `${this.getFrontendUrl()}/auth-callback`,
        failureRedirect: `${this.getFrontendUrl()}/login`
      };
    }
  }
  
  export default ConfigService;