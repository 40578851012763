import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LiveDemo from '../components/LiveDemo';
import SEOHead from '../components/SEOHead';
import {
  Wand2,
  Sparkles,
  Brain,
  Zap,
  Globe,
  Users,
  MessageCircle,
  Image,
  ChevronDown,
  ArrowRight,
  Shield,
  Target,
  Rocket,
  Star,
  Check,
  FileText,
  Ghost,
  Layout,
  File,
  Twitter,
  Facebook,
  Linkedin
} from 'lucide-react';


const Home = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
    <SEOHead/>
    
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
      {/* Hero Section with 3D Animation */}
      <section className="relative h-screen flex items-center justify-center">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
        
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="max-w-7xl mx-auto px-4 text-center relative z-10"
        >
          <motion.h1 
            initial={{ opacity: 1 }} // Remove initial scale transform
            className="text-5xl sm:text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
            style={{ 
              contain: 'content',
              contentVisibility: 'auto'
            }}
          >
            Future of Content
          </motion.h1>
          
          <motion.p 
            className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Transform your ideas into extraordinary content with AI-powered creativity
          </motion.p>

          <motion.div 
            className="flex flex-col sm:flex-row gap-4 justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <Link 
              to="/register" 
              className="px-8 py-4 bg-blue-600 rounded-full font-medium hover:bg-blue-700 transition-all transform hover:scale-105 flex items-center justify-center group"
            >
              Get Started Free
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Link>
            
            <Link
              to="features"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('features')?.scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }}
              className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
            >
              Explore Features
            </Link>
          </motion.div>
        </motion.div>

        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
          <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
        </div>
      </section>

      {/* Stats Counter Section */}
      <section className="py-20 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: 'Active Users', value: '36K+' },
              { label: 'Content Generated', value: '1M+' },
              { label: 'Languages', value: '100+' },
              { label: 'Time Saved', value: '637K hrs' }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-center"
              >
                <h3 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                  {stat.value}
                </h3>
                <p className="text-gray-400 mt-2">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Preview */}
      <section id="features" className="py-20 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Unlock the Power of AI
            </h2>
            <p className="text-gray-400 text-xl">
              Create content that captivates, converts, and ranks
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Brain className="h-8 w-8" />,
                title: "AI-Powered Creation",
                description: "Generate human-like content that passes AI detection"
              },
              {
                icon: <Target className="h-8 w-8" />,
                title: "SEO Optimization",
                description: "Rank higher with AI-optimized content strategies"
              },
              {
                icon: <Globe className="h-8 w-8" />,
                title: "Multi-Language Support",
                description: "Create content in over 100 languages instantly"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl hover:transform hover:scale-105 transition-all"
              >
                <div className="bg-blue-500/20 p-3 rounded-lg w-fit mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

     
{/* Continuing from previous sections */}

      {/* Interactive Workflow Section */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              How It Works
            </h2>
            <p className="text-gray-400 text-xl">
              Create amazing content in three simple steps
            </p>
          </motion.div>

          <div className="relative">
            {/* Connection lines */}
            <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 to-purple-500 transform -translate-y-1/2 hidden md:block" />

            <div className="grid md:grid-cols-3 gap-12 relative">
              {[
                {
                  step: "01",
                  title: "Choose Template",
                  description: "Select from our library of pre-built templates or start from scratch",
                  icon: <Wand2 className="h-6 w-6" />
                },
                {
                  step: "02",
                  title: "Customize Content",
                  description: "Input your requirements and let AI do the heavy lifting",
                  icon: <Sparkles className="h-6 w-6" />
                },
                {
                  step: "03",
                  title: "Generate & Edit",
                  description: "Review, edit, and export your perfect content",
                  icon: <Zap className="h-6 w-6" />
                }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.3 }}
                  className="relative z-10"
                >
                  <div className="bg-gray-800 rounded-2xl p-8 hover:bg-gray-750 transition-all group">
                    <div className="bg-blue-500/20 w-12 h-12 rounded-full flex items-center justify-center mb-6 group-hover:bg-blue-500/30 transition-all">
                      {item.icon}
                    </div>
                    <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-xl font-bold">
                      {item.step}
                    </div>
                    <h3 className="text-2xl font-bold mb-4">{item.title}</h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Loved by Content Creators
            </h2>
            <p className="text-gray-400 text-xl">
              See what our users have to say
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
          {[
              {
                name: "Sarah Johnson",
                role: "Content Manager",
                company: "TechStart Inc",
                initials: "SJ",
                quote: "The AI-powered content generation has revolutionized our content strategy. We're producing 3x more content in half the time."
              },
              {
                name: "Michael Chen",
                role: "Digital Marketer",
                company: "Growth Labs",
                initials: "MC",
                quote: "The quality of AI-generated content is impressive. It's helped us scale our content marketing efforts significantly."
              },
              {
                name: "Emma Davis",
                role: "Freelance Writer",
                company: "Self-employed",
                initials: "ED",
                quote: "This tool has become an essential part of my writing workflow. It helps me overcome writer's block and generate fresh ideas."
              }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl relative"
              >
                <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
                  <Star className="h-8 w-8 text-yellow-500" />
                </div>
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mr-4 font-bold text-lg font-serif">
                    {testimonial.initials}
                  </div>
                  <div>
                    <h4 className="font-bold">{testimonial.name}</h4>
                    <p className="text-sm text-gray-400">
                      {testimonial.role} at {testimonial.company}
                    </p>
                  </div>
                </div>
                <p className="text-gray-300 italic">"{testimonial.quote}"</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Feature Comparison Matrix */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Why Choose Us
            </h2>
            <p className="text-gray-400 text-xl">
              Compare our features with traditional content creation
            </p>
          </motion.div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="p-4 text-left"></th>
                  <th className="p-4 text-center">
                    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 rounded-xl">
                      <h3 className="text-xl font-bold mb-2">The Content GPT</h3>
                      <p className="text-sm text-gray-200">AI-Powered Platform</p>
                    </div>
                  </th>
                  <th className="p-4 text-center">
                    <div className="bg-gray-800 p-4 rounded-xl">
                      <h3 className="text-xl font-bold mb-2">Traditional Approach</h3>
                      <p className="text-sm text-gray-400">Manual Writing Process</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {[
                  {
                    feature: "Content Creation Speed",
                    us: "Minutes",
                    them: "Hours to Days"
                  },
                  {
                    feature: "Multi-Language Support",
                    us: "100+ Languages",
                    them: "Limited"
                  },
                  {
                    feature: "SEO Optimization",
                    us: "Built-in",
                    them: "Manual"
                  },
                  {
                    feature: "Content Variations",
                    us: "Unlimited",
                    them: "Time-consuming"
                  },
                  {
                    feature: "Cost Efficiency",
                    us: "High",
                    them: "Low"
                  }
                ].map((row, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="hover:bg-gray-800/50"
                  >
                    <td className="p-4 font-medium">{row.feature}</td>
                    <td className="p-4 text-center text-blue-400">{row.us}</td>
                    <td className="p-4 text-center text-gray-400">{row.them}</td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
{/* Integrations Showcase */}
<section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Seamless Integrations
            </h2>
            <p className="text-gray-400 text-xl">
              Connect with your favorite tools and platforms
            </p>
          </motion.div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { name: "WordPress", icon: "WordPress" },
              { name: "Shopify", icon: "Shopify" },
              { name: "Google Docs", icon: "FileText" },
              { name: "Medium", icon: "File" },
              { name: "Notion", icon: "FileText" },
              { name: "Ghost", icon: "Ghost" },
              { name: "Webflow", icon: "Globe" },
              { name: "Wix", icon: "Layout" }
            ].map((platform, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800 rounded-xl p-6 hover:bg-gray-750 transition-all group cursor-pointer"
              >
                <div className="flex flex-col items-center space-y-4">
                  <div className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center group-hover:bg-blue-500/30 transition-all">
                    {/* Import and use the appropriate icon component */}
                    <span className="text-3xl">{platform.icon}</span>
                  </div>
                  <span className="font-medium">{platform.name}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Live Demo Section */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                See It In Action
              </h2>
              <p className="text-gray-400 text-xl mb-8">
                Experience the power of AI-driven content creation in real-time
              </p>
              <div className="space-y-4">
                {[
                  "Select your content type",
                  "Input your requirements",
                  "Watch AI generate your content",
                  "Edit and export instantly"
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 }}
                    className="flex items-center space-x-3"
                  >
                    <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center">
                      <Check className="h-4 w-4" />
                    </div>
                    <span>{step}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800 rounded-2xl p-6 shadow-2xl"
            >
              {/* Interactive Demo Interface */}
              <motion.div
  initial={{ opacity: 0, x: 50 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
  className="bg-gray-800 rounded-2xl p-6 shadow-2xl"
>
  <LiveDemo />
</motion.div>
              <button className="w-full py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium hover:from-blue-600 hover:to-purple-600 transition-all">
                Try Live Demo
              </button>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Success Stories */}
      <section className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Success Stories
            </h2>
            <p className="text-gray-400 text-xl">
              Real results from real customers
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                metric: "300%",
                label: "Increase in Content Output",
                company: "TechStart Inc",
                description: "Tripled their content production while maintaining quality"
              },
              {
                metric: "5x",
                label: "ROI Improvement",
                company: "Growth Labs",
                description: "Achieved 5x return on investment through efficient content creation"
              },
              {
                metric: "60%",
                label: "Time Saved",
                company: "Media Corp",
                description: "Reduced content creation time by more than half"
              }
            ].map((story, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl relative group hover:transform hover:scale-105 transition-all"
              >
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
                <h3 className="text-4xl font-bold mb-2 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                  {story.metric}
                </h3>
                <p className="text-xl font-semibold mb-4">{story.label}</p>
                <p className="text-gray-400 mb-2">{story.company}</p>
                <p className="text-gray-300">{story.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
{/* FAQ Section */}
<section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Common Questions
            </h2>
            <p className="text-gray-400 text-xl">
              Everything you need to know
            </p>
          </motion.div>

          <div className="max-w-3xl mx-auto space-y-4">
            {[
              {
                question: "How does AI content generation work?",
                answer: "Our AI uses advanced language models to understand context and generate human-like content. It analyzes your requirements and creates original, engaging content tailored to your needs."
              },
              {
                question: "Is the content unique and SEO-friendly?",
                answer: "Yes! All generated content is unique and optimized for search engines. Our AI ensures originality while following SEO best practices for better rankings."
              },
              {
                question: "Can I edit the generated content?",
                answer: "Absolutely! You have full control to edit, modify, and refine the AI-generated content to match your exact requirements and brand voice."
              },
              {
                question: "What languages are supported?",
                answer: "We support over 100 languages with high-quality content generation capabilities in each one."
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800 rounded-xl overflow-hidden"
              >
                <button
                  className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-750 transition-all"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown className="h-5 w-5 transform transition-transform" />
                </button>
                <div className="px-6 pb-6">
                  <p className="text-gray-400">{faq.answer}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-4xl md:text-6xl font-bold mb-6">
              Ready to Transform Your Content?
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join thousands of content creators who are already using our AI to create amazing content
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105"
              >
                Get Started Free
              </Link>
              <Link
                to="/pricing"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                View Pricing
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Home;