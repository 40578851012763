import React, { useRef } from 'react';
import { FileText, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const detailedReportConfig = {
  id: 'detailed-report',
  label: 'Detailed Report',
  icon: <FileText className="w-4 h-4" />,
  description: 'Generate comprehensive data analysis report',
};

export const createPrompt = (data, columns) => {
  // Format columns for analysis
  const formattedColumns = columns.map(col => ({
    name: col.Header || col.id,
    accessor: col.accessor || col.id,
    type: typeof data[0]?.[col.accessor || col.id]
  }));

  const tablePreview = JSON.stringify(data);

  const prompt = `Generate a detailed analytical report for this dataset in the following exact JSON format:

{
  "executiveSummary": {
    "overview": "string",
    "keyHighlights": ["string"],
    "recommendations": ["string"]
  },
  "dataQuality": {
    "completeness": {
      "score": number,
      "issues": ["string"]
    },
    "consistency": {
      "score": number,
      "issues": ["string"]
    }
  },
  "columnAnalysis": {
    "columnName": {
      "type": "string",
      "summary": "string",
      "quality": "string",
      "patterns": ["string"],
      "recommendations": ["string"]
    }
  },
  "relationships": {
    "correlations": [{
      "columns": ["string", "string"],
      "strength": number,
      "description": "string"
    }],
    "dependencies": ["string"]
  },
  "segmentAnalysis": {
    "segments": [{
      "name": "string",
      "size": number,
      "characteristics": ["string"],
      "performance": "string"
    }]
  },
  "visualizations": {
    "chartType": {
      "title": "string",
      "data": [{
        "category": "string",
        "value": number
      }]
    }
  },
  "recommendations": {
    "dataCollection": ["string"],
    "analysis": ["string"],
    "business": ["string"]
  }
}

Table Structure:
${formattedColumns.map(col => `${col.name} (${col.type})`).join('\n')}

Full Dataset:
${tablePreview}

Analyze all aspects of this data thoroughly and provide comprehensive insights. Don't assess the empty column or cells, only provide results based on the data!`;

  return prompt;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);

    return {
      executiveSummary: parsedJson.executiveSummary || {},
      dataQuality: parsedJson.dataQuality || {},
      columnAnalysis: parsedJson.columnAnalysis || {},
      relationships: parsedJson.relationships || {},
      segmentAnalysis: parsedJson.segmentAnalysis || {},
      visualizations: parsedJson.visualizations || {},
      recommendations: parsedJson.recommendations || {}
    };

  } catch (error) {
    return {
      executiveSummary: {
        overview: 'Failed to parse report',
        keyHighlights: [],
        recommendations: []
      },
      dataQuality: {},
      columnAnalysis: {},
      relationships: {},
      segmentAnalysis: {},
      visualizations: {},
      recommendations: {}
    };
  }
};

export const DetailedReportResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('detailed-report.pdf');
  };

  // Transform visualization data for charts
  const chartData = results.visualizations?.chartType?.data || [];

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef} className="space-y-8">
        {/* Executive Summary */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Executive Summary</h2>
          <div className="prose dark:prose-invert max-w-none">
            <p className="text-gray-700 dark:text-gray-300">{results.executiveSummary.overview}</p>
            
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">Key Highlights</h3>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                {results.executiveSummary.keyHighlights.map((highlight, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300">{highlight}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Data Quality */}
        <div className="bg-gray-50 dark:bg-gray-800/50 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Data Quality Assessment</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">Completeness</h3>
                <span className="text-2xl font-bold text-green-600 dark:text-green-400">
                  {results.dataQuality.completeness?.score}%
                </span>
              </div>
              <ul className="space-y-2">
                {results.dataQuality.completeness?.issues.map((issue, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {issue}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">Consistency</h3>
                <span className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                  {results.dataQuality.consistency?.score}%
                </span>
              </div>
              <ul className="space-y-2">
                {results.dataQuality.consistency?.issues.map((issue, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {issue}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Column Analysis */}
        <div className="bg-gray-50 dark:bg-gray-800/50 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Column Analysis</h2>
          <div className="grid grid-cols-1 gap-6">
            {Object.entries(results.columnAnalysis).map(([column, analysis]) => (
              <div key={column} className="bg-white dark:bg-gray-800 p-4 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">{column}</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">Summary</h4>
                    <p className="text-gray-700 dark:text-gray-300">{analysis.summary}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">Patterns</h4>
                    <ul className="list-disc pl-5 space-y-1">
                      {analysis.patterns.map((pattern, index) => (
                        <li key={index} className="text-gray-700 dark:text-gray-300">{pattern}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">Recommendations</h4>
                    <ul className="list-disc pl-5 space-y-1">
                      {analysis.recommendations.map((rec, index) => (
                        <li key={index} className="text-gray-700 dark:text-gray-300">{rec}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Relationships */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Data Relationships</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">Correlations</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {results.relationships.correlations.map((correlation, index) => (
                  <div key={index} className="bg-gray-50 dark:bg-gray-800/50 p-4 rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-gray-700 dark:text-gray-300">
                        {correlation.columns.join(' → ')}
                      </span>
                      <span className="font-medium text-blue-600 dark:text-blue-400">
                        {correlation.strength.toFixed(2)}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{correlation.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Segment Analysis */}
        <div className="bg-gray-50 dark:bg-gray-800/50 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Segment Analysis</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {results.segmentAnalysis.segments.map((segment, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg">
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{segment.name}</h3>
                  <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                    Size: {segment.size}%
                  </span>
                </div>
                <div className="space-y-3">
                  <div>
                    <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">Characteristics</h4>
                    <ul className="list-disc pl-5 space-y-1">
                      {segment.characteristics.map((char, charIndex) => (
                        <li key={charIndex} className="text-gray-700 dark:text-gray-300">{char}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-600 dark:text-gray-400">Performance</h4>
                    <p className="text-gray-700 dark:text-gray-300">{segment.performance}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Visualizations */}
        {results.visualizations && (
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Key Metrics Visualization</h2>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={results.visualizations['bar chart']?.data || []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip contentStyle={{ 
                    backgroundColor: 'rgb(31 41 55)', 
                    border: 'none',
                    color: 'white'
                  }} />
                  <Legend />
                  <Bar dataKey="value" fill="#4f46e5" name="Sales Value" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {/* Final Recommendations */}
        <div className="bg-gray-50 dark:bg-gray-800/50 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">Recommendations</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">Data Collection</h3>
              <ul className="space-y-2">
                {results.recommendations.dataCollection.map((rec, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {rec}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">Analysis Improvements</h3>
              <ul className="space-y-2">
                {results.recommendations.analysis.map((rec, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {rec}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">Business Actions</h3>
              <ul className="space-y-2">
                {results.recommendations.business.map((rec, index) => (
                  <li key={index} className="text-gray-700 dark:text-gray-300 flex items-start">
                    <span className="mr-2">•</span>
                    {rec}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default {
  config: detailedReportConfig,
  createPrompt,
  parseResults,
  ResultsComponent: DetailedReportResults
};