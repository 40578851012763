import React, { useRef } from 'react';
import { BarChart2, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const summaryStatsConfig = {
  id: 'summary-stats',
  label: 'Summary Statistics',
  icon: <BarChart2 className="w-4 h-4" />,
  description: 'Comprehensive statistical analysis',
};

export const createPrompt = (data, columns) => {
  
  
  // Format columns to handle both old and new format
  const formattedColumns = columns.map(col => {
    const accessor = col.accessor || col.id;
    const sampleValue = data[0]?.[accessor];
    
    let type = 'string';
    if (typeof sampleValue === 'number') {
      type = 'number';
    } else if (!isNaN(Date.parse(sampleValue))) {
      type = 'date';
    }
    
    return {
      header: col.Header || col.id || accessor,
      accessor: accessor,
      type: type
    };
  }).filter(col => data.some(row => row[col.accessor] !== undefined));

  const tablePreview = data.map(row => {
    return formattedColumns.map(col => `${col.header}: ${row[col.accessor]}`).join(', ');
  }).join('\n');

  const prompt = `Analyze this dataset and provide comprehensive summary statistics in this exact JSON format:

{
  "numerical": {
    "columnName": {
      "mean": number,
      "median": number,
      "mode": number|null,
      "min": number,
      "max": number,
      "stdDev": number,
      "quartiles": [number, number, number],
      "outliers": [number]
    }
  },
  "categorical": {
    "columnName": {
      "uniqueCount": number,
      "mostCommon": {"value": number},
      "leastCommon": {"value": number},
      "distribution": {"category": "percentage"}
    }
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ]
}

Table Structure:
${formattedColumns.map(col => `${col.header} (${col.type})`).join('\n')}

Sample Data:
${tablePreview}`;

 
  return prompt;
};

export const parseResults = (apiResponse) => {
  
  
  try {
    // Extract everything between the first set of ```json tags
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);
    

    // Extract stats from summaryStats property
    if (parsedJson.summaryStats) {
      const stats = parsedJson.summaryStats;
      
      return {
        numerical: stats.numerical || {},
        categorical: stats.categorical || {},
        insights: stats.insights || []
      };
    }

    // If no summaryStats, check if we have direct stats
    if (parsedJson.numerical || parsedJson.categorical || parsedJson.insights) {
      
      return {
        numerical: parsedJson.numerical || {},
        categorical: parsedJson.categorical || {},
        insights: parsedJson.insights || []
      };
    }

    
    throw new Error('No statistics found in response');

  } catch (error) {
    
    return {
      numerical: {},
      categorical: {},
      insights: ['Failed to parse analysis results: ' + error.message]
    };
  }
};

export const SummaryStatsResults = ({ results }) => {
  const reportRef = useRef(null);
  console.log('Rendering results:', results);
  
  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('summary-statistics.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Numerical Statistics */}
        {Object.keys(results.numerical).length > 0 && (
          <div>
            <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
              Numerical Analysis
            </h4>
            <div className="grid grid-cols-2 gap-6">
              {Object.entries(results.numerical).map(([column, stats]) => (
                <div key={column} className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
                  <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 pb-2 border-b border-gray-200 dark:border-gray-700">
                    {column}
                  </h5>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(stats).map(([key, value]) => (
                      <div key={key} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                        <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">{key}</div>
                        <div className="text-gray-900 dark:text-gray-100 font-medium">
                          {Array.isArray(value) ? value.join(', ') : value}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Categorical Statistics */}
        {Object.keys(results.categorical).length > 0 && (
          <div>
            <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
              Categorical Analysis
            </h4>
            <div className="grid grid-cols-2 gap-6">
              {Object.entries(results.categorical).map(([column, stats]) => (
                <div key={column} className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
                  <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3 pb-2 border-b border-gray-200 dark:border-gray-700">
                    {column}
                  </h5>
                  <div className="space-y-4">
                    <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                      <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Total Unique Values</div>
                      <div className="text-gray-900 dark:text-gray-100 font-medium">{stats.uniqueCount}</div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                        <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Most Common</div>
                        <div className="text-gray-900 dark:text-gray-100 font-medium">
                          {stats.mostCommon.value}
                        </div>
                      </div>
                      <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                        <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Least Common</div>
                        <div className="text-gray-900 dark:text-gray-100 font-medium">
                          {stats.leastCommon.value}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                      <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">Distribution</div>
                      <div className="space-y-2">
                        {Object.entries(stats.distribution).map(([category, percentage]) => (
                          <div key={category} className="flex items-center justify-between">
                            <span className="text-gray-700 dark:text-gray-300">{category}</span>
                            <div className="flex items-center gap-2">
                              <div className="w-32 h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                                <div 
                                  className="h-full bg-green-500 dark:bg-green-600 rounded-full"
                                  style={{ width: percentage }}
                                />
                              </div>
                              <span className="text-gray-600 dark:text-gray-400 text-sm font-medium">
                                {percentage}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Key Insights */}
        {results.insights?.length > 0 && (
          <div>
            <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
              Key Insights
            </h4>
            <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
              <ul className="space-y-3">
                {results.insights.map((insight, index) => (
                  <li 
                    key={index}
                    className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                  >
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                      {index + 1}
                    </span>
                    <span>{insight}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  config: summaryStatsConfig,
  createPrompt,
  parseResults,
  ResultsComponent: SummaryStatsResults
};