import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Loader, MessageSquare, AlertCircle, Plus, X } from 'lucide-react';
import { Card } from '../ui/card';
import { Checkbox } from '../ui/checkbox';
import CellSelector from './CellSelector';
import { Input } from '../ui/input';

const CellEditor = ({ 
  value, 
  onSave, 
  onClose, 
  cellId,
  columnName,
  rowData,
  columnData,
  isGenerating,
  userPoints,
  onAiGenerate,
  columns,
  data // Add this
}) => {
  const [editValue, setEditValue] = useState(value);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [manualContextRange, setManualContextRange] = useState('');
  const [prompt, setPrompt] = useState('');
  const [showAiPrompt, setShowAiPrompt] = useState(false);
  const [error, setError] = useState('');
  const [includeRowContext, setIncludeRowContext] = useState(true);
const [includeColumnContext, setIncludeColumnContext] = useState(false);
  
const [selectedContextRanges, setSelectedContextRanges] = useState([]);

const handleAddContextRange = () => {
  if (manualContextRange && !selectedContextRanges.includes(manualContextRange)) {
    setSelectedContextRanges([...selectedContextRanges, manualContextRange]);
    setManualContextRange('');
  }
};

const handleRemoveContextRange = (index) => {
  setSelectedContextRanges(ranges => ranges.filter((_, i) => i !== index));
};


const handleGenerate = async () => {
  if (!prompt.trim()) {
    setError('Please enter a prompt');
    return;
  }

  if (userPoints < 3) {
    setError('Insufficient points. You need 3 points to generate content.');
    return;
  }

  try {
    // Process the selected ranges to get context
    const manualContext = selectedContextRanges.flatMap(range => {
      const [start, end] = range.split('-');
      // If no end is specified, use start as end
      const endPoint = end || start;
      
      // Extract column and row from start point
      const startCol = start.match(/[A-Z]+/)[0];
      const startRow = parseInt(start.match(/\d+/)[0]) - 1;
      
      // Extract column and row from end point
      const endCol = endPoint.match(/[A-Z]+/)[0];
      const endRow = parseInt(endPoint.match(/\d+/)[0]) - 1;
      
      // Get all column letters between start and end
      const colRange = [];
      let currentCol = startCol;
      while (currentCol <= endCol) {
        colRange.push(currentCol);
        currentCol = String.fromCharCode(currentCol.charCodeAt(0) + 1);
      }
      
      // Collect values
      const values = [];
      for (let row = startRow; row <= endRow; row++) {
        if (data[row]) {
          for (const col of colRange) {
            const value = data[row][col];
            if (value) values.push(`${col}${row + 1}: ${value}`);
          }
        }
      }
      return values;
    });

    // Close the editor immediately
    onClose();
    
    // Generate content with the manual context included
    const generatedContent = await onAiGenerate(prompt, {
      includeRowContext,
      includeColumnContext,
      manualContext: manualContext.length > 0 ? `Selected cell values:\n${manualContext.join('\n')}\n` : ''
    });
    
    onSave(generatedContent);
  } catch (err) {
    console.error('Generation error:', err);
  }
};

  const handleSave = () => {
    onSave(editValue);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <Card className="w-full max-w-2xl bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl text-gray-900 dark:text-gray-100">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <h3 className="text-lg font-semibold">Edit Cell: {columnName}</h3>
          </div>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="w-5 h-5" />
          </Button>
        </div>

        <div className="space-y-4">
          {/* Manual Edit Section */}
          <div>
            <label className="block text-sm font-medium mb-1">Cell Content</label>
            <textarea
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              className="w-full p-3 rounded border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900"
              rows={4}
            />
          </div>

          {/* AI Section Toggle */}
          <Button 
            variant="ghost" 
            onClick={() => setShowAiPrompt(!showAiPrompt)}
            className="flex items-center gap-2"
          >
            <MessageSquare className="w-4 h-4" />
            {showAiPrompt ? 'Hide AI Prompt' : 'Show AI Prompt'}
          </Button>

          {/* AI Section */}
{showAiPrompt && (
  <div className="space-y-4 border-t pt-4">
    {/* Context Options */}
    <div className="flex flex-col gap-2">
      <label className="text-sm font-medium">Context Options:</label>
      <div className="flex gap-4">
      <div className="flex items-center space-x-2">
  <Checkbox 
    id="rowContext"
    checked={includeRowContext}
    onCheckedChange={setIncludeRowContext}
    className="border-gray-300 dark:border-white data-[state=checked]:border-white"
  />
  <label htmlFor="rowContext" className="block text-sm font-medium mb-1">
    Include row context
  </label>
</div>
<div className="flex items-center space-x-2">
  <Checkbox 
    id="columnContext"
    checked={includeColumnContext}
    onCheckedChange={setIncludeColumnContext}
    className="border-gray-300 dark:border-white data-[state=checked]:border-white"
  />
  <label htmlFor="columnContext" className="block text-sm font-medium mb-1">
    Include column context
  </label>
</div>
      </div>
    </div>

    <div className="space-y-2">
      <label className="block text-sm font-medium">Manual Context Selection:</label>
      <Button
  variant="outline"
  size="sm"
  onClick={() => setIsSelectorOpen(true)}
  className="whitespace-nowrap flex items-center gap-2"
>
  <Plus className="h-4 w-4" />
  Select Cells for Context
</Button>
{selectedContextRanges.length > 0 && (
  <div className="flex flex-wrap gap-2 mt-2">
    {selectedContextRanges.map((range, index) => (
      <div 
        key={index} 
        className="flex items-center bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 px-2 py-1 rounded-md text-sm"
      >
        <span>{range}</span>
        <Button
          variant="ghost"
          size="sm"
          className="h-4 w-4 ml-1 hover:bg-transparent"
          onClick={() => handleRemoveContextRange(index)}
        >
          <X className="h-3 w-3" />
        </Button>
      </div>
    ))}
    </div>
)}
      {isSelectorOpen && (
  <CellSelector
    isOpen={isSelectorOpen}
    onClose={() => setIsSelectorOpen(false)}
    onSelect={(ranges) => {
      setSelectedContextRanges(ranges);
      setIsSelectorOpen(false);
    }}
    data={data}
    columns={columns}
    existingSelections={selectedContextRanges}
  />
)}
    </div>

    {/* AI Prompt Input */}
    <div>
                <label className="block text-sm font-medium mb-1">AI Prompt</label>
                <textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter your prompt for AI generation..."
                  className="w-full p-3 rounded border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900"
                  rows={3}
                />
              </div>

              {/* Points Info */}
              <div className="flex items-center justify-between text-sm text-gray-500">
                <span>Cost: 3 points</span>
                <span>Your balance: {userPoints} points</span>
              </div>

              {/* Generate Button */}
              <Button 
                onClick={handleGenerate}
                disabled={isGenerating || !prompt.trim()}
                className="w-full"
              >
                {isGenerating ? (
                  <>
                    <Loader className="w-4 h-4 animate-spin mr-2" />
                    Generating...
                  </>
                ) : (
                  'Generate with AI'
                )}
              </Button>
            </div>
          )}

          {/* Error Display */}
          {error && (
            <div className="flex items-center space-x-2 text-red-500 bg-red-50 dark:bg-red-900/30 p-2 rounded">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end space-x-2 pt-4 border-t">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CellEditor;