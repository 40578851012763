import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import { motion, AnimatePresence } from 'framer-motion';
import { Smartphone } from 'lucide-react';
import CompetitorChart from '../../components/CompetitorChart';
import {
  Search,
  ArrowRight,
  Star,
  Target,
  BarChart2,
  Globe,
  Trophy,
  ChevronDown,
  LineChart,
  Settings,
  FileText,
  Zap,
  Activity,
  Check,
  Users,
  Eye,
  TrendingUp,
  AlertCircle,
  Clock,
  CheckCircle,
  Brain,
  Laptop,
  Rocket,
  Lock,
  Shield,
  Award,
  Sparkles
} from 'lucide-react';

const SeoOptimizerLanding = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeMetric, setActiveMetric] = useState(0);
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedTab, setSelectedTab] = useState('onPage');
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const parallaxOffset = scrollY * 0.5;

  const metrics = [
    {
      value: "93%",
      label: "Higher Rankings",
      description: "Average improvement in search positions",
      icon: TrendingUp,
      color: "from-blue-500 to-cyan-500"
    },
    {
      value: "217%",
      label: "More Traffic",
      description: "Increase in organic search traffic",
      icon: Users,
      color: "from-purple-500 to-pink-500"
    },
    {
      value: "4.8x",
      label: "ROI",
      description: "Return on investment from SEO",
      icon: Trophy,
      color: "from-amber-500 to-orange-500"
    }
  ];

  const features = [
    {
      title: "AI-Powered Analysis",
      description: "Real-time content optimization using advanced machine learning",
      icon: Brain,
      gradient: "from-cyan-400 to-blue-500"
    },
    {
      title: "Competitor Intelligence",
      description: "Deep insights into competitor strategies and rankings",
      icon: Eye,
      gradient: "from-purple-400 to-pink-500"
    },
    {
      title: "Technical SEO",
      description: "Comprehensive technical optimization and monitoring",
      icon: Settings,
      gradient: "from-amber-400 to-orange-500"
    }
  ];

  return (
    <>
      <SEOHead 
        title="AI-Powered SEO Optimization Tools & Software | Rank Higher in 2024"
        description="Transform your website's SEO with our AI-powered optimization tools. Get higher rankings, more traffic, and better ROI. Try our advanced SEO software today."
        keywords="SEO tools, AI SEO, search engine optimization, SEO software, website ranking, SEO optimization"
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          {/* Animated gradient background */}
          <div className="absolute inset-0 bg-gradient-radial from-blue-500/20 via-transparent to-transparent animate-pulse-slow" />
          
          {/* Floating elements */}
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute hidden md:block"
              initial={{ opacity: 0, scale: 0 }}
              animate={{ 
                opacity: [0.5, 0.8, 0.5],
                scale: [1, 1.2, 1],
                x: [0, 20, 0],
                y: [0, -20, 0]
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                delay: i * 0.7,
                ease: "easeInOut"
              }}
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
            >
              <div className="w-4 h-4 md:w-8 md:h-8 rounded-full bg-blue-500/20 backdrop-blur-sm" />
            </motion.div>
          ))}

          <div className="relative z-10 max-w-7xl mx-auto px-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <h1 className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-400">
                Next-Gen SEO
              </h1>
              <p className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Harness AI to dominate search rankings and drive organic growth
              </p>

              <div className="flex flex-col md:flex-row gap-4 justify-center">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link
                    to="/register"
                    className="px-8 py-4 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full font-medium inline-flex items-center group"
                  >
                    Start Optimizing Free
                    <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a
                    href="#features"
                    onClick={(e) => {
                      e.preventDefault();
                      document.querySelector('#features')?.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'start'
                      });
                    }}
                    className="px-8 py-4 bg-white/10 backdrop-blur-sm rounded-full font-medium hover:bg-white/20 transition-all"
                  >
                    Explore Features
                  </a>
                </motion.div>
              </div>
            </motion.div>

            {/* Floating Metrics */}
            <div className="mt-16 grid md:grid-cols-3 gap-8">
              {metrics.map((metric, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 + index * 0.2 }}
                  className="relative group"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-xl blur-xl group-hover:blur-2xl transition-all" />
                  <div className="relative bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700">
                    <metric.icon className="h-8 w-8 mb-4 text-gray-400" />
                    <h3 className="text-3xl font-bold bg-gradient-to-r bg-clip-text text-transparent from-blue-400 to-cyan-400">
                      {metric.value}
                    </h3>
                    <p className="text-lg font-medium text-gray-300 mb-2">{metric.label}</p>
                    <p className="text-sm text-gray-400">{metric.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

{/* Features Showcase */}
<section id="features" className="py-24 bg-black relative overflow-hidden">
          {/* Animated grid background */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#808080_1px,transparent_1px),linear-gradient(to_bottom,#808080_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-[0.2]" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Intelligent SEO Features
              </h2>
              <p className="text-gray-400 text-xl">
                Powered by advanced AI algorithms
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="group relative"
                >
                  {/* Glowing background effect */}
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-cyan-500/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  
                  <div className="relative bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 p-8 rounded-xl overflow-hidden">
                    {/* Animated corner accent */}
                    <div className="absolute top-0 right-0 w-16 h-16">
                      <div className="absolute top-0 right-0 w-16 h-16 bg-gradient-to-r from-blue-500/20 to-cyan-500/20 transform rotate-45 translate-x-8 -translate-y-8 group-hover:translate-x-4 group-hover:-translate-y-4 transition-transform duration-500" />
                    </div>

                    <div className={`w-16 h-16 rounded-full bg-gradient-to-r ${feature.gradient} p-3 mb-6`}>
                      <feature.icon className="w-10 h-10 text-white" />
                    </div>

                    <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                    <p className="text-gray-400 mb-6">{feature.description}</p>

                    {/* Feature details list */}
                    <ul className="space-y-3">
                      {[1, 2, 3].map((item, i) => (
                        <motion.li
                          key={i}
                          initial={{ opacity: 0, x: -10 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ delay: (index * 0.2) + (i * 0.1) }}
                          className="flex items-center space-x-2"
                        >
                          <CheckCircle className="h-5 w-5 text-cyan-500" />
                          <span className="text-gray-300">Feature Detail {item}</span>
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Live SEO Analysis Demo */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          {/* Animated particles */}
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-2 h-2 bg-blue-500 rounded-full"
              animate={{
                y: ["0vh", "100vh"],
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: Math.random() * 5 + 5,
                repeat: Infinity,
                delay: Math.random() * 5,
              }}
              style={{
                left: `${Math.random() * 100}%`,
                top: "-5%",
              }}
            />
          ))}

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="space-y-6"
              >
                <h2 className="text-4xl md:text-5xl font-bold">
                  Real-Time SEO Analysis
                </h2>
                <p className="text-xl text-gray-400">
                  Watch your SEO score improve as you optimize your content
                </p>

                <div className="space-y-4">
                  {[
                    "Instant keyword analysis",
                    "Live content optimization",
                    "Real-time ranking predictions",
                    "Competitor tracking updates"
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-center space-x-3"
                    >
                      <div className="w-6 h-6 rounded-full bg-gradient-to-r from-blue-500 to-cyan-500 flex items-center justify-center">
                        <Check className="h-4 w-4 text-white" />
                      </div>
                      <span className="text-gray-300">{item}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Interactive Demo Interface */}
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-xl blur-xl" />
                <div className="relative bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6">
                  {/* Demo content here */}
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-semibold">SEO Analysis Dashboard</h3>
                    <div className="flex items-center space-x-2">
                      <Clock className="h-5 w-5 text-cyan-500" />
                      <span className="text-sm text-gray-400">Analyzing...</span>
                    </div>
                  </div>

                  {/* SEO Score Meter */}
                  <div className="mb-8">
                    <div className="relative h-4 bg-gray-700 rounded-full overflow-hidden">
                      <motion.div
                        className="absolute h-full bg-gradient-to-r from-blue-500 to-cyan-500"
                        initial={{ width: "0%" }}
                        whileInView={{ width: "85%" }}
                        viewport={{ once: true }}
                        transition={{ duration: 1.5, ease: "easeOut" }}
                      />
                    </div>
                    <div className="flex justify-between mt-2">
                      <span className="text-sm text-gray-400">SEO Score</span>
                      <span className="text-sm font-medium text-cyan-500">85/100</span>
                    </div>
                  </div>

                  {/* Continue with more demo interface elements... */}
                </div>
              </motion.div>
            </div>
          </div>
        </section>

{/* Success Stories & Case Studies */}
<section className="py-24 bg-black relative overflow-hidden">
          {/* Animated circuit board pattern */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-20" xmlns="http://www.w3.org/2000/svg">
              <pattern id="circuit-board" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
                <path d="M0 0h100v100H0z" fill="none" />
                <path d="M10 10h80v80H10z" stroke="rgba(59, 130, 246, 0.2)" strokeWidth="1" fill="none" />
                <circle cx="50" cy="50" r="2" fill="rgba(59, 130, 246, 0.2)" />
                <path d="M50 10v40M10 50h40" stroke="rgba(59, 130, 246, 0.2)" strokeWidth="1" />
              </pattern>
              <rect width="100%" height="100%" fill="url(#circuit-board)" />
            </svg>
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">Success Stories</h2>
              <p className="text-gray-400 text-xl">Real results from real businesses</p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  company: "TechStart Inc",
                  industry: "SaaS",
                  improvement: "312%",
                  metric: "Organic Traffic Growth",
                  quote: "Transformed our search presence completely",
                  duration: "3 months"
                },
                {
                  company: "E-commerce Plus",
                  industry: "Retail",
                  improvement: "892%",
                  metric: "Revenue from SEO",
                  quote: "Rankings skyrocketed across all keywords",
                  duration: "6 months"
                },
                {
                  company: "Content Hub",
                  industry: "Media",
                  improvement: "245%",
                  metric: "Conversion Rate",
                  quote: "AI-driven insights changed everything",
                  duration: "4 months"
                }
              ].map((story, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="group relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  
                  <div className="relative h-full bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 p-8 rounded-xl">
                    <div className="flex items-center justify-between mb-6">
                      <div>
                        <h3 className="text-xl font-bold">{story.company}</h3>
                        <p className="text-gray-400">{story.industry}</p>
                      </div>
                      <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500/20 to-cyan-500/20 flex items-center justify-center">
                        <Trophy className="h-6 w-6 text-cyan-500" />
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
                        {story.improvement}
                      </div>
                      <p className="text-gray-300">{story.metric}</p>
                    </div>

                    <blockquote className="mb-4 text-gray-300 italic">
                      "{story.quote}"
                    </blockquote>

                    <div className="flex items-center justify-between text-sm text-gray-400">
                      <span>Results in {story.duration}</span>
                      <button className="text-cyan-500 hover:text-cyan-400 transition-colors">
                        View Case Study →
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Competitor Analysis Visualization */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Advanced Competitor Analysis
              </h2>
              <p className="text-gray-400 text-xl">
                Uncover your competitors' strategies in real-time
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="space-y-8">
                {[
                  {
                    title: "Keyword Gap Analysis",
                    description: "Discover untapped ranking opportunities",
                    icon: Search,
                    color: "from-blue-500 to-cyan-500"
                  },
                  {
                    title: "Content Strategy Insights",
                    description: "Learn what's working in your niche",
                    icon: FileText,
                    color: "from-purple-500 to-pink-500"
                  },
                  {
                    title: "Backlink Intelligence",
                    description: "Track and replicate competitor backlinks",
                    icon: Globe,
                    color: "from-amber-500 to-orange-500"
                  }
                ].map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.2 }}
                    className="flex items-start space-x-4"
                  >
                    <div className={`w-12 h-12 rounded-xl bg-gradient-to-r ${feature.color} p-2.5 flex-shrink-0`}>
                      <feature.icon className="w-7 h-7 text-white" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>

              {/* Interactive Competitor Comparison Chart */}
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-xl blur-xl" />
                <div className="relative bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6">
                  {/* Chart content will go here */}
                  {/* Replace the existing placeholder */}
<div className="h-[400px] flex items-center justify-center">
  <CompetitorChart />
</div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

       {/* Technical SEO Audit Flow */}
       <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#808080_1px,transparent_1px),linear-gradient(to_bottom,#808080_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-[0.2]" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">Technical SEO Mastery</h2>
              <p className="text-gray-400 text-xl">Complete technical optimization in minutes</p>
            </motion.div>

            <div className="grid md:grid-cols-4 gap-8">
              {[
                { 
                  title: "Site Speed",
                  score: 98,
                  icon: Zap,
                  color: "from-green-500 to-emerald-500"
                },
                {
                  title: "Mobile UX",
                  score: 95,
                  icon: Smartphone,
                  color: "from-blue-500 to-cyan-500"
                },
                {
                  title: "Security",
                  score: 100,
                  icon: Shield,
                  color: "from-purple-500 to-pink-500"
                },
                {
                  title: "Core Web Vitals",
                  score: 97,
                  icon: Activity,
                  color: "from-amber-500 to-orange-500"
                }
              ].map((metric, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-cyan-500/10 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  
                  <div className="relative bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 p-6 rounded-xl">
                    <div className="flex justify-between items-start mb-4">
                      <metric.icon className="h-8 w-8 text-gray-400" />
                      <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
                        {metric.score}/100
                      </div>
                    </div>
                    <h3 className="text-lg font-semibold mb-2">{metric.title}</h3>
                    <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
                      <motion.div
                        className={`h-full bg-gradient-to-r ${metric.color}`}
                        initial={{ width: "0%" }}
                        whileInView={{ width: `${metric.score}%` }}
                        viewport={{ once: true }}
                        transition={{ duration: 1, delay: index * 0.2 }}
                      />
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Advanced FAQ System */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">Common Questions</h2>
              <p className="text-gray-400 text-xl">Everything you need to know</p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  q: "How does AI improve SEO results?",
                  a: "Our AI analyzes millions of top-ranking pages to understand search patterns and ranking factors, providing real-time optimization suggestions that would take hours manually."
                },
                {
                  q: "What makes your tool different?",
                  a: "Unlike traditional SEO tools, we provide real-time, AI-powered recommendations that adapt to your specific content and industry, ensuring higher rankings and better ROI."
                },
                {
                  q: "How quickly can I see results?",
                  a: "Most users see significant ranking improvements within 30 days, with full results typically visible within 90 days of implementing our recommendations."
                },
                {
                  q: "Is it compatible with my CMS?",
                  a: "Yes! We support all major platforms including WordPress, Shopify, Wix, and custom CMS solutions through our API and direct integrations."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative"
                >
                  <div className="relative bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 p-6 rounded-xl">
                    <h3 className="text-xl font-semibold mb-3">{faq.q}</h3>
                    <p className="text-gray-400">{faq.a}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">Ready to Dominate Search Rankings?</h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of businesses already using our AI-powered SEO tools to drive massive organic growth
              </p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full font-medium hover:from-blue-600 hover:to-cyan-600 transition-all transform hover:scale-105"
                >
                  Get Started Free
                </Link>
                <a
                  href="#demo"
                  className="px-8 py-4 bg-white/10 backdrop-blur-sm rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  Schedule Demo
                </a>
              </motion.div>

              <div className="mt-12 flex justify-center items-center space-x-8 text-gray-400">
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-cyan-500 mr-2" />
                  <span>14-day free trial</span>
                </div>
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-cyan-500 mr-2" />
                  <span>No credit card required</span>
                </div>
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-cyan-500 mr-2" />
                  <span>Cancel anytime</span>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SeoOptimizerLanding;