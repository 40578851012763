import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@radix-ui/react-dialog';
import { Button } from '../ui/button';
import { X } from 'lucide-react';

const CellSelector = ({
  isOpen,
  onClose,
  onSelect,
  data,
  columns,
  existingSelections = []
}) => {
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedRanges, setSelectedRanges] = useState(existingSelections);
  
  // Reset state when dialog opens
  useEffect(() => {
    if (isOpen) {
      setSelectionStart(null);
      setSelectionEnd(null);
      setIsDragging(false);
      setSelectedRanges(existingSelections);
    }
  }, [isOpen, existingSelections]);

  const handleDeselection = (row, col) => {
    if (!isDragging && isInSavedSelection(row, col)) {
      // Find and remove the range(s) that contain this cell
      setSelectedRanges(ranges => ranges.filter(range => {
        const [start, end] = range.split('-');
        const startCol = start.match(/[A-Z]+/)[0];
        const startRow = parseInt(start.match(/\d+/)[0]) - 1;
        const endCol = end.match(/[A-Z]+/)[0];
        const endRow = parseInt(end.match(/\d+/)[0]) - 1;
        
        const colIndex = columns.findIndex(c => c.id === columns[col].id);
        const startColIndex = columns.findIndex(c => c.id === startCol);
        const endColIndex = columns.findIndex(c => c.id === endCol);
        
        // Return true to keep ranges that don't contain this cell
        return !(row >= startRow && row <= endRow && 
                colIndex >= startColIndex && colIndex <= endColIndex);
      }));
    }
  };
  
  const handleMouseDown = (row, col) => {
    setIsDragging(true);
    setSelectionStart({ row, col });
    setSelectionEnd({ row, col });
  };
  
  const handleMouseMove = useCallback((row, col) => {
    if (isDragging) {
      setSelectionEnd({ row, col });
    }
  }, [isDragging]);
  
  const handleMouseUp = () => {
    if (isDragging && selectionStart && selectionEnd) {
      const startRow = Math.min(selectionStart.row, selectionEnd.row);
      const endRow = Math.max(selectionStart.row, selectionEnd.row);
      const startCol = columns[Math.min(selectionStart.col, selectionEnd.col)].id;
      const endCol = columns[Math.max(selectionStart.col, selectionEnd.col)].id;
      
      // If this is a deselection operation (clicking on already selected cells)
      if (isInSavedSelection(startRow, selectionStart.col)) {
        const rangesToRemove = [];
        for (let row = startRow; row <= endRow; row++) {
          for (let col = Math.min(selectionStart.col, selectionEnd.col); 
               col <= Math.max(selectionStart.col, selectionEnd.col); col++) {
            rangesToRemove.push({ row, col: columns[col].id });
          }
        }
        
        let updatedRanges = [...selectedRanges];
        rangesToRemove.forEach(cell => {
          updatedRanges = removeFromRange(cell);
        });
        
        setSelectedRanges(updatedRanges);
      } else {
        // Normal selection operation
        const newRange = `${startCol}${startRow + 1}-${endCol}${endRow + 1}`;
        if (!selectedRanges.includes(newRange)) {
          setSelectedRanges([...selectedRanges, newRange]);
        }
      }
    }
    
    setIsDragging(false);
    setSelectionStart(null);
    setSelectionEnd(null);
  };

  

  const isInCurrentSelection = (row, col) => {
    if (!selectionStart || !selectionEnd || !isDragging) return false;
    
    const minRow = Math.min(selectionStart.row, selectionEnd.row);
    const maxRow = Math.max(selectionStart.row, selectionEnd.row);
    const minCol = Math.min(selectionStart.col, selectionEnd.col);
    const maxCol = Math.max(selectionStart.col, selectionEnd.col);
    
    return row >= minRow && row <= maxRow && col >= minCol && col <= maxCol;
  };

  const isInSavedSelection = (row, col) => {
    return selectedRanges.some(range => {
      const [start, end] = range.split('-');
      const startCol = start.match(/[A-Z]+/)[0];
      const startRow = parseInt(start.match(/\d+/)[0]) - 1;
      const endCol = end.match(/[A-Z]+/)[0];
      const endRow = parseInt(end.match(/\d+/)[0]) - 1;
      
      const colIndex = columns.findIndex(c => c.id === columns[col].id);
      const startColIndex = columns.findIndex(c => c.id === startCol);
      const endColIndex = columns.findIndex(c => c.id === endCol);
      
      return row >= startRow && row <= endRow && 
             colIndex >= startColIndex && colIndex <= endColIndex;
    });
  };

  const removeFromRange = (cellToRemove) => {
    // Convert cell coordinates to row and column indices
    const rowIndex = parseInt(cellToRemove.row);
    const colIndex = columns.findIndex(c => c.id === cellToRemove.col);
  
    return selectedRanges.reduce((newRanges, range) => {
      const [start, end] = range.split('-');
      const startCol = start.match(/[A-Z]+/)[0];
      const startRow = parseInt(start.match(/\d+/)[0]);
      const endCol = end.match(/[A-Z]+/)[0];
      const endRow = parseInt(end.match(/\d+/)[0]);
      
      const startColIndex = columns.findIndex(c => c.id === startCol);
      const endColIndex = columns.findIndex(c => c.id === endCol);
      
      // If cell is not in this range, keep range as is
      if (rowIndex + 1 < startRow || rowIndex + 1 > endRow || 
          colIndex < startColIndex || colIndex > endColIndex) {
        return [...newRanges, range];
      }
  
      // Split the range into new ranges excluding the deselected cell
      const newRangeParts = [];
  
      // Top section (if exists)
      if (rowIndex + 1 > startRow) {
        newRangeParts.push(`${startCol}${startRow}-${endCol}${rowIndex}`);
      }
  
      // Bottom section (if exists)
      if (rowIndex + 1 < endRow) {
        newRangeParts.push(`${startCol}${rowIndex + 2}-${endCol}${endRow}`);
      }
  
      // Left section (if exists)
      if (colIndex > startColIndex) {
        newRangeParts.push(`${startCol}${startRow}-${columns[colIndex - 1].id}${endRow}`);
      }
  
      // Right section (if exists)
      if (colIndex < endColIndex) {
        newRangeParts.push(`${columns[colIndex + 1].id}${startRow}-${endCol}${endRow}`);
      }
  
      return [...newRanges, ...newRangeParts.filter(range => {
        const [s, e] = range.split('-');
        return s !== e; // Filter out single-cell ranges
      })];
    }, []);
  };

  const removeRange = (rangeToRemove) => {
    setSelectedRanges(ranges => ranges.filter(range => range !== rangeToRemove));
  };

  
  const handleConfirm = () => {
    onSelect(selectedRanges);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <div className="fixed inset-0 z-50 flex items-center justify-center" onClick={() => onClose()}>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full max-h-[80vh] overflow-hidden" onClick={e => e.stopPropagation()}>
          <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
            <h2 className="text-lg font-semibold">Select Cells for Context</h2>
            <Button variant="ghost" size="icon" onClick={onClose}>
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="p-4 space-y-4">
            {/* Selected Ranges Display */}
            <div className="flex flex-wrap gap-2 min-h-[32px]">
              {selectedRanges.map((range, index) => (
                <div 
                  key={index}
                  className="flex items-center bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-200 px-2 py-1 rounded-md text-sm"
                >
                  <span>{range}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-4 w-4 ml-1 hover:bg-transparent"
                    onClick={() => removeRange(range)}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </div>
              ))}
            </div>

            {/* Grid */}
            <div className="overflow-auto max-h-[50vh]">
              <table className="w-full border-collapse select-none">
                <thead>
                  <tr>
                    <th className="w-12 bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 sticky top-0 left-0 z-20"></th>
                    {columns.map((col, index) => (
                      <th 
                        key={col.id}
                        className="w-20 px-2 py-1 bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 sticky top-0 z-10 text-center"
                      >
                        {col.id}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 20 }).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="w-12 px-2 py-1 bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 sticky left-0 z-10 text-center">
                        {rowIndex + 1}
                      </td>
                      {columns.map((col, colIndex) => (
                        <td
                        key={`${rowIndex}-${col.id}`}
                        className={`w-20 h-8 border border-gray-200 dark:border-gray-700 text-center cursor-pointer transition-colors
                          ${isInCurrentSelection(rowIndex, colIndex) ? 'bg-blue-100 dark:bg-blue-900/30' : ''}
                          ${isInSavedSelection(rowIndex, colIndex) ? 'bg-green-100 dark:bg-green-900/30' : ''}
                          hover:bg-gray-50 dark:hover:bg-gray-700`}
                        onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                        onMouseMove={() => handleMouseMove(rowIndex, colIndex)}
                        onMouseUp={handleMouseUp}
                        onClick={() => handleDeselection(rowIndex, colIndex)}
                      >
                        <div className="px-2 py-1 truncate max-w-[80px] max-h-[32px] overflow-hidden">
                          {data[rowIndex]?.[col.id] || ''}
                        </div>
                      </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          <div className="p-4 border-t border-gray-200 dark:border-gray-700 flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose}>Cancel</Button>
            <Button onClick={handleConfirm}>Confirm Selection</Button>
          </div>
        </div>
      </div>
      </div>
    </Dialog>
  );
};

export default CellSelector;