import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight, Copy, Check, Eye, EyeOff, Undo } from 'lucide-react';

const BoxShadowGenerator = () => {
  const [settings, setSettings] = useState({
    horizontalOffset: 0,
    verticalOffset: 0,
    blur: 10,
    spread: 0,
    color: '#000000',
    opacity: 20,
    inset: false,
    multiple: false
  });

  const [presets, setPresets] = useState([
    { name: 'Subtle', values: { horizontalOffset: 0, verticalOffset: 2, blur: 4, spread: 0, opacity: 10 } },
    { name: 'Medium', values: { horizontalOffset: 0, verticalOffset: 4, blur: 8, spread: 0, opacity: 15 } },
    { name: 'Strong', values: { horizontalOffset: 0, verticalOffset: 8, blur: 16, spread: 0, opacity: 20 } },
    { name: 'Layered', values: { horizontalOffset: 0, verticalOffset: 4, blur: 6, spread: -1, opacity: 10, multiple: true } },
  ]);

  const [copied, setCopied] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [previewBg, setPreviewBg] = useState('light'); // New state for background toggle

  const generateShadow = () => {
    const rgba = `rgba(0, 0, 0, ${settings.opacity / 100})`;
    const baseShadow = `${settings.horizontalOffset}px ${settings.verticalOffset}px ${settings.blur}px ${settings.spread}px ${rgba}`;
    
    if (settings.multiple) {
      return `${settings.inset ? 'inset ' : ''}${baseShadow}, 
              ${settings.horizontalOffset}px ${settings.verticalOffset * 2}px ${settings.blur * 2}px ${settings.spread}px rgba(0, 0, 0, ${settings.opacity / 200})`;
    }
    
    return `${settings.inset ? 'inset ' : ''}${baseShadow}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`box-shadow: ${generateShadow()};`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const resetSettings = () => {
    setSettings({
      horizontalOffset: 0,
      verticalOffset: 0,
      blur: 10,
      spread: 0,
      color: '#000000',
      opacity: 20,
      inset: false,
      multiple: false
    });
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="bg-gray-800 rounded-xl p-6 space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Shadow Settings</h3>
              <button
                onClick={resetSettings}
                className="flex items-center space-x-1 text-sm text-gray-400 hover:text-white transition-colors"
              >
                <Undo className="h-4 w-4" />
                <span>Reset</span>
              </button>
            </div>

            {Object.entries({
              'Horizontal Offset': 'horizontalOffset',
              'Vertical Offset': 'verticalOffset',
              'Blur Radius': 'blur',
              'Spread Radius': 'spread',
              'Opacity': 'opacity'
            }).map(([label, key]) => (
              <div key={key} className="space-y-2">
                <div className="flex justify-between">
                  <label className="text-sm">{label}</label>
                  <span className="text-sm text-gray-400">
                    {settings[key]}{key === 'opacity' ? '%' : 'px'}
                  </span>
                </div>
                <input
                  type="range"
                  min={key === 'opacity' ? 0 : -50}
                  max={key === 'opacity' ? 100 : 50}
                  value={settings[key]}
                  onChange={(e) => setSettings(prev => ({ ...prev, [key]: parseInt(e.target.value) }))}
                  className="w-full"
                />
              </div>
            ))}

            <div className="flex items-center justify-between">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={settings.inset}
                  onChange={(e) => setSettings(prev => ({ ...prev, inset: e.target.checked }))}
                  className="rounded"
                />
                <span>Inset Shadow</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={settings.multiple}
                  onChange={(e) => setSettings(prev => ({ ...prev, multiple: e.target.checked }))}
                  className="rounded"
                />
                <span>Double Shadow</span>
              </label>
            </div>
          </div>

          <div className="bg-gray-800 rounded-xl p-6 space-y-4">
            <h3 className="text-lg font-medium">Presets</h3>
            <div className="grid grid-cols-2 gap-2">
              {presets.map((preset) => (
                <button
                  key={preset.name}
                  onClick={() => setSettings(prev => ({ ...prev, ...preset.values }))}
                  className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors text-sm"
                >
                  {preset.name}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-800 rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Preview</h3>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setPreviewBg(prev => prev === 'light' ? 'dark' : 'light')}
                    className="px-3 py-1 text-sm bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    {previewBg === 'light' ? 'Dark' : 'Light'} Background
                  </button>
                  <button
                    onClick={() => setShowPreview(!showPreview)}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    {showPreview ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                  </button>
                </div>
              </div>
            </div>
            
            {showPreview && (
              <div className={`relative h-64 rounded-lg overflow-hidden ${previewBg === 'light' ? 'bg-gray-100' : 'bg-gray-900'}`}>
                <div className="absolute inset-0 bg-grid-pattern opacity-5" />
                <div className="relative h-full flex items-center justify-center p-8">
                  <motion.div 
                    className={`w-32 h-32 rounded-lg ${previewBg === 'light' ? 'bg-white' : 'bg-gray-800'}`}
                    style={{ boxShadow: generateShadow() }}
                    animate={{ boxShadow: generateShadow() }}
                  />
                </div>
              </div>
            )}

            <div className="mt-4 relative">
              <pre className="bg-gray-900 rounded-lg p-4 text-sm overflow-x-auto">
                <code>box-shadow: {generateShadow()};</code>
              </pre>
              <button
                onClick={handleCopy}
                className="absolute right-2 top-2 p-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
              >
                {copied ? <Check className="h-4 w-4 text-green-500" /> : <Copy className="h-4 w-4" />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Create Professional Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Our AI-powered tools help you create engaging content that stands out. From blog posts to social media, we've got you covered. Get started with 1000 free credits!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BoxShadowGenerator;