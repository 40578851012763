import React from 'react';
import { X } from 'lucide-react';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';

const AnalysisPanel = ({ isOpen, onClose, results, toolType, isLoading }) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black/30 z-40"
        onClick={onClose}
      />
      
      {/* Panel */}
<div 
  className="fixed left-1/2 bottom-32 -translate-x-1/2 w-[900px] h-[60vh] z-50 transform transition-all duration-300 ease-in-out flex flex-col"
>
  <Card className="h-full bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 shadow-2xl flex flex-col">
    <div className="flex-none flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
      <div className="flex items-center space-x-2">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Analysis Results
        </h3>
        <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400 rounded-full">
          {toolType?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
        </span>
      </div>
      <Button
        variant="ghost"
        size="sm"
        onClick={onClose}
        className="hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-white"
      >
        <X className="h-4 w-4 text-gray-500 dark:text-white" />
      </Button>
    </div>

    <div className="flex-1 overflow-auto p-6">
      {isLoading ? (
        <div className="space-y-4">
          <div className="h-8 w-3/4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="h-32 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
            ))}
          </div>
        </div>
      ) : results ? (
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {results}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-12 text-gray-500 dark:text-gray-400">
          <div className="text-lg font-medium">No analysis results available</div>
          <p className="mt-2 text-sm">Try selecting a different analysis tool</p>
        </div>
      )}
    </div>
  </Card>
</div>
    </>
  );
};

export default AnalysisPanel;