import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { X } from 'lucide-react';
import { Checkbox } from '../ui/checkbox';
import TableAIPrompt from './TableAIPrompt';

const PromptBox = ({ 
  isMultiSelect, 
  selectedCells, 
  userPoints, 
  prompt, 
  setPrompt, 
  handleBulkGenerate, 
  setColumns,
  handleClearSelection,
  data,
  columns,
  setData,
  saveToHistory
}) => {
  const [includeRowContext, setIncludeRowContext] = useState(false);
  const [includeColumnContext, setIncludeColumnContext] = useState(false);

  // Handle keyboard shortcuts for multiple selection
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isMultiSelect) return;

      // Prevent handling if focus is in textarea
      if (document.activeElement.tagName === 'TEXTAREA') return;

      if (e.key === 'Escape') {
        handleClearSelection();
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault();
        handleClearSelection();
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
        e.preventDefault();
        // Handle select all
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        e.preventDefault();
        // Handle copy
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        e.preventDefault();
        // Handle paste
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        e.preventDefault();
        // Handle undo
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isMultiSelect, handleClearSelection]);

  const handleGenerate = () => {
    handleBulkGenerate(includeRowContext, includeColumnContext);
  };

  return (
    <div className={`fixed left-1/2 transform -translate-x-1/2 w-[600px] transition-all duration-300 ease-in-out ${
      isMultiSelect ? 'bottom-8' : 'bottom-8'
    }`}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
        {isMultiSelect ? (
          <div className="p-4 space-y-3">
            <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
              <div className="flex items-center space-x-4">
                <span>Points Required: {selectedCells.length * 3}</span>
                <span>Your Balance: {userPoints} points</span>
                <span className={userPoints >= selectedCells.length * 3 ? 'text-green-600' : 'text-red-600'}>
                  Remaining: {userPoints - (selectedCells.length * 3)} points
                </span>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleClearSelection}
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>

            {/* Context Options */}
            <div className="flex gap-4 text-sm text-gray-600 dark:text-gray-400">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="rowContext"
                  checked={includeRowContext}
                  onCheckedChange={setIncludeRowContext}
                  className="border-gray-300 dark:border-white data-[state=checked]:border-white"
                />
                <label htmlFor="rowContext" className="block text-sm font-medium mb-1">
                  Include row context
                </label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="columnContext"
                  checked={includeColumnContext}
                  onCheckedChange={setIncludeColumnContext}
                  className="border-gray-300 dark:border-white data-[state=checked]:border-white"
                />
                <label htmlFor="columnContext" className="block text-sm font-medium mb-1">
                  Include column context
                </label>
              </div>
            </div>
            
            <div className="flex space-x-3">
            <textarea
  className="flex-1 p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-900 dark:border-gray-700 text-gray-900 dark:text-gray-100"value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyDown={(e) => {
                  if (document.activeElement === e.target) {
                    e.stopPropagation();
                    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
                      e.target.select();
                    }
                  }
                }}
                placeholder={`Generate content for ${selectedCells.length} selected cells...`}
                rows={3}
              />
              <Button
                onClick={handleGenerate}
                disabled={userPoints < selectedCells.length * 3}
                className="self-end px-6 text-green-600"
              >
                Generate
              </Button>
            </div>
          </div>
        ) : (
 
          <TableAIPrompt
  data={data}
  columns={columns}
  setData={setData}
  setColumns={setColumns}  // Add this
  saveToHistory={saveToHistory}
  userPoints={userPoints}
/>
        
        )}
      </div>
    </div>
  );
};

export default PromptBox;