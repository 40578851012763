import confetti from 'canvas-confetti';

export const triggerConfetti = () => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
    zIndex: 9999
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio)
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
    colors: ['#7C3AED', '#6D28D9', '#4C1D95']
  });

  fire(0.2, {
    spread: 60,
    colors: ['#2563EB', '#1D4ED8', '#1E40AF']
  });

  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
    colors: ['#7C3AED', '#6D28D9', '#4C1D95']
  });
};