import React, { useState } from 'react';
import { Loader, MessageSquare, AlertCircle, X } from 'lucide-react';
import { Button } from '../ui/button';
import { Card } from '../ui/card'

const AICellEditor = ({
  isOpen,
  onClose,
  onGenerate,
  cellValue,
  columnName,
  rowData,
  userPoints,
  isGenerating
}) => {
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState('');

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }

    if (userPoints < 3) {
      setError('Insufficient points. You need 3 points to generate content.');
      return;
    }

    try {
      await onGenerate(prompt);
      setPrompt('');
      setError('');
    } catch (err) {
      setError(err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <Card className="w-full max-w-lg bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <MessageSquare className="w-5 h-5 text-blue-500" />
            <h3 className="text-lg font-semibold">AI Assistant</h3>
          </div>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="w-5 h-5" />
          </Button>
        </div>

        <div className="space-y-4">
          {/* Current Cell Value */}
          <div>
            <label className="block text-sm font-medium mb-1">Current Value</label>
            <div className="p-2 bg-gray-50 dark:bg-gray-900 rounded border">
              {cellValue || 'Empty cell'}
            </div>
          </div>

          {/* Context */}
          <div>
            <label className="block text-sm font-medium mb-1">Column Context</label>
            <div className="text-sm text-gray-500">
              Generating content for: <span className="font-medium">{columnName}</span>
            </div>
          </div>

          {/* AI Prompt Input */}
          <div>
            <label className="block text-sm font-medium mb-1">AI Prompt</label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter your prompt for AI generation..."
              className="w-full p-3 rounded border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 dark:bg-gray-900"
              rows={4}
            />
          </div>

          {/* Points Info */}
          <div className="flex items-center justify-between text-sm">
            <span>Cost: 3 points</span>
            <span>Your balance: {userPoints} points</span>
          </div>

          {/* Error Display */}
          {error && (
            <div className="flex items-center space-x-2 text-red-500 bg-red-50 dark:bg-red-900/30 p-2 rounded">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button 
              onClick={handleGenerate}
              disabled={isGenerating || !prompt.trim()}
              className="relative"
            >
              {isGenerating ? (
                <>
                  <Loader className="w-4 h-4 animate-spin mr-2" />
                  Generating...
                </>
              ) : (
                'Generate with AI'
              )}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AICellEditor;