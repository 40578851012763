import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Copy, ArrowRight, Code, Plus, Minus, RefreshCw } from 'lucide-react';
import { Link } from 'react-router-dom';

const CSSGradientGenerator = () => {
  const [gradientType, setGradientType] = useState('linear');
  const [angle, setAngle] = useState(90);
  const [colors, setColors] = useState([
    { color: '#FF416C', position: 0 },
    { color: '#FF4B2B', position: 100 }
  ]);
  const [copied, setCopied] = useState(false);
  const [previewSize, setPreviewSize] = useState({ width: 'w-full', height: 'h-64' });

  const generateGradientStyle = useCallback(() => {
    const colorStops = colors
      .sort((a, b) => a.position - b.position)
      .map(stop => `${stop.color} ${stop.position}%`)
      .join(', ');
    
    if (gradientType === 'linear') {
      return {
        background: `linear-gradient(${angle}deg, ${colorStops})`
      };
    } else {
      return {
        background: `radial-gradient(circle at center, ${colorStops})`
      };
    }
  }, [colors, angle, gradientType]);

  const generateGradientCSS = useCallback(() => {
    const style = generateGradientStyle();
    return `background: ${style.background};`;
  }, [generateGradientStyle]);

  const handleColorChange = (index, newColor) => {
    const newColors = [...colors];
    newColors[index].color = newColor;
    setColors(newColors);
  };

  const handlePositionChange = (index, newPosition) => {
    const newColors = [...colors];
    newColors[index].position = Math.max(0, Math.min(100, newPosition));
    setColors(newColors);
  };

  const addColor = () => {
    if (colors.length < 5) {
      const lastPosition = colors[colors.length - 1].position;
      const newPosition = Math.min(lastPosition + 20, 100);
      setColors([...colors, { color: '#FFFFFF', position: newPosition }]);
    }
  };

  const removeColor = (index) => {
    if (colors.length > 2) {
      const newColors = colors.filter((_, i) => i !== index);
      setColors(newColors);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generateGradientCSS());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const randomizeGradient = () => {
    const randomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    
    const newColors = colors.map(color => ({
      ...color,
      color: randomColor()
    }));
    setColors(newColors);
    setAngle(Math.floor(Math.random() * 360));
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      {/* Controls */}
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6">
        <div className="flex flex-wrap gap-4">
          <div className="space-y-2">
            <label className="text-sm text-gray-300">Gradient Type</label>
            <select
              value={gradientType}
              onChange={(e) => setGradientType(e.target.value)}
              className="bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="linear">Linear</option>
              <option value="radial">Radial</option>
            </select>
          </div>

          {gradientType === 'linear' && (
            <div className="space-y-2">
              <label className="text-sm text-gray-300">Angle</label>
              <input
                type="number"
                value={angle}
                onChange={(e) => setAngle(Number(e.target.value))}
                className="bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 w-24 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}
        </div>

        {/* Color Stops */}
        <div className="space-y-4">
          {colors.map((color, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                type="color"
                value={color.color}
                onChange={(e) => handleColorChange(index, e.target.value)}
                className="w-12 h-12 rounded cursor-pointer"
              />
              <input
                type="number"
                value={color.position}
                onChange={(e) => handlePositionChange(index, Number(e.target.value))}
                className="bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 w-24 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                min="0"
                max="100"
              />
              <span className="text-gray-400">%</span>
              {colors.length > 2 && (
                <button
                  onClick={() => removeColor(index)}
                  className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  <Minus className="h-5 w-5" />
                </button>
              )}
            </div>
          ))}
          {colors.length < 5 && (
            <button
              onClick={addColor}
              className="flex items-center space-x-2 text-sm text-blue-400 hover:text-blue-300 transition-colors"
            >
              <Plus className="h-4 w-4" />
              <span>Add Color Stop</span>
            </button>
          )}
        </div>
      </div>

      {/* Preview */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="font-medium">Preview</h3>
          <button
            onClick={randomizeGradient}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-500 transition-colors"
          >
            <RefreshCw className="h-4 w-4" />
            <span>Randomize</span>
          </button>
        </div>
        <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
          <div
            className={`${previewSize.width} ${previewSize.height} rounded-lg shadow-lg`}
            style={generateGradientStyle()}
          />
        </div>
      </div>

      {/* Code Output */}
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-medium flex items-center">
            <Code className="h-5 w-5 mr-2" />
            CSS Code
          </h3>
          <button
            onClick={copyToClipboard}
            className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-500 transition-colors flex items-center space-x-2"
          >
            <Copy className="h-4 w-4" />
            <span>{copied ? 'Copied!' : 'Copy CSS'}</span>
          </button>
        </div>
        <pre className="bg-gray-900 rounded-lg p-4 overflow-x-auto">
          <code className="text-sm text-gray-300">{generateGradientCSS()}</code>
        </pre>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Create Stunning AI Content with Beautiful Gradients
              </h2>
              <p className="text-purple-100 mb-6">
                Use our AI content generation tools to create engaging articles, social media posts, and more - now with beautiful gradient styling options. Get 1000 free points to start creating!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating for Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CSSGradientGenerator;