import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Edit } from 'lucide-react';
import SEOHead from '../../components/SEOHead';
import {
  Sparkles,
  Brain,
  Feather,
  Globe,
  Clock,
  Target,
  ArrowRight,
  ChevronDown,
  Star,
  Zap,
  PenTool,
  BookOpen,
  Search,
  BarChart,
  MessageCircle,
  ScrollText,
  Layout,
  Users,
  CheckCircle
} from 'lucide-react';

const BlogGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    setIsVisible(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Analytics data for floating stats
  const stats = {
    wordsGenerated: Math.floor(scrollY * 100),
    activeSessions: 1234,
    averageRating: 4.9
  };


  const aiFeatures = [
    {
      icon: Brain,
      title: "Neural Content Generation",
      description: "Advanced AI algorithms create human-like content with deep understanding of context and tone",
      color: "blue"
    },
    {
      icon: Target,
      title: "SEO Optimization",
      description: "Built-in SEO tools ensure your content ranks high on search engines",
      color: "purple"
    },
    {
      icon: Layout,
      title: "Smart Structuring",
      description: "AI-powered outline generation for perfect content flow and readability",
      color: "green"
    },
    {
      icon: Globe,
      title: "Multilingual Support",
      description: "Create content in 50+ languages with native-level quality",
      color: "indigo"
    },
    {
      icon: MessageCircle,
      title: "Tone Adaptation",
      description: "Match your brand voice with customizable writing styles",
      color: "pink"
    },
    {
      icon: BarChart,
      title: "Performance Analytics",
      description: "Track content engagement and SEO performance in real-time",
      color: "orange"
    }
  ];

  const blogTypes = [
    {
      title: "Technical Blogs",
      description: "Complex topics explained with clarity",
      icon: PenTool,
      examples: ["Software Development", "Engineering", "Data Science"]
    },
    {
      title: "Marketing Content",
      description: "Engaging posts that convert",
      icon: Target,
      examples: ["Product Launches", "Case Studies", "Industry Trends"]
    },
    {
      title: "Educational Content",
      description: "Clear and informative tutorials",
      icon: BookOpen,
      examples: ["How-to Guides", "Tutorials", "Expert Insights"]
    },
    {
      title: "SEO Articles",
      description: "Rank-optimized content",
      icon: Search,
      examples: ["Keyword Focus", "Link Building", "Topic Clusters"]
    }
  ];

  const demoContent = {
    topic: "The Future of Remote Work",
    outline: [
      "Introduction",
      "Current Remote Work Trends",
      "Technology Enablers",
      "Challenges and Solutions",
      "Future Predictions",
      "Conclusion"
    ],
    sample: `The landscape of work is undergoing a dramatic transformation...`
  };


  const processSteps = [
    {
      title: "Input Your Topic",
      description: "Enter your subject and target keywords",
      icon: PenTool,
      color: "blue"
    },
    {
      title: "AI Analysis",
      description: "Our AI researches and plans content structure",
      icon: Brain,
      color: "purple"
    },
    {
      title: "Content Generation",
      description: "Advanced AI creates engaging content",
      icon: Sparkles,
      color: "pink"
    },
    {
      title: "SEO Optimization",
      description: "Automatic optimization for search engines",
      icon: Target,
      color: "green"
    },
    {
      title: "Review & Customize",
      description: "Fine-tune the content to your needs",
      icon: Edit,
      color: "yellow"
    }
  ];

  const metrics = [
    {
      label: "Average Time Saved",
      value: "85%",
      description: "Compared to traditional writing",
      icon: Clock
    },
    {
      label: "SEO Performance",
      value: "+127%",
      description: "Increase in organic traffic",
      icon: BarChart
    },
    {
      label: "Content Quality",
      value: "94%",
      description: "Human-rated accuracy score",
      icon: CheckCircle
    },
    {
      label: "User Satisfaction",
      value: "4.9/5",
      description: "Based on 10,000+ reviews",
      icon: Star
    }
  ];

  const integrations = [
    {
      name: "WordPress",
      icon: "/icons/wordpress.svg",
      description: "Direct publishing to WordPress sites"
    },
    {
      name: "Medium",
      icon: "/icons/medium.svg",
      description: "Seamless Medium integration"
    },
    {
      name: "Ghost",
      icon: "/icons/ghost.svg",
      description: "Automatic Ghost publishing"
    },
    {
      name: "Webflow",
      icon: "/icons/webflow.svg",
      description: "Easy Webflow integration"
    }
  ];

  const testimonials = [
    {
      name: "Sarah Chen",
      role: "Content Marketing Lead",
      company: "TechGrowth",
      image: "/testimonials/sarah.jpg", // Using placeholder image API in actual render
      content: "The AI understands context brilliantly. We've increased our blog output by 400% while maintaining high engagement rates.",
      metrics: {
        improvement: "+285%",
        metric: "Organic Traffic"
      }
    },
    {
      name: "James Wilson",
      role: "Digital Marketing Director",
      company: "ScaleUp Solutions",
      image: "/testimonials/james.jpg",
      content: "Game-changing for our content strategy. The SEO optimization is incredible, and our posts are ranking higher than ever.",
      metrics: {
        improvement: "+192%",
        metric: "Conversion Rate"
      }
    },
    {
      name: "Elena Rodriguez",
      role: "Content Creator",
      company: "Creative Minds",
      image: "/testimonials/elena.jpg",
      content: "As a solo content creator, this tool has been invaluable. It's like having an entire content team at my fingertips.",
      metrics: {
        improvement: "75%",
        metric: "Time Saved"
      }
    }
  ];

  const pricingPlans = [
    {
      name: "Starter",
      description: "Perfect for individual creators",
      price: "29",
      features: [
        "5,000 words per month",
        "Basic SEO optimization",
        "3 content types",
        "Email support"
      ],
      highlight: false
    },
    {
      name: "Professional",
      description: "Ideal for growing businesses",
      price: "79",
      features: [
        "50,000 words per month",
        "Advanced SEO tools",
        "All content types",
        "Priority support",
        "Analytics dashboard",
        "Custom templates"
      ],
      highlight: true
    },
    {
      name: "Enterprise",
      description: "For large teams and agencies",
      price: "Custom",
      features: [
        "Unlimited words",
        "Enterprise SEO suite",
        "API access",
        "Dedicated success manager",
        "Custom AI training",
        "SLA guarantee"
      ],
      highlight: false
    }
  ];

  // Add this after the other constant definitions and before the return statement:

const faqs = [
  {
    question: "How does the AI blog generator work?",
    answer: "Our AI blog generator uses advanced natural language processing to create high-quality content. It analyzes your topic, researches relevant information, and generates engaging blog posts while maintaining SEO best practices and your preferred writing style."
  },
  {
    question: "Can I customize the AI-generated content?",
    answer: "Absolutely! While our AI creates the initial content, you have full control to edit, refine, and customize the text. You can adjust the tone, style, and structure to perfectly match your brand voice and requirements."
  },
  {
    question: "How long does it take to generate a blog post?",
    answer: "Our AI typically generates a complete 1500-word blog post in under 2 minutes. This includes research, outline creation, content generation, and SEO optimization. You can then review and customize the content as needed."
  },
  {
    question: "Is the content unique and SEO-friendly?",
    answer: "Yes! Each generated blog post is 100% unique and includes built-in SEO optimization. The content follows search engine best practices, includes relevant keywords naturally, and is structured for maximum search visibility."
  },
  {
    question: "What languages are supported?",
    answer: "We currently support content generation in 50+ languages, including English, Spanish, French, German, Chinese, Japanese, and more. The AI maintains natural language patterns and cultural nuances for each supported language."
  },
  {
    question: "Can I integrate it with my existing tools?",
    answer: "Yes, we offer seamless integration with popular platforms like WordPress, Medium, Ghost, and more. You can publish directly to your preferred platform or export content in various formats including HTML and Markdown."
  },
  {
    question: "How accurate is the generated content?",
    answer: "Our AI achieves a 94% accuracy rate for factual content, verified by human experts. The system is regularly updated with the latest information and includes built-in fact-checking mechanisms."
  },
  {
    question: "What types of blog content can I create?",
    answer: "You can create any type of blog content including how-to guides, listicles, industry news, product reviews, technical articles, thought leadership pieces, and more. The AI adapts its style and structure based on your chosen content type."
  }
];

  return (
    <>
      <SEOHead 
        title="AI Blog Generator | Create Engaging Blog Posts in Minutes | Next-Gen Content Creation"
        description="Transform your ideas into SEO-optimized, engaging blog posts with our advanced AI blog generator. Create high-quality content in minutes, not hours."
        keywords="AI blog writer, blog generator, content creation, AI writing assistant, SEO blog content, automated blog writing"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Dynamic Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          {/* Animated Particles Background */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-blue-500/20 rounded-full"
                style={{
                  width: Math.random() * 50 + 10,
                  height: Math.random() * 50 + 10,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            ))}
          </div>

          {/* Hero Content */}
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Next-Gen AI
              <span className="block">Blog Creation</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Create engaging, SEO-optimized blog content in minutes with the power of advanced AI
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => {
                  document.querySelector('#demo')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See It in Action
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">100M+</div>
                <div className="text-gray-400">Words Generated</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">94%</div>
                <div className="text-gray-400">Time Saved</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </motion.div>
          </motion.div>

          {/* Scroll Indicator */}
          <motion.div 
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
            animate={{ y: [0, 10, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <div className="w-6 h-10 border-2 border-white/30 rounded-full flex justify-center">
              <div className="w-1 h-3 bg-white/60 rounded-full mt-2" />
            </div>
          </motion.div>
        </section>

        {/* Floating Statistics Panel */}
        <motion.div
          className="fixed bottom-8 right-8 bg-gray-800/90 backdrop-blur-sm rounded-2xl p-4 shadow-lg z-50"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 2 }}
        >
          <div className="flex items-center space-x-6">
            <div>
              <div className="text-sm text-gray-400">Words Generated</div>
              <div className="text-xl font-bold text-blue-400">
                {stats.wordsGenerated.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Active Users</div>
              <div className="text-xl font-bold text-green-400">
                {stats.activeSessions.toLocaleString()}
              </div>
            </div>
          </div>
        </motion.div>

        
      {/* Interactive Demo Section */}
      <section id="demo" className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Watch AI Transform Your Ideas Into
              <span className="text-blue-400"> Engaging Content</span>
            </h2>
            <p className="text-xl text-gray-400">
              See how our AI turns simple prompts into fully-formed blog posts
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Input Side */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6"
            >
              <h3 className="text-xl font-bold mb-4">Your Input</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Topic</label>
                  <input
                    type="text"
                    value={demoContent.topic}
                    readOnly
                    className="w-full bg-gray-700/50 rounded-lg p-3 text-white"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Style</label>
                  <select className="w-full bg-gray-700/50 rounded-lg p-3 text-white">
                    <option>Professional</option>
                    <option>Casual</option>
                    <option>Technical</option>
                  </select>
                </div>
              </div>
            </motion.div>

            {/* Output Side */}
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6"
            >
              <h3 className="text-xl font-bold mb-4">AI Generated Output</h3>
              <div className="space-y-4">
                <div className="bg-gray-700/50 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Generated Outline</h4>
                  <ul className="space-y-2">
                    {demoContent.outline.map((item, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="flex items-center text-gray-300"
                      >
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mr-2" />
                        {item}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* AI Features Showcase */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Powered by Advanced AI
            </h2>
            <p className="text-xl text-gray-400">
              State-of-the-art features for exceptional content creation
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {aiFeatures.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 hover:bg-gray-800/70 transition-all group"
              >
                <feature.icon className={`h-8 w-8 text-${feature.color}-400 mb-4 group-hover:text-${feature.color}-300 transition-colors`} />
                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Content Types Grid */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Create Any Type of Content
            </h2>
            <p className="text-xl text-gray-400">
              Versatile AI that adapts to your content needs
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {blogTypes.map((type, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8"
              >
                <type.icon className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">{type.title}</h3>
                <p className="text-gray-400 mb-4">{type.description}</p>
                <div className="flex flex-wrap gap-2">
                  {type.examples.map((example, i) => (
                    <span
                      key={i}
                      className="px-3 py-1 bg-blue-500/20 rounded-full text-sm text-blue-300"
                    >
                      {example}
                    </span>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
     
      {/* Writing Process Visualization */}
      <section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Streamlined Writing Process
            </h2>
            <p className="text-xl text-gray-400">
              From idea to published content in minutes
            </p>
          </motion.div>

          <div className="relative">
            {/* Connecting Line */}
            <div className="absolute top-1/2 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent" />

            <div className="grid md:grid-cols-5 gap-8">
              {processSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="relative"
                >
                  <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6 text-center">
                    <div className={`w-12 h-12 mx-auto mb-4 rounded-full bg-${step.color}-500/20 flex items-center justify-center`}>
                      <step.icon className={`h-6 w-6 text-${step.color}-400`} />
                    </div>
                    <h3 className="text-lg font-bold mb-2">{step.title}</h3>
                    <p className="text-gray-400 text-sm">{step.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Performance Metrics */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Results That Speak
            </h2>
            <p className="text-xl text-gray-400">
              Real metrics from thousands of users
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {metrics.map((metric, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6 text-center"
              >
                <metric.icon className="h-8 w-8 text-blue-400 mx-auto mb-4" />
                <div className="text-3xl font-bold text-blue-400 mb-2">
                  {metric.value}
                </div>
                <div className="text-lg font-medium mb-2">{metric.label}</div>
                <p className="text-sm text-gray-400">{metric.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Integration Showcase */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Seamless Integrations
            </h2>
            <p className="text-xl text-gray-400">
              Publish directly to your favorite platforms
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {integrations.map((integration, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-800/70 transition-all text-center"
              >
                <img
                  src={integration.icon}
                  alt={integration.name}
                  className="h-12 w-12 mx-auto mb-4"
                />
                <h3 className="font-medium mb-2">{integration.name}</h3>
                <p className="text-sm text-gray-400">{integration.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      </div>


     {/* Testimonials Section */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
  </div>

  <div className="max-w-7xl mx-auto px-4 relative z-10">
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="text-center mb-16"
    >
      <h2 className="text-4xl md:text-5xl font-bold mb-4 text-white">
        Loved by Content Teams
      </h2>
      <p className="text-xl text-gray-400">
        See what our users have achieved
      </p>
    </motion.div>

    <div className="grid md:grid-cols-3 gap-8">
      {testimonials.map((testimonial, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.2 }}
          className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 hover:bg-gray-800/70 transition-all"
        >
          <div className="flex items-center mb-6">
            <div className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center text-blue-400 text-xl font-bold mr-4">
              {testimonial.name.split(' ').map(n => n[0]).join('')}
            </div>
            <div>
              <h3 className="font-bold text-white">{testimonial.name}</h3>
              <p className="text-sm text-gray-400">{testimonial.role}</p>
              <p className="text-sm text-gray-400">{testimonial.company}</p>
            </div>
          </div>
          <div className="mb-6">
            <p className="text-gray-300 italic">"{testimonial.content}"</p>
          </div>
          <div className="bg-blue-500/10 rounded-lg p-4 text-center">
            <div className="text-2xl font-bold text-blue-400">
              {testimonial.metrics.improvement}
            </div>
            <div className="text-sm text-gray-400">
              {testimonial.metrics.metric}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  </div>
</section>

{/* FAQ Section with Advanced Accordion */}
<section className="py-24 bg-gradient-to-b from-black to-gray-900">
  <div className="max-w-3xl mx-auto px-4">
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="text-center mb-16"
    >
      <h2 className="text-4xl md:text-5xl font-bold mb-4 text-white">
        Common Questions
      </h2>
      <p className="text-xl text-gray-400">
        Everything you need to know
      </p>
    </motion.div>

    <div className="space-y-4">
      {faqs.map((faq, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
          className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden"
        >
          <button
            onClick={() => toggleFaq(index)}
            className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-800/70 transition-all text-white"
          >
            <span className="font-medium text-lg">{faq.question}</span>
            <ChevronDown 
              className={`h-5 w-5 text-gray-400 transform transition-transform ${
                openFaq === index ? 'rotate-180' : ''
              }`}
            />
          </button>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: openFaq === index ? 'auto' : 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            {openFaq === index && (
              <div className="p-6 pt-0 text-gray-400">
                {faq.answer}
              </div>
            )}
          </motion.div>
        </motion.div>
      ))}
    </div>
  </div>
</section>

{/* Final CTA Section */}
<section className="py-24 bg-black relative overflow-hidden">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
    <motion.div
      animate={{
        opacity: [0.5, 1, 0.5],
        scale: [1, 1.2, 1],
      }}
      transition={{
        duration: 10,
        repeat: Infinity,
        ease: "easeInOut",
      }}
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
    />
  </div>

  <div className="max-w-7xl mx-auto px-4 relative z-10">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="text-center"
    >
      <h2 className="text-4xl md:text-6xl font-bold mb-6 text-white">
        Ready to Transform Your Content?
      </h2>
      <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
        Join thousands of content creators using AI to produce exceptional blog content
      </p>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.2 }}
        className="flex flex-col sm:flex-row gap-4 justify-center"
      >
        <Link
          to="/register"
          className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group text-white"
        >
          Start Creating Now
          <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
        </Link>
        <Link
          to="/pricing"
          className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all text-white"
        >
          Pricing
        </Link>
      </motion.div>
      <p className="mt-6 text-gray-400">
        No credit card required · 14-day free trial · Cancel anytime
      </p>
    </motion.div>
  </div>
</section>
    </>
  );
};

export default BlogGeneratorLanding;