import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Palette, Copy, RefreshCw, Download, ArrowRight, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const ColorPaletteGenerator = () => {
  const [colors, setColors] = useState(generateRandomColors(5));
  const [paletteHistory, setPaletteHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('generator');

  function generateRandomColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const hue = Math.floor(Math.random() * 360);
      const saturation = 65 + Math.floor(Math.random() * 25);
      const lightness = 45 + Math.floor(Math.random() * 25);
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
    return colors;
  }

  const generateNewPalette = useCallback(() => {
    setPaletteHistory(prev => [...prev, colors].slice(-10));
    setColors(generateRandomColors(5));
  }, [colors]);

  const copyColor = useCallback((color) => {
    navigator.clipboard.writeText(color);
  }, []);

  const downloadPalette = useCallback(() => {
    const text = colors.join('\n');
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'color-palette.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [colors]);

  const rgbToHex = (color) => {
    if (color.startsWith('hsl')) {
      // Convert HSL to RGB first (simplified conversion)
      const matches = color.match(/\d+/g);
      const h = matches[0];
      const s = matches[1];
      const l = matches[2];
      
      // Implement HSL to RGB conversion here
      // This is a simplified version
      const rgb = hslToRgb(h, s, l);
      return rgbToHex(rgb);
    }
    return color;
  };

  const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-2">
            <Palette className="h-6 w-6 text-purple-500" />
            <h2 className="text-xl font-bold">Color Palette Generator</h2>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={generateNewPalette}
              className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
              title="Generate New Palette"
            >
              <RefreshCw className="h-5 w-5" />
            </button>
            <button
              onClick={downloadPalette}
              className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
              title="Download Palette"
            >
              <Download className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4 mb-6">
          {colors.map((color, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative group"
            >
              <div
                className="h-32 rounded-lg cursor-pointer"
                style={{ backgroundColor: color }}
                onClick={() => copyColor(color)}
              />
              <div className="absolute inset-x-0 bottom-0 p-2 bg-black/50 backdrop-blur-sm rounded-b-lg opacity-0 group-hover:opacity-100 transition-opacity">
                <div className="flex justify-between items-center">
                  <span className="text-xs font-mono">{color}</span>
                  <Copy className="h-4 w-4 cursor-pointer" onClick={() => copyColor(color)} />
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {paletteHistory.length > 0 && (
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-400">Recent Palettes</h3>
            <div className="grid grid-cols-1 gap-4">
              {paletteHistory.map((palette, index) => (
                <div key={index} className="flex space-x-2">
                  {palette.map((color, colorIndex) => (
                    <div
                      key={colorIndex}
                      className="flex-1 h-8 rounded cursor-pointer"
                      style={{ backgroundColor: color }}
                      onClick={() => copyColor(color)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-6">
          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Click on any color to copy its value. Generate unlimited palettes and download them for your projects.
            </p>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Create Stunning Content with AI
              </h2>
              <p className="text-purple-100 mb-6">
                Use our AI-powered tools to generate engaging content, perfect color schemes, and eye-catching designs. Get started with 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Now
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ColorPaletteGenerator;