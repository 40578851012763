import React, { useState, useCallback, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AIDetectionTool from './freetools/AIDetectionTool.js';
import GrammarChecker from './freetools/GrammarChecker.js';
import ReadabilityScoreCalculator from './freetools/ReadabilityScoreCalculator.js';
import KeywordDensityAnalyzer from './freetools/KeywordDensityAnalyzer.js';
import TextCaseConverter from './freetools/TextCaseConverter.js';
import ReadingTimeEstimator from './freetools/ReadingTimeEstimator.js';
import HeadlineAnalyzer from './freetools/HeadlineAnalyzer.js';
import GrammarPatternIdentifier from './freetools/GrammarPatternIdentifier.js';
import SentenceLengthAnalyzer from './freetools/SentenceLengthAnalyzer.js';
import MetaDescriptionChecker from './freetools/MetaDescriptionChecker.js';
import TitleTagOptimizer from './freetools/TitleTagOptimizer.js';
import URLSlugGenerator from './freetools/URLSlugGenerator.js';
import MetaTagsGenerator from './freetools/MetaTagsGenerator.js';
import OpenGraphTagsGenerator from './freetools/OpenGraphTagsGenerator.js';
import RobotsTxtGenerator from './freetools/RobotsTxtGenerator.js';
import XMLSitemapGenerator from './freetools/XMLSitemapGenerator.js';
import LoremIpsumGenerator from './freetools/LoremIpsumGenerator.js';
import HTMLToMarkdown from './freetools/HTMLToMarkdown.js';
import MarkdownToHTML from './freetools/MarkdownToHTML.js';
import TextCleaner from './freetools/TextCleaner.js';
import CitationGenerator from './freetools/CitationGenerator.js';
import EmailSignatureGenerator from './freetools/EmailSignatureGenerator.js';
import HashtagGenerator from './freetools/HashtagGenerator.js';
import CSSMinifier from './freetools/CSSMinifier.js';
import HTMLMinifier from './freetools/HTMLMinifier.js';
import JavaScriptBeautifier from './freetools/JavaScriptBeautifier.js';
import ColorPaletteGenerator from './freetools/ColorPaletteGenerator.js';
import CSSGradientGenerator from './freetools/CSSGradientGenerator.js';
import BoxShadowGenerator from './freetools/BoxShadowGenerator.js';
import CSSFlexboxGenerator from './freetools/CSSFlexboxGenerator.js';
import VideoTranscriptTool from './freetools/VideoTranscriptTool.js';
import SEOHead from '../components/SEOHead';

import {
  Search,
  FileText,
  Code,
  Layout,
  Wand2,
  Globe,
  Type,
  Clock,
  Hash,
  Palette,
  Box,
  Grid,
  BookOpen,
  Mail,
  Text,
  AlertTriangle,
  Shield,
  CheckCircle,
  Eye,
  Key,
  FileCode,
  Bot,
  X,
  Link
} from 'lucide-react';

// Memoized Modal Component
const Modal = memo(({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900 rounded-2xl max-w-4xl w-full h-[90vh] flex flex-col">
        {children}
      </div>
    </div>
  );
});

Modal.displayName = 'Modal';

// Memoized Tool Card Component
const ToolCard = memo(({ tool, onClick }) => (
  <motion.button
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    onClick={onClick}
    className="bg-gray-800/50 hover:bg-gray-700/50 border border-gray-700 rounded-xl p-6 text-left transition-all hover:transform hover:scale-105"
  >
    <div className="flex items-start space-x-4">
      <div className="bg-blue-500/20 p-3 rounded-lg">
        <tool.icon className="h-6 w-6 text-blue-400" />
      </div>
      <div>
        <h3 className="font-medium mb-2">{tool.name}</h3>
        <p className="text-sm text-gray-400">Free forever</p>
      </div>
    </div>
  </motion.button>
));

ToolCard.displayName = 'ToolCard';

// Memoized Category Section Component
const CategorySection = memo(({ category, onToolSelect }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="mb-16"
  >
    <div className="flex items-center space-x-3 mb-8">
      <category.icon className="h-6 w-6 text-blue-400" />
      <h2 className="text-2xl font-bold">{category.name}</h2>
    </div>

    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {category.tools.map((tool) => (
        <ToolCard key={tool.id} tool={tool} onClick={() => onToolSelect(tool)} />
      ))}
    </div>
  </motion.div>
));

CategorySection.displayName = 'CategorySection';

const FreeTools = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTool, setSelectedTool] = useState(null);

  const categories = [
    {
      name: 'Content Analysis',
      icon: FileText,
      tools: [
        { id: 'ai-detection', name: 'AI Detection', icon: Bot },
        
        { id: 'grammar', name: 'Grammar Checker', icon: CheckCircle },
        { id: 'readability', name: 'Readability Score Calculator', icon: Eye },
        { id: 'keyword', name: 'Keyword Density Analyzer', icon: Key },
        { id: 'case-converter', name: 'Text Case Converter', icon: Type },
        { id: 'reading-time', name: 'Reading Time Estimator', icon: Clock },
        { id: 'headline', name: 'Headline Analyzer', icon: Text },
        { id: 'grammar-pattern', name: 'Grammar Pattern Identifier', icon: FileText },
        { id: 'sentence-length', name: 'Sentence Length Analyzer', icon: FileText }
      ]
    },
    {
      name: 'SEO Tools',
      icon: Globe,
      tools: [
        { id: 'meta-desc', name: 'Meta Description Checker', icon: FileText },
        { id: 'title-tag', name: 'Title Tag Optimizer', icon: Type },
        { id: 'url-slug', name: 'URL Slug Generator', icon: Link },
        { id: 'meta-tags', name: 'Meta Tags Generator', icon: Code },
        { id: 'og-tags', name: 'Open Graph Tags Generator', icon: Layout },
        { id: 'robots', name: 'robots.txt Generator', icon: FileCode },
        { id: 'sitemap', name: 'XML Sitemap Generator', icon: Globe }
      ]
    },
    {
      name: 'Writing Tools',
      icon: BookOpen,
      tools: [
        { id: 'lorem', name: 'Lorem Ipsum Generator', icon: Text },
        { id: 'html-md', name: 'HTML to Markdown', icon: Code },
        { id: 'md-html', name: 'Markdown to HTML', icon: Code },
        { id: 'text-cleaner', name: 'Text Cleaner', icon: Wand2 },
        { id: 'citation', name: 'Citation Generator', icon: FileText },
        { id: 'email-sig', name: 'Email Signature Generator', icon: Mail },
        { id: 'hashtag', name: 'Hashtag Generator', icon: Hash }
      ]
    },
    {
      name: 'Development Tools',
      icon: Code,
      tools: [
        { id: 'css-min', name: 'CSS Minifier', icon: Code },
        { id: 'html-min', name: 'HTML Minifier', icon: Code },
        { id: 'js-beautify', name: 'JavaScript Beautifier', icon: Code },
        { id: 'color-palette', name: 'Color Palette Generator', icon: Palette },
        { id: 'gradient', name: 'CSS Gradient Generator', icon: Palette },
        { id: 'box-shadow', name: 'Box Shadow Generator', icon: Box },
        { id: 'flexbox', name: 'CSS Flexbox Generator', icon: Grid }
      ]
    }
  ];


  // Custom Modal Component
  // Memoized Modal Component
const Modal = memo(({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900 rounded-2xl max-w-4xl w-full h-[90vh] flex flex-col">
        {children}
      </div>
    </div>
  );
});

Modal.displayName = 'Modal';

// Memoized Tool Card Component
const ToolCard = memo(({ tool, onClick }) => (
  <motion.button
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    onClick={onClick}
    className="bg-gray-800/50 hover:bg-gray-700/50 border border-gray-700 rounded-xl p-6 text-left transition-all hover:transform hover:scale-105"
  >
    <div className="flex items-start space-x-4">
      <div className="bg-blue-500/20 p-3 rounded-lg">
        <tool.icon className="h-6 w-6 text-blue-400" />
      </div>
      <div>
        <h3 className="font-medium mb-2">{tool.name}</h3>
        <p className="text-sm text-gray-400">Free forever</p>
      </div>
    </div>
  </motion.button>
));

ToolCard.displayName = 'ToolCard';

// Memoized Category Section Component
const CategorySection = memo(({ category, onToolSelect }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="mb-16"
  >
    <div className="flex items-center space-x-3 mb-8">
      <category.icon className="h-6 w-6 text-blue-400" />
      <h2 className="text-2xl font-bold">{category.name}</h2>
    </div>

    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {category.tools.map((tool) => (
        <ToolCard key={tool.id} tool={tool} onClick={() => onToolSelect(tool)} />
      ))}
    </div>
  </motion.div>
));

CategorySection.displayName = 'CategorySection';

const filteredCategories = categories.map(category => ({
  ...category,
  tools: category.tools.filter(tool =>
    tool.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
})).filter(category => category.tools.length > 0);

return (
  <>
  <SEOHead/>
  
  <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
    {/* Hero Section */}
    <section className="py-20 relative">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Free Content Tools
          </h1>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Powerful tools to enhance your content creation workflow - no signup required
          </p>

          {/* Search Bar */}
          <div className="max-w-xl mx-auto mb-16">
            <div className="relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="text"
                placeholder="Search tools..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-gray-800/50 border border-gray-700 rounded-full py-4 pl-12 pr-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </motion.div>
      </div>
    </section>

    {/* Tools Grid */}
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4">
        {filteredCategories.map((category) => (
          <CategorySection
            key={category.name}
            category={category}
            onToolSelect={setSelectedTool}
          />
        ))}
      </div>
    </section>

    {/* Tool Modal */}
    <Modal isOpen={!!selectedTool} onClose={() => setSelectedTool(null)}>
      {selectedTool && (
        <div className="flex flex-col h-full">
          <div className="border-b border-gray-800 p-6 flex items-center justify-between flex-shrink-0">
            <div className="flex items-center space-x-3">
              <selectedTool.icon className="h-6 w-6 text-blue-400" />
              <h3 className="text-xl font-bold">{selectedTool.name}</h3>
            </div>
            <button
              onClick={() => setSelectedTool(null)}
              className="text-gray-400 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <div className="p-6 overflow-y-auto flex-grow">
         
{selectedTool.id === 'ai-detection' && <AIDetectionTool />}
{selectedTool.id === 'grammar' && <GrammarChecker />}
{selectedTool.id === 'readability' && <ReadabilityScoreCalculator />}
{selectedTool.id === 'keyword' && <KeywordDensityAnalyzer />}
{selectedTool.id === 'case-converter' && <TextCaseConverter />}
{selectedTool.id === 'reading-time' && <ReadingTimeEstimator />}
{selectedTool.id === 'headline' && <HeadlineAnalyzer />}
{selectedTool.id === 'grammar-pattern' && <GrammarPatternIdentifier />}
{selectedTool.id === 'sentence-length' && <SentenceLengthAnalyzer />}


{selectedTool.id === 'meta-desc' && <MetaDescriptionChecker />}
{selectedTool.id === 'title-tag' && <TitleTagOptimizer />}
{selectedTool.id === 'url-slug' && <URLSlugGenerator />}
{selectedTool.id === 'meta-tags' && <MetaTagsGenerator />}
{selectedTool.id === 'og-tags' && <OpenGraphTagsGenerator />}
{selectedTool.id === 'robots' && <RobotsTxtGenerator />}
{selectedTool.id === 'sitemap' && <XMLSitemapGenerator />}


{selectedTool.id === 'lorem' && <LoremIpsumGenerator />}
{selectedTool.id === 'html-md' && <HTMLToMarkdown />}
{selectedTool.id === 'md-html' && <MarkdownToHTML />}
{selectedTool.id === 'text-cleaner' && <TextCleaner />}
{selectedTool.id === 'citation' && <CitationGenerator />}
{selectedTool.id === 'email-sig' && <EmailSignatureGenerator />}
{selectedTool.id === 'hashtag' && <HashtagGenerator />}


{selectedTool.id === 'css-min' && <CSSMinifier />}
{selectedTool.id === 'html-min' && <HTMLMinifier />}
{selectedTool.id === 'js-beautify' && <JavaScriptBeautifier />}
{selectedTool.id === 'color-palette' && <ColorPaletteGenerator />}
{selectedTool.id === 'gradient' && <CSSGradientGenerator />}
{selectedTool.id === 'box-shadow' && <BoxShadowGenerator />}
{selectedTool.id === 'flexbox' && <CSSFlexboxGenerator />}


            
          </div>
        </div>
      )}
    </Modal>
  </div>
  </>
);
};

export default memo(FreeTools);