import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { 
  Info, 
  Loader2, 
  Key, 
  ArrowRight, 
  Download,
  BarChart2,
  Maximize2,
  AlertTriangle,
  Target
} from 'lucide-react';
import { Link } from 'react-router-dom';

const KeywordDensityAnalyzer = () => {
  const [text, setText] = useState('');
  const [mainKeyword, setMainKeyword] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [minWordLength, setMinWordLength] = useState(3);
  const [excludeCommonWords, setExcludeCommonWords] = useState(true);
  const [maxKeywords, setMaxKeywords] = useState(20);

  // Common words to exclude if the option is enabled
  const commonWords = new Set([
    'the', 'be', 'to', 'of', 'and', 'a', 'in', 'that', 'have', 'i', 'it', 'for',
    'not', 'on', 'with', 'he', 'as', 'you', 'do', 'at', 'this', 'but', 'his',
    'by', 'from', 'they', 'we', 'say', 'her', 'she', 'or', 'an', 'will', 'my',
    'one', 'all', 'would', 'there', 'their', 'what', 'so', 'up', 'out', 'if',
    'about', 'who', 'get', 'which', 'go', 'me', 'when', 'make', 'can', 'like',
    'time', 'no', 'just', 'him', 'know', 'take', 'into', 'your', 'some', 'could',
    'them', 'see', 'other', 'than', 'then', 'now', 'look', 'only', 'come', 'its',
    'over', 'think', 'also', 'back', 'after', 'use', 'two', 'how', 'our', 'work',
    'first', 'well', 'way', 'even', 'new', 'want', 'because', 'any', 'these',
    'give', 'day', 'most', 'us'
  ]);

  const getWordCount = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const analyzeKeywords = useCallback(() => {
    if (!text.trim()) return;
    
    setIsAnalyzing(true);
    
    setTimeout(() => {
      // Get accurate word count before cleaning
      const totalWords = getWordCount(text);
      
      // Clean and split the text
      const words = text.toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
        .split(/\s+/)
        .filter(word => 
          word.length >= minWordLength && 
          (!excludeCommonWords || !commonWords.has(word))
        );
      
      // Count word frequencies
      const wordFreq = words.reduce((acc, word) => {
        acc[word] = (acc[word] || 0) + 1;
        return acc;
      }, {});

      // Calculate main keyword density if provided
      let mainKeywordStats = null;
      if (mainKeyword.trim()) {
        const mainKeywordLower = mainKeyword.toLowerCase();
        const mainKeywordCount = text.toLowerCase().split(mainKeywordLower).length - 1;
        mainKeywordStats = {
          word: mainKeyword,
          count: mainKeywordCount,
          density: (mainKeywordCount / totalWords * 100).toFixed(2)
        };
      }

      // Calculate keyword density and sort by frequency
      const keywordDensity = Object.entries(wordFreq)
        .map(([word, count]) => ({
          word,
          count,
          density: (count / totalWords * 100).toFixed(2)
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0, maxKeywords);

      // Find phrases (2-3 words)
      const phrases = [];
      const wordsArray = text.toLowerCase().split(/\s+/);
      for (let i = 0; i < wordsArray.length - 1; i++) {
        // 2-word phrases
        const phrase2 = wordsArray.slice(i, i + 2).join(' ');
        phrases.push(phrase2);
        
        // 3-word phrases
        if (i < wordsArray.length - 2) {
          const phrase3 = wordsArray.slice(i, i + 3).join(' ');
          phrases.push(phrase3);
        }
      }

      // Count phrase frequencies
      const phraseFreq = phrases.reduce((acc, phrase) => {
        acc[phrase] = (acc[phrase] || 0) + 1;
        return acc;
      }, {});

      // Calculate phrase density
      const phraseDensity = Object.entries(phraseFreq)
        .filter(([_, count]) => count > 1) // Only phrases that appear more than once
        .map(([phrase, count]) => ({
          phrase,
          count,
          density: (count / (totalWords - 1) * 100).toFixed(2)
        }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 10);

      // Calculate readability metrics
      const sentences = text.split(/[.!?]+/).filter(Boolean);
      const avgWordsPerSentence = totalWords / sentences.length;

      setResult({
        totalWords,
        uniqueWords: Object.keys(wordFreq).length,
        keywordDensity,
        phraseDensity,
        avgWordsPerSentence: avgWordsPerSentence.toFixed(1),
        sentences: sentences.length,
        mainKeywordStats
      });

      setIsAnalyzing(false);
    }, 1000);
  }, [text, mainKeyword, minWordLength, excludeCommonWords, maxKeywords]);

  const downloadReport = useCallback(() => {
    if (!result) return;

    const report = `Keyword Density Analysis Report
${new Date().toLocaleString()}

Total Words: ${result.totalWords}
Unique Words: ${result.uniqueWords}
Average Words per Sentence: ${result.avgWordsPerSentence}
Total Sentences: ${result.sentences}

${result.mainKeywordStats ? `Main Keyword "${result.mainKeywordStats.word}":
Count: ${result.mainKeywordStats.count}
Density: ${result.mainKeywordStats.density}%

` : ''}Top Keywords:
${result.keywordDensity.map(k => `${k.word}: ${k.count} times (${k.density}%)`).join('\n')}

Top Phrases:
${result.phraseDensity.map(p => `${p.phrase}: ${p.count} times (${p.density}%)`).join('\n')}
`;

    const blob = new Blob([report], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'keyword-density-report.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [result]);

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      {/* Analysis Options */}
      <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-4 space-y-4">
        <h3 className="font-medium mb-2">Analysis Options</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="text-sm text-gray-400 block mb-2">
              Main Keyword
            </label>
            <input
              type="text"
              value={mainKeyword}
              onChange={(e) => setMainKeyword(e.target.value)}
              placeholder="Enter main keyword..."
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-lg"
            />
          </div>
          <div>
            <label className="text-sm text-gray-400 block mb-2">
              Minimum Word Length
            </label>
            <input
              type="number"
              min="1"
              max="10"
              value={minWordLength}
              onChange={(e) => setMinWordLength(Number(e.target.value))}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-lg"
            />
          </div>
          <div>
            <label className="text-sm text-gray-400 block mb-2">
              Max Keywords to Show
            </label>
            <input
              type="number"
              min="5"
              max="100"
              value={maxKeywords}
              onChange={(e) => setMaxKeywords(Number(e.target.value))}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-lg"
            />
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="excludeCommon"
              checked={excludeCommonWords}
              onChange={(e) => setExcludeCommonWords(e.target.checked)}
              className="rounded border-gray-700 bg-gray-800"
            />
            <label htmlFor="excludeCommon" className="text-sm text-gray-400">
              Exclude Common Words
            </label>
          </div>
        </div>
      </div>

      {/* Text Input */}
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to analyze keyword density..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {getWordCount(text)} words
          </span>
          <button
            onClick={analyzeKeywords}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Key className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {/* Results */}
      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {/* Main Keyword Stats */}
          {result.mainKeywordStats && (
            <div className="bg-blue-500/20 border border-blue-500/30 rounded-xl p-6">
              <div className="flex items-center space-x-2 mb-4">
                <Target className="h-5 w-5 text-blue-400" />
                <h3 className="font-medium">Main Keyword Analysis</h3>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-sm text-gray-400">Keyword</div>
                  <div className="text-lg font-bold mt-1">{result.mainKeywordStats.word}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Occurrences</div>
                  <div className="text-lg font-bold mt-1">{result.mainKeywordStats.count}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Density</div>
                  <div className="text-lg font-bold mt-1">{result.mainKeywordStats.density}%</div>
                </div>
              </div>
            </div>
          )}

          {/* Overview Stats */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[
              { label: 'Total Words', value: result.totalWords },
              { label: 'Unique Words', value: result.uniqueWords },
              { label: 'Avg. Words/Sentence', value: result.avgWordsPerSentence },
              { label: 'Total Sentences', value: result.sentences }
            ].map((stat) => (
              <div
                key={stat.label}
                className="bg-gray-800/50 border border-gray-700 rounded-xl p-4"
              >
                <div className="text-sm text-gray-400">{stat.label}</div>
                <div className="text-2xl font-bold mt-1">{stat.value}</div>
              </div>
            ))}
          </div>

          {/* Rest of the component remains the same */}
          {/* Keywords Table */}
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-medium flex items-center space-x-2">
                <BarChart2 className="h-4 w-4" />
                <span>Keyword Density Analysis</span>
              </h3>
              <button
                onClick={downloadReport}
                className="text-sm text-gray-400 hover:text-white flex items-center space-x-1"
              >
                <Download className="h-4 w-4" />
                <span>Download Report</span>
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-gray-400">
                    <th className="pb-2">Keyword</th>
                    <th className="pb-2">Count</th>
                    <th className="pb-2">Density</th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {result.keywordDensity.map((keyword) => (
                    <tr key={keyword.word} className="border-t border-gray-700">
                      <td className="py-2">{keyword.word}</td>
                      <td className="py-2">{keyword.count}</td>
                      <td className="py-2">{keyword.density}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Phrases Analysis */}
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
            <h3 className="font-medium mb-4">Common Phrases</h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-gray-400">
                    <th className="pb-2">Phrase</th>
                    <th className="pb-2">Count</th>
                    <th className="pb-2">Density</th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {result.phraseDensity.map((phrase) => (
                    <tr key={phrase.phrase} className="border-t border-gray-700">
                      <td className="py-2">{phrase.phrase}</td>
                      <td className="py-2">{phrase.count}</td>
                      <td className="py-2">{phrase.density}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Info Message */}
          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Keyword density analysis can help optimize content for SEO, but avoid keyword stuffing. 
              Aim for natural keyword placement and maintain content quality as the primary focus.
              For most keywords, a density between 1-3% is considered optimal.
            </p>
          </div>
          </motion.div>
      )}
          {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                    Want More Advanced SEO Tools?
                  </h2>
                  <p className="text-purple-100 mb-6">
                    Get access to our premium SEO tools suite including keyword research, competitor analysis, and real-time rank tracking. Sign up now and get 1000 free points to try our premium tools!
                    </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
       
    </div>
  );
};

export default memo(KeywordDensityAnalyzer);