import React, { useEffect } from 'react';
import SEOHead from '../components/SEOHead';
import { 
  Image, 
  Sparkles, 
  ArrowLeft,
  Camera,
  Paintbrush,
  Wand2,
  Sun,
  Moon 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

const ComingSoon = () => {
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();

  // Memoized features array
  const features = React.useMemo(() => [
    {
      icon: Camera,
      title: 'AI Image Generation',
      description: 'Create unique images from text descriptions'
    },
    {
      icon: Paintbrush,
      title: 'Style Customization',
      description: 'Choose from multiple artistic styles'
    },
    {
      icon: Wand2,
      title: 'Image Enhancement',
      description: 'Enhance and modify existing images'
    }
  ], []);

  // Optimize form submission with debouncing
  const [email, setEmail] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  
  const debouncedSubmit = React.useCallback(
    debounce(async (email) => {
      try {
        setIsSubmitting(true);
        // API call would go here
        // await apiService.submitEmail(email);
      } catch (error) {
        console.error('Error submitting email:', error);
      } finally {
        setIsSubmitting(false);
      }
    }, 500),
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && !isSubmitting) {
      debouncedSubmit(email);
    }
  };

  return (
    <>
      <SEOHead />
      <div className={`min-h-screen ${theme === 'dark' ? 'dark' : ''} bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800 py-16 px-4`}>
        <div className="max-w-4xl mx-auto text-center">
          {/* Theme Toggle */}
          <button
            onClick={toggleTheme}
            className="fixed top-4 right-4 p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            aria-label="Toggle theme"
          >
            {theme === 'dark' ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
          </button>

          {/* Back Button */}
          <button
            onClick={() => navigate(-1)}
            className="mb-8 inline-flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </button>

          {/* Main Content */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 md:p-12 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-500 to-blue-500" />
            
            {/* Rest of the component structure remains the same, just add dark: variants */}
            <div className="relative">
              <div className="mx-auto w-16 h-16 bg-purple-100 dark:bg-purple-900/50 rounded-xl flex items-center justify-center mb-6">
                <Image className="h-8 w-8 text-purple-600 dark:text-purple-400" />
              </div>

              <div className="flex items-center justify-center mb-4">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">AI Image Generation</h1>
                <div className="ml-3 flex-shrink-0">
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 dark:bg-purple-900/50 text-purple-800 dark:text-purple-300">
                    <Sparkles className="h-4 w-4 mr-1 animate-pulse" />
                    Coming Soon
                  </span>
                </div>
              </div>

              <p className="text-xl text-gray-600 dark:text-gray-300 mb-12">
                We're working on something amazing! Transform your ideas into stunning visuals with our advanced AI image generation tools.
              </p>

              {/* Features Grid with dark mode */}
              <div className="grid md:grid-cols-3 gap-8 mb-12">
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-6 transform transition-transform hover:scale-105"
                  >
                    <feature.icon className="h-8 w-8 text-purple-600 dark:text-purple-400 mb-4" />
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>

              {/* Notification Form */}
              <form onSubmit={handleSubmit} className="bg-purple-50 dark:bg-purple-900/20 rounded-xl p-6 mb-8">
                <h3 className="text-lg font-semibold text-purple-900 dark:text-purple-100 mb-4">
                  Get Notified When We Launch
                </h3>
                <div className="flex gap-4 max-w-md mx-auto">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="flex-1 px-4 py-2 border border-purple-300 dark:border-purple-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                    disabled={isSubmitting}
                  />
                  <button 
                    type="submit"
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                  >
                    Notify Me
                  </button>
                </div>
              </form>

              {/* Progress Indicator */}
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-600 dark:text-gray-300">
                <div className="w-2 h-2 rounded-full bg-green-500" />
                <span>In Development</span>
                <span className="mx-2">•</span>
                <span>Launch Expected Q2 2024</span>
              </div>
            </div>
          </div>

          {/* Additional Info */}
          <div className="mt-8 text-center text-gray-600 dark:text-gray-300">
            <p>
              Have suggestions for our AI Image Generator?{' '}
              <button className="text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300">
                Share your ideas
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

// Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default React.memo(ComingSoon);