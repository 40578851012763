import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Clock, Book, Info, ArrowRight, BarChart3, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const ReadingTimeEstimator = () => {
  const [text, setText] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const analyzeText = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const words = text.trim().split(/\s+/).filter(Boolean);
      const wordCount = words.length;
      
      // Average reading speed (words per minute)
      const casualReadingSpeed = 200;
      const focusedReadingSpeed = 250;
      const speedReadingSpeed = 400;

      // Calculate reading times
      const casualTime = Math.ceil(wordCount / casualReadingSpeed);
      const focusedTime = Math.ceil(wordCount / focusedReadingSpeed);
      const speedTime = Math.ceil(wordCount / speedReadingSpeed);

      // Additional analysis
      const sentences = text.split(/[.!?]+/).filter(Boolean);
      const sentenceCount = sentences.length;
      const avgWordsPerSentence = Math.round(wordCount / sentenceCount) || 0;
      
      const paragraphs = text.split(/\n\s*\n/).filter(Boolean);
      const paragraphCount = paragraphs.length;
      
      const characters = text.length;
      const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
      
      setAnalysis({
        wordCount,
        sentenceCount,
        paragraphCount,
        characters,
        uniqueWords,
        readingTimes: {
          casual: casualTime,
          focused: focusedTime,
          speed: speedTime
        },
        avgWordsPerSentence,
        readabilityScore: calculateReadabilityScore(text)
      });
      
      setIsAnalyzing(false);
    }, 800);
  }, [text]);

  const calculateReadabilityScore = (text) => {
    if (!text.trim()) return 0;
    
    const words = text.trim().split(/\s+/).filter(Boolean);
    const sentences = text.split(/[.!?]+/).filter(Boolean);
    const syllables = countSyllables(text);
    
    // Simplified Flesch Reading Ease calculation
    const wordsPerSentence = words.length / sentences.length;
    const syllablesPerWord = syllables / words.length;
    
    const score = 206.835 - (1.015 * wordsPerSentence) - (84.6 * syllablesPerWord);
    return Math.min(Math.max(Math.round(score), 0), 100);
  };

  const countSyllables = (text) => {
    return text.toLowerCase()
      .replace(/[^a-z]/g, '')
      .replace(/[^aeiou]+/g, ' ')
      .trim()
      .split(/\s+/).length;
  };

  const ReadingTimeCard = memo(({ icon: Icon, title, time }) => (
    <div className="bg-gray-800 rounded-lg p-4 flex items-center space-x-3">
      <Icon className="h-5 w-5 text-blue-400" />
      <div>
        <p className="text-sm text-gray-400">{title}</p>
        <p className="font-medium">{time} min</p>
      </div>
    </div>
  ));

  ReadingTimeCard.displayName = 'ReadingTimeCard';

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to estimate reading time..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeText}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Clock className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {analysis && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <ReadingTimeCard 
              icon={Book} 
              title="Casual Reading" 
              time={analysis.readingTimes.casual} 
            />
            <ReadingTimeCard 
              icon={Clock} 
              title="Focused Reading" 
              time={analysis.readingTimes.focused} 
            />
            <ReadingTimeCard 
              icon={BarChart3} 
              title="Speed Reading" 
              time={analysis.readingTimes.speed} 
            />
          </div>

          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Words</p>
                <p className="text-lg font-medium">{analysis.wordCount}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Characters</p>
                <p className="text-lg font-medium">{analysis.characters}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Sentences</p>
                <p className="text-lg font-medium">{analysis.sentenceCount}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Paragraphs</p>
                <p className="text-lg font-medium">{analysis.paragraphCount}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Unique Words</p>
                <p className="text-lg font-medium">{analysis.uniqueWords}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Avg. Words/Sentence</p>
                <p className="text-lg font-medium">{analysis.avgWordsPerSentence}</p>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>Readability Score</span>
                <span>{analysis.readabilityScore}/100</span>
              </div>
              <div className="h-4 bg-gray-700 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${analysis.readabilityScore}%` }}
                  className={`h-full rounded-full ${
                    analysis.readabilityScore > 70 
                      ? 'bg-green-500' 
                      : analysis.readabilityScore > 30 
                      ? 'bg-yellow-500' 
                      : 'bg-red-500'
                  }`}
                />
              </div>
            </div>
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Reading times are estimated based on average reading speeds: casual (200 wpm), 
              focused (250 wpm), and speed reading (400 wpm). Actual reading time may vary 
              based on content complexity and individual reading speed.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Advanced Text Analysis?
              </h2>
              <p className="text-purple-100 mb-6">
                Unlock premium features like advanced readability metrics, content optimization suggestions, and SEO analysis. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ReadingTimeEstimator;