import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { FileText, Copy, Download, Info, ArrowRight, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const MarkdownToHTML = () => {
  const [markdown, setMarkdown] = useState('');
  const [html, setHtml] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [copied, setCopied] = useState(false);
  const [viewMode, setViewMode] = useState('split'); // split, preview, source

  // Markdown conversion helper functions
  const convertMarkdown = useCallback((md) => {
    // Basic markdown conversion rules
    let converted = md
      // Headers
      .replace(/^# (.*$)/gm, '<h1>$1</h1>')
      .replace(/^## (.*$)/gm, '<h2>$1</h2>')
      .replace(/^### (.*$)/gm, '<h3>$1</h3>')
      .replace(/^#### (.*$)/gm, '<h4>$1</h4>')
      .replace(/^##### (.*$)/gm, '<h5>$1</h5>')
      .replace(/^###### (.*$)/gm, '<h6>$1</h6>')
      
      // Bold and Italic
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\*(.*?)\*/g, '<em>$1</em>')
      .replace(/\_\_(.*?)\_\_/g, '<strong>$1</strong>')
      .replace(/\_(.*?)\_/g, '<em>$1</em>')
      
      // Links and Images
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')
      .replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1">')
      
      // Lists
      .replace(/^\s*\d+\.\s(.+)/gm, '<li>$1</li>')
      .replace(/^\s*[\-\*]\s(.+)/gm, '<li>$1</li>')
      
      // Code blocks
      .replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>')
      .replace(/`([^`]+)`/g, '<code>$1</code>')
      
      // Blockquotes
      .replace(/^\> (.+)/gm, '<blockquote>$1</blockquote>')
      
      // Paragraphs
      .replace(/\n\n([^#\n]+)\n/g, '<p>$1</p>')
      
      // Horizontal rules
      .replace(/^(-{3,}|\*{3,})$/gm, '<hr>');

    // Wrap lists properly
    converted = converted.replace(/<li>.*?<\/li>/g, (match) => {
      if (match.startsWith('<li>1.')) {
        return `<ol>${match}</ol>`;
      }
      return `<ul>${match}</ul>`;
    });

    return converted;
  }, []);

  const handleConvert = useCallback(() => {
    setIsProcessing(true);
    setTimeout(() => {
      const converted = convertMarkdown(markdown);
      setHtml(converted);
      setIsProcessing(false);
    }, 500);
  }, [markdown, convertMarkdown]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(html);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [html]);

  const handleDownload = useCallback(() => {
    const blob = new Blob([html], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'converted.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [html]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Convert your Markdown to clean, semantic HTML. Supports headers, lists, links,
        images, code blocks, and more. Perfect for content creation and web publishing.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <InfoMessage />
      
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => setViewMode('split')}
          className={`px-4 py-2 rounded-lg ${
            viewMode === 'split' ? 'bg-blue-500' : 'bg-gray-700'
          }`}
        >
          Split View
        </button>
        <button
          onClick={() => setViewMode('preview')}
          className={`px-4 py-2 rounded-lg ${
            viewMode === 'preview' ? 'bg-blue-500' : 'bg-gray-700'
          }`}
        >
          Preview
        </button>
        <button
          onClick={() => setViewMode('source')}
          className={`px-4 py-2 rounded-lg ${
            viewMode === 'source' ? 'bg-blue-500' : 'bg-gray-700'
          }`}
        >
          Source
        </button>
      </div>

      <div className={`grid ${viewMode === 'split' ? 'grid-cols-2 gap-4' : 'grid-cols-1'}`}>
        {(viewMode === 'split' || viewMode === 'source') && (
          <div className="space-y-4">
            <textarea
              value={markdown}
              onChange={(e) => setMarkdown(e.target.value)}
              placeholder="Type or paste your Markdown here..."
              className="w-full h-96 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono"
            />
            <button
              onClick={handleConvert}
              disabled={!markdown.trim() || isProcessing}
              className="w-full px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              {isProcessing ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Converting...</span>
                </>
              ) : (
                <>
                  <FileText className="h-4 w-4" />
                  <span>Convert to HTML</span>
                </>
              )}
            </button>
          </div>
        )}

        {(viewMode === 'split' || viewMode === 'preview') && (
          <div className="space-y-4">
            <div className="relative">
              <div
                className="w-full h-96 p-4 bg-gray-800 border border-gray-700 rounded-xl overflow-auto"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              {html && (
                <div className="absolute top-2 right-2 space-x-2">
                  <button
                    onClick={handleCopy}
                    className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                    title="Copy HTML"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                  <button
                    onClick={handleDownload}
                    className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                    title="Download HTML"
                  >
                    <Download className="h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Create Professional Content Faster
              </h2>
              <p className="text-purple-100 mb-6">
                Level up your content creation with our premium AI tools. Get access to advanced formatting options, 
                templates, and AI-powered content suggestions. Sign up now and receive 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MarkdownToHTML;