import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import {
  ShoppingBag,
  ArrowRight,
  Star,
  Target,
  Sparkles,
  BarChart2,
  Globe,
  Clock,
  Tags,
  ChevronDown,
  MessageCircle,
  Languages,
  Search,
  TrendingUp,
  CheckCircle,
  Users,
  Zap,
  Brain,
  Box,
  RefreshCcw,
  Share2,
  Shield,
  Database,
  Terminal,
  Code
} from 'lucide-react';

const ProductDescriptionWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeDemo, setActiveDemo] = useState('fashion');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  // Demo examples for different industries
  const demoExamples = {
    fashion: {
      before: "Blue cotton t-shirt with short sleeves",
      after: "Experience ultimate comfort with our premium cotton t-shirt. Crafted from breathable, soft-touch fabric, this versatile piece features a classic crew neck and relaxed fit. Perfect for everyday wear or casual styling."
    },
    tech: {
      before: "Wireless noise-cancelling headphones",
      after: "Immerse yourself in pure audio excellence with our state-of-the-art wireless headphones. Featuring advanced noise-cancelling technology, 40-hour battery life, and premium memory foam ear cushions for unparalleled comfort."
    },
    home: {
      before: "Modern desk lamp with adjustable arm",
      after: "Transform your workspace with our sleek, ergonomic desk lamp. Featuring intuitive touch controls, 5 color temperatures, and a flexible arm design, this lamp combines contemporary style with functional excellence."
    }
  };

  return (
    <>
      <SEOHead 
        title="AI Product Description Generator | Create Converting Product Descriptions"
        description="Transform your product listings with AI-powered product descriptions. Generate SEO-optimized, converting product content in seconds. Perfect for e-commerce and marketplaces."
        keywords="AI product description generator, product description writer, e-commerce content generator, AI product content, SEO product descriptions"
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              AI-Powered Product
              <span className="block">Description Generator</span>
            </motion.h1>

            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform basic product details into compelling, conversion-focused descriptions in seconds
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-blue-600 rounded-full font-medium hover:bg-blue-700 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Generating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <a
                href="#demo"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See Examples
              </a>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
              className="mt-12 flex flex-wrap justify-center gap-8"
            >
              <div className="flex items-center">
                <Box className="h-5 w-5 text-blue-400 mr-2" />
                <span>10M+ Products Generated</span>
              </div>
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-400 mr-2" />
                <span>50K+ Active Users</span>
              </div>
              <div className="flex items-center">
                <Star className="h-5 w-5 text-blue-400 mr-2" />
                <span>4.9/5 Rating</span>
              </div>
            </motion.div>
          </motion.div>

          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-blue-500/30 rounded-full filter blur-3xl animate-pulse" />
            <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
          </div>
        </section>
{/* Interactive Demo Section */}
<section id="demo" className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                See the Magic Happen
              </h2>
              <p className="text-gray-400 text-xl">
                Watch basic product details transform into compelling descriptions
              </p>
            </motion.div>

            <div className="mb-8 flex justify-center gap-4">
              {Object.keys(demoExamples).map((type) => (
                <motion.button
                  key={type}
                  onClick={() => setActiveDemo(type)}
                  className={`px-6 py-2 rounded-full capitalize transition-all ${
                    activeDemo === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type}
                </motion.button>
              ))}
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="grid md:grid-cols-2 gap-8"
            >
              <div className="bg-gray-800 p-8 rounded-2xl relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-orange-500" />
                <h3 className="text-xl font-semibold mb-4">Basic Input</h3>
                <p className="text-gray-400">{demoExamples[activeDemo].before}</p>
              </div>

              <div className="bg-gray-800 p-8 rounded-2xl relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500" />
                <h3 className="text-xl font-semibold mb-4">AI-Generated Description</h3>
                <p className="text-gray-400">{demoExamples[activeDemo].after}</p>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Features Grid */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-gray-400 text-xl">
                Create product descriptions that sell with cutting-edge features
              </p>
            </motion.div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid md:grid-cols-3 gap-8"
            >
              {[
                {
                  icon: Brain,
                  title: "AI Understanding",
                  description: "Our AI comprehends product features and target audience preferences to create compelling descriptions"
                },
                {
                  icon: Target,
                  title: "SEO Optimization",
                  description: "Built-in keyword optimization and search engine friendly structure for better rankings"
                },
                {
                  icon: Globe,
                  title: "Multi-Language",
                  description: "Generate descriptions in 100+ languages while maintaining natural flow and context"
                },
                {
                  icon: RefreshCcw,
                  title: "Bulk Generation",
                  description: "Create descriptions for multiple products simultaneously with consistent quality"
                },
                {
                  icon: Terminal,
                  title: "API Access",
                  description: "Integrate our AI directly into your e-commerce platform or workflow"
                },
                {
                  icon: Shield,
                  title: "Plagiarism Free",
                  description: "Every description is unique and passes AI detection tests"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-gray-800 p-8 rounded-2xl hover:bg-gray-750 transition-all group"
                >
                  <div className="bg-blue-500/20 w-12 h-12 rounded-lg flex items-center justify-center mb-6 group-hover:bg-blue-500/30 transition-all">
                    <feature.icon className="h-6 w-6 text-blue-400" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

        {/* Platform Integration */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Works Where You Do
              </h2>
              <p className="text-gray-400 text-xl">
                Seamlessly integrate with your favorite e-commerce platforms
              </p>
            </motion.div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid grid-cols-2 md:grid-cols-4 gap-8"
            >
              {[
                "Shopify",
                "WooCommerce",
                "Amazon",
                "eBay",
                "Etsy",
                "BigCommerce",
                "Magento",
                "Walmart Marketplace"
              ].map((platform, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-gray-800 p-6 rounded-xl hover:bg-gray-750 transition-all text-center group cursor-pointer"
                >
                  <div className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center mx-auto mb-4 group-hover:bg-blue-500/30 transition-all">
                    <Code className="h-8 w-8 text-blue-400" />
                  </div>
                  <span className="font-medium">{platform}</span>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>
{/* Workflow Demonstration */}
<section className="py-24 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Simple Yet Powerful Workflow
              </h2>
              <p className="text-gray-400 text-xl">
                Generate perfect product descriptions in three easy steps
              </p>
            </motion.div>

            <div className="relative">
              <div className="absolute top-1/2 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-purple-500 -translate-y-1/2 hidden md:block" />
              
              <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="grid md:grid-cols-3 gap-12 relative z-10"
              >
                {[
                  {
                    step: "01",
                    title: "Input Details",
                    description: "Enter your product specifications, features, and target keywords",
                    icon: Box
                  },
                  {
                    step: "02",
                    title: "AI Generation",
                    description: "Our AI creates optimized descriptions with multiple variations",
                    icon: Sparkles
                  },
                  {
                    step: "03",
                    title: "Customize & Export",
                    description: "Fine-tune the output and export to your platform",
                    icon: Share2
                  }
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    variants={itemVariants}
                    className="relative"
                  >
                    <div className="bg-gray-800 rounded-2xl p-8 hover:bg-gray-750 transition-all group">
                      <div className="bg-blue-500/20 w-12 h-12 rounded-full flex items-center justify-center mb-6 group-hover:bg-blue-500/30 transition-all">
                        <step.icon className="h-6 w-6 text-blue-400" />
                      </div>
                      <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-xl font-bold">
                        {step.step}
                      </div>
                      <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
                      <p className="text-gray-400">{step.description}</p>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        </section>

        {/* Performance Metrics */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Proven Results
              </h2>
              <p className="text-gray-400 text-xl">
                See how our AI transforms e-commerce performance
              </p>
            </motion.div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid md:grid-cols-4 gap-8"
            >
              {[
                {
                  metric: "147%",
                  label: "Average Conversion Rate Increase",
                  icon: TrendingUp
                },
                {
                  metric: "83%",
                  label: "Time Saved on Content Creation",
                  icon: Clock
                },
                {
                  metric: "92%",
                  label: "SEO Visibility Improvement",
                  icon: Search
                },
                {
                  metric: "3.5x",
                  label: "Return on Investment",
                  icon: BarChart2
                }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-gray-800 p-8 rounded-2xl text-center hover:bg-gray-750 transition-all group"
                >
                  <div className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center mx-auto mb-4 group-hover:bg-blue-500/30 transition-all">
                    <stat.icon className="h-6 w-6 text-blue-400" />
                  </div>
                  <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400 mb-2">
                    {stat.metric}
                  </div>
                  <p className="text-gray-400">{stat.label}</p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

        {/* Social Proof */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Trusted by Leading Brands
              </h2>
              <p className="text-gray-400 text-xl">
                Join thousands of successful e-commerce businesses
              </p>
            </motion.div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid md:grid-cols-3 gap-8"
            >
              {[
  {
    name: "Sarah Chen",
    role: "E-commerce Director",
    company: "FashionTech",
    content: "The AI product descriptions have transformed our e-commerce strategy. We've seen a 200% increase in conversion rates and our product listings now rank consistently higher in search results.",
    initials: "SC"
  },
  {
    name: "Michael Rodriguez",
    role: "Marketing Manager",
    company: "TechGear Global",
    content: "Managing descriptions for thousands of products was impossible before. Now we generate perfect descriptions in minutes, maintaining consistent quality across our entire catalog.",
    initials: "MR"
  },
  {
    name: "Emma Thompson",
    role: "Marketplace Seller",
    company: "HomeStyle",
    content: "The multilingual feature is a game-changer. We've expanded to international markets effortlessly, with perfectly localized product descriptions that maintain their selling power.",
    initials: "ET"
  }
].map((testimonial, index) => (
  <motion.div
    key={index}
    variants={itemVariants}
    className="bg-gray-800 p-8 rounded-2xl relative group hover:bg-gray-750 transition-all"
  >
    <div className="flex items-center mb-6">
      <div className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mr-4 font-bold text-lg">
        {testimonial.initials}
      </div>
      <div>
        <h4 className="font-bold">{testimonial.name}</h4>
        <p className="text-gray-400 text-sm">{testimonial.role}</p>
        <p className="text-gray-400 text-sm">{testimonial.company}</p>
      </div>
    </div>
    <p className="text-gray-300 italic">{testimonial.content}</p>
    <div className="flex mt-4">
      {[...Array(5)].map((_, i) => (
        <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
      ))}
    </div>
  </motion.div>
))}
              
            </motion.div>
          </div>
        </section>
         {/* FAQ Section */}
         <section className="py-24 bg-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-3xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Common Questions
              </h2>
              <p className="text-gray-400 text-xl">
                Everything you need to know
              </p>
            </motion.div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="space-y-4"
            >
              {[
                {
                  question: "How does the AI ensure product description accuracy?",
                  answer: "Our AI combines product specifications with industry knowledge to create accurate, compelling descriptions. It analyzes key features and benefits while maintaining factual integrity."
                },
                {
                  question: "Can I customize the AI's writing style?",
                  answer: "Yes! You can customize tone, style, and format to match your brand voice. The AI adapts to your preferences while maintaining conversion optimization."
                },
                {
                  question: "How many languages are supported?",
                  answer: "We support over 100 languages with native-quality translations, perfect for international e-commerce expansion."
                },
                {
                  question: "Is there an API available for integration?",
                  answer: "Yes, we offer a robust API for seamless integration with your existing e-commerce platform or custom solution."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-gray-800 rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => toggleFaq(index)}
                    className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-750 transition-all"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown 
                      className={`h-5 w-5 transform transition-transform ${
                        openFaq === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-6 text-gray-400">
                      {faq.answer}
                    </div>
                  )}
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                  Product Descriptions?
                </span>
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of successful e-commerce businesses using AI to create converting product content
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105"
                >
                  Start Generating Free
                </Link>
                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Pricing
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">No credit card required • 14-day free trial</p>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductDescriptionWriterLanding;