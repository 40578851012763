import React, { useRef } from 'react';
import { PieChart as PieChartIcon, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const pieChartConfig = {
  id: 'pie-chart',
  label: 'Pie Chart Analysis',
  icon: <PieChartIcon className="w-4 h-4" />,
  description: 'Analyze categorical distributions and proportions',
};

export const createPrompt = (data, columns) => {
    const categoricalColumns = columns.filter(col => {
      const sampleValue = data[0]?.[col.accessor || col.id];
      return typeof sampleValue === 'string' || typeof sampleValue === 'boolean';
    });
  
    const numericColumns = columns.filter(col => {
      const sampleValue = data[0]?.[col.accessor || col.id];
      return typeof sampleValue === 'number';
    });
  
    const tablePreview = data.map(row => {
      return columns.map(col => `${col.Header || col.id}: ${row[col.accessor || col.id]}`).join(', ');
    }).join('\n');
  
    const prompt = `Analyze this dataset and provide distribution analysis in this exact JSON format:
  
  {
    "distributions": [
      {
        "name": "column_name",
        "type": "categorical|numerical",
        "data": [
          { "label": "category or range", "value": number, "percentage": number }
        ],
        "insights": [
          "specific insight about this distribution"
        ]
      }
    ],
    "comparisons": [
      {
        "primary": "column_name",
        "secondary": "column_name",
        "correlation": number,
        "insights": [
          "insight about relationship between these variables"
        ]
      }
    ],
    "summary": {
      "dominantCategories": [
        { "column": "name", "category": "value", "percentage": number }
      ],
      "unusualDistributions": [
        { "column": "name", "reason": "explanation" }
      ]
    },
    "recommendations": [
      "actionable recommendation based on distributions"
    ]
  }
  
  For numerical columns, create meaningful bins/ranges and calculate their distributions. For example:
  - Sales/Revenue: Group into ranges like "0-1M", "1M-2M", etc.
  - Units: Group into ranges like "0-100", "101-500", etc.
  - Satisfaction: Group into ranges like "Low (0-3)", "Medium (4-7)", "High (8-10)"
  - Marketing Spend: Group into quartiles or meaningful monetary ranges
  
  Table Structure:
  Categorical Columns: ${categoricalColumns.map(col => col.Header || col.id).join(', ')}
  Numeric Columns: ${numericColumns.map(col => col.Header || col.id).join(', ')}
  
  Complete Dataset:
  ${JSON.stringify(data)}
  
  Sample Rows:
  ${tablePreview}`;
  
    return prompt;
  };

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);

    return {
      distributions: parsedJson.distributions || [],
      comparisons: parsedJson.comparisons || [],
      summary: parsedJson.summary || {},
      recommendations: parsedJson.recommendations || []
    };

  } catch (error) {
    return {
      distributions: [],
      comparisons: [],
      summary: {},
      recommendations: ['Failed to parse analysis results: ' + error.message]
    };
  }
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

export const PieChartResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('distribution-analysis.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Distribution Charts */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {results.distributions.map((dist, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg">
              <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
                {dist.name} Distribution
              </h4>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={dist.data}
                      dataKey="value"
                      nameKey="label"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                    >
                      {dist.data.map((entry, i) => (
                        <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="mt-4 space-y-2">
                {dist.insights.map((insight, i) => (
                  <p key={i} className="text-sm text-gray-600 dark:text-gray-300">{insight}</p>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Comparisons */}
        {results.comparisons.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6 mt-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Distribution Comparisons</h4>
            <div className="space-y-4">
              {results.comparisons.map((comp, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-4 rounded-md">
                  <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-2">
                    {comp.primary} vs {comp.secondary}
                  </h5>
                  <div className="text-sm text-gray-600 dark:text-gray-300">
                    Correlation: {comp.correlation.toFixed(2)}
                  </div>
                  <ul className="mt-2 space-y-1">
                    {comp.insights.map((insight, i) => (
                      <li key={i} className="text-sm text-gray-600 dark:text-gray-300">• {insight}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Summary */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Key Findings</h4>
            <div className="space-y-4">
              {results.summary.dominantCategories.map((item, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md">
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {item.column}
                  </div>
                  <div className="text-gray-900 dark:text-gray-100">
                    Dominant: {item.category} ({item.percentage.toFixed(1)}%)
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Unusual Patterns</h4>
            <div className="space-y-4">
              {results.summary.unusualDistributions.map((item, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md">
                  <div className="text-sm text-gray-500 dark:text-gray-400">{item.column}</div>
                  <div className="text-gray-900 dark:text-gray-100">{item.reason}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Recommendations */}
        {results.recommendations?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6 mt-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Recommendations</h4>
            <ul className="space-y-3">
              {results.recommendations.map((recommendation, index) => (
                <li 
                  key={index}
                  className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 font-medium">
                    {index + 1}
                  </span>
                  <span>{recommendation}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  config: pieChartConfig,
  createPrompt,
  parseResults,
  ResultsComponent: PieChartResults
};