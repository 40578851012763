import React, { useRef } from 'react';
import { Network, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ZAxis, Label } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const scatterPlotConfig = {
  id: 'scatter-analysis',
  label: 'Scatter Plot Analysis',
  icon: <Network className="w-4 h-4" />,
  description: 'Analyze relationships between variables',
};

export const createPrompt = (data, columns) => {
  // Filter for numeric columns only
  const numericColumns = columns.filter(col => {
    const sampleValue = data[0]?.[col.accessor || col.id];
    return typeof sampleValue === 'number';
  });

  const tablePreview = data.map(row => {
    return columns.map(col => `${col.Header || col.id}: ${row[col.accessor || col.id]}`).join(', ');
  }).join('\n');

  const prompt = `Analyze the relationships between numerical variables in this dataset and provide analysis in this exact JSON format:

{
  "correlations": {
    "pairs": [
      {
        "variables": ["var1", "var2"],
        "correlation": number,
        "strength": "strong|moderate|weak",
        "direction": "positive|negative"
      }
    ]
  },
  "scatterPlotData": {
    "bestPair": {
      "xAxis": "variable1",
      "yAxis": "variable2",
      "data": [
        {"x": number, "y": number}
      ]
    },
    "regressionLine": {
      "slope": number,
      "intercept": number,
      "r2": number
    }
  },
  "clusters": {
    "count": number,
    "description": "description of any visible clusters",
    "clusterCenters": [
      {"x": number, "y": number, "size": number}
    ]
  },
  "outliers": {
    "count": number,
    "points": [
      {"x": number, "y": number, "description": "string"}
    ]
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ]
}

Available Numeric Columns:
${numericColumns.map(col => col.Header || col.id).join(', ')}

Full Table Data:
${tablePreview}

Include all numeric columns in the correlation analysis. For the scatter plot, identify and use the pair of variables with the strongest meaningful relationship.`;

  return prompt;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);

    return {
      correlations: parsedJson.correlations || { pairs: [] },
      scatterPlotData: parsedJson.scatterPlotData || { bestPair: { data: [] }, regressionLine: {} },
      clusters: parsedJson.clusters || { clusterCenters: [] },
      outliers: parsedJson.outliers || { points: [] },
      insights: parsedJson.insights || []
    };
  } catch (error) {
    return {
      correlations: { pairs: [] },
      scatterPlotData: { bestPair: { data: [] }, regressionLine: {} },
      clusters: { clusterCenters: [] },
      outliers: { points: [] },
      insights: ['Failed to parse analysis results: ' + error.message]
    };
  }
};

export const ScatterPlotResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const { correlations, scatterPlotData, clusters, outliers, insights } = results;
  const { bestPair, regressionLine } = scatterPlotData;

  // Calculate regression line points
  const regressionPoints = bestPair.data.length > 0 ? [
    { x: Math.min(...bestPair.data.map(d => d.x)), y: regressionLine.slope * Math.min(...bestPair.data.map(d => d.x)) + regressionLine.intercept },
    { x: Math.max(...bestPair.data.map(d => d.x)), y: regressionLine.slope * Math.max(...bestPair.data.map(d => d.x)) + regressionLine.intercept }
  ] : [];

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('scatter-plot-analysis.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Scatter Plot */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Scatter Plot: {bestPair.xAxis} vs {bestPair.yAxis}
          </h4>
          <div className="h-[400px]">
  <ResponsiveContainer width="100%" height="100%">
    <ScatterChart margin={{ top: 20, right: 20, bottom: 40, left: 40 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis 
        dataKey="x" 
        type="number" 
        name={bestPair.xAxis}
        angle={-45}
        textAnchor="end"
        interval={0}
        tick={{ 
          fontSize: '0.8rem',
          fill: 'currentColor',
          className: 'text-gray-600 dark:text-gray-300'
        }}
      >
        <Label 
          value={bestPair.xAxis} 
          position="bottom" 
          offset={20}
          style={{
            fill: 'currentColor',
            textAnchor: 'middle',
            fontSize: '0.9rem'
          }}
          className="text-gray-700 dark:text-gray-200"
        />
      </XAxis>
      <YAxis 
        dataKey="y" 
        type="number" 
        name={bestPair.yAxis}
        tick={{ 
          fontSize: '0.8rem',
          fill: 'currentColor',
          className: 'text-gray-600 dark:text-gray-300'
        }}
      >
        <Label 
          value={bestPair.yAxis} 
          angle={-90} 
          position="left"
          offset={-20}
          style={{
            fill: 'currentColor',
            textAnchor: 'middle',
            fontSize: '0.9rem'
          }}
          className="text-gray-700 dark:text-gray-200"
        />
      </YAxis>
      <Tooltip 
        cursor={{ strokeDasharray: '3 3' }}
        contentStyle={{
          backgroundColor: 'rgb(var(--background))',
          border: '1px solid rgb(var(--border))',
          borderRadius: '0.375rem'
        }}
        labelStyle={{
          color: 'rgb(var(--foreground))'
        }}
      />
      <Legend 
        verticalAlign="top"
        height={36}
        wrapperStyle={{
          fontSize: '0.9rem',
          color: 'currentColor'
        }}
      />
      
      {/* Main scatter plot */}
      <Scatter
        name="Data Points"
        data={bestPair.data}
        fill="#8884d8"
        opacity={0.6}
      />

      {/* Regression line */}
      <Scatter
        name="Regression Line"
        data={regressionPoints}
        line={{ stroke: '#ff7300', strokeWidth: 2 }}
        shape="line"
        legendType="line"
      />

      {/* Cluster centers */}
      {clusters.clusterCenters.length > 0 && (
        <Scatter
          name="Cluster Centers"
          data={clusters.clusterCenters}
          fill="#82ca9d"
          shape="circle"
          legendType="circle"
        />
      )}

      {/* Outliers */}
      {outliers.points.length > 0 && (
        <Scatter
          name="Outliers"
          data={outliers.points}
          fill="#ff0000"
          shape="star"
          legendType="star"
        />
      )}
    </ScatterChart>
  </ResponsiveContainer>
</div>
        </div>

        {/* Correlation Analysis */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Correlation Analysis</h4>
            <div className="space-y-4">
              {correlations.pairs.map((pair, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-700 dark:text-gray-300">
                      {pair.variables.join(' vs ')}
                    </span>
                    <span className={`px-2 py-1 rounded text-sm ${
                      pair.strength === 'strong' ? 'bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300' :
                      pair.strength === 'moderate' ? 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-700 dark:text-yellow-300' :
                      'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300'
                    }`}>
                      {pair.strength}
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Correlation:</span>
                      <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                        {pair.correlation.toFixed(3)}
                      </span>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Direction:</span>
                      <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                        {pair.direction}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Statistical Summary</h4>
            <div className="space-y-4">
              <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3">Regression Analysis</h5>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">R² Value:</span>
                    <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                      {regressionLine.r2.toFixed(3)}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">Slope:</span>
                    <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                      {regressionLine.slope.toFixed(3)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3">Clusters & Outliers</h5>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">Clusters:</span>
                    <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                      {clusters.count}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">Outliers:</span>
                    <span className="ml-2 font-medium text-gray-900 dark:text-gray-100">
                      {outliers.count}
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  <span className="text-sm text-gray-500 dark:text-gray-400">Pattern:</span>
                  <p className="mt-1 text-gray-700 dark:text-gray-300">{clusters.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Insights */}
        {insights?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6 mt-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Key Insights</h4>
            <ul className="space-y-3">
              {insights.map((insight, index) => (
                <li 
                  key={index}
                  className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                    {index + 1}
                  </span>
                  <span>{insight}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  config: scatterPlotConfig,
  createPrompt,
  parseResults,
  ResultsComponent: ScatterPlotResults
};