import React, { useState } from 'react';
import { 
  Loader, 
  AlertCircle, 
  LineChart, 
  PieChart, 
  TrendingUp, 
  FileText,
  BrainCircuit,
  Network,
  GanttChart,
  Laptop
} from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import AnalysisPanel from './AnalysisPanel';
import SummaryStats from './summaryStats';
import TimeSeriesAnalysis from './timeSeriesAnalysis';
import CorrelationAnalysis from './correlationAnalysis';
import correlationAnalysis from './correlationAnalysis';
import LineChartAnalysis from './Linechartanalysis';
import pieChartAnalysis from './pieChartAnalysis';
import scatterPlotAnalysis from './scatterPlotAnalysis';
import aiForecasting from './aiForecasting';
import patternRecognition from './patternRecognition';
import ExecutiveSummary from './executiveSummary';
import executiveSummary from './executiveSummary';
import DetailedReport from './detailedReport';
import detailedReport from './detailedReport';

const TableAIPrompt = ({ 
  data = [], 
  columns = [], 
  setData,
  saveToHistory, 
  userPoints 
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [selectedTool, setSelectedTool] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);

  const tools = {
    dataAnalysis: {
      label: 'Smart Analysis',
      icon: <BrainCircuit className="w-4 h-4" />,
      subTools: [
        SummaryStats.config,
        TimeSeriesAnalysis.config,
        correlationAnalysis.config,
      ]
    },
    visualization: {
      label: 'Data Visualization',
      icon: <GanttChart className="w-4 h-4" />,
      subTools: [
        LineChartAnalysis.config,
        pieChartAnalysis.config,
        scatterPlotAnalysis.config,
      ]
    },
    intelligence: {
      label: 'Business Intelligence',
      icon: <Laptop className="w-4 h-4" />,
      subTools: [
        aiForecasting.config,
        patternRecognition.config,
      ]
    },
    reporting: {
      label: 'Smart Reports',
      icon: <FileText className="w-4 h-4" />,
      subTools: [
        executiveSummary.config,
        DetailedReport.config,
      ]
    }
  };

  const handleToolSelection = async (toolId) => {
    
    
    if (userPoints < 10) {
      setError('Insufficient points. Analysis operations require 10 points.');
      return;
    }

    setSelectedTool(toolId);
    setIsProcessing(true);
    setError('');
    setIsPanelOpen(true);

    try {
      const apiUrl = process.env.NODE_ENV === 'production' 
        ? 'https://api.thecontentgpt.com/api/spreadsheet/table-ai'
        : 'http://localhost:5001/api/spreadsheet/table-ai';

      // Get tool-specific handlers
      const tool = toolId === 'summary-stats' ? SummaryStats : 
            toolId === 'time-series' ? TimeSeriesAnalysis :
            toolId === 'correlation' ? CorrelationAnalysis :
            toolId === 'line-chart' ? LineChartAnalysis :
            toolId === 'pie-chart' ? pieChartAnalysis :
            toolId === 'scatter-analysis' ? scatterPlotAnalysis :
            toolId === 'ai-forecasting' ? aiForecasting :
            toolId === 'pattern-recognition' ? patternRecognition :
            toolId === 'executive-summary' ? executiveSummary :
            toolId === 'detailed-report' ? detailedReport :
            null;
      if (!tool) {
        throw new Error('Tool type not implemented');
      }

      // Generate prompt using tool's handler
      // Generate prompt using tool's handler
const prompt = tool.createPrompt(data, columns);


const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({
        prompt,
        tableContext: {
            columns: columns.map(col => ({
                name: col.Header || col.id,
                accessor: col.accessor || col.id,
                type: col.type
            })),
            data,
            toolId
        },
        metadata: {
            points_to_deduct: 5,
            feature: 'table_ai'
        }
    })
});

if (!response.ok) {
    throw new Error(`Failed to process analysis: ${response.statusText}`);
}

const result = await response.json();


// Parse results using tool's parser
const parsedResults = tool.parseResults(result.explanation);


setAnalysisResults(parsedResults);
setIsPanelOpen(true);

    } catch (err) {
      console.error('Error in analysis:', err);
      setError(err.message || 'Failed to process analysis');
      setIsPanelOpen(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const renderResults = () => {
    if (!selectedTool || !analysisResults) {
      return null;
    }

    switch (selectedTool) {
      case 'summary-stats':
        return <SummaryStats.ResultsComponent results={analysisResults} />;
      case 'correlation':
        return <CorrelationAnalysis.ResultsComponent results={analysisResults} />;
      case 'time-series':
        return <TimeSeriesAnalysis.ResultsComponent results={analysisResults} />;
      case 'line-chart':
        return <LineChartAnalysis.ResultsComponent results={analysisResults} />;
        case 'pie-chart':
        return <pieChartAnalysis.ResultsComponent results={analysisResults} />;
        case 'scatter-analysis':
        return <scatterPlotAnalysis.ResultsComponent results={analysisResults} />;
        case 'ai-forecasting':
        return <aiForecasting.ResultsComponent results={analysisResults} />;
        case 'pattern-recognition':
        return <patternRecognition.ResultsComponent results={analysisResults} />;
        case 'executive-summary':
  return <ExecutiveSummary.ResultsComponent results={analysisResults} />;
  case 'detailed-report':
  return <DetailedReport.ResultsComponent results={analysisResults} />;
      default:
        return (
          <div className="text-center text-gray-500 dark:text-gray-400 p-4">
            Tool results not implemented
          </div>
        );
    }
  };

  return (
    <>
      <Card className="p-4 bg-white dark:bg-gray-900 border-0 shadow-lg">
        <div className="flex flex-col space-y-4">
        {error && (
  <Alert variant="destructive" className="bg-red-50 dark:bg-red-900/20 border-red-200 dark:border-red-800">
    <AlertCircle className="h-4 w-4 text-red-600 dark:text-red-400" />
    <AlertDescription className="text-red-600 dark:text-red-400">{error}</AlertDescription>
  </Alert>
)}

{/* Add cost indicator */}
<div className="mb-4 text-sm text-gray-600 dark:text-gray-400 font-medium">
  Each analysis operation costs ONLY <span className="text-purple-600 dark:text-purple-400">10 points</span>
</div>
          
          <div className="grid grid-cols-4 gap-3">
            {Object.entries(tools).map(([key, category]) => (
              <DropdownMenu key={key}>
                <DropdownMenuTrigger asChild>
                <Button 
  variant="outline"
  className="w-full h-24 flex flex-col items-center justify-center space-y-2 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 border border-gray-200 dark:border-gray-700 hover:border-green-900 dark:hover:border-yellow-400 transition-all duration-300"
  disabled={isProcessing}
>
  <div className="p-2 rounded-full bg-gray-100 dark:bg-gray-800">
    <div className="text-gray-700 dark:text-gray-200">
      {category.icon}
    </div>
  </div>
  <span className="text-sm font-medium text-gray-700 dark:text-gray-200">{category.label}</span>
  <span className="text-xs text-gray-500 dark:text-gray-400">{category.description}</span>
</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
                  <DropdownMenuLabel className="text-gray-700 dark:text-gray-200">{category.label}</DropdownMenuLabel>
                  <DropdownMenuSeparator className="bg-gray-200 dark:bg-gray-700" />
                  {category.subTools.map((tool) => (
                    <DropdownMenuItem
                      key={tool.id}
                      onClick={() => handleToolSelection(tool.id)}
                      className="flex items-center space-x-2 text-gray-600 dark:text-gray-300 hover:text-yellow-600 dark:hover:text-yellow-400 cursor-pointer"
                    >
                      {tool.icon}
                      <span>{tool.label}</span>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            ))}
          </div>
        </div>
      </Card>

      <AnalysisPanel
        isOpen={isPanelOpen}
        onClose={() => setIsPanelOpen(false)}
        results={renderResults()}
        toolType={selectedTool}
        isLoading={isProcessing}
      />
    </>
  );
};

export default TableAIPrompt;