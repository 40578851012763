import React, { useState, useEffect } from 'react';
import { Edit2, Loader, Upload, X, Download, AlertCircle } from 'lucide-react';
import * as XLSX from 'xlsx';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import apiService from '../../services/apiService';


// Modified ResizableHeader component with proper styling
const ResizableHeader = ({ width, onResize, children }) => {
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="absolute right-0 top-0 h-full w-2 cursor-col-resize bg-gray-300 opacity-0 hover:opacity-100"
          onClick={e => e.stopPropagation()}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        className="p-2 border text-left bg-gray-100 relative"
        style={{ 
          width,
          height: '40px',
          position: 'relative',
          zIndex: 1,
          overflow: 'hidden' // Add overflow control
        }}
      >
        <div className="absolute inset-0 p-2">
          <span className="font-semibold block">{children}</span>
        </div>
      </th>
    </Resizable>
  );
};

const DynamicAISpreadsheet = ({ initialFile }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  const [loading, setLoading] = useState({});
  const [editValue, setEditValue] = useState('');
  const [prompt, setPrompt] = useState('');
  const [generatingCells, setGeneratingCells] = useState(new Set());
  const [focusedCell, setFocusedCell] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [userPoints, setUserPoints] = useState(0);
  const [error, setError] = useState('');

  // Add this near the top of your DynamicAISpreadsheet component
const checkAndUpdatePoints = async () => {
  try {
    const response = await apiService.fetch('/points', {
      method: 'GET'
    });
    if (response.balance !== undefined) {
      setUserPoints(response.balance);
    }
  } catch (error) {
    console.error('Error checking points:', error);
  }
};

// Add this useEffect to periodically check points
useEffect(() => {
  checkAndUpdatePoints();
  // Check points every 30 seconds
  const interval = setInterval(checkAndUpdatePoints, 30000);
  return () => clearInterval(interval);
}, []);

// Add this useEffect to your component
useEffect(() => {
  const handlePointsUpdate = (event) => {
    if (event.detail.balance !== undefined) {
      setUserPoints(event.detail.balance);
    }
  };

  window.addEventListener('pointsUpdate', handlePointsUpdate);
  return () => window.removeEventListener('pointsUpdate', handlePointsUpdate);
}, []);

  // Add points loading effect
  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/points'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setUserPoints(data.balance);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };

    fetchPoints();
  }, []);

  // Add this useEffect to handle initialFile
  useEffect(() => {
    if (initialFile) {
      handleFileUpload({ target: { files: [initialFile] } });
    }
  }, [initialFile]);

  // Initialize default column widths
  useEffect(() => {
    const defaultWidths = {
      'manufacturer': 150,
      'series': 100,     // Wider column for series name
      'series_id': 150,
      // Add other column default widths as needed
    };

    const widths = {};
    columns.forEach(column => {
      if (!columnWidths[column]) {
        widths[column] = defaultWidths[column] || 200; // Fallback to 200 if no specific width
      }
    });
    setColumnWidths(prev => ({ ...prev, ...widths }));
  }, [columns]);

  const onResize = (column) => (e, { size }) => {
    e.preventDefault();
    setColumnWidths(prev => ({
      ...prev,
      [column]: Math.max(100, size.width) // Minimum width of 100px
    }));
  };
  
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        
        const headers = [];
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cell = worksheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
          headers.push(cell ? cell.v : `Column${C + 1}`);
        }
        
        const jsonData = [];
        for (let R = range.s.r + 1; R <= range.e.r; R++) {
          const row = {};
          for (let C = range.s.c; C <= range.e.c; C++) {
            const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
            row[headers[C]] = cell ? cell.v : '';
          }
          jsonData.push(row);
        }
        
        setColumns(headers);
        setData(jsonData);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    };
    if (file) {
      reader.readAsBinaryString(file);
    }
  };

  const handleDownload = () => {
    try {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data, { header: columns });
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'spreadsheet_export.xlsx');
    } catch (error) {
      console.error('Error exporting Excel file:', error);
    }
  };



// Update processWithAI function to handle points
const processWithAI = async (rowData, columnToFill, userPrompt, isMultiCell = false) => {
  try {
    // Calculate points cost (3 point per cell)
    const pointCost = isMultiCell ? selectedCells.length : 3;
    
    // Check if user has enough points
    if (userPoints < pointCost) {
      throw new Error(`Insufficient points. You need ${pointCost} points. Current balance: ${userPoints}`);
    }

    const rowContext = Object.entries(rowData)
      .filter(([key, value]) => value && value !== 'MISSING')
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    const fullPrompt = isMultiCell ? 
      `Given this data context:\n${rowContext}\n\nUser request: ${userPrompt}\n\nGenerate content for multiple cells. Return ONLY the raw content for each cell, formatted as a JSON array.` :
      `Given this data context:\n${rowContext}\n\nUser request: ${userPrompt}\n\nGenerate content for the ${columnToFill} field.`;

      const response = await apiService.fetch('/spreadsheet/process', {
        method: 'POST',
        body: {
          prompt: userPrompt,
          isMultiCell,
          rowData,
          columnName: columnToFill,
          metadata: {
            input_word_count: userPrompt.split(' ').length,  // Add this
            points_to_deduct: pointCost,  // Add this
            feature: 'spreadsheet'  // Add this
          }
        }
      });

    // Update points balance from response
    if (response.remainingPoints !== undefined) {
      setUserPoints(response.remainingPoints);
      // Trigger global points update
      window.dispatchEvent(new CustomEvent('pointsUpdate', { 
        detail: { balance: response.remainingPoints }
      }));
    }

    if (isMultiCell) {
      try {
        return Array.isArray(response.content) ? response.content : JSON.parse(response.content);
      } catch (e) {
        console.error('Error parsing multi-cell response:', e);
        return response.content.split('\n').filter(line => line.trim());
      }
    }
    
    return response.content;

  } catch (error) {
    console.error('Error in AI processing:', error);
    throw error;
  }
};

// Update handlePromptSubmit to handle errors better
const handlePromptSubmit = async () => {
  if (!prompt.trim()) return;

  try {
    if (selectedCells.length > 0) {
      // Show points warning if not enough points
      if (userPoints < selectedCells.length) {
        setError(`Insufficient points. You need ${selectedCells.length} points. Current balance: ${userPoints}`);
        return;
      }

      const cellsToGenerate = new Set(selectedCells.map(cell => cell.cellId));
      setGeneratingCells(prev => new Set([...prev, ...cellsToGenerate]));
      
      const cellsToUpdate = [...selectedCells];
      setSelectedCells([]);
      
      try {
        const firstCell = cellsToUpdate[0];
        const contents = await processWithAI(data[firstCell.rowIndex], firstCell.columnName, prompt, true);
        
        setData(currentData => {
          const newData = [...currentData];
          cellsToUpdate.forEach((cell, index) => {
            if (index < contents.length) {
              newData[cell.rowIndex] = {
                ...newData[cell.rowIndex],
                [cell.columnName]: contents[index]
              };
            }
          });
          return newData;
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setGeneratingCells(prev => {
          const newSet = new Set(prev);
          cellsToGenerate.forEach(cellId => newSet.delete(cellId));
          return newSet;
        });
      }
    } else if (selectedCell) {
      // Show points warning if not enough points
      if (userPoints < 1) {
        setError(`Insufficient points. You need 1 point. Current balance: ${userPoints}`);
        return;
      }

      const [rowIndex, columnName] = selectedCell.split('-');
      const currentCellId = selectedCell;
      const currentPromptValue = prompt;
      
      setGeneratingCells(prev => new Set([...prev, currentCellId]));
      setSelectedCell(null);
      setPrompt('');
      
      try {
        const content = await processWithAI(data[rowIndex], columnName, currentPromptValue, false);
        
        setData(currentData => {
          const newData = [...currentData];
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnName]: content
          };
          return newData;
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setGeneratingCells(prev => {
          const newSet = new Set(prev);
          newSet.delete(currentCellId);
          return newSet;
        });
      }
    }
  } catch (error) {
    setError(error.message || 'An error occurred while processing content');
  }
};


const toggleCellSelection = (rowIndex, columnName, event) => {
  event.stopPropagation();
  const cellId = `${rowIndex}-${columnName}`;
  
  // Toggle selection first
  setSelectedCells(prev => {
    const isSelected = prev.some(cell => cell.cellId === cellId);
    if (isSelected) {
      return prev.filter(cell => cell.cellId !== cellId);
    }
    return [...prev, { cellId, rowIndex, columnName }];
  });

  // Calculate next cell position
  const colIndex = columns.indexOf(columnName);
  let nextRow = rowIndex;
  let nextCol = colIndex;

  if (colIndex + 1 < columns.length) {
    nextCol = colIndex + 1;
  } else if (rowIndex + 1 < data.length) {
    nextRow = rowIndex + 1;
    nextCol = 0;
  }

  const nextCellId = `${nextRow}-${columns[nextCol]}`;
  
  // Use setTimeout to ensure state updates are processed before moving focus
  setTimeout(() => {
    setFocusedCell(nextCellId);
    const nextCell = document.getElementById(`cell-${nextCellId}`);
    if (nextCell) {
      nextCell.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      nextCell.focus();
    }
  }, 0);
};

useEffect(() => {
  const handleKeyDown = (e) => {
    if (document.activeElement.tagName === 'INPUT' || 
        document.activeElement.tagName === 'TEXTAREA') {
      return;
    }

    if (!focusedCell) return;
    
    const [currentRow, currentCol] = focusedCell.split('-');
    const rowIndex = parseInt(currentRow);
    const colIndex = columns.indexOf(currentCol);
    
    let newRow = rowIndex;
    let newCol = colIndex;

    switch (e.key) {
      case 'ArrowUp':
        newRow = Math.max(0, rowIndex - 1);
        break;
      case 'ArrowDown':
        newRow = Math.min(data.length - 1, rowIndex + 1);
        break;
      case 'ArrowLeft':
        newCol = Math.max(0, colIndex - 1);
        break;
      case 'ArrowRight':
        newCol = Math.min(columns.length - 1, colIndex + 1);
        break;
      case ' ': // Space bar
        e.preventDefault();
        e.stopPropagation();
        
        const currentCellId = `${rowIndex}-${columns[colIndex]}`;
        setSelectedCells(prev => {
          const isCurrentlySelected = prev.some(cell => cell.cellId === currentCellId);
          if (isCurrentlySelected) {
            return prev.filter(cell => cell.cellId !== currentCellId);
          } else {
            return [...prev, { 
              cellId: currentCellId, 
              rowIndex: rowIndex, 
              columnName: columns[colIndex] 
            }];
          }
        });

        // Move to next cell
        if (colIndex + 1 < columns.length) {
          setFocusedCell(`${rowIndex}-${columns[colIndex + 1]}`);
        } else if (rowIndex + 1 < data.length) {
          setFocusedCell(`${rowIndex + 1}-${columns[0]}`);
        }
        return;
      default:
        return;
    }

    const newFocusedCell = `${newRow}-${columns[newCol]}`;
    setFocusedCell(newFocusedCell);
    
    const cellElement = document.getElementById(`cell-${newFocusedCell}`);
    if (cellElement) {
      cellElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      cellElement.focus();
    }
  };

  window.addEventListener('keydown', handleKeyDown);
  return () => window.removeEventListener('keydown', handleKeyDown);
}, [focusedCell, columns, data.length, selectedCells]);

  const selectNextCell = (currentRow, currentCol) => {
    const colIndex = columns.indexOf(currentCol);
    let nextRow = currentRow;
    let nextCol = colIndex;

    if (colIndex === columns.length - 1) {
      nextRow = currentRow + 1;
      nextCol = 0;
    } else {
      nextCol = colIndex + 1;
    }

    if (nextRow < data.length) {
      const nextCellId = `${nextRow}-${columns[nextCol]}`;
      setFocusedCell(nextCellId);
    }
  };



  const handleCleanSelectedCells = () => {
    setData(currentData => {
      const newData = [...currentData];
      selectedCells.forEach(cell => {
        newData[cell.rowIndex] = {
          ...newData[cell.rowIndex],
          [cell.columnName]: ''
        };
      });
      return newData;
    });
    setSelectedCells([]); // Clear selection after cleaning
  };

  
  
  const handleCellClick = (rowIndex, columnName, event) => {
    // Ignore if clicking checkbox
    if (event.target.type === 'checkbox') {
      // Set focus to the cell when checkbox is clicked
      setFocusedCell(`${rowIndex}-${columnName}`);
      return;
    }
    
    const cellId = `${rowIndex}-${columnName}`;
    setSelectedCell(cellId);
    setFocusedCell(cellId);
    setEditValue(data[rowIndex][columnName] || '');
    setPrompt('');
  };

  const handleSaveCell = (rowIndex, columnName) => {
    const newData = [...data];
    newData[rowIndex] = {
      ...newData[rowIndex],
      [columnName]: editValue
    };
    setData(newData);
    setSelectedCell(null);
  };

  
  return (
    <div className="w-full">
      {/* Action Buttons */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="flex gap-4">
            <label className="cursor-pointer inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">
              <Upload className="w-4 h-4 mr-2" />
              Upload Excel
              <input
                type="file"
                accept=".xlsx,.xls"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>
            
            {data.length > 0 && (
              <button
                onClick={handleDownload}
                className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
              >
                <Download className="w-4 h-4 mr-2" />
                Download Excel
              </button>
            )}
          </div>
        </div>
      </div>
  
      {/* Spreadsheet Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border rounded overflow-x-auto">
          <table className="w-full text-sm table-fixed border-collapse">
            <thead>
              <tr className="bg-gray-100">
                {columns.map(column => (
                  <ResizableHeader
                    key={column}
                    width={columnWidths[column] || 200}
                    onResize={onResize(column)}
                  >
                    {column}
                  </ResizableHeader>
                ))}
              </tr>
            </thead>
            <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => {
                  const cellId = `${rowIndex}-${column}`;
                  const isSelected = selectedCell === cellId;
                  const isMultiSelected = selectedCells.some(cell => cell.cellId === cellId);
                  const isGenerating = generatingCells.has(cellId);
                  const isFocused = focusedCell === cellId;

                  return (
                    <td
  id={`cell-${cellId}`}
  key={column}
  className={`p-2 border border-gray-700 dark:border-gray-700 relative cursor-pointer overflow-hidden
    ${isMultiSelected ? 'bg-purple-100 dark:bg-purple-900/30' : 'dark:bg-gray-800'}
    ${isGenerating ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
    ${isFocused ? 'outline outline-2 outline-blue-500' : ''}`}
  style={{ 
    width: columnWidths[column] || 200,
    maxWidth: columnWidths[column] || 200
  }}
  onClick={(e) => handleCellClick(rowIndex, column, e)}
  tabIndex={0}
  onFocus={() => setFocusedCell(cellId)}
>
  {/* Checkbox */}
  <div className="absolute top-1 right-1 flex items-center justify-center w-5 h-5 z-10">
    <input
      type="checkbox"
      className="w-4 h-4 cursor-pointer dark:bg-gray-700 dark:border-gray-600"
      checked={isMultiSelected}
      onChange={(e) => toggleCellSelection(rowIndex, column, e)}
    />
  </div>

  {/* Loading spinner */}
  {isGenerating && (
    <div className="absolute inset-0 flex items-center justify-center bg-blue-50 dark:bg-blue-900/20 bg-opacity-50 z-20">
      <Loader className="w-4 h-4 animate-spin text-blue-500" />
    </div>
  )}

  {/* Cell content */}
  <div className="min-h-[1.5rem] relative">
    <div className="whitespace-pre-wrap break-words text-gray-900 dark:text-gray-300">
      {row[column] || ''}
    </div>
    {row[column] === 'MISSING' && (
      <Edit2 className="w-4 h-4 text-gray-400 dark:text-gray-500 absolute top-0 right-0" />
    )}
  </div>


                      {isSelected && (
                        <div 
                          className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
                          onClick={() => setSelectedCell(null)}
                        >
                          <div 
                            className="bg-white rounded-lg p-4 max-w-2xl w-full max-h-[80vh] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="flex justify-between items-center mb-4">
                              <h3 className="text-lg font-semibold">Edit Cell: {column}</h3>
                              <button
                                onClick={() => setSelectedCell(null)}
                                className="text-gray-500 hover:text-gray-700"
                              >
                                <X className="w-5 h-5" />
                              </button>
                            </div>
                            
                            <div className="space-y-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                  AI Prompt
                                </label>
                                <div className="flex space-x-2">
                                  <textarea
                                    className="flex-1 p-2 border rounded"
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    placeholder="Enter your prompt for AI..."
                                    rows={2}
                                  />
                                  <button
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    onClick={handlePromptSubmit}
                                  >
                                    Generate
                                  </button>
                                </div>
                              </div>

                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                  Cell Content
                                </label>
                                <textarea
                                  className="w-full p-2 border rounded"
                                  value={editValue}
                                  onChange={(e) => setEditValue(e.target.value)}
                                  rows={4}
                                />
                              </div>

                              <div className="flex justify-end space-x-2">
                                <button
                                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                  onClick={() => setSelectedCell(null)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                  onClick={() => handleSaveCell(rowIndex, column)}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="mt-4 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
        onClick={() => setData([...data, {}])}
      >
        Add Row
      </button>
    </div>

{/* Points error display */}
{error && (
        <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex items-center z-50">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
          <button onClick={() => setError('')} className="ml-4">
            <X className="h-4 w-4" />
          </button>
        </div>
      )}

      {/* Bottom bar with multi-select */}
{selectedCells.length > 0 && (
  <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t p-4 shadow-lg z-50">
    <div className="max-w-4xl mx-auto">
      <div className="flex flex-col space-y-4">
        {/* Points and Instruction Info */}
        <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
          <div className="flex items-center space-x-4">
            <span>Points Required: {selectedCells.length}</span>
            <span>Your Balance: {userPoints} points</span>
            <span className={`${userPoints >= selectedCells.length ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
              Remaining After: {userPoints - selectedCells.length} points
            </span>
          </div>
          <div className="text-sm italic">
            Tip: Press Space to select/unselect cells
          </div>
        </div>
        
        {/* Input and Buttons */}
        <div className="flex space-x-4">
          <textarea
            className="flex-1 p-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt to generate content for selected cells..."
          />
          <div className="flex space-x-2">
            <button
              className="px-6 py-2 bg-red-500 text-white rounded hover:bg-red-600 whitespace-nowrap"
              onClick={handleCleanSelectedCells}
            >
              Clean {selectedCells.length} cells
            </button>
            <button
              className={`px-6 py-2 text-white rounded whitespace-nowrap ${
                userPoints >= selectedCells.length
                  ? 'bg-purple-600 hover:bg-purple-700'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              onClick={handlePromptSubmit}
              disabled={userPoints < selectedCells.length}
            >
              Generate for {selectedCells.length} cells
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default DynamicAISpreadsheet;