import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { FileCode, Loader2, Copy, ArrowRight, Info, Download, UploadCloud } from 'lucide-react';
import { Link } from 'react-router-dom';

const CSSMinifier = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [stats, setStats] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);

  const minifyCSS = useCallback((css) => {
    // Remove comments
    css = css.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '');
    
    // Remove whitespace
    css = css.replace(/\s+/g, ' ');
    
    // Remove spaces before and after brackets
    css = css.replace(/\s*{\s*/g, '{');
    css = css.replace(/\s*}\s*/g, '}');
    
    // Remove spaces before and after colons and semicolons
    css = css.replace(/\s*:\s*/g, ':');
    css = css.replace(/\s*;\s*/g, ';');
    
    // Remove last semicolon in each rule
    css = css.replace(/;}/g, '}');
    
    // Remove spaces after commas
    css = css.replace(/,\s*/g, ',');
    
    // Trim
    css = css.trim();
    
    return css;
  }, []);

  const processCSS = useCallback(() => {
    setIsProcessing(true);
    
    setTimeout(() => {
      const originalSize = new Blob([input]).size;
      const minified = minifyCSS(input);
      const minifiedSize = new Blob([minified]).size;
      const savings = originalSize - minifiedSize;
      const percentage = ((savings / originalSize) * 100).toFixed(2);
      
      setOutput(minified);
      setStats({
        originalSize,
        minifiedSize,
        savings,
        percentage
      });
      setIsProcessing(false);
    }, 800);
  }, [input, minifyCSS]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(output);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleDownload = () => {
    const blob = new Blob([output], { type: 'text/css' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'minified.css';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/css') {
      const reader = new FileReader();
      reader.onload = (e) => setInput(e.target.result);
      reader.readAsText(file);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <div 
          className={`relative ${isDragActive ? 'border-blue-500' : 'border-gray-700'} 
            border-2 border-dashed rounded-xl transition-colors`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Paste your CSS code here or drag & drop a CSS file..."
            className="w-full h-48 p-4 bg-gray-800 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          />
          {isDragActive && (
            <div className="absolute inset-0 bg-blue-500/10 rounded-xl flex items-center justify-center">
              <div className="flex items-center space-x-2 text-blue-500">
                <UploadCloud className="h-6 w-6" />
                <span>Drop your CSS file here</span>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {input.length} characters
          </span>
          <button
            onClick={processCSS}
            disabled={!input.trim() || isProcessing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isProcessing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Processing...</span>
              </>
            ) : (
              <>
                <FileCode className="h-4 w-4" />
                <span>Minify CSS</span>
              </>
            )}
          </button>
        </div>
      </div>

      {output && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {stats && (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4">
                <div className="text-sm text-gray-400">Original Size</div>
                <div className="text-lg font-medium">{stats.originalSize} bytes</div>
              </div>
              <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4">
                <div className="text-sm text-gray-400">Minified Size</div>
                <div className="text-lg font-medium">{stats.minifiedSize} bytes</div>
              </div>
              <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4">
                <div className="text-sm text-gray-400">Saved</div>
                <div className="text-lg font-medium">{stats.savings} bytes</div>
              </div>
              <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4">
                <div className="text-sm text-gray-400">Reduction</div>
                <div className="text-lg font-medium">{stats.percentage}%</div>
              </div>
            </div>
          )}

          <div className="relative">
            <pre className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white overflow-auto">
              {output}
            </pre>
            <div className="absolute top-4 right-4 space-x-2">
              <button
                onClick={handleCopy}
                className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                title="Copy to clipboard"
              >
                <Copy className="h-4 w-4" />
              </button>
              <button
                onClick={handleDownload}
                className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                title="Download minified CSS"
              >
                <Download className="h-4 w-4" />
              </button>
            </div>
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              This minifier removes comments, whitespace, and unnecessary characters while preserving functionality.
              Always test your minified CSS in a development environment before deploying to production.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Need Help With Content Creation?
              </h2>
              <p className="text-purple-100 mb-6">
                Turn your ideas into engaging content with our AI-powered writing tools. Get access to article generation, content optimization, and more. Start with 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CSSMinifier;