import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0A041C] text-white min-h-[400px]">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 min-h-[300px]">
          {/* Company Section */}
          <div className="space-y-4 min-h-[200px]">
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-400 hover:text-white block py-1">About us</Link></li>
              <li><Link to="/affiliate" className="text-gray-400 hover:text-white block py-1">Affiliate Program</Link></li>
              <li><Link to="/blog" className="text-gray-400 hover:text-white block py-1">Blog</Link></li>
              <li><Link to="/pricing" className="text-gray-400 hover:text-white block py-1">Pricing</Link></li>
            </ul>
          </div>

          {/* Popular Tools Section */}
          <div className="space-y-4 min-h-[200px]">
            <h3 className="text-lg font-semibold mb-4">Popular Tools</h3>
            <ul className="space-y-2">
              <li><Link to="/landing/human-writer" className="text-gray-400 hover:text-white block py-1">AI Detection Bypass</Link></li>
              <li><Link to="/landing/blog-writer" className="text-gray-400 hover:text-white block py-1">Blog Generator</Link></li>
              <li><Link to="/landing/social-media-writer" className="text-gray-400 hover:text-white block py-1">Social Media Writer</Link></li>
              <li><Link to="/landing/email-newsletter" className="text-gray-400 hover:text-white block py-1">Email Newsletter</Link></li>
              <li><Link to="/landing/product-description-writer" className="text-gray-400 hover:text-white block py-1">Product Description</Link></li>
            </ul>
          </div>

          {/* Support Section */}
          <div className="space-y-4 min-h-[200px]">
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/contact" className="text-gray-400 hover:text-white block py-1">Contact us</Link></li>
              <li><Link to="/register" className="text-gray-400 hover:text-white block py-1">Register</Link></li>
              <li><Link to="/login" className="text-gray-400 hover:text-white block py-1">Login</Link></li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="space-y-4 min-h-[200px]">
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="text-gray-400 hover:text-white block py-1">Terms & Conditions</Link></li>
              <li><Link to="/privacy" className="text-gray-400 hover:text-white block py-1">Privacy Policy</Link></li>
              <li><Link to="/refund" className="text-gray-400 hover:text-white block py-1">Refund Policy</Link></li>
            </ul>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            {/* Copyright */}
            <div className="text-gray-400 text-sm">
              © {currentYear} The Content GPT. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;