import React, { useState } from 'react';
import SEOHead from '../components/SEOHead';
import { 
  Mail, 
  MessageSquare, 
  FileText, 
  Youtube, 
  Book, 
  HelpCircle,
  ExternalLink,
  ArrowRight,
  Clock,
  CheckCircle2
} from 'lucide-react';

const Support = () => {
  const [showCopied, setShowCopied] = useState(false);

  const copyEmail = async () => {
    try {
      await navigator.clipboard.writeText('support@thecontentgpt.com');
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy email:', err);
    }
  };

  const supportResources = [
    {
      icon: MessageSquare,
      title: 'Contact Support',
      description: 'Get help from our support team',
      details: 'Email us at support@thecontentgpt.com'
    },
    {
      icon: Clock,
      title: 'Response Time',
      description: '24-48 hours response time',
      details: 'We aim to respond to all inquiries within 1-2 business days'
    },
    {
      icon: FileText,
      title: 'Documentation',
      description: 'Browse our help articles',
      details: 'Find answers to common questions'
    }
  ];

  const commonQuestions = [
    {
      question: 'How do I purchase more points?',
      answer: 'You can purchase additional points from your dashboard by clicking on your points balance or visiting the pricing page.'
    },
    {
      question: 'What is the points system?',
      answer: 'Points are our virtual currency used to generate content. Different features require different amounts of points based on their complexity and length.'
    },
    
    {
      question: 'How do I cancel my subscription?',
      answer: 'You can cancel your subscription at any time from your account settings. Your access will continue until the end of your billing period.'
    }
  ];

  return (
    <>
      <SEOHead />
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Hero Section */}
      <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Support Center</h1>
            <p className="mt-4 text-xl text-gray-600 dark:text-gray-400">
              We're here to help you succeed with your content creation
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Support Resources Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {supportResources.map((resource, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-purple-100 dark:bg-purple-900/50 rounded-lg flex items-center justify-center">
                  <resource.icon className="h-6 w-6 text-purple-600 dark:text-purple-400" />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {resource.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {resource.description}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-500">
                {resource.details}
              </p>
            </div>
          ))}
        </div>

        {/* Contact Card */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-8 mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <Mail className="h-12 w-12 text-purple-600 dark:text-purple-400 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Contact Our Support Team
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Our dedicated support team is ready to help you with any questions or concerns
            </p>
            <div className="inline-flex items-center space-x-2">
              <button
                onClick={copyEmail}
                className="inline-flex items-center px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
              >
                support@thecontentgpt.com
                {showCopied ? (
                  <CheckCircle2 className="ml-2 h-5 w-5" />
                ) : (
                  <ArrowRight className="ml-2 h-5 w-5" />
                )}
              </button>
              {showCopied && (
                <span className="text-green-600 dark:text-green-400">Copied!</span>
              )}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
              We typically respond within 24-48 hours
            </p>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8 text-center">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto divide-y divide-gray-200 dark:divide-gray-700">
            {commonQuestions.map((item, index) => (
              <div key={index} className="py-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  {item.question}
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-16 text-center">
          <p className="text-gray-600 dark:text-gray-400">
            Can't find what you're looking for?
          </p>
          <button
            onClick={copyEmail}
            className="mt-4 inline-flex items-center px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
          >
            Contact Support
            <Mail className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
    </>
  );
};
export default Support;