import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { FileText, Copy, Check, Info, ArrowRight, Plus, Trash2, Settings2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const RobotsTxtGenerator = () => {
  const [userAgents, setUserAgents] = useState([{ name: '*', rules: [] }]);
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [showCustomSection, setShowCustomSection] = useState(false);
  const [customSection, setCustomSection] = useState('');

  const commonPaths = {
    wordpress: ['/wp-admin/', '/wp-includes/', '/wp-content/plugins/', '/wp-content/themes/'],
    ecommerce: ['/cart/', '/checkout/', '/my-account/', '/order-status/'],
    common: ['/admin/', '/login/', '/private/', '/temp/', '/cgi-bin/']
  };

  const addUserAgent = useCallback(() => {
    setUserAgents(prev => [...prev, { name: '*', rules: [] }]);
  }, []);

  const removeUserAgent = useCallback((index) => {
    setUserAgents(prev => prev.filter((_, i) => i !== index));
  }, []);

  const updateUserAgent = useCallback((index, field, value) => {
    setUserAgents(prev => prev.map((agent, i) => 
      i === index ? { ...agent, [field]: value } : agent
    ));
  }, []);

  const addRule = useCallback((agentIndex, rule) => {
    setUserAgents(prev => prev.map((agent, i) => 
      i === agentIndex 
        ? { ...agent, rules: [...agent.rules, rule] }
        : agent
    ));
  }, []);

  const removeRule = useCallback((agentIndex, ruleIndex) => {
    setUserAgents(prev => prev.map((agent, i) => 
      i === agentIndex 
        ? { ...agent, rules: agent.rules.filter((_, j) => j !== ruleIndex) }
        : agent
    ));
  }, []);

  const generateRobotsTxt = useCallback(() => {
    let content = '';
    
    userAgents.forEach(agent => {
      content += `User-agent: ${agent.name}\n`;
      agent.rules.forEach(rule => {
        content += `${rule.type}: ${rule.path}\n`;
      });
      content += '\n';
    });

    if (sitemapUrl) {
      content += `Sitemap: ${sitemapUrl}\n\n`;
    }

    if (customSection) {
      content += `${customSection}\n`;
    }

    return content.trim();
  }, [userAgents, sitemapUrl, customSection]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generateRobotsTxt());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [generateRobotsTxt]);

  const CommonRulesSection = memo(({ agentIndex, onAddRule }) => (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium">Common Rules</h3>
        <button
          onClick={() => setShowCustomSection(!showCustomSection)}
          className="text-sm text-blue-400 hover:text-blue-300 flex items-center space-x-1"
        >
          <Settings2 className="h-4 w-4" />
          <span>Custom Section</span>
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <h4 className="text-sm font-medium mb-2">WordPress</h4>
          {commonPaths.wordpress.map((path) => (
            <button
              key={path}
              onClick={() => onAddRule({ type: 'Disallow', path })}
              className="text-sm text-gray-300 hover:text-white block mb-2"
            >
              {path}
            </button>
          ))}
        </div>
        <div>
          <h4 className="text-sm font-medium mb-2">E-commerce</h4>
          {commonPaths.ecommerce.map((path) => (
            <button
              key={path}
              onClick={() => onAddRule({ type: 'Disallow', path })}
              className="text-sm text-gray-300 hover:text-white block mb-2"
            >
              {path}
            </button>
          ))}
        </div>
        <div>
          <h4 className="text-sm font-medium mb-2">Common</h4>
          {commonPaths.common.map((path) => (
            <button
              key={path}
              onClick={() => onAddRule({ type: 'Disallow', path })}
              className="text-sm text-gray-300 hover:text-white block mb-2"
            >
              {path}
            </button>
          ))}
        </div>
      </div>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-6">
        {userAgents.map((agent, agentIndex) => (
          <motion.div
            key={agentIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-4"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <select
                  value={agent.name}
                  onChange={(e) => updateUserAgent(agentIndex, 'name', e.target.value)}
                  className="bg-gray-700 border-gray-600 rounded-lg text-sm p-2"
                >
                  <option value="*">All robots (*)</option>
                  <option value="Googlebot">Googlebot</option>
                  <option value="Bingbot">Bingbot</option>
                  <option value="Slurp">Yahoo! Slurp</option>
                  <option value="DuckDuckBot">DuckDuckBot</option>
                </select>
                {userAgents.length > 1 && (
                  <button
                    onClick={() => removeUserAgent(agentIndex)}
                    className="text-red-400 hover:text-red-300"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                )}
              </div>
              <button
                onClick={() => addRule(agentIndex, { type: 'Disallow', path: '/' })}
                className="flex items-center space-x-2 text-sm text-blue-400 hover:text-blue-300"
              >
                <Plus className="h-4 w-4" />
                <span>Add Rule</span>
              </button>
            </div>

            <div className="space-y-2">
              {agent.rules.map((rule, ruleIndex) => (
                <div key={ruleIndex} className="flex items-center space-x-2">
                  <select
                    value={rule.type}
                    onChange={(e) => updateUserAgent(agentIndex, 'rules', 
                      agent.rules.map((r, i) => 
                        i === ruleIndex ? { ...r, type: e.target.value } : r
                      )
                    )}
                    className="bg-gray-700 border-gray-600 rounded-lg text-sm p-2"
                  >
                    <option value="Allow">Allow</option>
                    <option value="Disallow">Disallow</option>
                  </select>
                  <input
                    type="text"
                    value={rule.path}
                    onChange={(e) => updateUserAgent(agentIndex, 'rules',
                      agent.rules.map((r, i) =>
                        i === ruleIndex ? { ...r, path: e.target.value } : r
                      )
                    )}
                    placeholder="/path/"
                    className="flex-1 bg-gray-700 border-gray-600 rounded-lg text-sm p-2"
                  />
                  <button
                    onClick={() => removeRule(agentIndex, ruleIndex)}
                    className="text-red-400 hover:text-red-300"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>

            <CommonRulesSection agentIndex={agentIndex} onAddRule={(rule) => addRule(agentIndex, rule)} />
          </motion.div>
        ))}

        <button
          onClick={addUserAgent}
          className="w-full py-3 border-2 border-dashed border-gray-700 rounded-xl text-gray-400 hover:text-gray-300 hover:border-gray-600 transition-colors"
        >
          Add User Agent
        </button>

        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <FileText className="h-4 w-4" />
            <span className="font-medium">Sitemap URL</span>
          </div>
          <input
            type="url"
            value={sitemapUrl}
            onChange={(e) => setSitemapUrl(e.target.value)}
            placeholder="https://example.com/sitemap.xml"
            className="w-full bg-gray-700 border-gray-600 rounded-lg text-sm p-2"
          />
        </div>

        {showCustomSection && (
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Settings2 className="h-4 w-4" />
              <span className="font-medium">Custom Section</span>
            </div>
            <textarea
              value={customSection}
              onChange={(e) => setCustomSection(e.target.value)}
              placeholder="Add custom directives here..."
              className="w-full h-24 bg-gray-700 border-gray-600 rounded-lg text-sm p-2 resize-none"
            />
          </div>
        )}

        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="font-medium">Generated robots.txt</h3>
            <button
              onClick={copyToClipboard}
              className="flex items-center space-x-2 text-sm text-blue-400 hover:text-blue-300"
            >
              {copied ? (
                <>
                  <Check className="h-4 w-4" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4" />
                  <span>Copy</span>
                </>
              )}
            </button>
          </div>
          <pre className="bg-gray-900 rounded-lg p-4 text-sm overflow-x-auto">
            {generateRobotsTxt()}
          </pre>
        </div>

        <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
          <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
          <p>
            This robots.txt generator helps you create proper directives for search engine crawlers.
            Make sure to test your robots.txt file before deploying it to production.
          </p>
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Need Help With Content Creation?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to advanced AI content generation tools, SEO optimization features, and more. Sign up now and receive 1000 free credits to explore our premium features!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default RobotsTxtGenerator;