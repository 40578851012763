import React from 'react';
import { MessageSquare } from 'lucide-react';

const FloatingPromptButton = ({ position, onClick }) => {
  if (!position) return null;

  return (
    <div 
      className="absolute z-20 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      <button
        onClick={onClick}
        className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
      >
        <MessageSquare className="w-4 h-4" />
        Prompt
      </button>
    </div>
  );
};

export default FloatingPromptButton;