import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, Tooltip } from 'recharts';
import { useState } from 'react';

const CompetitorChart = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const data = [
    { metric: 'Domain Authority', you: 45, competitor1: 60, competitor2: 35 },
    { metric: 'Backlinks', you: 75, competitor1: 65, competitor2: 55 },
    { metric: 'Keywords', you: 85, competitor1: 70, competitor2: 45 },
    { metric: 'Content Score', you: 90, competitor1: 75, competitor2: 60 },
    { metric: 'Page Speed', you: 95, competitor1: 80, competitor2: 85 },
    { metric: 'User Experience', you: 88, competitor1: 72, competitor2: 65 },
  ];

  return (
    <div className="w-full h-full flex flex-col items-center justify-center text-gray-200">
      <div className="w-full max-w-lg">
        <RadarChart 
          outerRadius={140} 
          width={500} 
          height={400} 
          data={data}
          className="mx-auto"
        >
          <PolarGrid stroke="rgba(255, 255, 255, 0.2)" />
          <PolarAngleAxis 
            dataKey="metric" 
            tick={{ fill: 'rgb(156, 163, 175)' }}
          />
          <PolarRadiusAxis 
            tick={{ fill: 'rgb(156, 163, 175)' }} 
            stroke="rgba(255, 255, 255, 0.2)"
          />
          <Radar
            name="Your Site"
            dataKey="you"
            stroke="#3B82F6"
            fill="#3B82F6"
            fillOpacity={0.3}
          />
          <Radar
            name="Competitor 1"
            dataKey="competitor1"
            stroke="#22D3EE"
            fill="#22D3EE"
            fillOpacity={0.3}
          />
          <Radar
            name="Competitor 2"
            dataKey="competitor2"
            stroke="#9333EA"
            fill="#9333EA"
            fillOpacity={0.3}
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: 'rgba(17, 24, 39, 0.8)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '0.5rem',
              backdropFilter: 'blur(4px)'
            }}
          />
          <Legend />
        </RadarChart>
      </div>
      <p className="mt-4 text-sm text-gray-400">
        Hover over the chart to see detailed metrics
      </p>
    </div>
  );
};

export default CompetitorChart;