import React, { useRef } from 'react';
import { Network, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const patternRecognitionConfig = {
  id: 'pattern-recognition',
  label: 'Pattern Recognition',
  icon: <Network className="w-4 h-4" />,
  description: 'Discover hidden patterns and relationships',
};

export const createPrompt = (data, columns) => {
  // Format columns and get their types
  const formattedColumns = columns.map(col => {
    const accessor = col.accessor || col.id;
    const sampleValue = data[0]?.[accessor];
    
    let type = 'string';
    if (typeof sampleValue === 'number') {
      type = 'number';
    } else if (!isNaN(Date.parse(sampleValue))) {
      type = 'date';
    }
    
    return {
      header: col.Header || col.id || accessor,
      accessor: accessor,
      type: type
    };
  });

  const tablePreview = data.map(row => {
    return formattedColumns.map(col => `${col.header}: ${row[col.accessor]}`).join(', ');
  }).join('\n');

  const prompt = `Analyze this dataset for patterns, correlations, and anomalies. Provide the analysis in this exact JSON format:

{
  "patterns": {
    "relationships": [
      {
        "variables": ["column1", "column2"],
        "type": "positive|negative|no correlation",
        "strength": number (0-1),
        "description": "detailed description"
      }
    ],
    "clusters": [
      {
        "name": "cluster name",
        "characteristics": ["trait1", "trait2"],
        "size": number,
        "significance": "description"
      }
    ]
  },
  "anomalies": {
    "columnName": [
      {
        "value": number/string,
        "confidence": number (0-1),
        "explanation": "why this is anomalous"
      }
    ]
  },
  "cyclicalPatterns": {
    "pattern": "description of pattern",
    "frequency": "daily/weekly/monthly/etc",
    "strength": number (0-1)
  },
  "associations": {
    "rules": [
      {
        "if": ["condition1", "condition2"],
        "then": ["result1", "result2"],
        "confidence": number (0-1),
        "support": number (0-1)
      }
    ]
  },
  "visualizationData": {
    "scatterPlots": [
      {
        "xAxis": "column1",
        "yAxis": "column2",
        "points": [
          {"x": number, "y": number, "label": "string"}
        ]
      }
    ]
  },
  "insights": [
    "key insight 1",
    "key insight 2"
  ]
}

Table Structure:
${formattedColumns.map(col => `${col.header} (${col.type})`).join('\n')}

Complete Dataset:
${JSON.stringify(data)}

Sample Data Preview:
${tablePreview}`;

  return prompt;
};

export const parseResults = (apiResponse) => {
    try {
      // First try to parse the entire response as JSON
      try {
        const directParse = JSON.parse(apiResponse);
        if (directParse) {
          return {
            patterns: directParse.patterns || {},
            anomalies: directParse.anomalies || {},
            cyclicalPatterns: directParse.cyclicalPatterns || {},
            associations: directParse.associations || {},
            visualizationData: directParse.visualizationData || {},
            insights: directParse.insights || []
          };
        }
      } catch (e) {
        // If direct parse fails, try to extract JSON from markdown code block
        const jsonMatch = apiResponse.match(/```json\n([\s\S]*?\n)```/) || 
                         apiResponse.match(/```\n([\s\S]*?\n)```/) ||
                         apiResponse.explanation?.match(/```json\n([\s\S]*?\n)```/);
        
        if (!jsonMatch || !jsonMatch[1]) {
          // If we can't find JSON in code blocks, try to find it in the explanation
          if (apiResponse.explanation) {
            try {
              const parsedJson = JSON.parse(apiResponse.explanation);
              return {
                patterns: parsedJson.patterns || {},
                anomalies: parsedJson.anomalies || {},
                cyclicalPatterns: parsedJson.cyclicalPatterns || {},
                associations: parsedJson.associations || {},
                visualizationData: parsedJson.visualizationData || {},
                insights: parsedJson.insights || []
              };
            } catch (e) {
              throw new Error('Could not parse JSON from explanation');
            }
          }
          throw new Error('Invalid API response format');
        }
  
        const parsedJson = JSON.parse(jsonMatch[1].trim());
        return {
          patterns: parsedJson.patterns || {},
          anomalies: parsedJson.anomalies || {},
          cyclicalPatterns: parsedJson.cyclicalPatterns || {},
          associations: parsedJson.associations || {},
          visualizationData: parsedJson.visualizationData || {},
          insights: parsedJson.insights || []
        };
      }
    } catch (error) {
      console.error('Pattern Recognition parsing error:', error);
      return {
        patterns: {},
        anomalies: {},
        cyclicalPatterns: {},
        associations: {},
        visualizationData: {},
        insights: ['Failed to parse analysis results: ' + error.message]
      };
    }
  };

export const PatternRecognitionResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('pattern-analysis.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Relationships and Correlations */}
        {results.patterns?.relationships?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Relationships</h4>
            <div className="grid gap-4">
              {results.patterns.relationships.map((relationship, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-sm">
                  <div className="flex items-center justify-between mb-2">
                    <div className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {relationship.variables.join(' → ')}
                    </div>
                    <div className="flex items-center gap-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        relationship.type === 'positive' ? 'bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-400' :
                        relationship.type === 'negative' ? 'bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400' :
                        'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400'
                      }`}>
                        {relationship.type}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Strength: {(relationship.strength * 100).toFixed(1)}%
                      </span>
                    </div>
                  </div>
                  <p className="text-gray-600 dark:text-gray-300">{relationship.description}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Scatter Plot Visualizations */}
        {results.visualizationData?.scatterPlots?.length > 0 && (
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Pattern Visualizations</h4>
            <div className="grid gap-6">
              {results.visualizationData.scatterPlots.map((plot, index) => (
                <div key={index} className="h-[300px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                      <CartesianGrid />
                      <XAxis dataKey="x" name={plot.xAxis} />
                      <YAxis dataKey="y" name={plot.yAxis} />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Legend />
                      <Scatter
                        name={`${plot.xAxis} vs ${plot.yAxis}`}
                        data={plot.points}
                        fill="#8884d8"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Clusters */}
        {results.patterns?.clusters?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Identified Clusters</h4>
            <div className="grid md:grid-cols-2 gap-4">
              {results.patterns.clusters.map((cluster, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-sm">
                  <div className="flex justify-between items-start mb-3">
                    <h5 className="font-medium text-gray-900 dark:text-gray-100">{cluster.name}</h5>
                    <span className="text-sm bg-purple-100 dark:bg-purple-900/30 text-purple-700 dark:text-purple-400 px-2 py-1 rounded-full">
                      Size: {cluster.size}
                    </span>
                  </div>
                  <div className="space-y-2">
                    <div className="flex flex-wrap gap-2">
                      {cluster.characteristics.map((trait, i) => (
                        <span key={i} className="text-xs bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 px-2 py-1 rounded-full">
                          {trait}
                        </span>
                      ))}
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{cluster.significance}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Association Rules */}
        {results.associations?.rules?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Association Rules</h4>
            <div className="space-y-4">
              {results.associations.rules.map((rule, index) => (
                <div key={index} className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="flex-grow">
                      <div className="text-gray-600 dark:text-gray-300">
                        If: {rule.if.join(' AND ')}
                      </div>
                      <div className="text-gray-900 dark:text-gray-100 font-medium">
                        Then: {rule.then.join(' AND ')}
                      </div>
                    </div>
                    <div className="text-right text-sm">
                      <div className="text-gray-500 dark:text-gray-400">
                        Confidence: {(rule.confidence * 100).toFixed(1)}%
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        Support: {(rule.support * 100).toFixed(1)}%
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Anomalies */}
        {Object.keys(results.anomalies).length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Detected Anomalies</h4>
            {Object.entries(results.anomalies).map(([column, anomalies]) => (
              <div key={column} className="mb-4">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3">{column}</h5>
                <div className="space-y-3">
                  {anomalies.map((anomaly, index) => (
                    <div key={index} className="bg-white dark:bg-gray-900 p-3 rounded-md shadow-sm">
                      <div className="flex justify-between items-start">
                        <div className="text-red-600 dark:text-red-400 font-medium">
                          {anomaly.value}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          Confidence: {(anomaly.confidence * 100).toFixed(1)}%
                        </div>
                      </div>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                        {anomaly.explanation}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Cyclical Patterns */}
        {results.cyclicalPatterns && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-4 mb-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Cyclical Patterns</h4>
            <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-sm">
              <div className="flex justify-between items-start mb-3">
              <div className="text-gray-900 dark:text-gray-100">
                  <span className="font-medium">Frequency:</span> {results.cyclicalPatterns.frequency}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Strength: {(results.cyclicalPatterns.strength * 100).toFixed(1)}%
                </div>
              </div>
              <p className="text-gray-600 dark:text-gray-300">{results.cyclicalPatterns.pattern}</p>
            </div>
          </div>
        )}

        {/* Key Insights */}
        {results.insights?.length > 0 && (
          <div className="bg-gray-50/50 dark:bg-gray-800/50 rounded-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Key Insights</h4>
            <ul className="space-y-3">
              {results.insights.map((insight, index) => (
                <li 
                  key={index}
                  className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 font-medium">
                    {index + 1}
                  </span>
                  <span>{insight}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  config: patternRecognitionConfig,
  createPrompt,
  parseResults,
  ResultsComponent: PatternRecognitionResults
};