import React, { useState } from 'react';
import { RefreshCw, Shuffle, Check } from 'lucide-react';

const SAMPLE_TEXTS = {
  business: {
    original: "Our product helps businesses improve their workflow efficiency. It provides automated solutions for common tasks and integrates with existing systems. Users report significant time savings and increased productivity after implementation.",
    variations: [
      "Transform your business operations with our revolutionary efficiency solution. Our seamless platform automates routine tasks while perfectly integrating with your current infrastructure. Companies consistently report dramatic productivity gains and substantial time optimization post-deployment.",
      "Revolutionize your business processes with our innovative workflow solution. By automating everyday tasks and seamlessly connecting with your existing tools, our platform delivers remarkable efficiency gains. Organizations experience notable productivity boosts and time savings from day one.",
      "Elevate your business performance with our cutting-edge efficiency platform. Featuring advanced automation capabilities and smooth system integration, our solution streamlines operations effectively. Clients consistently achieve significant productivity improvements and remarkable resource optimization."
    ]
  },
  marketing: {
    original: "Our new smartphone features a high-resolution display and long battery life. The camera takes great photos in any lighting condition. It's available in three colors and comes with 128GB of storage.",
    variations: [
      "Experience visual brilliance with our latest smartphone's stunning display and worry-free battery performance. Capture life's moments perfectly with our advanced camera system, optimized for any environment. Choose from three elegant colors, with abundant 128GB storage for all your needs.",
      "Immerse yourself in crystal-clear visuals and unmatched battery endurance with our newest smartphone. Create professional-quality photos in any setting with our revolutionary camera technology. Select your perfect style from three premium finishes, backed by generous 128GB storage.",
      "Discover excellence with our next-gen smartphone's breathtaking display and marathon battery life. Master photography in any light with our innovative camera system. Express your style with three distinctive colors, complemented by spacious 128GB storage capacity."
    ]
  },
  technical: {
    original: "The software uses machine learning algorithms to analyze data patterns. It processes information in real-time and generates actionable insights. The system is scalable and can handle large datasets.",
    variations: [
      "Leveraging advanced machine learning algorithms, our software identifies complex data patterns with precision. Delivering real-time processing capabilities, it produces valuable, implementable insights. The architecture scales seamlessly to accommodate extensive data volumes.",
      "Our sophisticated platform employs state-of-the-art ML algorithms for pattern recognition in data. Through instantaneous processing, it delivers strategic, actionable intelligence. The robust infrastructure easily scales to manage expanding datasets.",
      "Powered by cutting-edge machine learning, our solution uncovers hidden patterns in your data. With lightning-fast processing, it converts raw information into strategic insights. The flexible system grows effortlessly with your data needs."
    ]
  }
};

const toneOptions = [
  { value: "professional", label: "Professional" },
  { value: "casual", label: "Casual" },
  { value: "creative", label: "Creative" }
];

const styleOptions = [
  { value: "business", label: "Business" },
  { value: "marketing", label: "Marketing" },
  { value: "technical", label: "Technical" }
];

const lengthOptions = [
  { value: "similar", label: "Similar" },
  { value: "shorter", label: "Shorter" },
  { value: "longer", label: "Longer" }
];

export default function ContentRewriterDemo() {
  const [selectedStyle, setSelectedStyle] = useState("business");
  const [currentVariation, setCurrentVariation] = useState(0);
  const [isCopied, setIsCopied] = useState(false);

  const handleRefresh = () => {
    setCurrentVariation((prev) => 
      (prev + 1) % SAMPLE_TEXTS[selectedStyle].variations.length
    );
  };

  const handleShuffle = () => {
    const newIndex = Math.floor(Math.random() * SAMPLE_TEXTS[selectedStyle].variations.length);
    setCurrentVariation(newIndex);
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(SAMPLE_TEXTS[selectedStyle].variations[currentVariation]);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
    setCurrentVariation(0);
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 mb-12">
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold text-white">Original Text</h3>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-400">
                Words: {SAMPLE_TEXTS[selectedStyle].original.split(/\s+/).length}
              </span>
              <div className="w-px h-4 bg-gray-700" />
              <span className="text-sm text-gray-400">
                Characters: {SAMPLE_TEXTS[selectedStyle].original.length}
              </span>
            </div>
          </div>
          <div className="bg-black/50 rounded-xl p-6">
            <p className="text-gray-300">{SAMPLE_TEXTS[selectedStyle].original}</p>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold text-white">Rewritten Version</h3>
            <div className="flex items-center space-x-3">
              <button 
                onClick={handleRefresh}
                className="p-2 hover:bg-gray-700/50 rounded-lg transition-colors text-gray-300 hover:text-white"
                title="Get next variation"
              >
                <RefreshCw className="h-5 w-5" />
              </button>
              <button 
                onClick={handleShuffle}
                className="p-2 hover:bg-gray-700/50 rounded-lg transition-colors text-gray-300 hover:text-white"
                title="Random variation"
              >
                <Shuffle className="h-5 w-5" />
              </button>
              <button 
                onClick={handleCopy}
                className="p-2 hover:bg-gray-700/50 rounded-lg transition-colors text-gray-300 hover:text-white"
                title={isCopied ? "Copied!" : "Copy to clipboard"}
              >
                {isCopied ? <Check className="h-5 w-5 text-green-400" /> : (
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="bg-purple-500/10 rounded-xl p-6 border border-purple-500/20">
            <p className="text-gray-300">
              {SAMPLE_TEXTS[selectedStyle].variations[currentVariation]}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-3 gap-4">
        <div className="bg-gray-900/50 rounded-lg p-4">
          <div className="text-sm text-gray-400 mb-2">Tone</div>
          <select 
            className="w-full bg-black/50 border border-gray-700 rounded-lg px-3 py-2 text-gray-300"
            onChange={(e) => console.log('Tone:', e.target.value)}
          >
            {toneOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-gray-900/50 rounded-lg p-4">
          <div className="text-sm text-gray-400 mb-2">Style</div>
          <select 
            className="w-full bg-black/50 border border-gray-700 rounded-lg px-3 py-2 text-gray-300"
            value={selectedStyle}
            onChange={handleStyleChange}
          >
            {styleOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-gray-900/50 rounded-lg p-4">
          <div className="text-sm text-gray-400 mb-2">Length</div>
          <select 
            className="w-full bg-black/50 border border-gray-700 rounded-lg px-3 py-2 text-gray-300"
            onChange={(e) => console.log('Length:', e.target.value)}
          >
            {lengthOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}