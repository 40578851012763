import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Image,
  Wand2,
  Sparkles,
  Camera,
  Layers,
  Paintbrush,
  Palette,
  Brain,
  Zap,
  ArrowRight,
  Check,
  Star,
  Mail,
  Share2,
  Globe,
  MousePointer,
  Cpu,
  MessageSquare,
  AlertCircle
} from 'lucide-react';

const ComingSoonImageTool = ({
  toolName = "AI Image Generation Studio",
  tagline = "The Future of Creative AI is Coming",
  expectedDate = "Spring 2024"
}) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [activeFeature, setActiveFeature] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollProgress, setScrollProgress] = useState(0);

  // Mouse position effect for interactive backgrounds
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // Scroll progress effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = scrollPx / winHeightPx;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Auto-rotate features
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % features.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const features = [
    {
      icon: Wand2,
      title: "AI-Powered Generation",
      description: "Create stunning images from text descriptions with state-of-the-art AI",
      color: "blue"
    },
    {
      icon: Brain,
      title: "Neural Style Transfer",
      description: "Apply artistic styles to your images using advanced neural networks",
      color: "purple"
    },
    {
      icon: Layers,
      title: "Multi-Layer Composition",
      description: "Build complex images with intelligent layer management",
      color: "indigo"
    },
    {
      icon: Paintbrush,
      title: "Smart Editing",
      description: "Edit images naturally using text commands and AI assistance",
      color: "cyan"
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      // API call would go here
      setIsSubmitted(true);
      setError('');
      setEmail('');
    } catch (err) {
      setError('Something went wrong. Please try again.');
    }
  };

  return (
    <>
      <SEOHead 
        title="AI Image Generation Studio | Next-Gen AI Image Creation Tool"
        description="Revolutionary AI image generation tool coming soon. Create stunning visuals with text, edit with natural language, and apply neural style transfers. Join the waitlist for early access."
        keywords="AI image generation, neural style transfer, AI art, text to image, artificial intelligence, creative AI, digital art, image editing"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Progress Bar */}
        <motion.div 
          className="fixed top-0 left-0 right-0 h-1 bg-blue-500 z-50"
          style={{ 
            scaleX: scrollProgress,
            transformOrigin: "0%"
          }}
        />

        {/* Interactive Background */}
        <div 
          className="absolute inset-0 opacity-20"
          style={{
            background: `radial-gradient(circle at ${mousePosition.x * 100}% ${mousePosition.y * 100}%, rgba(59, 130, 246, 0.3), transparent 50%)`
          }}
        />

        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center justify-center py-20 px-4">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent animate-pulse" />
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto text-center relative z-10"
          >
            {/* Coming Soon Badge */}
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/20 text-blue-400 mb-8"
            >
              <AlertCircle className="h-4 w-4 mr-2" />
              <span className="text-sm font-medium">Coming {expectedDate}</span>
            </motion.div>

            {/* Main Title */}
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="text-6xl md:text-8xl font-bold mb-6"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                {toolName}
              </span>
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto"
            >
              {tagline}
            </motion.p>

            {/* Email Signup */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="max-w-md mx-auto mb-12"
            >
              {!isSubmitted ? (
                <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4">
                  <div className="flex-1">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="w-full px-6 py-4 rounded-full bg-white/10 border border-white/20 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all text-white placeholder-gray-400"
                    />
                    {error && (
                      <p className="text-red-500 text-sm mt-2 text-left ml-4">{error}</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                  >
                    Join Waitlist
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </button>
                </form>
              ) : (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  className="bg-green-500/20 text-green-400 px-6 py-4 rounded-full flex items-center justify-center"
                >
                  <Check className="h-5 w-5 mr-2" />
                  <span>Thank you! You're on the waitlist.</span>
                </motion.div>
              )}
            </motion.div>

            {/* Stats */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
              className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-4xl mx-auto"
            >
              {[
                { label: "Beta Users", value: "1,000+" },
                { label: "Images Generated", value: "1K+" },
                { label: "Success Rate", value: "99.9%" },
                { label: "Response Time", value: "<1s" }
              ].map((stat, index) => (
                <div key={index} className="bg-white/5 rounded-2xl p-6 backdrop-blur-sm">
                  <div className="text-2xl font-bold text-blue-400 mb-2">{stat.value}</div>
                  <div className="text-gray-400">{stat.label}</div>
                </div>
              ))}
            </motion.div>
          </motion.div>
        </section>

        {/* Continue with more sections... */}
      </div>
    </>
  );
};

export default ComingSoonImageTool;