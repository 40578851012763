import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Wand2, Check, X, Loader } from 'lucide-react';

const LiveDemo = () => {
  const [step, setStep] = useState(0);
  const [topic, setTopic] = useState('');
  const [generating, setGenerating] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');

  const topics = [
    "How to Start a Successful Blog",
    "Top 10 Digital Marketing Strategies",
    "Guide to Social Media Management",
    "Email Marketing Best Practices",
    "Content Creation Tips"
  ];

  const demoContent = {
    "How to Start a Successful Blog": `Listen up - everyone talks about starting a blog, but here's what actually matters. First, pick a niche you actually know something about. Don't try being everything to everyone. That's a rookie mistake.

Get yourself a decent hosting service. Cheap shared hosting might save you $20 now but will cost you readers later when your site crashes. WordPress is fine for starting out. Don't waste time comparing every platform under the sun.

Write stuff people actually want to read. That means solving problems or teaching something useful. Nobody cares about your morning routine unless you're already famous. Research what your target audience is searching for and write about that.

Set a schedule and stick to it. Two good posts a month beat four rushed posts a week. Quality over quantity - always. And for heaven's sake, learn basic SEO. You can write the best content in the world, but it's worthless if nobody can find it.`,

    "Top 10 Digital Marketing Strategies": `Cut the fluff - here's what actually works in digital marketing right now. First up, stop spreading yourself thin across every platform. Pick the channels where your customers actually hang out and master those.

SEO isn't dead, it's just grown up. Focus on search intent, not keyword stuffing. If you're still obsessing over keyword density, you're living in 2010.

Email marketing still crushes social media for ROI. Build your list like your business depends on it, because it probably does. And no, buying lists doesn't count. That's just spam with extra steps.

Paid ads? Fine, but track everything. If you can't tell which ads are making money and which are burning it, stop running ads until you figure it out. Testing isn't optional anymore - it's survival.

Video content isn't just nice to have - it's essential. But don't waste time trying to go viral. Focus on providing value to your actual target audience.`,

    "Guide to Social Media Management": `Here's the truth about social media management - consistency beats perfection every time. You don't need fancy graphics for every post. You need a clear message and regular posting.

Stop trying to be on every platform. Pick two or three where your audience actually spends time. Yes, TikTok is huge, but if your customers are 60-year-old business executives, why waste your time there?

Automation is your friend, but don't let your accounts look like robots run them. Schedule the basic stuff, but respond to comments and messages in real time. That's where the real engagement happens.

And for crying out loud, stop obsessing over follower counts. A thousand engaged followers beat 100,000 ghosts every time.`,

    "Email Marketing Best Practices": `Let's get real about email marketing. Your open rates are trash because your subject lines are boring. Stop using "Newsletter #45" and start using lines that make people want to click.

Segment your list or stop complaining about poor results. Blasting the same email to everyone is lazy marketing. Different customers need different messages. That's just basic business.

Clean your list regularly. Those 100,000 subscribers mean nothing if half of them haven't opened an email in a year. Better to have 1,000 engaged readers than 10,000 dead emails.

Mobile optimization isn't optional. If your emails look broken on phones, you're throwing away money. Test everything on mobile first.`,

    "Content Creation Tips": `Stop waiting for inspiration - it's not coming. Set a schedule and write. Good content comes from consistency, not random bursts of creativity.

Research before you write. Don't just rehash what everyone else is saying. Find the gaps in existing content and fill them. That's how you stand out.

Your first draft will suck. That's normal. Edit ruthlessly. Cut anything that doesn't directly help your reader. They don't care about your clever metaphors - they want solutions to their problems.

Keywords matter, but not how you think. Write for humans first, then optimize for search engines. If your content reads like it was written by a robot, start over. Google's smarter than you think.`
  };

  const handleGenerate = async () => {
    if (!topic) return;
    
    setGenerating(true);
    // Simulate API delay with variable timing for realism
    await new Promise(resolve => setTimeout(resolve, 1500 + Math.random() * 1000));
    
    setGeneratedContent(demoContent[topic]);
    setGenerating(false);
    setStep(2);
  };

  const resetDemo = () => {
    setStep(0);
    setTopic('');
    setGeneratedContent('');
  };

  return (
    <div className="bg-gray-900 rounded-xl p-6 w-full max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Experience AI Writing
        </h3>
        {step > 0 && (
          <button
            onClick={resetDemo}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>

      {step === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <h4 className="text-lg mb-4 text-gray-300">Select a topic to generate content:</h4>
          <div className="space-y-2">
            {topics.map((t, index) => (
              <motion.button
                key={t}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`w-full p-4 rounded-lg text-left transition-all ${
                  topic === t
                    ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                    : 'bg-gray-800 hover:bg-gray-700'
                }`}
                onClick={() => {
                  setTopic(t);
                  setStep(1);
                }}
              >
                {t}
              </motion.button>
            ))}
          </div>
        </motion.div>
      )}

      {step === 1 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="space-y-4"
        >
          <div className="bg-gray-800 p-4 rounded-lg">
            <h4 className="text-lg text-gray-300 mb-2">Selected Topic:</h4>
            <p className="text-white font-medium">{topic}</p>
          </div>
          <button
            onClick={handleGenerate}
            disabled={generating}
            className="w-full py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg font-medium hover:from-blue-600 hover:to-purple-600 transition-all flex items-center justify-center"
          >
            {generating ? (
              <>
                <Loader className="animate-spin h-5 w-5 mr-2" />
                Generating Content...
              </>
            ) : (
              <>
                <Wand2 className="h-5 w-5 mr-2" />
                Generate Content
              </>
            )}
          </button>
        </motion.div>
      )}

      {step === 2 && generatedContent && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="bg-gray-800 rounded-lg p-6 mb-4">
            <pre className="whitespace-pre-wrap text-sm text-gray-300 font-sans">
              {generatedContent}
            </pre>
          </div>
          <div className="flex gap-4">
            <button
              onClick={resetDemo}
              className="flex-1 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg font-medium hover:from-blue-600 hover:to-purple-600 transition-all"
            >
              Try Another Topic
            </button>
            <button
              onClick={() => window.location.href = '/register'}
              className="flex-1 py-3 bg-white/10 rounded-lg font-medium hover:bg-white/20 transition-all"
            >
              Sign Up Free
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default LiveDemo;