import React, { useState } from 'react';
import { UsageCalculator, ROICalculator } from '../components/Pricingcal';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import SEOHead from '../components/SEOHead';
import PaymentForm from '../components/PaymentForm';
import { Link } from 'react-router-dom';
import { 
  Check, 
  Sparkles,
  Zap,
  Shield,
  Star,
  Users,
  FileText,
  MessageCircle,
  Package,
  Globe,
  Rocket,
  ChevronDown,
  ArrowRight,
  Lightning,
  Brain,
  Crown
} from 'lucide-react';

const PricingPage = () => {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState('monthly');

  const plans = {
    free: {
      name: 'Free',
      label: '',
      description: 'Perfect for trying out our services',
      price: { 
        monthly: 0,
        yearly: 0,
        yearlyMonthly: 0
      },
      features: [
        '1,000 Words/month',
        'Basic Templates',
        'Blog Post Writer',
        'Content Improver',
        'Product Descriptions',
        'AI Image Creation (Coming Soon)',
        '3 Exports/month',
        'Web-based Editor',
        'Basic Support'
      ],
      action: 'Start Free',
      icon: Sparkles,
      gradient: 'from-slate-700 to-slate-800'
    },
    starter: {
      name: 'STARTER',
      label: '',
      description: 'Great for content creators and small teams',
      price: { 
        monthly: 17.99,
        yearly: 179.99,
        yearlyMonthly: 14.99
      },
      features: [
        '50,000 Words/month',
        '100+ Templates',
        'All Writing Tools',
        'SEO Optimizer',
        'Plagiarism Checker',
        'AI Image Creation (Coming Soon)',
        'Unlimited Exports',
        'Chrome Extension',
        '24/7 Email Support',
        '50+ Languages',
        'Team Collaboration (up to 2)',
        'Content Analytics'
      ],
      action: 'Get Started',
      icon: Zap,
      gradient: 'from-slate-800 to-purple-700'
    },
    pro: {
      name: 'PRO',
      label: 'Most Popular',
      description: 'Perfect for growing businesses',
      price: { 
        monthly: 79.99,
        yearly: 719.99,
        yearlyMonthly: 59.99
      },
      features: [
        '200,000 Words/month',
        '200+ Templates',
        'Advanced SEO Tools',
        'Custom Templates',
        'Brand Voice Settings',
        'AI Image Creation (Coming Soon)',
        'Priority Processing',
        'Advanced Analytics',
        'Priority Support',
        '100+ Languages',
        'Team Collaboration (up to 5)',
        'Custom Integrations',
        'Training Sessions',
        'Dedicated Success Manager'
      ],
      action: 'Get Started',
      icon: Crown,
      gradient: 'from-purple-600 to-indigo-700'
    }
  };

  const handlePlanSelection = (plan) => {
    localStorage.setItem('selectedPlan', JSON.stringify({
      name: plan.name,
      price: billingCycle === 'yearly' ? plan.price.yearlyMonthly : plan.price.monthly,
      cycle: billingCycle,
      totalPrice: billingCycle === 'yearly' ? plan.price.yearly : plan.price.monthly * 12
    }));
    navigate('/register');
  };

  const renderPrice = (plan) => {
    if (billingCycle === 'yearly') {
      return (
        <div className="text-center">
          <span className="text-5xl font-bold text-white">${plan.price.yearlyMonthly}</span>
          <span className="text-gray-400 ml-2">/mo</span>
          <div className="text-sm text-gray-400 mt-1">
            Billed annually (${plan.price.yearly}/year)
          </div>
          {plan.price.yearlyMonthly < plan.price.monthly && (
            <div className="mt-2">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-500/20 text-green-400">
                Save {Math.round((1 - plan.price.yearlyMonthly / plan.price.monthly) * 100)}%
              </span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="text-center">
        <span className="text-5xl font-bold text-white">${plan.price.monthly}</span>
        <span className="text-gray-400 ml-2">/mo</span>
      </div>
    );
  };

  return (
    <>
      <SEOHead 
        title="AI Content Writing Pricing Plans | Most Affordable AI Writer"
        description="Choose the perfect AI content writing plan for your needs. Starting at $0/month. Generate blog posts, articles, and more with our affordable AI writer."
        keywords="ai writer pricing, ai content generator cost, affordable ai writer, ai writing tool plans"
      />
      
      <div className="min-h-screen bg-gray text-white">
        {/* Hero Section */}
        <section className="relative py-20 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Simple, Transparent
              <span className="block bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                AI Writing Pricing
              </span>
            </h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-12">
              Choose the perfect plan for your content creation needs
            </p>

            {/* Billing Toggle */}
            <div className="inline-flex items-center bg-gray-800 p-1 rounded-full">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-6 py-2 rounded-full text-sm font-medium transition-all ${
                  billingCycle === 'monthly'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingCycle('yearly')}
                className={`px-6 py-2 rounded-full text-sm font-medium transition-all flex items-center ${
                  billingCycle === 'yearly'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Annual
                <span className="ml-2 bg-green-500/20 text-green-400 text-xs font-semibold px-2 py-0.5 rounded-full">
                  Save 20%
                </span>
              </button>
            </div>
          </motion.div>
        </section>

        {/* Pricing Cards */}
        <section className="py-12 relative">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-7xl mx-auto px-4 grid md:grid-cols-3 gap-8"
          >
            {Object.entries(plans).map(([key, plan], index) => (
              <motion.div
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`relative bg-gradient-to-br ${plan.gradient} rounded-2xl p-8 ${
                  plan.label === 'Most Popular' ? 'ring-2 ring-blue-500 scale-105 z-10' : ''
                }`}
              >
                {plan.label && (
                  <div className="absolute -top-5 left-0 right-0 flex justify-center">
                    <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                      {plan.label}
                    </span>
                  </div>
                )}

                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h3 className="text-lg font-semibold">{plan.name}</h3>
                    <p className="text-sm text-gray-400">{plan.description}</p>
                  </div>
                  <plan.icon className="h-8 w-8 text-blue-400" />
                </div>

                {renderPrice(plan)}

                <PaymentForm 
                  plan={plan} 
                  billingCycle={billingCycle}
                />

                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="h-5 w-5 text-green-400 flex-shrink-0 mr-3" />
                      <span className="text-sm text-gray-300">{feature}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </motion.div>
        </section>

{/* Feature Comparison Matrix */}
<section className="py-24 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-7xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4">Compare Features</h2>
              <p className="text-gray-400">Detailed comparison of all plan features</p>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-4 text-left text-gray-400">Features</th>
                    {Object.entries(plans).map(([key, plan]) => (
                      <th key={key} className="p-4 text-center">
                        <div className={`bg-gradient-to-r ${plan.gradient} p-4 rounded-xl`}>
                          <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                          <p className="text-sm text-gray-300">${plan.price.monthly}/mo</p>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {[
                    {
                      feature: "Monthly Word Limit",
                      free: "1,000",
                      starter: "50,000",
                      pro: "200,000"
                    },
                    {
                      feature: "AI Image Generation",
                      free: "Basic",
                      starter: "50/mo",
                      pro: "100/mo"
                    },
                    {
                      feature: "Language Support",
                      free: "English",
                      starter: "15+ Languages",
                      pro: "30+ Languages"
                    },
                    {
                      feature: "Templates",
                      free: "Basic",
                      starter: "100+",
                      pro: "200+"
                    },
                    {
                      feature: "Team Members",
                      free: "1",
                      starter: "1",
                      pro: "Unlimited"
                    }
                  ].map((row, index) => (
                    <motion.tr
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-800/50"
                    >
                      <td className="p-4 font-medium">{row.feature}</td>
                      <td className="p-4 text-center text-gray-400">{row.free}</td>
                      <td className="p-4 text-center text-gray-400">{row.starter}</td>
                      <td className="p-4 text-center text-gray-400">{row.pro}</td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </motion.div>
        </section>

        {/* Usage Calculator */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
        <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="max-w-4xl mx-auto px-4"
  >
    <div className="text-center mb-16">
      <h2 className="text-4xl font-bold mb-4">Calculate Your Usage</h2>
      <p className="text-gray-400">Estimate your monthly content needs</p>
    </div>

    <UsageCalculator onUsageChange={(totalWords) => {
      // Optional: Handle total words change
      console.log('Total words:', totalWords);
    }} />
  </motion.div>
        </section>
{/* ROI Calculator Section */}
<section className="py-24 relative">
  <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
  
  <motion.div 
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    className="max-w-7xl mx-auto px-4"
  >
    <div className="text-center mb-16">
      <h2 className="text-4xl font-bold mb-4">Calculate Your ROI</h2>
      <p className="text-gray-400">See how much time and money you can save with AI content creation</p>
    </div>

    <ROICalculator />
  </motion.div>
</section>

        {/* Trust Signals */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4">Trusted by Content Teams Worldwide</h2>
              <p className="text-gray-400">Join thousands of satisfied customers</p>
            </div>

            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  metric: "36K+",
                  label: "Active Users",
                  icon: Users,
                  color: "blue"
                },
                {
                  metric: "1M+",
                  label: "Content Generated",
                  icon: FileText,
                  color: "purple"
                },
                {
                  metric: "99.9%",
                  label: "Uptime",
                  icon: Shield,
                  color: "green"
                },
                {
                  metric: "4.9/5",
                  label: "Customer Rating",
                  icon: Star,
                  color: "yellow"
                }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="text-center"
                >
                  <div className={`w-16 h-16 mx-auto mb-4 rounded-full bg-${stat.color}-500/20 flex items-center justify-center`}>
                    <stat.icon className={`h-8 w-8 text-${stat.color}-400`} />
                  </div>
                  <div className="text-3xl font-bold mb-2">{stat.metric}</div>
                  <div className="text-gray-400">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>
{/* Enterprise Section */}
<section className="py-24 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4"
          >
            <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-3xl p-12 relative overflow-hidden">
              {/* Decorative elements */}
              <div className="absolute top-0 right-0 w-64 h-64 bg-white/10 rounded-full filter blur-3xl transform translate-x-1/2 -translate-y-1/2" />
              <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-500/10 rounded-full filter blur-3xl transform -translate-x-1/2 translate-y-1/2" />

              <div className="relative z-10 flex flex-col md:flex-row items-center justify-between gap-8">
                <div>
                  <h2 className="text-3xl font-bold mb-4">Need a Custom Enterprise Solution?</h2>
                  <p className="text-xl text-blue-100 max-w-xl">
                    Get a tailored plan that fits your organization's specific needs. Custom limits, advanced features, and dedicated support.
                  </p>
                  <ul className="mt-6 space-y-2">
                    {[
                      "Custom word limits",
                      "Dedicated account manager",
                      "API access",
                      "Custom AI model training",
                      "SSO & advanced security"
                    ].map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-400 mr-2" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex flex-col gap-4">
                  <button className="px-8 py-4 bg-white text-blue-600 rounded-xl font-medium hover:bg-gray-100 transition-all flex items-center justify-center">
                    Contact Sales
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </button>
                  <a href="#" className="text-center text-sm text-blue-200 hover:text-white transition-colors">
                    Learn more about Enterprise
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </section>

        {/* FAQs Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto px-4"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4">Common Questions</h2>
              <p className="text-gray-400">Everything you need to know about our pricing</p>
            </div>

            <div className="space-y-4">
              {[
                {
                  question: "How do your billing cycles work?",
                  answer: "We offer both monthly and annual billing cycles. Annual plans come with a 20% discount compared to monthly billing. You can switch between billing cycles at any time."
                },
                {
                  question: "Can I upgrade or downgrade my plan?",
                  answer: "Yes! You can upgrade or downgrade your plan at any time. When you upgrade, you'll be charged the prorated difference. When downgrading, your new rate will apply to your next billing cycle."
                },
                {
                  question: "Do unused words roll over?",
                  answer: "No, word credits reset at the beginning of each billing cycle to ensure consistent service quality for all users."
                },
                {
                  question: "What happens if I exceed my word limit?",
                  answer: "You'll receive notifications when you reach 80% and 90% of your limit. If you exceed your limit, you can either upgrade your plan or purchase additional words as needed."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800 rounded-xl overflow-hidden"
                >
                  <button className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-750 transition-all">
                    <span className="font-medium">{faq.question}</span>
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  </button>
                  <div className="px-6 pb-4">
                    <p className="text-gray-400">{faq.answer}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        {/* Final CTA */}
        <section className="py-24 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 via-transparent to-transparent" />
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Start Creating Amazing Content
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                With AI Today
              </span>
            </h2>
            <p className="text-xl text-gray-400 mb-8 max-w-2xl mx-auto">
              Join thousands of content creators who trust our AI to create exceptional content
            </p>
            
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
              to="/register"
              
             className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium hover:from-blue-600 hover:to-purple-600 transition-all flex items-center justify-center">
                Get Started Free
                <ArrowRight className="ml-2 h-5 w-5" />
                </Link>
              
              <button className="px-8 py-4 bg-gray-800 rounded-xl font-medium hover:bg-gray-750 transition-all">
                View Documentation
              </button>
            </div>
            <p className="mt-6 text-gray-400">
              No credit card required • 1,000 words free trial • Cancel anytime
            </p>
          </motion.div>
        </section>
      </div>
      
      
    </>
  );
};

export default PricingPage;