import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Info, Loader2, BarChart, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const ReadabilityScoreCalculator = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const calculateStats = (text) => {
    // Remove extra whitespace and split into sentences
    const cleanText = text.trim().replace(/\s+/g, ' ');
    const sentences = cleanText.split(/[.!?]+/).filter(Boolean);
    const words = cleanText.split(/\s+/).filter(Boolean);
    const characters = cleanText.replace(/\s/g, '').length;
    const syllables = countSyllables(cleanText);
    const complexWords = words.filter(word => countSyllables(word) > 2).length;
    
    return {
      sentences: sentences.length,
      words: words.length,
      characters,
      syllables,
      complexWords,
      avgWordLength: characters / words.length,
      avgSentenceLength: words.length / sentences.length,
      avgSyllablesPerWord: syllables / words.length
    };
  };

  const countSyllables = (text) => {
    const cleanText = text.toLowerCase().replace(/[^a-z]/g, ' ');
    const words = cleanText.split(' ').filter(Boolean);
    
    return words.reduce((total, word) => {
      // Count vowel groups as syllables
      const syllableCount = word.match(/[aeiouy]+/g)?.length || 0;
      
      // Adjust for common patterns
      let adjustedCount = syllableCount;
      
      // Silent e at end
      if (word.match(/[aeiouy]e$/)) adjustedCount--;
      
      // Handle special cases
      if (word.match(/ed$/)) adjustedCount--;
      if (word.match(/le$/)) adjustedCount++;
      
      // Ensure at least one syllable per word
      return total + Math.max(1, adjustedCount);
    }, 0);
  };

  const calculateReadabilityScores = (stats) => {
    // Flesch Reading Ease
    const fleschScore = 206.835 - 1.015 * (stats.words / stats.sentences) - 84.6 * (stats.syllables / stats.words);
    
    // Flesch-Kincaid Grade Level
    const fleschKincaidGrade = 0.39 * (stats.words / stats.sentences) + 11.8 * (stats.syllables / stats.words) - 15.59;
    
    // Gunning Fog Index
    const gunningFog = 0.4 * ((stats.words / stats.sentences) + 100 * (stats.complexWords / stats.words));
    
    // SMOG Index
    const smog = 1.0430 * Math.sqrt(stats.complexWords * (30 / stats.sentences)) + 3.1291;
    
    // Automated Readability Index
    const automatedReadability = 4.71 * (stats.characters / stats.words) + 0.5 * (stats.words / stats.sentences) - 21.43;
    
    return {
      fleschScore: Math.round(fleschScore * 10) / 10,
      fleschKincaidGrade: Math.round(fleschKincaidGrade * 10) / 10,
      gunningFog: Math.round(gunningFog * 10) / 10,
      smog: Math.round(smog * 10) / 10,
      automatedReadability: Math.round(automatedReadability * 10) / 10
    };
  };

  const getReadingLevel = (fleschScore) => {
    if (fleschScore >= 90) return { level: 'Very Easy', grade: '5th Grade', description: 'Easily understood by an average 11-year-old student' };
    if (fleschScore >= 80) return { level: 'Easy', grade: '6th Grade', description: 'Conversational English for consumers' };
    if (fleschScore >= 70) return { level: 'Fairly Easy', grade: '7th Grade', description: 'Suitable for adult readers' };
    if (fleschScore >= 60) return { level: 'Standard', grade: '8th & 9th Grade', description: 'Plain English. Easily understood by teenagers' };
    if (fleschScore >= 50) return { level: 'Fairly Difficult', grade: '10th to 12th Grade', description: 'Fairly difficult to read' };
    if (fleschScore >= 30) return { level: 'Difficult', grade: 'College', description: 'Best understood by college graduates' };
    return { level: 'Very Difficult', grade: 'College Graduate', description: 'Complex academic language' };
  };

  const getTextSuggestions = (stats, scores) => {
    const suggestions = [];
    
    if (stats.avgSentenceLength > 20) {
      suggestions.push({
        type: 'warning',
        text: 'Consider breaking down longer sentences to improve readability'
      });
    }
    
    if (stats.avgWordLength > 5.5) {
      suggestions.push({
        type: 'warning',
        text: 'Try using shorter, simpler words where possible'
      });
    }
    
    if ((stats.complexWords / stats.words) > 0.2) {
      suggestions.push({
        type: 'warning',
        text: 'Reduce the number of complex words (words with 3+ syllables)'
      });
    }
    
    return suggestions;
  };

  const analyzeText = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const stats = calculateStats(text);
      const scores = calculateReadabilityScores(stats);
      const readingLevel = getReadingLevel(scores.fleschScore);
      const suggestions = getTextSuggestions(stats, scores);
      
      setResult({
        stats,
        scores,
        readingLevel,
        suggestions
      });
      
      setIsAnalyzing(false);
    }, 1000);
  }, [text]);

  const ScoreCard = memo(({ title, score, description }) => (
    <div className="bg-gray-800 rounded-lg p-4 space-y-2">
      <h4 className="font-medium text-sm text-gray-300">{title}</h4>
      <div className="text-2xl font-bold">{score}</div>
      {description && (
        <p className="text-sm text-gray-400">{description}</p>
      )}
    </div>
  ));

  ScoreCard.displayName = 'ScoreCard';

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to analyze readability..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeText}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <BookOpen className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {/* Reading Level Summary */}
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-4">Reading Level Analysis</h3>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <div className="text-4xl font-bold text-blue-400">
                  {result.readingLevel.level}
                </div>
              </div>
              <p className="text-gray-300">
                Grade Level: {result.readingLevel.grade}
              </p>
              <p className="text-gray-400">
                {result.readingLevel.description}
              </p>
            </div>
          </div>

          {/* Detailed Scores */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <ScoreCard
              title="Flesch Reading Ease"
              score={result.scores.fleschScore}
              description="Higher score = easier to read"
            />
            <ScoreCard
              title="Flesch-Kincaid Grade"
              score={result.scores.fleschKincaidGrade}
              description="US grade level required"
            />
            <ScoreCard
              title="Gunning Fog Index"
              score={result.scores.gunningFog}
              description="Years of formal education needed"
            />
          </div>

          {/* Text Statistics */}
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-4">Text Statistics</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <div className="text-sm text-gray-400">Words</div>
                <div className="text-xl font-bold">{result.stats.words}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Sentences</div>
                <div className="text-xl font-bold">{result.stats.sentences}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Avg. Words per Sentence</div>
                <div className="text-xl font-bold">{Math.round(result.stats.avgSentenceLength * 10) / 10}</div>
              </div>
              <div>
                <div className="text-sm text-gray-400">Complex Words</div>
                <div className="text-xl font-bold">{result.stats.complexWords}</div>
              </div>
            </div>
          </div>

          {/* Suggestions */}
          {result.suggestions.length > 0 && (
            <div className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
              <h3 className="text-xl font-bold mb-4">Suggestions for Improvement</h3>
              <div className="space-y-3">
                {result.suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="flex items-start space-x-3 text-gray-300"
                  >
                    <BarChart className="h-5 w-5 text-blue-400 mt-0.5" />
                    <p>{suggestion.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              These scores are calculated using standard readability formulas. The results
              should be used as guidelines rather than absolute measures.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Writing Analysis Tools?
              </h2>
              <p className="text-purple-100 mb-6">
                Unlock premium features like advanced readability metrics, writing suggestions, and real-time checking. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default memo(ReadabilityScoreCalculator);