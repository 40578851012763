import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Copy, Check, ArrowRight, Image, Palette, Layout } from 'lucide-react';

const EmailSignatureGenerator = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    jobTitle: '',
    company: '',
    email: '',
    phone: '',
    website: '',
    linkedin: '',
    companyLogo: '',
    accentColor: '#4F46E5',
    layout: 'classic'
  });

  const [copied, setCopied] = useState(false);
  const [showPreview, setShowPreview] = useState(true);

  const layouts = {
    classic: 'Classic (Horizontal)',
    compact: 'Compact (Vertical)',
    modern: 'Modern (With Dividers)'
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData(prev => ({
          ...prev,
          companyLogo: e.target.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const generateClassicSignature = () => `
    <div style="font-family: Arial, sans-serif; max-width: 500px; margin: 0; padding: 15px;">
      <table cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
        <tr>
          ${formData.companyLogo ? `
            <td style="padding-right: 15px; vertical-align: middle;">
              <img src="${formData.companyLogo}" alt="Company Logo" style="max-width: 100px; height: auto;"/>
            </td>
          ` : ''}
          <td style="vertical-align: middle;">
            <p style="margin: 0; font-size: 16px; font-weight: bold; color: ${formData.accentColor};">
              ${formData.fullName}
            </p>
            ${formData.jobTitle ? `
              <p style="margin: 3px 0; font-size: 14px; color: #666;">
                ${formData.jobTitle}${formData.company ? ` | ${formData.company}` : ''}
              </p>
            ` : ''}
            <div style="margin-top: 10px;">
              ${formData.email ? `
                <p style="margin: 2px 0; font-size: 13px;">
                  📧 <a href="mailto:${formData.email}" style="color: ${formData.accentColor}; text-decoration: none;">
                    ${formData.email}
                  </a>
                </p>
              ` : ''}
              ${formData.phone ? `
                <p style="margin: 2px 0; font-size: 13px;">
                  📱 <a href="tel:${formData.phone}" style="color: ${formData.accentColor}; text-decoration: none;">
                    ${formData.phone}
                  </a>
                </p>
              ` : ''}
              ${formData.website ? `
                <p style="margin: 2px 0; font-size: 13px;">
                  🌐 <a href="${formData.website}" style="color: ${formData.accentColor}; text-decoration: none;">
                    ${formData.website.replace(/(^\w+:|^)\/\//, '')}
                  </a>
                </p>
              ` : ''}
              ${formData.linkedin ? `
                <p style="margin: 2px 0; font-size: 13px;">
                  💼 <a href="${formData.linkedin}" style="color: ${formData.accentColor}; text-decoration: none;">
                    LinkedIn Profile
                  </a>
                </p>
              ` : ''}
            </div>
          </td>
        </tr>
      </table>
    </div>
  `;

  const generateSignature = useCallback(() => {
    switch (formData.layout) {
      case 'classic':
      default:
        return generateClassicSignature();
    }
  }, [formData]);

  const copySignature = () => {
    const signature = generateSignature();
    const textarea = document.createElement('textarea');
    textarea.innerHTML = signature;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Form Section */}
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              placeholder="Full Name *"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleInputChange}
              placeholder="Job Title"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              placeholder="Company Name"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="Website URL"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="url"
              name="linkedin"
              value={formData.linkedin}
              onChange={handleInputChange}
              placeholder="LinkedIn Profile URL"
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 text-sm text-gray-300">
                <Image className="h-4 w-4" />
                <span>Company Logo</span>
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="hidden"
                id="logo-upload"
              />
              <label
                htmlFor="logo-upload"
                className="px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-sm text-gray-300 hover:bg-gray-700 cursor-pointer"
              >
                Upload Logo
              </label>
            </div>

            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 text-sm text-gray-300">
                <Palette className="h-4 w-4" />
                <span>Accent Color</span>
              </label>
              <input
                type="color"
                name="accentColor"
                value={formData.accentColor}
                onChange={handleInputChange}
                className="w-8 h-8 rounded cursor-pointer"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 text-sm text-gray-300">
                <Layout className="h-4 w-4" />
                <span>Layout</span>
              </label>
              <select
                name="layout"
                value={formData.layout}
                onChange={handleInputChange}
                className="bg-gray-800 border border-gray-700 rounded-lg text-sm text-gray-300 p-2"
              >
                {Object.entries(layouts).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Preview Section */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Preview</h3>
            <button
              onClick={copySignature}
              className="px-4 py-2 bg-blue-600 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center space-x-2"
            >
              {copied ? (
                <>
                  <Check className="h-4 w-4" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4" />
                  <span>Copy HTML</span>
                </>
              )}
            </button>
          </div>
          
          <div className="bg-white rounded-lg p-4">
            <div dangerouslySetInnerHTML={{ __html: generateSignature() }} />
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden">
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Create Professional Content in Minutes
              </h2>
              <p className="text-purple-100 mb-6">
                Generate emails, blog posts, social media content, and more with our AI-powered content creation tools. Sign up now and get 1000 free points to try our premium features!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignatureGenerator;