import React, { useEffect } from 'react';
import { Shield } from 'lucide-react';
import SEOHead from '../../components/SEOHead';

const PrivacyPolicy = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SEOHead />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Header */}
          <div className="text-center mb-12">
            <div className="flex justify-center mb-4">
              <Shield className="h-12 w-12 text-blue-500" />
            </div>
            <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 mb-2">
              Privacy Policy
            </h1>
            <p className="text-lg text-gray-400">
              Last Updated: November 12, 2024
            </p>
          </div>

          {/* Legal Notice Box */}
          <div className="bg-gray-800/50 rounded-xl p-6 mb-8 border border-gray-700">
            <div className="flex items-start">
              <Shield className="h-6 w-6 text-blue-500 mt-1 mr-3 flex-shrink-0" />
              <div>
                <p className="text-blue-400 font-semibold mb-2">
                  YOUR PRIVACY IS IMPORTANT TO US
                </p>
                <p className="text-gray-300 text-sm">
                  PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE COLLECT, USE, PROTECT, AND HANDLE YOUR PERSONAL INFORMATION.
                </p>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-gray-800/30 rounded-xl shadow-lg p-8 backdrop-blur-sm border border-gray-700">
            <div className="prose prose-invert max-w-none">
              {/* Content remains the same, just updating text colors */}
              {/* Example of updated section - repeat for all sections */}
              <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
                1. Introduction
              </h2>
              <p className="text-gray-300">
                Welcome to The Content GPT (referred to as "we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from) or use our services, and tell you about your privacy rights and how the law protects you.
              </p>
              
              <p className="text-gray-300"> 
              This privacy policy is designed to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information. By using our services, you acknowledge that you have read and understood this privacy policy. If you do not agree with our policies and practices, please do not use our services.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">2. Important Information and Who We Are</h2>
            <p className="text-gray-300"> 
              The Content GPT is an AI-powered content creation platform that provides various services including, but not limited to, content generation, content optimization, and AI writing assistance. This privacy policy applies to all information collected through our website, AI writing platform, related services, and any other features, tools, materials, or other services offered by The Content GPT.
            </p>
            <p className="text-gray-300"> 
              If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager at privacy@thecontentgpt.com. You have the right to make a complaint at any time to your local supervisory authority for data protection issues. However, we would appreciate the chance to deal with your concerns before you approach the authority, so please contact us in the first instance.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">3. Changes to the Privacy Policy and Your Duty to Inform Us of Changes</h2>
            <p className="text-gray-300"> 
              We reserve the right to update this privacy policy at any time. The updated version will be indicated by an updated "Last Updated" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we will notify you either through the email address you have provided us or by placing a prominent notice on our website. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
            </p>
            <p className="text-gray-300"> 
              It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us. You can update your personal data by accessing your account settings or by contacting our support team.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">4. The Data We Collect About You</h2>
            <p className="text-gray-300"> 
              Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store, and transfer different kinds of personal data about you which we have grouped together as follows:
            </p>
            <p className="text-gray-300"> 
              Identity Data includes your full name, username or similar identifier, and any other information you provide in your account profile. We collect this information when you create an account, update your profile, or interact with our customer support team. This information is essential for providing our services and maintaining the security of your account.
            </p>
            <p className="text-gray-300"> 
              Contact Data includes your email address, billing address, delivery address, and any other contact information you provide. This information is necessary for sending you important updates about our services, processing your transactions, and providing customer support. We ensure this information is stored securely and used only for legitimate business purposes.
            </p>
            <p className="text-gray-300"> 
              Financial Data includes payment card details, bank account information, and other payment-related data. We do not store complete payment card information on our servers. Instead, we use trusted third-party payment processors who maintain high security standards and comply with the Payment Card Industry Data Security Standard (PCI DSS).
            </p>
            <p className="text-gray-300"> 
              Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website. This data helps us ensure the security of our platform, optimize our services for different devices and browsers, and analyze usage patterns to improve our services.
            </p>
            <p className="text-gray-300"> 
              Usage Data encompasses information about how you use our website and services, including your content generation history, writing preferences, template usage, and interaction with our platform features. This data helps us understand user behavior, improve our services, and provide personalized experiences. It includes the types of content you generate, the frequency of your usage, and your preferred settings.
            </p>
            <p className="text-gray-300"> 
              Content Data refers to the information and content you input into our system for processing, including but not limited to text for rewriting, topics for content generation, and any other input data you provide to our AI systems. We treat this data with the utmost confidentiality and use it solely for providing our services.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">5. How We Collect Your Personal Data</h2>
            <p className="text-gray-300"> 
              We use different methods to collect data from and about you, including through direct interactions, automated technologies or interactions, and third parties or publicly available sources. When you directly interact with us, you may provide Personal Data by filling in forms on our website or by corresponding with us by email or otherwise. This includes personal data you provide when you create an account, subscribe to our services, request marketing materials to be sent to you, or give us feedback.
            </p>
            <p className="text-gray-300"> 
              Through automated technologies or interactions, as you interact with our website, we automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using cookies, server logs, and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.
            </p>
            <p className="text-gray-300"> 
              Our AI content generation features process the input you provide to generate content. While we do not store the generated content permanently on our servers, we may temporarily retain it to provide our services and improve our AI models. The content you generate remains your property, and we do not claim any ownership rights to it.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">6. How We Use Your Personal Data</h2>
            <p className="text-gray-300"> 
              We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: where we need to perform the contract we are about to enter into or have entered into with you; where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and where we need to comply with a legal obligation.
            </p>
            <p className="text-gray-300"> 
              The primary purpose of collecting your personal data is to provide and improve our services. This includes using your data to maintain and enhance our platform, process your transactions, provide customer support, and send you important service-related notices. We also use your data to personalize your experience, such as remembering your preferences and providing customized content suggestions.
            </p>
            <p className="text-gray-300"> 
              For users who have opted in to our marketing communications, we may use your Contact Data to send you information about new features, special offers, and other updates we believe may interest you. You can opt out of these communications at any time through your account settings or by clicking the unsubscribe link in our emails.
            </p>
            <p className="text-gray-300"> 
              We analyze Usage Data to understand how our services are being used, identify trends, and make improvements to our platform. This analysis helps us develop new features, fix issues, and enhance the overall user experience. We may also use this data to generate aggregate, anonymized statistics about service usage.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">7. Data Security</h2>
            <p className="text-gray-300"> 
              We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, accessed, altered, or disclosed in an unauthorized way. These measures include encryption of data in transit and at rest, secure access controls, regular security assessments, and robust backup procedures. We limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know.
            </p>
            <p className="text-gray-300"> 
              Our infrastructure is hosted on secure servers with industry-standard protections, including firewalls, intrusion detection systems, and regular security audits. We maintain strict access controls and authentication procedures to ensure that only authorized personnel can access our systems and your data. All employees with access to personal data are bound by confidentiality obligations and undergo regular privacy and security training.
            </p>
            <p className="text-gray-300"> 
              We have procedures in place to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. In the event of a data breach, our incident response team will immediately assess the situation, contain the breach, and take necessary remedial actions. We maintain detailed logs of all system activities to help investigate and prevent security incidents.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">8. Data Retention</h2>
            <p className="text-gray-300"> 
              We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
            </p>
            <p className="text-gray-300"> 
              To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting, or other requirements.
            </p>
            <p className="text-gray-300"> 
              When you delete your account, we begin a process of securely deleting or anonymizing your personal data from our systems, unless we are required to retain certain information for legal or legitimate business purposes. Content data that you have generated using our services may be retained in an anonymized form to improve our AI models and services.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">9. Your Legal Rights</h2>
            <p className="text-gray-300"> 
              Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to request access to your personal data, request correction of your personal data, request erasure of your personal data, object to processing of your personal data, request restriction of processing your personal data, request transfer of your personal data, and withdraw consent to processing of your personal data.
            </p>
            <p className="text-gray-300"> 
              If you wish to exercise any of these rights, please contact us through our designated channels. You will not have to pay a fee to access your personal data or to exercise any of the other rights. However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
            </p>
            <p className="text-gray-300"> 
              We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">10. Third-Party Links and Integrations</h2>
            <p className="text-gray-300"> 
              Our website and services may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
            </p>
            <p className="text-gray-300"> 
              We may use third-party service providers to help us operate our business and our website or administer activities on our behalf. These providers have access to your personal data only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose. We ensure that any third-party service providers we work with maintain appropriate security standards and comply with relevant data protection regulations.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">11. International Data Transfers</h2>
            <p className="text-gray-300"> 
              We may transfer your personal data to countries outside your country of residence for processing and storage. When we do so, we ensure appropriate safeguards are in place to protect your data and comply with applicable data protection laws. These safeguards may include data transfer agreements incorporating standard data protection clauses or other suitable mechanisms as required by law.
            </p>
            <p className="text-gray-300"> 
              For users in the European Economic Area (EEA), we ensure that any transfer of personal data outside the EEA complies with the requirements of the General Data Protection Regulation (GDPR). This includes transfers to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission, or where we use specific contracts approved by the European Commission that give personal data the same protection it has in Europe.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">12. Contact Information</h2>
            <p className="text-gray-300"> 
              If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager at privacy@thecontentgpt.com. You can also reach us by mail at our registered office address: The Content GPT, [Address].
            </p>
            <p className="text-gray-300"> 
              For users in the European Union, you have the right to make a complaint at any time to your local supervisory authority for data protection issues. However, we would appreciate the chance to deal with your concerns before you approach the supervisory authority, so please contact us in the first instance.
            </p>

              {/* Last updated notice */}
              <div className="mt-12 p-6 bg-gray-800/50 rounded-lg border border-gray-700">
                <p className="text-sm text-gray-400">
                  This privacy policy was last updated on November 12, 2024. Our privacy policy may change from time to time. If we make any material changes to this privacy policy, we will notify you through our website or by email prior to the change becoming effective.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Optimize with React.memo since content is static
export default React.memo(PrivacyPolicy);