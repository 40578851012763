import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Wand, Info, ArrowRight, Check, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const TitleTagOptimizer = () => {
  const [title, setTitle] = useState('');
  const [keyword, setKeyword] = useState('');
  
  // Best practices for title tags
  const bestPractices = {
    lengthMin: 50,
    lengthMax: 60,
    keywordPosition: 'beginning', // keyword should be at the beginning
    brandingSeparator: '|', // or '—' or '-'
    commonSeparators: ['|', '-', '—', ':', '»'],
    stopWords: ['a', 'an', 'and', 'are', 'as', 'at', 'be', 'by', 'for', 'from', 'has', 'he', 'in', 'is', 'it', 'its', 'of', 'on', 'that', 'the', 'to', 'was', 'were', 'will', 'with'],
  };

  const analyzeTitleTag = useCallback(() => {
    if (!title) return null;

    const analysis = {
      length: title.length,
      scores: {
        length: 0,
        keyword: 0,
        structure: 0,
        brandingSeparator: 0
      },
      warnings: [],
      suggestions: []
    };

    // Check length
    if (title.length < bestPractices.lengthMin) {
      analysis.warnings.push('Title is too short - might not fully utilize SEO potential');
      analysis.scores.length = 50;
    } else if (title.length > bestPractices.lengthMax) {
      analysis.warnings.push('Title is too long - may get truncated in search results');
      analysis.scores.length = 50;
    } else {
      analysis.scores.length = 100;
    }

    // Check keyword usage and position
    if (keyword) {
      if (!title.toLowerCase().includes(keyword.toLowerCase())) {
        analysis.warnings.push('Main keyword is missing from the title');
        analysis.scores.keyword = 0;
      } else {
        const keywordPosition = title.toLowerCase().indexOf(keyword.toLowerCase());
        if (keywordPosition === 0) {
          analysis.scores.keyword = 100;
        } else if (keywordPosition < title.length / 3) {
          analysis.scores.keyword = 80;
          analysis.suggestions.push('Consider moving the keyword closer to the beginning');
        } else {
          analysis.scores.keyword = 60;
          analysis.warnings.push('Keyword appears too late in the title');
        }
      }
    }

    // Check structure and branding
    const hasSeparator = bestPractices.commonSeparators.some(sep => title.includes(sep));
    if (!hasSeparator) {
      analysis.suggestions.push('Consider adding a separator (|, -, —) for better structure');
      analysis.scores.structure = 50;
    } else {
      analysis.scores.structure = 100;
    }

    // Calculate overall score
    const overallScore = Math.round(
      (analysis.scores.length + analysis.scores.keyword + analysis.scores.structure) / 3
    );

    return {
      ...analysis,
      overallScore
    };
  }, [title, keyword]);

  const result = title ? analyzeTitleTag() : null;

  const ScoreIndicator = memo(({ score, label }) => (
    <div className="space-y-1">
      <div className="flex justify-between text-sm">
        <span>{label}</span>
        <span>{score}%</span>
      </div>
      <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${score}%` }}
          className={`h-full rounded-full ${
            score >= 80 ? 'bg-green-500' : score >= 60 ? 'bg-yellow-500' : 'bg-red-500'
          }`}
        />
      </div>
    </div>
  ));

  ScoreIndicator.displayName = 'ScoreIndicator';

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Enter your main keyword..."
            className="w-full p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter your title tag..."
            className="w-full p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="text-sm text-gray-400">
            {title.length} / {bestPractices.lengthMax} characters
          </div>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="space-y-4">
            <ScoreIndicator score={result.overallScore} label="Overall Score" />
            <ScoreIndicator score={result.scores.length} label="Length Optimization" />
            <ScoreIndicator score={result.scores.keyword} label="Keyword Usage" />
            <ScoreIndicator score={result.scores.structure} label="Structure" />
          </div>

          {result.warnings.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium text-red-400">Warnings:</h4>
              {result.warnings.map((warning, index) => (
                <div key={index} className="flex items-center space-x-2 text-sm text-red-400">
                  <X className="h-4 w-4 flex-shrink-0" />
                  <span>{warning}</span>
                </div>
              ))}
            </div>
          )}

          {result.suggestions.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium text-green-400">Suggestions:</h4>
              {result.suggestions.map((suggestion, index) => (
                <div key={index} className="flex items-center space-x-2 text-sm text-green-400">
                  <Check className="h-4 w-4 flex-shrink-0" />
                  <span>{suggestion}</span>
                </div>
              ))}
            </div>
          )}

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              These recommendations are based on current SEO best practices. 
              Individual results may vary depending on your specific needs and context.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Supercharge Your Content Creation?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to our full suite of AI-powered content creation tools, including SEO optimization, article writing, and more. Start with 1000 free points today!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TitleTagOptimizer;