// SpreadsheetManager.js
import React, { useState } from 'react';
import SpreadsheetHero from './SpreadsheetHero';
import DynamicAISpreadsheet from './AISpreadsheet';

const SpreadsheetManager = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  return (
    <div>
      {!selectedFile ? (
        <SpreadsheetHero onFileSelect={handleFileSelect} />
      ) : (
        <DynamicAISpreadsheet initialFile={selectedFile} />
      )}
    </div>
  );
};

export default SpreadsheetManager;