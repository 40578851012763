import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { SearchCheck, Info, ArrowRight, Check, X, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const MetaDescriptionChecker = () => {
  const [metaDescription, setMetaDescription] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const analyzeMetaDescription = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const analysis = {
        length: metaDescription.length,
        words: metaDescription.split(/\s+/).filter(Boolean).length,
        checks: [
          {
            name: 'Length',
            passed: metaDescription.length >= 120 && metaDescription.length <= 155,
            message: `Meta description is ${metaDescription.length} characters. Ideal length is between 120-155 characters.`
          },
          {
            name: 'Word Count',
            passed: metaDescription.split(/\s+/).filter(Boolean).length >= 10,
            message: 'Meta description should contain at least 10 words for better context.'
          },
          {
            name: 'Call-to-Action',
            passed: /buy|shop|learn|discover|get|find|explore|read|download|start|try|watch|see/i.test(metaDescription),
            message: 'Include a clear call-to-action word to improve click-through rates.'
          },
          {
            name: 'Keyword Usage',
            passed: true, // This would normally check against provided keywords
            message: 'Contains natural keyword placement without stuffing.'
          },
          {
            name: 'Special Characters',
            passed: !/[^\w\s.,!?-]/.test(metaDescription),
            message: 'Avoid using special characters that might display incorrectly in search results.'
          },
          {
            name: 'Uniqueness',
            passed: !/(^|\s)\w{1,2}(\s|$)/.test(metaDescription),
            message: 'Avoid single or two-letter words unless necessary.'
          }
        ]
      };

      // Calculate overall score
      const passedChecks = analysis.checks.filter(check => check.passed).length;
      analysis.score = Math.round((passedChecks / analysis.checks.length) * 100);

      setResult(analysis);
      setIsAnalyzing(false);
    }, 1000);
  }, [metaDescription]);

  const getScoreColor = (score) => {
    if (score >= 80) return 'bg-green-500';
    if (score >= 60) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          placeholder="Enter your meta description here to check its SEO effectiveness..."
          className="w-full h-32 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          maxLength={200}
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {metaDescription.length} / 155 characters
          </span>
          <button
            onClick={analyzeMetaDescription}
            disabled={!metaDescription.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <SearchCheck className="h-4 w-4" />
                <span>Analyze Description</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Overall Score</span>
              <span>{result.score}%</span>
            </div>
            <div className="h-4 bg-gray-700 rounded-full overflow-hidden">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${result.score}%` }}
                className={`h-full rounded-full ${getScoreColor(result.score)}`}
              />
            </div>
          </div>

          <div className="space-y-4">
            {result.checks.map((check, index) => (
              <div key={index} className="flex items-start space-x-3">
                {check.passed ? (
                  <Check className="h-5 w-5 text-green-500 mt-0.5" />
                ) : (
                  <X className="h-5 w-5 text-red-500 mt-0.5" />
                )}
                <div>
                  <h4 className="font-medium">{check.name}</h4>
                  <p className="text-sm text-gray-400">{check.message}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Meta descriptions should be compelling, include relevant keywords naturally, 
              and accurately describe the page content to improve click-through rates.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Need Help Creating Perfect Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to our AI-powered content creation suite! Generate SEO-optimized meta descriptions, 
                titles, and full articles automatically. Start with 1000 free points today!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MetaDescriptionChecker;