import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Wand2, Share2 } from 'lucide-react';

const SalesLiveDemo = () => {
  const [content, setContent] = useState('');
  const [generating, setGenerating] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');
  const [contentType, setContentType] = useState('product');

  const generateContent = () => {
    if (!content) return;
    
    setGenerating(true);
    // Simulate AI generation with sample responses
    const responses = {
      product: "Experience unparalleled productivity with our AI-driven solution. Automate routine tasks, collaborate seamlessly, and boost your team's efficiency by up to 300%. Start transforming your workflow today and join thousands of satisfied teams worldwide.",
      email: "Subject: Transform Your Workflow Today! 🚀\n\nDear [Name],\n\nTired of repetitive tasks slowing you down? Our AI solution helps teams like yours save 20+ hours per week. Join industry leaders who've already revolutionized their workflow.",
      ad: "🔥 Boost Team Productivity 3x!\nAI-Powered Workflow Solution\nAutomate Tasks • Seamless Collaboration • Real-time Insights\nStart Free Trial Today!"
    };

    // Simulate typing effect
    let i = 0;
    const text = responses[contentType];
    const interval = setInterval(() => {
      setGeneratedContent(text.substring(0, i));
      i++;
      if (i > text.length) {
        clearInterval(interval);
        setGenerating(false);
      }
    }, 20);
  };

  return (
    <div className="bg-black/50 rounded-xl p-6 space-y-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-white">Live Demo</h3>
        <span className="px-3 py-1 bg-blue-500/20 rounded-full text-blue-400 text-sm">
          {generating ? 'AI Writing...' : 'Ready'}
        </span>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm text-gray-400">Content Type</label>
          <select 
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
            className="w-full bg-gray-800/50 border border-gray-700 rounded-lg p-3 text-gray-300"
          >
            <option value="product">Product Description</option>
            <option value="email">Email Copy</option>
            <option value="ad">Ad Copy</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-400">Key Features</label>
          <textarea 
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full bg-gray-800/50 border border-gray-700 rounded-lg p-3 text-gray-300 h-24"
            placeholder="Enter your product's key features..."
          />
        </div>

        <button 
          onClick={generateContent}
          disabled={generating || !content}
          className={`w-full py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg font-medium transition-all transform hover:scale-105 flex items-center justify-center ${
            generating || !content ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-purple-600'
          }`}
        >
          <Wand2 className="h-5 w-5 mr-2" />
          {generating ? 'Generating...' : 'Generate Copy'}
        </button>
      </div>

      {generatedContent && (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-900/30 to-purple-900/30 rounded-xl p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <h4 className="font-semibold text-white">Generated Copy</h4>
            <Share2 className="h-5 w-5 text-gray-400 cursor-pointer hover:text-blue-400 transition-colors" />
          </div>
          <p className="text-gray-300 leading-relaxed whitespace-pre-line">
            {generatedContent}
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default SalesLiveDemo;