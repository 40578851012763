import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ROICalculator = () => {
  const [inputs, setInputs] = useState({
    adSpend: 5000,
    conversionRate: 2.5,
    orderValue: 100
  });

  const [results, setResults] = useState({
    conversionIncrease: 0,
    additionalRevenue: 0,
    roi: 0
  });

  // Calculate effects of AI optimization
  const calculateResults = () => {
    // Average improvement metrics based on historical data
    const avgConversionImprovement = 1.45; // 45% improvement
    const avgCTRImprovement = 1.67; // 67% improvement
    const avgCPCReduction = 0.85; // 15% reduction

    // Calculate new metrics
    const improvedConversionRate = inputs.conversionRate * avgConversionImprovement;
    const currentMonthlyConversions = (inputs.adSpend / 100) * inputs.conversionRate;
    const improvedMonthlyConversions = (inputs.adSpend / 100) * improvedConversionRate;
    const additionalConversions = improvedMonthlyConversions - currentMonthlyConversions;
    const additionalRevenue = additionalConversions * inputs.orderValue;
    
    // ROI calculation: (Gain from Investment - Cost of Investment) / Cost of Investment
    const monthlyAICost = 299; // Example monthly subscription cost
    const roi = ((additionalRevenue - monthlyAICost) / monthlyAICost) * 100;

    setResults({
      conversionIncrease: ((improvedConversionRate - inputs.conversionRate) / inputs.conversionRate) * 100,
      additionalRevenue: additionalRevenue,
      roi: roi
    });
  };

  // Recalculate when inputs change
  useEffect(() => {
    calculateResults();
  }, [inputs]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: parseFloat(value) || 0
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8"
    >
      <div className="grid md:grid-cols-2 gap-12">
        <div className="space-y-6">
          <h3 className="text-2xl font-bold mb-6">Input Your Metrics</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-300 mb-2">Monthly Ad Spend ($)</label>
              <input 
                type="number"
                name="adSpend"
                value={inputs.adSpend}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="5000"
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">Current Conversion Rate (%)</label>
              <input 
                type="number"
                name="conversionRate"
                value={inputs.conversionRate}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="2.5"
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">Average Order Value ($)</label>
              <input 
                type="number"
                name="orderValue"
                value={inputs.orderValue}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="100"
              />
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-purple-900/20 to-pink-900/20 rounded-xl p-8">
          <h3 className="text-2xl font-bold mb-6">Projected Results</h3>
          <div className="space-y-6">
            <motion.div 
              className="bg-black/30 rounded-lg p-4"
              animate={{ scale: [1, 1.02, 1] }}
              transition={{ duration: 0.5 }}
            >
              <div className="text-gray-400 mb-1">Conversion Rate Increase</div>
              <div className="text-3xl font-bold text-green-400">
                {results.conversionIncrease.toFixed(1)}%
              </div>
            </motion.div>
            <motion.div 
              className="bg-black/30 rounded-lg p-4"
              animate={{ scale: [1, 1.02, 1] }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div className="text-gray-400 mb-1">Additional Monthly Revenue</div>
              <div className="text-3xl font-bold text-green-400">
                ${results.additionalRevenue.toFixed(2)}
              </div>
            </motion.div>
            <motion.div 
              className="bg-black/30 rounded-lg p-4"
              animate={{ scale: [1, 1.02, 1] }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="text-gray-400 mb-1">ROI</div>
              <div className="text-3xl font-bold text-green-400">
                {results.roi.toFixed(1)}%
              </div>
            </motion.div>
          </div>
          <p className="text-sm text-gray-400 mt-4">
            * Projections based on average customer performance data
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default ROICalculator;