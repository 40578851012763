import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { RefreshCw } from 'lucide-react';

const Paraphrase = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleParaphrase = async () => {
      setIsLoading(true)
      // Simulate API call for paraphrasing - Replace with actual API call
    try {
        const response = await fetch('/api/paraphrase', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ input }),
        })

        if(!response.ok) {
            throw new Error('Failed to paraphrase')
        }

        const data = await response.json();
          setOutput(data.paraphrasedText);
    }
    catch (error) {
        console.error(error)
        setOutput('Error paraphrasing text.')
    }
    finally {
        setIsLoading(false)
    }
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black py-24">
          <div className="max-w-5xl mx-auto px-6"> {/* Adjusted max-width */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="space-y-8"
        >
            <div className="text-center">
                <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">Content Rephraser</h2> {/* Increased heading size */}
              <p className="text-gray-400 text-lg md:text-xl">Rewrite your text while maintaining the original meaning.</p> {/* Increased text size */}
            </div>
          <div className="bg-gray-800 rounded-lg p-6 overflow-hidden">
          <textarea
              className="w-full bg-gray-700 border border-gray-600 text-white px-4 py-3 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter text to paraphrase..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
              rows={6}
            />    
            <div className="flex justify-end mt-4">


                <button
                onClick={handleParaphrase}
                disabled={isLoading || !input}
                className="bg-blue-600 px-6 py-3 rounded-full font-medium text-white hover:bg-blue-700 disabled:bg-gray-500 disabled:cursor-not-allowed flex items-center transition-colors duration-300"
              >
                            {isLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div> // Loading spinner
                        ) : (
                    <RefreshCw className="h-5 w-5 mr-2" />
                  )}
                  Paraphrase
              </button>
            </div>


            {output && (
              <div className="mt-6 bg-gray-700 rounded-lg p-6">
                <h3 className="text-xl font-medium text-white mb-2">Paraphrased Text:</h3>
                <p className="text-gray-300 whitespace-pre-wrap">{output}</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Paraphrase;
