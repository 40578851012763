import React, { useState, useEffect,useMemo } from 'react';
import { FileText, MessageCircle, Package, Rocket } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


const UsageCalculator = ({ onUsageChange }) => {
  const [usage, setUsage] = useState({
    blogPosts: 0,
    socialMedia: 0,
    productDesc: 0
  });

  const contentTypes = {
    blogPosts: {
      label: "Blog Posts",
      wordsPerUnit: 1500,
      icon: FileText,
      max: 50
    },
    socialMedia: {
      label: "Social Media Posts",
      wordsPerUnit: 200,
      icon: MessageCircle,
      max: 100
    },
    productDesc: {
      label: "Product Descriptions",
      wordsPerUnit: 300,
      icon: Package,
      max: 75
    }
  };

  const calculateTotalWords = () => {
    return Object.entries(usage).reduce((total, [key, value]) => {
      return total + (value * contentTypes[key].wordsPerUnit);
    }, 0);
  };

  const getRecommendedPlan = (totalWords) => {
    if (totalWords <= 1000) return { name: "Free", color: "gray" };
    if (totalWords <= 50000) return { name: "Starter", color: "blue" };
    return { name: "Pro", color: "purple" };
  };

  const handleSliderChange = (type, value) => {
    setUsage(prev => ({
      ...prev,
      [type]: parseInt(value)
    }));
  };

  useEffect(() => {
    const totalWords = calculateTotalWords();
    onUsageChange?.(totalWords);
  }, [usage]);

  const totalWords = calculateTotalWords();
  const recommendedPlan = getRecommendedPlan(totalWords);

  return (
    <div className="bg-gray-800 rounded-2xl p-8">
      <div className="space-y-8">
        {Object.entries(contentTypes).map(([key, content]) => (
          <div key={key} className="flex items-center gap-8">
            <div className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center">
              <content.icon className="h-6 w-6 text-blue-400" />
            </div>
            <div className="flex-1">
              <div className="flex justify-between mb-2">
                <label className="font-medium text-gray-300">{content.label}</label>
                <span className="text-blue-400">{usage[key]}/mo</span>
              </div>
              <input
                type="range"
                min="0"
                max={content.max}
                value={usage[key]}
                onChange={(e) => handleSliderChange(key, e.target.value)}
                className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>0</span>
                <span>{content.max}</span>
              </div>
            </div>
          </div>
        ))}

        <div className="pt-8 border-t border-gray-700">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-xl font-bold">Estimated Usage</h3>
              <p className="text-gray-400">Monthly word count</p>
            </div>
            <div className="text-right">
              <div className="text-3xl font-bold text-blue-400">
                {totalWords.toLocaleString()}
              </div>
              <div className="text-gray-400">words/month</div>
            </div>
          </div>
          
          <div className="mt-6 p-4 bg-blue-500/20 rounded-xl">
            <div className="flex items-center">
              <Rocket className="h-5 w-5 text-blue-400 mr-2" />
              <span className="text-gray-300">Recommended Plan:</span>
              <span className={`ml-2 font-semibold text-${recommendedPlan.color}-400`}>
                {recommendedPlan.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ROICalculator = () => {
    const [inputs, setInputs] = useState({
      hoursPerContent: 4,
      contentPerMonth: 10,
      hourlyCost: 50
    });
  
    const savings = useMemo(() => {
      // Calculate current costs
      const currentMonthlyHours = inputs.hoursPerContent * inputs.contentPerMonth;
      const currentMonthlyCost = currentMonthlyHours * inputs.hourlyCost;
      
      // AI writing typically reduces time by 80%
      const timeReductionFactor = 0.8;
      const aiHours = currentMonthlyHours * (1 - timeReductionFactor);
      
      // Pro plan monthly cost
      const aiMonthlyCost = 79;
      
      const timeSaved = currentMonthlyHours * timeReductionFactor;
      const costSaved = currentMonthlyCost - (aiHours * inputs.hourlyCost) - aiMonthlyCost;
      const roi = costSaved > 0 ? ((costSaved / aiMonthlyCost) * 100).toFixed(0) : 0;
  
      return {
        timeSaved,
        costSaved,
        roi,
        currentMonthlyCost,
        aiMonthlyCost
      };
    }, [inputs]);
  
    const SliderInput = ({ field, label, min, max, step, format }) => (
      <div className="space-y-2">
        <div className="flex justify-between">
          <label className="text-sm text-gray-400">{label}</label>
          <span className="text-blue-400 font-medium">
            {format(inputs[field])}
          </span>
        </div>
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={inputs[field]}
          onChange={(e) => setInputs(prev => ({
            ...prev,
            [field]: parseFloat(e.target.value)
          }))}
          className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-xs text-gray-500">
          <span>{format(min)}</span>
          <span>{format(max)}</span>
        </div>
      </div>
    );
  
    const SavingsMetric = ({ label, value }) => (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="text-gray-300 mb-2">{label}</div>
        <div className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          {value}
        </div>
      </motion.div>
    );
  
    return (
      <div className="grid md:grid-cols-2 gap-12 items-start">
        <div className="space-y-8">
          <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-8">
            <h3 className="text-xl font-medium mb-6">Current Content Creation</h3>
            <div className="space-y-6">
              <SliderInput
                field="hoursPerContent"
                label="Hours per content piece"
                min={1}
                max={8}
                step={0.5}
                format={v => `${v} ${v === 1 ? 'hour' : 'hours'}`}
              />
              <SliderInput
                field="contentPerMonth"
                label="Content pieces per month"
                min={1}
                max={50}
                step={1}
                format={v => `${v} ${v === 1 ? 'piece' : 'pieces'}`}
              />
              <SliderInput
                field="hourlyCost"
                label="Hourly cost"
                min={20}
                max={200}
                step={5}
                format={v => `$${v}`}
              />
            </div>
          </div>
        </div>
  
        <div className="bg-gradient-to-br from-blue-600/20 to-purple-600/20 backdrop-blur-xl rounded-xl p-8 border border-white/10">
          <h3 className="text-2xl font-bold mb-8">Your Potential Savings</h3>
          <div className="space-y-8">
            <SavingsMetric 
              label="Monthly Time Saved"
              value={`${Math.round(savings.timeSaved)} Hours`}
            />
            <SavingsMetric 
              label="Monthly Cost Saved"
              value={`$${Math.round(savings.costSaved).toLocaleString()}`}
            />
            <SavingsMetric 
              label="Return on Investment"
              value={`${savings.roi}%`}
            />
  
            <div className="pt-6 border-t border-white/10">
              <div className="text-sm text-gray-400 mb-4">
                Current monthly cost: ${Math.round(savings.currentMonthlyCost).toLocaleString()}
                <br />
                AI solution cost: ${savings.aiMonthlyCost}/month
              </div>
              <Link 
  to="/register" 
  className="block w-full py-3 text-center bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 rounded-lg font-medium transition-all"
>
  Start Saving Now
</Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

export { UsageCalculator, ROICalculator };