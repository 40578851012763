// src/services/coinPaymentsService.js
class CoinPaymentsService {
  static createPayment({ planName, amount, billingCycle, email, userId }) {
    try {
      // Ensure custom data is properly stringified and encoded
      const customData = {
        userId: userId.toString(),
        planName: planName.toUpperCase(),
        billingCycle: billingCycle.toLowerCase(),
        email: email.toLowerCase()
      };

      const params = new URLSearchParams({
        cmd: '_pay',
        reset: 1,
        merchant: process.env.REACT_APP_COINPAYMENTS_MERCHANT_ID,
        item_name: `${planName} ${billingCycle} Plan`,
        currency: 'USD',
        amountf: amount.toFixed(2),
        custom: JSON.stringify(customData), // Removed timestamp to keep it simpler
        success_url: `${window.location.origin}/dashboard?payment=success`,
        cancel_url: `${window.location.origin}/pricing?payment=cancelled`,
        ipn_url: 'https://thecontentgpt-api.onrender.com/api/payments/coinpayments-webhook',
        email,
        version: 1
      });

      const paymentUrl = `https://www.coinpayments.net/index.php?${params.toString()}`;
      window.open(paymentUrl, 'CoinPayments', 'width=800,height=800');

    } catch (error) {
      console.error('Error creating CoinPayments payment:', error);
      throw error;
    }
  }
}

export default CoinPaymentsService;
