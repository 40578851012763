import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, AlertTriangle, Info, Loader2, ClipboardCopy, Wand2, ArrowRight, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';


const GrammarChecker = () => {
  const [text, setText] = useState('');
  const [results, setResults] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [fixedText, setFixedText] = useState('');
  const [isFixing, setIsFixing] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  // Grammar rules and patterns (kept private in the component)
  const grammarRules = {
    // Common article mistakes
    articles: {
      patterns: [
        { regex: /\b(a)\s+[aeiou]/gi, suggestion: "an", message: "Use 'an' before words that start with vowel sounds" },
        { regex: /\b(an)\s+[^aeiou]/gi, suggestion: "a", message: "Use 'a' before words that start with consonant sounds" }
      ]
    },
    
    // Subject-verb agreement
    subjectVerbAgreement: {
      patterns: [
        { regex: /\b(they|we|you|I)\s+(is|was)\b/gi, message: "Incorrect subject-verb agreement" },
        { regex: /\b(he|she|it)\s+(are|were)\b/gi, message: "Incorrect subject-verb agreement" }
      ]
    },
    
    // Double negatives
    doubleNegatives: {
      patterns: [
        { regex: /\b(don't|cannot|can't|won't|wouldn't)\s+.*?\b(no|none|nobody|never|nothing)\b/gi, 
          message: "Avoid double negatives" }
      ]
    },
    
    // Common confusions
    commonConfusions: {
      patterns: [
        { regex: /\b(your)\s+(?:going|doing|walking|running)\b/gi, suggestion: "you're", message: "Did you mean 'you're'?" },
        { regex: /\b(their)\s+(?:going|doing|walking|running)\b/gi, suggestion: "they're", message: "Did you mean 'they're'?" },
        { regex: /\b(its)\s+(?:going|doing|walking|running)\b/gi, suggestion: "it's", message: "Did you mean 'it's'?" },
        { regex: /\b(whose)\s+(?:going|doing|walking|running)\b/gi, suggestion: "who's", message: "Did you mean 'who's'?" }
      ]
    },
    
    // Punctuation
    punctuation: {
      patterns: [
        { regex: /[,.!?][A-Za-z]/g, message: "Add a space after punctuation marks" },
        { regex: /\s+[,.!?]/g, message: "Remove space before punctuation marks" },
        { regex: /(.+?)\s*\.\s*$/gm, suggestion: ".", message: "Sentence should end with a period" }
      ]
    }
  };

  const fixGrammar = useCallback(() => {
    setIsFixing(true);
    
    setTimeout(() => {
      let correctedText = text;
      
      if (results && results.issues) {
        // Sort issues from last to first to avoid index shifting
        const sortedIssues = [...results.issues].sort((a, b) => b.index - a.index);
        
        sortedIssues.forEach(issue => {
          if (issue.suggestion) {
            // Replace all occurrences of the issue
            const regex = new RegExp(issue.original, 'gi');
            correctedText = correctedText.replace(regex, issue.suggestion);
          }
        });
      }
      
      setFixedText(correctedText);
      setShowSuggestions(false);
      setIsFixing(false);
    }, 1500);
  }, [text, results]);
  
  // Add copy function
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(fixedText);
      // Optional: Add some visual feedback
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const checkGrammar = useCallback(() => {
    setIsChecking(true);
    setFixedText('');
    setShowSuggestions(true);
    // Simulate processing time for better UX
    setTimeout(() => {
      const issues = [];
      const sentences = text.split(/[.!?]+/).filter(Boolean);
      
      // Check each grammar rule category
      Object.entries(grammarRules).forEach(([category, { patterns }]) => {
        patterns.forEach(({ regex, suggestion, message }) => {
          let match;
          while ((match = regex.exec(text)) !== null) {
            issues.push({
              category,
              index: match.index,
              length: match[0].length,
              original: match[0],
              suggestion,
              message,
              severity: category === 'punctuation' ? 'low' : 'medium'
            });
          }
        });
      });

      // Basic statistics
      const stats = {
        sentences: sentences.length,
        words: text.split(/\s+/).filter(Boolean).length,
        characters: text.length,
        issues: issues.length
      };

      setResults({ issues, stats });
      setIsChecking(false);
    }, 1500);
  }, [text]);

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={fixGrammar}
          disabled={!results?.issues?.length || isFixing}
          className="px-6 py-2 bg-gradient-to-r from-green-500 to-emerald-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
        >
          {isFixing ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Fixing...</span>
            </>
          ) : (
            <>
              <Wand2 className="h-4 w-4" />
              <span>Fix All Issues</span>
            </>
          )}
        </button>
      </div>

      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to check for grammar issues..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        
        {fixedText && (
  <div className="space-y-2">
    <div className="flex items-center justify-between">
      <h3 className="text-lg font-medium text-green-400">Fixed Text:</h3>
      <button
        onClick={copyToClipboard}
        className="px-4 py-1.5 bg-gray-700 hover:bg-gray-600 rounded-lg text-sm flex items-center space-x-2 transition-colors"
      >
        <ClipboardCopy className="h-4 w-4" />
        <span>Copy Text</span>
      </button>
    </div>
    <textarea
      value={fixedText}
      onChange={(e) => setFixedText(e.target.value)}
      className="w-full h-48 p-4 bg-gray-800/50 border border-green-500/30 rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
    />
  </div>
)}

        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={checkGrammar}
            disabled={!text.trim() || isChecking}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isChecking ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Checking...</span>
              </>
            ) : (
              <>
                <Check className="h-4 w-4" />
                <span>Check Grammar</span>
              </>
            )}
          </button>
        </div>
      </div>

      {results && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {/* Stats */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(results.stats).map(([key, value]) => (
              <div key={key} className="bg-gray-800/50 border border-gray-700 rounded-xl p-4">
                <div className="text-sm text-gray-400 capitalize">{key}</div>
                <div className="text-2xl font-bold">{value}</div>
              </div>
            ))}
          </div>

          {/* Collapsible Suggestions */}
          <div className="bg-gray-800/50 border border-gray-700 rounded-xl overflow-hidden">
            <button
              onClick={() => setShowSuggestions(!showSuggestions)}
              className="w-full p-4 flex items-center justify-between hover:bg-gray-700/50 transition-colors"
            >
              <div className="flex items-center space-x-2">
                <AlertTriangle className="h-5 w-5 text-yellow-400" />
                <h3 className="text-lg font-medium">
                  Suggestions ({results.issues.length})
                </h3>
              </div>
              {showSuggestions ? (
                <ChevronUp className="h-5 w-5" />
              ) : (
                <ChevronDown className="h-5 w-5" />
              )}
            </button>
            
            <AnimatePresence>
              {showSuggestions && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="p-4 space-y-3">
                    {results.issues.length > 0 ? (
                      results.issues.map((issue, index) => (
                        <div
                          key={index}
                          className="flex items-start space-x-3 p-3 bg-gray-800 rounded-lg"
                        >
                          <AlertTriangle className={`h-5 w-5 flex-shrink-0 ${
                            issue.severity === 'high' ? 'text-red-400' :
                            issue.severity === 'medium' ? 'text-yellow-400' :
                            'text-blue-400'
                          }`} />
                          <div className="space-y-1">
                            <p>{issue.message}</p>
                            {issue.suggestion && (
                              <p className="text-sm text-gray-400">
                                Suggestion: Replace "{issue.original}" with "{issue.suggestion}"
                              </p>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center space-x-2 text-green-400">
                        <Check className="h-5 w-5" />
                        <span>No grammar issues found!</span>
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Info message */}
          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              This is a basic grammar checker that catches common errors. For professional content, 
              consider using specialized grammar checking tools or having your text reviewed by an editor.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Advanced Grammar Features?
              </h2>
              <p className="text-purple-100 mb-6">
                Unlock premium features like advanced style suggestions, tone analysis, and real-time checking. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default GrammarChecker;