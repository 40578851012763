import React, { useState } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

const PointsResetTester = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const testPointsReset = async () => {
    try {
      setLoading(true);
      setError(null);
      setResult(null);

      const baseUrl = process.env.NODE_ENV === 'production' 
        ? 'https://api.thecontentgpt.com' 
        : 'http://localhost:5001';

      const response = await fetch(`${baseUrl}/api/test/points-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to test points reset');
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Points Reset System Test</h3>
        <button
          onClick={testPointsReset}
          disabled={loading}
          className={`px-4 py-2 rounded-lg text-white ${
            loading ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700'
          }`}
        >
          {loading ? 'Testing...' : 'Test Points Reset'}
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-50 rounded-lg flex items-start">
          <AlertCircle className="h-5 w-5 text-red-400 mr-2 mt-0.5 flex-shrink-0" />
          <div>
            <h4 className="text-sm font-medium text-red-800">Error Testing Points Reset</h4>
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      {result && (
        <div className="p-4 bg-green-50 rounded-lg">
          <div className="flex items-center mb-2">
            <CheckCircle className="h-5 w-5 text-green-400 mr-2 flex-shrink-0" />
            <h4 className="text-sm font-medium text-green-800">Test Completed Successfully</h4>
          </div>
          <div className="space-y-2 text-sm text-green-700">
            <p>Points before reset: {result.pointsBeforeReset}</p>
            <p>Points after reset: {result.pointsAfterReset}</p>
            <p>Next billing date: {new Date(result.nextBillingDate).toLocaleDateString()}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PointsResetTester;