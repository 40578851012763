import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Hash, Loader2, ArrowRight, Copy, Check, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const HashtagGenerator = () => {
  const [text, setText] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [copied, setCopied] = useState(false);

  // Categories for different types of content
  const categories = {
    business: ['marketing', 'entrepreneur', 'business', 'success', 'motivation', 'startup', 'digital', 'innovation'],
    lifestyle: ['life', 'lifestyle', 'inspiration', 'happy', 'love', 'beautiful', 'photooftheday', 'wellness'],
    technology: ['tech', 'technology', 'innovation', 'future', 'digital', 'software', 'coding', 'ai'],
    fashion: ['fashion', 'style', 'ootd', 'trendy', 'fashionista', 'streetstyle', 'outfit'],
    travel: ['travel', 'wanderlust', 'adventure', 'explore', 'vacation', 'travelgram', 'journey'],
    food: ['food', 'foodie', 'yummy', 'delicious', 'foodporn', 'instafood', 'cooking'],
    fitness: ['fitness', 'health', 'workout', 'gym', 'fit', 'training', 'healthy'],
    art: ['art', 'artist', 'creative', 'design', 'artwork', 'illustration', 'drawing']
  };

  const generateHashtags = useCallback(() => {
    if (!text.trim()) return;
    setIsGenerating(true);

    // Analyze text and generate relevant hashtags
    setTimeout(() => {
      const words = text.toLowerCase().split(/\s+/);
      const relevantHashtags = new Set();

      // Find matching category hashtags
      Object.entries(categories).forEach(([category, tags]) => {
        const categoryWords = [category, ...tags];
        const hasMatch = words.some(word => 
          categoryWords.some(tag => tag.includes(word) || word.includes(tag))
        );
        if (hasMatch) {
          // Add category-specific hashtags
          tags.forEach(tag => relevantHashtags.add(`#${tag}`));
          relevantHashtags.add(`#${category}`);
        }
      });

      // Add general engagement hashtags
      const engagementHashtags = [
        '#contentcreator',
        '#socialmedia',
        '#trending',
        '#viral',
        '#followme',
        '#share',
        '#community'
      ];
      engagementHashtags.forEach(tag => relevantHashtags.add(tag));

      setHashtags(Array.from(relevantHashtags).slice(0, 30));
      setIsGenerating(false);
    }, 1500);
  }, [text]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(hashtags.join(' '));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [hashtags]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Generate relevant hashtags for your content to increase visibility and engagement.
        Add more context in your text for better-targeted hashtags.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Describe your content here to generate relevant hashtags..."
          className="w-full h-32 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={generateHashtags}
            disabled={!text.trim() || isGenerating}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isGenerating ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Generating...</span>
              </>
            ) : (
              <>
                <Hash className="h-4 w-4" />
                <span>Generate Hashtags</span>
              </>
            )}
          </button>
        </div>
      </div>

      {hashtags.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="flex justify-between items-start">
            <h4 className="font-medium">Generated Hashtags:</h4>
            <button
              onClick={copyToClipboard}
              className="flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors"
            >
              {copied ? (
                <Check className="h-4 w-4 text-green-500" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span>{copied ? 'Copied!' : 'Copy All'}</span>
            </button>
          </div>

          <div className="flex flex-wrap gap-2">
            {hashtags.map((hashtag, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-gray-700/50 rounded-full text-sm hover:bg-gray-700 transition-colors cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(hashtag);
                }}
              >
                {hashtag}
              </span>
            ))}
          </div>

          <InfoMessage />
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Level Up Your Content Creation
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to advanced AI-powered tools for content creation, including trending hashtag analytics, engagement predictions, and personalized content suggestions. Start creating viral content today!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HashtagGenerator;