import React, { useRef } from 'react';
import { LineChart as LineChartIcon, Download } from 'lucide-react';
import { Button } from '../ui/button';
import { downloadAnalysis } from './tools/xcelExport';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const lineChartConfig = {
  id: 'line-chart',
  label: 'Line Chart Analysis',
  icon: <LineChartIcon className="w-4 h-4" />,
  description: 'Visualize trends across multiple metrics',
};

export const createPrompt = (data, columns) => {
  // Format columns and identify numeric and date columns
  const formattedColumns = columns.map(col => {
    const accessor = col.accessor || col.id;
    const sampleValue = data[0]?.[accessor];
    
    let type = 'string';
    if (typeof sampleValue === 'number') {
      type = 'number';
    } else if (!isNaN(Date.parse(sampleValue))) {
      type = 'date';
    }
    
    return {
      header: col.Header || col.id || accessor,
      accessor: accessor,
      type: type
    };
  });

  const numericColumns = formattedColumns.filter(col => col.type === 'number');
  const dateColumns = formattedColumns.filter(col => col.type === 'date');

  const tablePreview = data.map(row => {
    return formattedColumns.map(col => `${col.header}: ${row[col.accessor]}`).join(', ');
  }).join('\n');

  const prompt = `Analyze this dataset and provide a line chart visualization analysis in this exact JSON format:

{
  "chartConfig": {
    "xAxis": {
      "dataKey": "string",
      "label": "string"
    },
    "series": [{
      "dataKey": "string",
      "label": "string",
      "color": "string"
    }]
  },
  "processedData": [{
    "timestamp": "value",
    "metric1": number,
    "metric2": number,
    ...
  }],
  "insights": {
    "trends": [
      "key trend observation 1",
      "key trend observation 2"
    ],
    "correlations": [
      "correlation insight 1",
      "correlation insight 2"
    ],
    "anomalies": [
      "anomaly detection 1",
      "anomaly detection 2"
    ]
  },
  "statistics": {
    "metricName": {
      "min": number,
      "max": number,
      "average": number,
      "growthRate": number
    }
  }
}

Table Structure:
${formattedColumns.map(col => `${col.header} (${col.type})`).join('\n')}

Available Numeric Columns:
${numericColumns.map(col => col.header).join(', ')}

Available Date Columns:
${dateColumns.map(col => col.header).join(', ')}

Complete Dataset:
${tablePreview}

Consider these points in your analysis:
1. Select the most appropriate time-based column for the x-axis
2. Choose the most relevant numeric columns to visualize
3. Identify any notable patterns, trends, or anomalies
4. Calculate key statistics for each metric
5. Provide actionable insights based on the visualization`;

  return prompt;
};

export const parseResults = (apiResponse) => {
  try {
    const jsonMatch = apiResponse.match(/```json\n([\s\S]*?)\n```/);
    
    if (!jsonMatch || !jsonMatch[1]) {
      throw new Error('Invalid API response format');
    }

    const parsedJson = JSON.parse(jsonMatch[1]);
    
    return {
      chartConfig: parsedJson.chartConfig || { xAxis: {}, series: [] },
      processedData: parsedJson.processedData || [],
      insights: parsedJson.insights || { trends: [], correlations: [], anomalies: [] },
      statistics: parsedJson.statistics || {}
    };
  } catch (error) {
    return {
      chartConfig: { xAxis: {}, series: [] },
      processedData: [],
      insights: { trends: [], correlations: [], anomalies: [] },
      statistics: {},
      error: error.message
    };
  }
};

export const LineChartResults = ({ results }) => {
  const reportRef = useRef(null);

  if (!results) return null;

  const downloadPDF = async () => {
    const element = reportRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      backgroundColor: document.documentElement.classList.contains('dark') ? '#1a1a1a' : '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width/2, canvas.height/2]
    });
    
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
    pdf.save('line-chart-analysis.pdf');
  };

  return (
    <div className="space-y-8 px-2">
      <div className="flex justify-end mb-4 gap-2">
        <Button
          onClick={() => downloadAnalysis(results)}
          className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download Excel
        </Button>
        <Button
          onClick={downloadPDF}
          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </Button>
      </div>

      <div ref={reportRef}>
        {/* Main Chart */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Trend Visualization
          </h4>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={results.processedData}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200 dark:stroke-gray-700" />
                <XAxis 
                  dataKey={results.chartConfig.xAxis.dataKey}
                  label={results.chartConfig.xAxis.label}
                  className="text-gray-600 dark:text-gray-300"
                />
                <YAxis className="text-gray-600 dark:text-gray-300" />
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: 'rgb(31, 41, 55)',
                    border: 'none',
                    borderRadius: '0.375rem',
                    color: 'rgb(229, 231, 235)'
                  }}
                />
                <Legend />
                {results.chartConfig.series.map((series, index) => (
                  <Line
                    key={series.dataKey}
                    type="monotone"
                    dataKey={series.dataKey}
                    name={series.label}
                    stroke={series.color}
                    dot={false}
                    strokeWidth={2}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Statistics Grid */}
        <div className="mt-8">
          <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Metric Statistics
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(results.statistics).map(([metric, stats]) => (
              <div key={metric} className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                <h5 className="font-medium text-gray-800 dark:text-gray-200 mb-3">{metric}</h5>
                <div className="space-y-2">
                  {Object.entries(stats).map(([stat, value]) => (
                    <div key={stat} className="flex justify-between">
                      <span className="text-gray-600 dark:text-gray-400 capitalize">{stat}:</span>
                      <span className="font-medium text-gray-900 dark:text-gray-100">
                        {typeof value === 'number' ? value.toFixed(2) : value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Insights Sections */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.entries(results.insights).map(([category, items]) => (
            <div key={category} className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
              <h4 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100 capitalize">
                {category}
              </h4>
              <ul className="space-y-3">
                {items.map((item, index) => (
                  <li 
                    key={index}
                    className="flex items-start space-x-3 text-gray-700 dark:text-gray-300"
                  >
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 font-medium">
                      {index + 1}
                    </span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  config: lineChartConfig,
  createPrompt,
  parseResults,
  ResultsComponent: LineChartResults
};