import React, { useState } from 'react';
import SEOHead from '../../components/SEOHead';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  Layout,
  Target,
  RefreshCw,
  Globe,
  Award,
  Users,
  MessageSquare,
  ChevronDown,
  CheckCircle,
  Sparkles,
  Clock,
  Shuffle,
  Feather,
  Search,
  ThumbsUp
} from 'lucide-react';

const AboutPageWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const templates = [
    {
      title: "Professional",
      before: "Input: Tech startup focused on AI solutions",
      after: "We're a forward-thinking tech startup revolutionizing industries through innovative AI solutions. Founded in 2023, our team of expert engineers and data scientists is committed to making artificial intelligence accessible and practical for businesses of all sizes."
    },
    {
      title: "Creative",
      before: "Input: Independent art studio",
      after: "Welcome to our vibrant creative hub! Born from a passion for artistic expression, our studio is a space where imagination knows no bounds. We blend traditional techniques with contemporary vision to create unique pieces that tell compelling stories."
    },
    {
      title: "E-commerce",
      before: "Input: Sustainable fashion brand",
      after: "Our journey began with a simple belief: fashion can be both beautiful and environmentally responsible. Since 2020, we've been crafting sustainable clothing that doesn't compromise on style or our planet's future."
    }
  ];

  const features = [
    {
      title: "AI-Powered Writing",
      description: "Generate compelling about pages in seconds",
      icon: Sparkles
    },
    {
      title: "Industry-Specific Templates",
      description: "Pre-optimized templates for your business type",
      icon: Layout
    },
    {
      title: "Brand Voice Matching",
      description: "Maintain consistent tone and messaging",
      icon: MessageSquare
    },
    {
      title: "SEO Optimization",
      description: "Built-in SEO best practices for better visibility",
      icon: Search
    },
    {
      title: "Multiple Variations",
      description: "Generate different versions to choose from",
      icon: Shuffle
    },
    {
      title: "Quick Iterations",
      description: "Refine and perfect in minutes",
      icon: RefreshCw
    }
  ];

  const benefits = [
    {
      title: "Save Time",
      description: "Create professional about pages in minutes instead of hours",
      stats: "85% faster content creation"
    },
    {
      title: "Increase Engagement",
      description: "Capture visitor attention with compelling storytelling",
      stats: "63% higher engagement"
    },
    {
      title: "Boost Conversions",
      description: "Turn visitors into customers with persuasive content",
      stats: "47% conversion improvement"
    }
  ];

  const faqs = [
    {
      question: "How does the About Page Writer work?",
      answer: "Our AI analyzes your business information and industry to generate professionally crafted about pages. It combines best practices in storytelling, SEO, and conversion optimization to create compelling content that resonates with your audience."
    },
    {
      question: "Can I customize the generated content?",
      answer: "Absolutely! While our AI creates the initial content, you have full control to edit, refine, and personalize the text to perfectly match your brand voice and requirements."
    },
    {
      question: "Is the content unique and SEO-friendly?",
      answer: "Yes! Each generated about page is unique and includes SEO best practices like proper heading structure, keyword optimization, and engaging meta descriptions."
    },
    {
      question: "How many versions can I generate?",
      answer: "Our tool allows you to generate multiple versions of your about page. You can create different variations and choose the one that best fits your needs."
    }
  ];

  return (
    <>
      <SEOHead />
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Create Compelling</span>
          <span className="block text-purple-600">About Pages Instantly</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Transform your business story into an engaging about page that converts visitors into customers using AI-powered content generation.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Start Creating Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Examples
          </a>
        </div>
      </div>

      {/* Template Showcase */}
      <div id="demo" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">See it in Action</h2>
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex justify-center gap-4 mb-8">
            {templates.map((template, index) => (
              <button
                key={index}
                onClick={() => setSelectedTemplate(index)}
                className={`px-4 py-2 rounded-lg transition-all ${
                  selectedTemplate === index
                    ? 'bg-purple-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {template.title}
              </button>
            ))}
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="font-semibold text-gray-700">Input</h3>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-600">{templates[selectedTemplate].before}</p>
              </div>
            </div>
            <div className="space-y-4">
              <h3 className="font-semibold text-gray-700">Generated About Page</h3>
              <div className="bg-purple-50 p-4 rounded-lg">
                <p className="text-gray-600">{templates[selectedTemplate].after}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Powerful Features</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-gradient-to-r from-purple-100 to-pink-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Transform Your About Page</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
                <p className="text-gray-600 mb-4">{benefit.description}</p>
                <div className="bg-purple-50 p-3 rounded-lg">
                  <span className="text-purple-600 font-bold">{benefit.stats}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Create Your Perfect About Page Today</h2>
          <p className="text-xl mb-8">Join thousands of businesses telling their story better</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Get Started Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutPageWriterLanding;