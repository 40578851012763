import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import ContentRewriterDemo from '../../components/ContentRewriterDemo';
import {
  Wand2,
  Sparkles,
  RefreshCw,
  FileText,
  Languages,
  Target,
  Brain,
  Settings,
  Shuffle,
  Check,
  PenLine,
  MessageSquare,
  Lightbulb,
  ArrowRight,
  Star,
  Globe,
  ChevronDown,
  Zap,
  LayoutGrid,
  SplitSquareHorizontal
} from 'lucide-react';

const ContentRewriterLanding = () => {
  const [scrollY, setScrollY] = useState(0);
  const [activeFeature, setActiveFeature] = useState(0);
  const [openFaq, setOpenFaq] = useState(null);
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const features = [
    {
      title: "Neural Rewriting",
      description: "Advanced AI understands context and meaning",
      icon: Brain,
      color: "from-violet-500 to-purple-600"
    },
    {
      title: "Style Adaptation",
      description: "Maintain brand voice while transforming content",
      icon: PenLine,
      color: "from-blue-500 to-cyan-600"
    },
    {
      title: "Multilingual",
      description: "Rewrite content in 50+ languages",
      icon: Globe,
      color: "from-emerald-500 to-teal-600"
    }
  ];

  return (
    <>
      <SEOHead 
        title="AI Content Rewriter | Transform Content Instantly | ContentRewrite.ai"
        description="Transform your content with AI-powered rewriting. Get unique, engaging content while maintaining your message. Try our advanced content rewriter free."
        keywords="content rewriter, AI content rewriter, article rewriter, content spinner, paraphrasing tool, text rewriter"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center justify-center">
          {/* Animated background gradient */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/20 via-transparent to-transparent animate-pulse" />
          
          {/* Animated particles */}
          <div className="absolute inset-0 overflow-hidden">
            {Array.from({ length: 20 }).map((_, i) => (
              <div
                key={i}
                className="absolute bg-purple-500/20 rounded-full animate-float"
                style={{
                  width: Math.random() * 50 + 10,
                  height: Math.random() * 50 + 10,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDuration: `${Math.random() * 3 + 2}s`,
                  animationDelay: `${Math.random() * 2}s`
                }}
              />
            ))}
          </div>

          <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
            <div className="mb-8">
              <span className="inline-flex items-center px-4 py-2 rounded-full bg-purple-500/20 text-purple-300 backdrop-blur-sm">
                <Sparkles className="h-4 w-4 mr-2" />
                <span>AI-Powered Content Transformation</span>
              </span>
            </div>

            <h1 className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
              Rewrite Content
              <span className="block">With AI Magic</span>
            </h1>

            <p className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Transform your content into engaging, unique variations while preserving your message and tone
            </p>

            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full font-medium hover:from-purple-600 hover:to-pink-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Rewriting Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => {
                  document.querySelector('#demo')?.scrollIntoView({
                    behavior: 'smooth'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See It In Action
              </button>
            </div>

            {/* Trust indicators */}
            <div className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">10M+</div>
                <div className="text-gray-400">Articles Rewritten</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">99%</div>
                <div className="text-gray-400">Unique Content</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Grid */}
        <section className="py-24 bg-black relative">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <h2 className="text-4xl md:text-5xl font-bold text-center mb-16">
              Powered by Advanced AI
            </h2>

            <div className="grid md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="group bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className={`w-16 h-16 rounded-full bg-gradient-to-r ${feature.color} flex items-center justify-center mb-6`}>
                    <feature.icon className="h-8 w-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="demo" className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
  <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
  
  <div className="max-w-7xl mx-auto px-4 relative z-10">
    <div className="text-center mb-16">
      <h2 className="text-4xl md:text-5xl font-bold mb-4">
        Experience the
        <span className="text-purple-400"> Transformation</span>
      </h2>
      <p className="text-xl text-gray-400">
        Watch your content transform in real-time
      </p>
    </div>

    <ContentRewriterDemo />
  </div>
</section>
        {/* Content Types Showcase */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 animate-pulse" />
            <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-purple-500/50 to-transparent" />
            <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-pink-500/50 to-transparent" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Transform Any Content Type
              </h2>
              <p className="text-xl text-gray-400">
                Optimized for various content formats
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Blog Posts",
                  description: "Refresh and repurpose blog content while maintaining SEO value",
                  icon: FileText,
                  features: ["LSI keyword optimization", "Structure preservation", "Tone matching"]
                },
                {
                  title: "Marketing Copy",
                  description: "Create compelling variations of your marketing materials",
                  icon: Target,
                  features: ["Persuasive language", "Call-to-action enhancement", "Brand voice consistency"]
                },
                {
                  title: "Product Descriptions",
                  description: "Generate unique descriptions for similar products",
                  icon: LayoutGrid,
                  features: ["Feature highlighting", "SEO optimization", "Conversion focus"]
                }
                // ... More content types
              ].map((type, index) => (
                <div
                  key={index}
                  className="group bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-8 rounded-2xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <type.icon className="h-8 w-8 text-purple-400 mb-4" />
                  <h3 className="text-xl font-bold mb-4">{type.title}</h3>
                  <p className="text-gray-400 mb-6">{type.description}</p>
                  <ul className="space-y-2">
                    {type.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <Check className="h-4 w-4 text-purple-400 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
{/* Process Flow */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="absolute inset-0 bg-[radial-gradient-at-70%-30%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Three Steps to Perfect Content
              </h2>
              <p className="text-xl text-gray-400">
                Transform your content in minutes
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  step: 1,
                  title: "Input Content",
                  description: "Paste your text or upload a document",
                  icon: FileText,
                  color: "from-purple-500 to-purple-600"
                },
                {
                  step: 2,
                  title: "Choose Settings",
                  description: "Select tone, style, and other preferences",
                  icon: Settings,
                  color: "from-pink-500 to-purple-500"
                },
                {
                  step: 3,
                  title: "Generate & Refine",
                  description: "Get multiple variations and perfect your content",
                  icon: Wand2,
                  color: "from-blue-500 to-purple-500"
                }
              ].map((step, index) => (
                <div
                  key={index}
                  className="relative group"
                >
                  {/* Connector Lines */}
                  {index < 2 && (
                    <div className="hidden md:block absolute top-1/2 -right-4 w-8 h-px bg-gradient-to-r from-purple-500 to-transparent" />
                  )}
                  
                  <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 hover:transform hover:scale-105 transition-all duration-300">
                    <div className={`w-16 h-16 rounded-full bg-gradient-to-r ${step.color} flex items-center justify-center mb-6 group-hover:scale-110 transition-transform`}>
                      <step.icon className="h-8 w-8 text-white" />
                    </div>
                    <h3 className="text-xl font-bold mb-4">
                      <span className="text-purple-400 mr-2">{step.step}.</span>
                      {step.title}
                    </h3>
                    <p className="text-gray-400">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Advanced Capabilities */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Advanced AI Capabilities
              </h2>
              <p className="text-xl text-gray-400">
                Powered by cutting-edge language models
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Context Understanding",
                  description: "AI that truly understands the meaning behind your words",
                  icon: Brain,
                  features: ["Semantic analysis", "Context preservation", "Intent recognition"]
                },
                {
                  title: "Style Matching",
                  description: "Maintains your unique voice and brand style",
                  icon: PenLine,
                  features: ["Tone detection", "Brand voice preservation", "Style consistency"]
                },
                {
                  title: "Multilingual Support",
                  description: "Rewrite content across multiple languages",
                  icon: Globe,
                  features: ["50+ languages", "Cultural adaptation", "Local idioms"]
                },
                {
                  title: "SEO Intelligence",
                  description: "Optimize content for search engines while rewriting",
                  icon: Target,
                  features: ["Keyword preservation", "LSI optimization", "Structure maintenance"]
                },
                {
                  title: "Smart Variations",
                  description: "Generate multiple unique versions of your content",
                  icon: Shuffle,
                  features: ["Multiple angles", "Unique perspectives", "Creative alternatives"]
                },
                {
                  title: "Quality Assurance",
                  description: "Ensure perfect grammar and readability",
                  icon: Check,
                  features: ["Grammar check", "Readability score", "Clarity optimization"]
                }
              ].map((capability, index) => (
                <div
                  key={index}
                  className="group bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-8 rounded-2xl hover:transform hover:scale-105 transition-all duration-300"
                >
                  <div className="flex items-center mb-6">
                    <capability.icon className="h-8 w-8 text-purple-400 mr-3" />
                    <h3 className="text-xl font-bold">{capability.title}</h3>
                  </div>
                  <p className="text-gray-400 mb-6">{capability.description}</p>
                  <ul className="space-y-3">
                    {capability.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-purple-400 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
{/* Testimonials */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Loved by Content Teams
              </h2>
              <p className="text-xl text-gray-400">
                See what our users have achieved
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  quote: "The AI understands context like no other tool. Our content team's productivity has increased by 300%.",
                  author: "Sarah Chen",
                  role: "Content Director",
                  company: "TechGrowth Inc",
                  rating: 5
                },
                {
                  quote: "Perfect for scaling our content across multiple languages while maintaining brand voice.",
                  author: "Michael Rodriguez",
                  role: "Marketing Lead",
                  company: "Global Solutions",
                  rating: 5
                },
                {
                  quote: "The quality of rewritten content is exceptional. It's like having an expert writer on demand.",
                  author: "Emma Wilson",
                  role: "SEO Specialist",
                  company: "Digital First",
                  rating: 5
                }
              ].map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-2xl"
                >
                  <div className="flex mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                    ))}
                  </div>
                  <p className="text-gray-300 mb-6">"{testimonial.quote}"</p>
                  <div>
                    <div className="font-semibold">{testimonial.author}</div>
                    <div className="text-sm text-gray-400">{testimonial.role}</div>
                    <div className="text-sm text-gray-400">{testimonial.company}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Integration Showcase */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Seamless Integrations
              </h2>
              <p className="text-xl text-gray-400">
                Works with your favorite tools
              </p>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                "WordPress", "Google Docs", "Shopify", "WooCommerce",
                "Medium", "Ghost", "Webflow", "Custom API"
              ].map((platform, index) => (
                <div
                  key={index}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 flex items-center justify-center hover:bg-gray-800/70 transition-all"
                >
                  <span className="text-gray-300">{platform}</span>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-24 bg-gradient-to-b from-black to-gray-900">
          <div className="max-w-3xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Common Questions
              </h2>
            </div>

            <div className="space-y-4">
              {[
                {
                  question: "How does the AI ensure unique content?",
                  answer: "Our AI analyzes the core meaning of your text and generates completely new sentences while preserving your message. Each rewrite is unique and passes plagiarism checks."
                },
                {
                  question: "Can it maintain SEO rankings?",
                  answer: "Yes! The AI preserves important keywords and semantic relationships while rewriting, ensuring your SEO value is maintained or improved."
                },
                {
                  question: "What languages are supported?",
                  answer: "We support 50+ languages including English, Spanish, French, German, Chinese, Japanese, and more. The AI understands linguistic nuances for each language."
                }
              ].map((faq, index) => (
                <div
                  key={index}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => setOpenFaq(openFaq === index ? null : index)}
                    className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-800/70 transition-all"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown className={`h-5 w-5 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`} />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-6">
                      <p className="text-gray-400">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/20 via-transparent to-transparent" />
          </div>
          
          <div className="max-w-7xl mx-auto px-4 relative z-10 text-center">
            <h2 className="text-4xl md:text-6xl font-bold mb-6">
              Transform Your Content Today
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Join thousands of content creators using AI to scale their content production
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full font-medium hover:from-purple-600 hover:to-pink-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Rewriting Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/pricing"
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                View Pricing Plans
              </Link>
            </div>
            <p className="mt-6 text-gray-400">
              No credit card required • 14-day free trial • Cancel anytime
            </p>
          </div>
        </section>

        {/* Animated Return to Top Button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed bottom-8 right-8 p-4 bg-purple-500/80 hover:bg-purple-500 backdrop-blur-sm rounded-full shadow-lg transform transition-all hover:scale-110"
        >
          <ArrowRight className="h-6 w-6 transform rotate-[-90deg]" />
        </button>

        <style jsx="true">{`
          @keyframes float {
            0%, 100% {
              transform: translateY(0) rotate(0deg);
            }
            50% {
              transform: translateY(-20px) rotate(10deg);
            }
          }
          .animate-float {
            animation: float 10s ease-in-out infinite;
          }
        `}</style>
      </div>
    </>
  );
};

export default ContentRewriterLanding;