import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEOHead from '../../components/SEOHead';
import ROICalculator from '../../components/ROICalculator';

import {
  PenTool,
  Sparkles,
  Target,
  BarChart,
  Share2,
  Layers,
  ArrowRight,
  Star,
  Zap,
  ChevronDown,
  Globe,
  MessageCircle,
ShoppingBag,
  Code,
  Home,
  DollarSign,
  Heart,
  GraduationCap,
  CheckCircle,
  Copy,
  RefreshCcw,
  Brain,
  Wand2,
  Eye,
  Settings,
  Rocket,
  Award,
  LineChart,
  Users,
  Lock,
  Cpu
} from 'lucide-react';

const AdCopyGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [currentExample, setCurrentExample] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Cycle through examples automatically
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentExample((prev) => (prev + 1) % adExamples.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const adExamples = [
    {
      platform: "Facebook",
      before: "Our shoes are comfortable and affordable.",
      after: "Step into comfort without breaking the bank! 👟 Experience cloud-like comfort with our revolutionary design. Starting at $59.99 - Your feet will thank you! 🌟",
      metrics: {
        engagement: "+156%",
        conversion: "+89%",
        ctr: "+123%"
      }
    },
    {
      platform: "Google Ads",
      before: "Web design services - professional and reliable",
      after: "Transform Your Online Presence | Award-Winning Web Design | 50% Off First Month",
      metrics: {
        engagement: "+178%",
        conversion: "+95%",
        ctr: "+145%"
      }
    },
    {
      platform: "LinkedIn",
      before: "Looking for a project management tool?",
      after: "Boost Team Productivity by 300% | AI-Powered Project Management | Used by Fortune 500",
      metrics: {
        engagement: "+134%",
        conversion: "+76%",
        ctr: "+112%"
      }
    }
  ];

  const features = [
    {
      title: "Neural Ad Analysis",
      description: "Our AI analyzes millions of successful ads to understand what drives engagement",
      icon: Brain,
      color: "blue"
    },
    {
      title: "Multi-Platform Magic",
      description: "Automatically optimize for each platform's unique requirements and audience",
      icon: Share2,
      color: "purple"
    },
    {
      title: "Real-Time Optimization",
      description: "Get instant suggestions and improvements as you write",
      icon: Zap,
      color: "yellow"
    }
  ];

  const platforms = [
    {
      name: "Facebook Ads",
      metrics: ["12M+ ads analyzed", "89% better engagement"],
      icon: MessageCircle
    },
    {
      name: "Google Ads",
      metrics: ["15M+ keywords optimized", "167% higher CTR"],
      icon: Target
    },
    {
      name: "LinkedIn Ads",
      metrics: ["5M+ B2B campaigns", "145% more conversions"],
      icon: Users
    },
    {
      name: "Instagram Ads",
      metrics: ["8M+ creatives enhanced", "234% better reach"],
      icon: Eye
    }
  ];

  return (
    <>
      <SEOHead 
        title="AI Ad Copy Generator | Create High-Converting Ads Instantly"
        description="Transform your advertising with AI-powered ad copy generation. Create engaging, high-converting ads for multiple platforms in seconds. Try free today!"
        keywords="ad copy generator, AI advertising, ad copy writer, PPC ads, social media ads, Google ads generator"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section with Animated Background */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/20 via-transparent to-transparent" />
          
          {/* Animated particles */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-purple-500/20 rounded-full"
                style={{
                  width: Math.random() * 50 + 10,
                  height: Math.random() * 50 + 10,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            ))}
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              AI-Powered
              <span className="block">Ad Creation</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Create high-converting ad copy for any platform in seconds with the power of advanced AI
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/register" 
                className="px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full font-medium hover:from-purple-600 hover:to-pink-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Creating Free
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => {
                  document.querySelector('#demo')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                See Examples
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">100M+</div>
                <div className="text-gray-400">Ads Generated</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">145%</div>
                <div className="text-gray-400">Avg. CTR Increase</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-purple-400 mb-2">4.9/5</div>
                <div className="text-gray-400">User Rating</div>
              </div>
            </motion.div>
          </motion.div>
        </section>

        {/* Live Demo Section */}
        <section id="demo" className="py-24 bg-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                See the
                <span className="text-purple-400"> Magic</span>
              </h2>
              <p className="text-xl text-gray-400">
                Watch your ad copy transform from basic to brilliant
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8"
            >
              <div className="mb-8 flex justify-center gap-4">
                {adExamples.map((example, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentExample(index)}
                    className={`px-4 py-2 rounded-lg transition-all ${
                      currentExample === index
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    {example.platform}
                  </button>
                ))}
              </div>

              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-300">Original Copy</h3>
                  <div className="bg-gray-900/50 p-6 rounded-xl">
                    <p className="text-gray-400">{adExamples[currentExample].before}</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <h3 className="font-semibold text-gray-300">AI-Enhanced Copy</h3>
                  <div className="bg-purple-900/20 p-6 rounded-xl">
                    <p className="text-purple-200">{adExamples[currentExample].after}</p>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="bg-gray-800/50 p-4 rounded-lg">
                      <div className="text-green-400 font-bold">
                        {adExamples[currentExample].metrics.engagement}
                      </div>
                      <div className="text-sm text-gray-400">Engagement</div>
                    </div>
                    <div className="bg-gray-800/50 p-4 rounded-lg">
                      <div className="text-green-400 font-bold">
                        {adExamples[currentExample].metrics.conversion}
                      </div>
                      <div className="text-sm text-gray-400">Conversion</div>
                    </div>
                    <div className="bg-gray-800/50 p-4 rounded-lg">
                      <div className="text-green-400 font-bold">
                        {adExamples[currentExample].metrics.ctr}
                      </div>
                      <div className="text-sm text-gray-400">CTR</div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
{/* AI Features Showcase */}
<section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Powered by Advanced AI
              </h2>
              <p className="text-xl text-gray-400">
                Next-generation technology for breakthrough ad performance
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-2xl group hover:bg-gray-800/70 transition-all duration-300"
                >
                  <div className={`w-16 h-16 rounded-full bg-${feature.color}-500/20 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform`}>
                    <feature.icon className={`h-8 w-8 text-${feature.color}-400`} />
                  </div>
                  <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Platform Integration */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Multi-Platform Mastery
              </h2>
              <p className="text-xl text-gray-400">
                Optimize ads across all major platforms with a single click
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {platforms.map((platform, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm p-6 rounded-xl hover:transform hover:scale-105 transition-all"
                >
                  <platform.icon className="h-12 w-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-bold mb-4">{platform.name}</h3>
                  <div className="space-y-2">
                    {platform.metrics.map((metric, i) => (
                      <div key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-purple-400 mr-2" />
                        {metric}
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Success Stories */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Success Stories
              </h2>
              <p className="text-xl text-gray-400">
                See how companies are transforming their ad performance
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  company: "TechGrowth",
                  industry: "SaaS",
                  result: "892% ROI increase",
                  quote: "The AI suggestions are incredibly accurate. Our ad performance skyrocketed within weeks.",
                  author: "Sarah Chen",
                  role: "Marketing Director"
                },
                {
                  company: "EcoStore",
                  industry: "E-commerce",
                  result: "3.5x conversion rate",
                  quote: "Finally, an AI that understands our brand voice while optimizing for conversions.",
                  author: "Michael Ross",
                  role: "Digital Marketing Lead"
                },
                {
                  company: "ProServices",
                  industry: "B2B Services",
                  result: "67% lower CPA",
                  quote: "Game-changing technology for our B2B campaigns. The results speak for themselves.",
                  author: "Emma Watson",
                  role: "PPC Manager"
                }
              ].map((story, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-8 rounded-2xl group hover:transform hover:scale-105 transition-all"
                >
                  <div className="flex items-center justify-between mb-6">
                    <div>
                      <h3 className="text-xl font-bold">{story.company}</h3>
                      <p className="text-gray-400">{story.industry}</p>
                    </div>
                    <div className="bg-green-400/20 text-green-400 px-4 py-2 rounded-full text-sm">
                      {story.result}
                    </div>
                  </div>
                  <blockquote className="text-gray-300 mb-6">
                    "{story.quote}"
                  </blockquote>
                  <div className="border-t border-gray-700 pt-4">
                    <div className="font-medium">{story.author}</div>
                    <div className="text-sm text-gray-400">{story.role}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Advanced Capabilities */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_30%,_var(--tw-gradient-stops))] from-purple-500/20 via-transparent to-transparent" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Advanced Capabilities
              </h2>
              <p className="text-xl text-gray-400">
                Everything you need for breakthrough ad performance
              </p>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Smart A/B Testing",
                  description: "Automatically generate optimal variations for testing",
                  icon: RefreshCcw,
                  metrics: ["45% better performance", "Automated optimization"]
                },
                {
                  title: "Competitor Analysis",
                  description: "Learn from successful ads in your industry",
                  icon: Target,
                  metrics: ["Industry benchmarks", "Competitive insights"]
                },
                {
                  title: "Brand Voice AI",
                  description: "Maintain consistent brand tone across all ads",
                  icon: MessageCircle,
                  metrics: ["Voice matching", "Style consistency"]
                },
                {
                  title: "Performance Predictions",
                  description: "AI-powered performance forecasting",
                  icon: LineChart,
                  metrics: ["92% accuracy", "Real-time predictions"]
                },
                {
                  title: "Audience Targeting",
                  description: "Generate copy for specific audience segments",
                  icon: Users,
                  metrics: ["Demographic targeting", "Persona matching"]
                },
                {
                  title: "Campaign Integration",
                  description: "Seamlessly integrate with ad platforms",
                  icon: Share2,
                  metrics: ["One-click publish", "Multi-platform sync"]
                }
              ].map((capability, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-2xl hover:bg-gray-800/70 transition-all group"
                >
                  <capability.icon className="h-12 w-12 text-purple-400 mb-6 group-hover:scale-110 transition-transform" />
                  <h3 className="text-xl font-bold mb-4">{capability.title}</h3>
                  <p className="text-gray-400 mb-6">{capability.description}</p>
                  <div className="space-y-2">
                    {capability.metrics.map((metric, i) => (
                      <div key={i} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-purple-400 mr-2" />
                        {metric}
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
{/* ROI Calculator */}
<section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
  <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent" />
  
  <div className="max-w-7xl mx-auto px-4 relative z-10">
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="text-center mb-16"
    >
      <h2 className="text-4xl md:text-5xl font-bold mb-4">
        Calculate Your ROI
      </h2>
      <p className="text-xl text-gray-400">
        See your potential returns with AI-powered ad copy
      </p>
    </motion.div>

    <ROICalculator />
  </div>
</section>

        {/* Industry Solutions */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 animate-pulse" />
          </div>

          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Solutions by Industry
              </h2>
              <p className="text-xl text-gray-400">
                Tailored ad copy generation for your specific needs
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  industry: "E-commerce",
                  icon: ShoppingBag,
                  features: [
                    "Product-focused copy",
                    "Seasonal campaign templates",
                    "Dynamic pricing integration"
                  ]
                },
                {
                  industry: "SaaS",
                  icon: Code,
                  features: [
                    "Technical to simple copy",
                    "Feature benefit writing",
                    "B2B optimization"
                  ]
                },
                {
                  industry: "Real Estate",
                  icon: Home,
                  features: [
                    "Property highlight copy",
                    "Location-based targeting",
                    "Urgency creation"
                  ]
                },
                {
                  industry: "Finance",
                  icon: DollarSign,
                  features: [
                    "Compliance-aware copy",
                    "Trust-building language",
                    "Service comparison"
                  ]
                },
                {
                  industry: "Healthcare",
                  icon: Heart,
                  features: [
                    "HIPAA-compliant copy",
                    "Empathetic messaging",
                    "Service education"
                  ]
                },
                {
                  industry: "Education",
                  icon: GraduationCap,
                  features: [
                    "Course promotion",
                    "Student testimonials",
                    "Value proposition"
                  ]
                }
              ].map((industry, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="group bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-2xl p-8 hover:transform hover:scale-105 transition-all"
                >
                  <industry.icon className="h-12 w-12 text-purple-400 mb-6 group-hover:scale-110 transition-transform" />
                  <h3 className="text-xl font-bold mb-4">{industry.industry}</h3>
                  <ul className="space-y-3">
                    {industry.features.map((feature, i) => (
                      <li key={i} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-400 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-300">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
          <div className="max-w-4xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                Common Questions
              </h2>
              <p className="text-xl text-gray-400">
                Everything you need to know about our AI ad copy generator
              </p>
            </motion.div>

            <div className="space-y-4">
              {[
                {
                  question: "How does the AI generate relevant ad copy?",
                  answer: "Our AI analyzes millions of successful ads and your specific parameters to create highly converting copy. It considers your industry, target audience, platform requirements, and brand voice to generate optimized content."
                },
                {
                  question: "Can I maintain my brand voice across different platforms?",
                  answer: "Yes! Our AI learns your brand's unique voice and style, ensuring consistency while optimizing for each platform's specific requirements and audience expectations."
                },
                {
                  question: "Which ad platforms are supported?",
                  answer: "We support all major advertising platforms including Facebook, Instagram, Google Ads, LinkedIn, Twitter, and more. The AI automatically formats copy to meet each platform's specifications."
                },
                {
                  question: "How accurate are the AI-generated suggestions?",
                  answer: "Our AI achieves a 95% accuracy rate in generating high-performing ad copy, verified through A/B testing against human-written ads. The system continuously learns and improves from performance data."
                },
                {
                  question: "Can I export my ads directly to ad platforms?",
                  answer: "Yes! We offer direct integration with major ad platforms, allowing you to push your optimized ad copy directly to your campaigns with a single click."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => toggleFaq(index)}
                    className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-800/70 transition-all"
                  >
                    <span className="font-medium text-lg">{faq.question}</span>
                    <ChevronDown
                      className={`h-5 w-5 transform transition-transform ${
                        openFaq === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {openFaq === index && (
                    <div className="px-6 pb-6">
                      <p className="text-gray-400">{faq.answer}</p>
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="py-24 bg-black relative overflow-hidden">
          <div className="absolute inset-0">
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl"
            />
          </div>
          
          <div className="max-w-7xl mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-4xl md:text-6xl font-bold mb-6">
                Ready to Transform Your Advertising?
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Join thousands of marketers creating high-converting ads with AI
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <Link
                  to="/register"
                  className="px-8 py-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full font-medium hover:from-purple-600 hover:to-pink-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                >
                  Start Creating Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </Link>
                <Link
                  to="/pricing"
                  className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                >
                  View Pricing
                </Link>
              </motion.div>
              <p className="mt-6 text-gray-400">
                No credit card required • Free 14-day trial • Cancel anytime
              </p>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdCopyGeneratorLanding;