// SpreadsheetHero.js
import React from 'react';
import { Upload, FileSpreadsheet } from 'lucide-react';

const SpreadsheetHero = ({ onFileSelect }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 flex flex-col items-center justify-center relative overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10px] opacity-50">
          {[...Array(6)].map((_, i) => (
            <div
              key={i}
              className="animate-float"
              style={{
                position: 'absolute',
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animation: `float ${10 + Math.random() * 10}s ease-in-out infinite`,
                animationDelay: `${Math.random() * 5}s`
              }}
            >
              <FileSpreadsheet 
                size={48 + Math.random() * 24}
                className="text-purple-200 dark:text-purple-900 opacity-30"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-400 dark:to-blue-400">
          AI-Powered Spreadsheet
        </h1>
        
        <p className="text-lg md:text-xl mb-12 text-gray-600 dark:text-gray-300">
          Transform your data management with intelligent automation and advanced analytics
        </p>

        <label className="inline-flex items-center px-6 py-3 rounded-lg bg-purple-600 hover:bg-purple-700 text-white font-medium cursor-pointer transition-colors duration-200 group">
          <Upload className="w-5 h-5 mr-2 transition-transform group-hover:-translate-y-1" />
          Import Spreadsheet
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>

        {/* Features */}
        <div className="grid md:grid-cols-3 gap-8 mt-16">
          <div className="p-6 rounded-xl bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm">
            <h3 className="font-semibold mb-2 text-gray-900 dark:text-white">Smart Analysis</h3>
            <p className="text-gray-600 dark:text-gray-400">AI-powered insights and automated data processing</p>
          </div>
          <div className="p-6 rounded-xl bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm">
            <h3 className="font-semibold mb-2 text-gray-900 dark:text-white">Easy Collaboration</h3>
            <p className="text-gray-600 dark:text-gray-400">Work together seamlessly with real-time updates</p>
          </div>
          <div className="p-6 rounded-xl bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm">
            <h3 className="font-semibold mb-2 text-gray-900 dark:text-white">Advanced Features</h3>
            <p className="text-gray-600 dark:text-gray-400">Professional spreadsheet tools at your fingertips</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpreadsheetHero;