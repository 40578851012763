import React from 'react';

const Alert = React.forwardRef(({ className = '', variant = 'default', children, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={`relative w-full rounded-lg border p-4 
      bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 
      ${className}`}
    {...props}
  >
    {children}
  </div>
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <h5
    ref={ref}
    className={`mb-1 font-medium leading-none tracking-tight ${className}`}
    {...props}
  >
    {children}
  </h5>
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-sm text-gray-600 dark:text-gray-300 ${className}`}
    {...props}
  >
    {children}
  </div>
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };

export default Alert;