import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { BarChart2, Info, Loader2, ArrowRight, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';

const SentenceLengthAnalyzer = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const analyzeText = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const analysis = analyzeSentences(text);
      setResult(analysis);
      setIsAnalyzing(false);
    }, 1000);
  }, [text]);

  const analyzeSentences = (text) => {
    if (!text.trim()) return null;

    // Split into sentences (handling common abbreviations)
    const sentences = text.split(/[.!?]+(?=\s+|$)/).filter(Boolean);
    const words = text.split(/\s+/).filter(Boolean);
    
    // Calculate sentence lengths
    const sentenceLengths = sentences.map(s => s.trim().split(/\s+/).filter(Boolean).length);
    
    // Calculate statistics
    const stats = {
      totalSentences: sentences.length,
      totalWords: words.length,
      averageLength: Math.round(sentenceLengths.reduce((a, b) => a + b, 0) / sentences.length),
      shortSentences: sentenceLengths.filter(l => l < 10).length,
      mediumSentences: sentenceLengths.filter(l => l >= 10 && l <= 20).length,
      longSentences: sentenceLengths.filter(l => l > 20).length,
      readability: calculateReadability(text),
      lengthDistribution: calculateDistribution(sentenceLengths),
      sentenceDetails: sentences.map((s, i) => ({
        text: s.trim(),
        length: sentenceLengths[i],
        complexity: assessComplexity(sentenceLengths[i])
      }))
    };

    return stats;
  };

  const calculateReadability = (text) => {
    const words = text.split(/\s+/).filter(Boolean).length;
    const sentences = text.split(/[.!?]+(?=\s+|$)/).filter(Boolean).length;
    const syllables = countSyllables(text);
    
    // Simplified Flesch Reading Ease score
    const score = 206.835 - 1.015 * (words / sentences) - 84.6 * (syllables / words);
    return Math.min(100, Math.max(0, Math.round(score)));
  };

  const countSyllables = (text) => {
    return text.toLowerCase()
      .replace(/[^a-z]/g, '')
      .replace(/[^aeiouy]*[aeiouy]+/g, 'a')
      .length;
  };

  const calculateDistribution = (lengths) => {
    const distribution = {};
    lengths.forEach(length => {
      const bracket = Math.floor(length / 5) * 5;
      distribution[bracket] = (distribution[bracket] || 0) + 1;
    });
    return distribution;
  };

  const assessComplexity = (length) => {
    if (length < 10) return { level: 'Simple', color: 'text-green-400' };
    if (length <= 20) return { level: 'Moderate', color: 'text-yellow-400' };
    return { level: 'Complex', color: 'text-red-400' };
  };

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        Ideal sentence length varies by content type. Aim for a mix of lengths to maintain reader engagement.
        Generally, 15-20 words per sentence is considered optimal for readability.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to analyze sentence lengths..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeText}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <BarChart2 className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="text-sm text-gray-400">Average Length</div>
              <div className="text-2xl font-bold">{result.averageLength} words</div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="text-sm text-gray-400">Readability Score</div>
              <div className="text-2xl font-bold">{result.readability}/100</div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="text-sm text-gray-400">Total Sentences</div>
              <div className="text-2xl font-bold">{result.totalSentences}</div>
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="font-medium">Sentence Analysis:</h4>
            <div className="space-y-2">
              {result.sentenceDetails.map((sentence, index) => (
                <div
                  key={index}
                  className="p-3 bg-gray-800 rounded-lg flex items-start justify-between"
                >
                  <div className="flex-1 pr-4">
                    <p className="text-sm">{sentence.text}</p>
                  </div>
                  <div className={`text-sm ${sentence.complexity.color} whitespace-nowrap`}>
                    {sentence.length} words - {sentence.complexity.level}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <InfoMessage />
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Perfect Your Content?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to advanced content optimization tools, AI-powered writing suggestions, and real-time readability analysis. Start creating more engaging content today with 1000 free points!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                <FileText className="mr-2 h-5 w-5" />
                Claim Your Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SentenceLengthAnalyzer;