import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { Bot, Info, Loader2, ArrowRight, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';

const AIDetectionTool = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  

  // AI writing patterns
  const aiPatterns = {
    // Overly formal consecutive phrases
    
      formalPhrases: [
        // Academic/Professional Structure
        'it is important to note that',
        'it should be emphasized that',
        'it is worth mentioning that',
        'it must be acknowledged that',
        'it is crucial to understand that',
        'it is essential to recognize that',
        'it is imperative to consider that',
        'it is necessary to highlight that',
        'it is significant to observe that',
        'it bears mentioning that',
'realm',
        'it merits consideration that',
        'it warrants attention that',
        'it becomes apparent that',
        'it remains evident that',
    
        // Professional Analysis
        'upon careful analysis',
        'through detailed examination',
        'based on thorough research',
        'following comprehensive review',
        'after extensive investigation',
        'upon further consideration',
        'through systematic evaluation',
        'based on critical assessment',
        'following detailed analysis',
        'after comprehensive examination',
        'upon thorough investigation',
        'through meticulous review',
        'based on careful consideration',
        'following systematic assessment',
        'after detailed evaluation',
    
        // Complex Transitions
        'in light of these considerations',
        'with regard to these matters',
        'in consideration of these factors',
        'taking into account these aspects',
        'in view of these circumstances',
        'with respect to these elements',
        'in relation to these components',
        'concerning these parameters',
        'regarding these variables',
        'pertaining to these aspects',
        'in reference to these factors',
        'with attention to these details',
        'in response to these conditions',
        'considering these circumstances',
        'addressing these considerations',
    
        // Formal Conclusions
        'in conclusion it can be stated',
        'therefore it may be deduced',
        'consequently it becomes evident',
        'thus it can be concluded',
        'hence it is apparent',
        'as a result it follows that',
        'accordingly it is clear',
        'subsequently it emerges that',
        'consequently it appears that',
        'therefore it is evident that',
        'thus it may be inferred',
        'hence it can be determined',
        'as such it becomes clear',
        'thereby it is demonstrated',
        'wherefore it is shown',
    
        // Complex Prepositions
        'with respect to the aforementioned',
        'in accordance with the preceding',
        'pursuant to the above',
        'with reference to the stated',
        'in compliance with the noted',
        'in alignment with the discussed',
        'in conformity with the mentioned',
        'with adherence to the outlined',
        'in keeping with the described',
        'in line with the specified',
        'consistent with the indicated',
        'in concert with the presented',
        'in harmony with the detailed',
        'in conjunction with the expressed',
        'in agreement with the stated',
    
        // Elaborate Connectors
        'moreover it should be noted',
        'furthermore it is evident',
        'additionally it appears',
        'in addition it becomes clear',
        'likewise it is apparent',
        'similarly it can be observed',
        'correspondingly it emerges',
        'analogously it is shown',
        'equivalently it is demonstrated',
        'comparably it is revealed',
        'by the same token it follows',
        'in the same vein it appears',
        'along similar lines it seems',
        'in parallel fashion it becomes',
        'in comparable manner it shows',
    
        // Formal Emphasis
        'particularly noteworthy is the fact',
        'especially significant is the aspect',
        'notably important is the element',
        'markedly relevant is the factor',
        'distinctly crucial is the component',
        'substantially significant is the point',
        'considerably important is the matter',
        'remarkably notable is the issue',
        'exceptionally relevant is the topic',
        'particularly significant is the detail',
        'especially noteworthy is the feature',
        'notably crucial is the aspect',
        'markedly important is the element',
        'distinctly significant is the factor',
        'substantially crucial is the component',
    
        // Complex Causation
        'as a consequence of this development',
        'resulting from this circumstance',
        'stemming from this situation',
        'arising from this condition',
        'emerging from this context',
        'deriving from this scenario',
        'originating from this state',
        'proceeding from this case',
        'emanating from this instance',
        'flowing from this occurrence',
        'resulting from this phenomenon',
        'arising out of this development',
        'stemming out of this situation',
        'emerging out of this context',
        'deriving out of this circumstance',
    
        // Formal Clarification
        'to clarify this matter further',
        'to elucidate this point additionally',
        'to explicate this aspect more fully',
        'to elaborate on this topic further',
        'to expound upon this subject more',
        'to delineate this issue clearly',
        'to articulate this concept precisely',
        'to illuminate this matter thoroughly',
        'to specify this point explicitly',
        'to detail this aspect comprehensively',
        'to outline this topic systematically',
        'to define this matter accurately',
        'to describe this point extensively',
        'to explain this aspect thoroughly',
        'to clarify this issue completely',
    
        // Academic Process
        'through the process of analysis',
        'by means of evaluation',
        'via the method of assessment',
        'through the approach of examination',
        'by way of investigation',
        'via the procedure of review',
        'through the course of study',
        'by means of research',
        'via the process of inquiry',
        'through the method of exploration',
        'by way of observation',
        'via the approach of scrutiny',
        'through the procedure of analysis',
        'by means of examination',
        'via the course of evaluation',
        
        
            // Time-based Transitions
            'in the contemporary context',
            'in the present circumstances',
            'at the current juncture',
            'during this period of analysis',
            'throughout the observed timeframe',
            'in the modern context',
            'at this point in time',
            'during the period under review',
            'throughout the duration examined',
            'in the present scenario',
            'at the moment of consideration',
            'during this phase of study',
            'throughout the temporal scope',
            'in the existing framework',
            'at the present stage',
        
            // Comparative Analysis
            'when compared to previous findings',
            'in contrast to earlier results',
            'relative to prior observations',
            'as opposed to existing data',
            'in comparison with established norms',
            'contrary to previous assumptions',
            'in relation to baseline measurements',
            'as distinguished from prior cases',
            'in contradistinction to earlier work',
            'relative to established benchmarks',
            'in contrast with accepted standards',
            'as compared to historical data',
            'in relation to previous metrics',
            'contrary to established patterns',
            'as distinguished from baseline data',
        
            // Methodological Descriptions
            'employing a systematic approach',
            'utilizing an analytical framework',
            'implementing a structured methodology',
            'applying a comprehensive strategy',
            'adopting a systematic procedure',
            'incorporating a methodical process',
            'following a structured protocol',
            'employing a rigorous methodology',
            'utilizing a systematic framework',
            'implementing an analytical approach',
            'applying a structured system',
            'adopting a comprehensive methodology',
            'incorporating a rigorous protocol',
            'following an analytical framework',
            'employing a methodical strategy',
        
            // Statistical References
            'statistical analysis indicates that',
            'quantitative data suggests that',
            'numerical evidence demonstrates that',
            'empirical findings reveal that',
            'statistical measures show that',
            'quantitative analysis proves that',
            'numerical data indicates that',
            'empirical evidence suggests that',
            'statistical results demonstrate that',
            'quantitative measures reveal that',
            'numerical analysis shows that',
            'empirical data proves that',
            'statistical evidence indicates that',
            'quantitative findings demonstrate that',
            'numerical measures suggest that',
        
            // Theoretical Frameworks
            'within the theoretical framework of',
            'in the conceptual context of',
            'under the theoretical paradigm of',
            'within the conceptual framework of',
            'in the theoretical context of',
            'under the conceptual paradigm of',
            'within the theoretical structure of',
            'in the conceptual model of',
            'under the theoretical construct of',
            'within the conceptual system of',
            'in the theoretical paradigm of',
            'under the conceptual framework of',
            'within the theoretical model of',
            'in the conceptual structure of',
            'under the theoretical system of',
        
            // Process Descriptions
            'through iterative development',
            'via continuous improvement',
            'by means of systematic progression',
            'through sequential advancement',
            'via methodical development',
            'by means of progressive enhancement',
            'through systematic evolution',
            'via sequential improvement',
            'by means of iterative advancement',
            'through methodical progression',
            'via systematic development',
            'by means of continuous evolution',
            'through progressive enhancement',
            'via iterative improvement',
            'by means of sequential development',
        
            // Analytical Conclusions
            'based on the foregoing analysis',
            'in view of the preceding discussion',
            'considering the aforementioned points',
            'given the previous examination',
            'following the above analysis',
            'based on the preceding investigation',
            'in view of the foregoing discussion',
            'considering the previous points',
            'given the aforementioned examination',
            'following the preceding analysis',
            'based on the above investigation',
            'in view of the previous discussion',
            'considering the foregoing points',
            'given the preceding examination',
            'following the aforementioned analysis',
        
            // Technical Terminology
            'in accordance with technical specifications',
            'pursuant to established protocols',
            'in compliance with standard procedures',
            'in accordance with methodological requirements',
            'pursuant to technical guidelines',
            'in compliance with established standards',
            'in accordance with protocol specifications',
            'pursuant to procedural requirements',
            'in compliance with technical standards',
            'in accordance with established guidelines',
            'pursuant to standard specifications',
            'in compliance with methodological protocols',
            'in accordance with technical requirements',
            'pursuant to established procedures',
            'in compliance with protocol guidelines',
        
            // Policy Implications
            'with respect to policy considerations',
            'regarding regulatory implications',
            'concerning policy frameworks',
            'with respect to regulatory considerations',
            'regarding policy implications',
            'concerning regulatory frameworks',
            'with respect to policy structures',
            'regarding regulatory considerations',
            'concerning policy implications',
            'with respect to regulatory frameworks',
            'regarding policy structures',
            'concerning regulatory considerations',
            'with respect to policy implications',
            'regarding regulatory frameworks',
            'concerning policy structures'
        // Would you like me to continue with more patterns?
      ],

    // Marketing buzzwords that AI loves
    marketingBuzz: [
      'state-of-the-art',
      'cutting-edge',
      'industry-leading',
      'revolutionary',
      'innovative solution',
      'seamless experience',
      'user-friendly interface',
      'robust platform',
      'advanced features',
      'comprehensive solution',
      'powerful tools',
      'extensive capabilities',
      'enhanced functionality',
      'optimal performance',
      'superior quality',
      'exceptional service',
      'unique offering',
      'outstanding results',
      'premium experience',
      'world-class',

          // Tech Innovation Buzzwords
          'cutting-edge technology',
          'next-generation solution',
          'revolutionary platform',
          'groundbreaking innovation',
          'game-changing technology',
          'disruptive solution',
          'innovative approach',
          'bleeding-edge technology',
          'transformative solution',
          'paradigm-shifting platform',
          'future-proof solution',
          'state-of-the-art technology',
          'next-level innovation',
          'advanced technology stack',
          'pioneering solution',
      
          // Performance Descriptors
          'seamless integration',
          'optimal performance',
          'unparalleled efficiency',
          'maximum productivity',
          'enhanced capabilities',
          'superior functionality',
          'peak performance',
          'streamlined operations',
          'optimized workflow',
          'accelerated results',
          'improved efficiency',
          'enhanced productivity',
          'maximized potential',
          'streamlined process',
          'optimal efficiency',
      
          // Quality Indicators
          'best-in-class',
          'industry-leading',
          'top-tier solution',
          'premium quality',
          'enterprise-grade',
          'world-class service',
          'superior quality',
          'professional-grade',
          'top-of-the-line',
          'industry-standard',
          'best-of-breed',
          'high-caliber solution',
          'premium-grade',
          'top-quality service',
          'superior-class',
      
          // User Experience
          'user-friendly interface',
          'intuitive design',
          'seamless experience',
          'frictionless workflow',
          'enhanced usability',
          'smooth operation',
          'simplified interface',
          'streamlined experience',
          'user-centric design',
          'effortless navigation',
          'intuitive workflow',
          'enhanced interface',
          'seamless operation',
          'streamlined design',
          'simplified experience',
      
          // Business Impact
          'revenue-generating',
          'profit-maximizing',
          'cost-effective solution',
          'value-adding service',
          'ROI-driven approach',
          'business-enhancing',
          'profit-optimizing',
          'revenue-boosting',
          'cost-efficient solution',
          'value-oriented service',
          'ROI-focused approach',
          'business-optimizing',
          'profit-driving',
          'revenue-maximizing',
          'cost-saving solution',
      
          // Scale Descriptors
          'enterprise-scale',
          'industrial-strength',
          'commercial-grade',
          'production-ready',
          'scalable solution',
          'enterprise-ready',
          'industrial-grade',
          'commercial-strength',
          'production-grade',
          'scalable platform',
          'enterprise-level',
          'industrial-class',
          'commercial-class',
          'production-class',
          'scalable system',
      
          // Customer Focus
          'customer-centric',
          'client-focused',
          'user-oriented',
          'customer-driven',
          'client-centric approach',
          'user-focused solution',
          'customer-oriented service',
          'client-driven platform',
          'user-centric system',
          'customer-focused approach',
          'client-oriented solution',
          'user-driven service',
          'customer-centered platform',
          'client-focused system',
          'user-oriented approach',
      
          // Market Position
          'market-leading',
          'industry-standard',
          'sector-defining',
          'market-dominant',
          'industry-leading position',
          'sector-leading solution',
          'market-defining platform',
          'industry-dominant service',
          'sector-standard system',
          'market-standard approach',
          'industry-defining position',
          'sector-dominant solution',
          'market-leading platform',
          'industry-standard service',
          'sector-leading system',
      
          // Solution Characteristics
          'robust platform',
          'comprehensive solution',
          'integrated system',
          'versatile platform',
          'flexible solution',
          'adaptable system',
          'robust framework',
          'comprehensive platform',
          'integrated solution',
          'versatile system',
          'flexible platform',
          'adaptable solution',
          'robust system',
          'comprehensive framework',
          'integrated platform',
      
          // Time-to-Market
          'rapid deployment',
          'quick implementation',
          'fast integration',
          'speedy rollout',
          'swift deployment',
          'rapid implementation',
          'quick integration',
          'fast rollout',
          'speedy deployment',
          'swift implementation',
          'rapid integration',
          'quick rollout',
          'fast deployment',
          'speedy implementation',
          'swift integration',

              'digital-first approach',
              'digital transformation journey',
              'digital revolution',
              'digital ecosystem',
              'digital maturity',
              'digital enablement',
              'digital acceleration',
              'digital innovation',
              'digital evolution',
              'digital disruption',
              'digital roadmap',
              'digital strategy',
              'digital footprint',
              'digital presence',
              'digital landscape',
          
              // Cloud Computing
              'cloud-native solution',
              'cloud-enabled platform',
              'cloud infrastructure',
              'cloud optimization',
              'cloud scalability',
              'cloud architecture',
              'cloud ecosystem',
              'cloud transformation',
              'cloud migration',
              'cloud integration',
              'cloud deployment',
              'cloud security',
              'cloud efficiency',
              'cloud strategy',
              'cloud innovation',
          
              // AI/ML Marketing
              'machine learning powered',
              'AI-driven solution',
              'intelligent automation',
              'smart analytics',
              'cognitive computing',
              'predictive intelligence',
              'automated insights',
              'neural processing',
              'deep learning enabled',
              'AI enhancement',
              'machine intelligence',
              'cognitive automation',
              'smart algorithms',
              'intelligent processing',
              'AI optimization',
          
              // Analytics and Data
    
              'actionable analytics',
              'predictive analysis',
              'real-time analytics',
              'advanced analytics',
              'data visualization',
              'business intelligence',
              'data insights',
              'analytical framework',
              'metrics-driven',
              'data-centric approach',
              'analytical insights',
              'predictive modeling',
              'data analytics',
              'insight generation',
          
              // Security/Compliance
              'enterprise security',
              'compliance-ready',
              'security-first approach',
              'regulatory compliance',
              'data protection',
              'secure infrastructure',
              'privacy-focused',
              'security framework',
              'compliant solution',
              'secure platform',
              'protected environment',
              'security measures',
              'compliance standards',
              'secure architecture',
              'protection mechanisms',
          
              // Mobile/App Marketing
              'mobile-first design',
              'app optimization',
              'cross-platform capability',
              'mobile experience',
              'app performance',
              'mobile integration',
              'app ecosystem',
              'mobile solution',
              'app efficiency',
              'mobile strategy',
              'app innovation',
              'mobile optimization',
              'app architecture',
              'mobile framework',
              'app enhancement',
          
              // Social Media Marketing
    
          
              // E-commerce
              'conversion optimization',
              'shopping experience',
              'purchase journey',
              'cart optimization',
              'checkout process',
              'retail solution',
              'commerce platform',
              'shopping innovation',
              'sales optimization',
              'retail experience',
              'commerce solution',
              'shopping platform',
              'sales innovation',
              'retail optimization',
              'commerce experience',

              // Growth Marketing
              'growth hacking',
              'scalable growth',
              'sustainable growth',
              'growth strategy',
              'growth optimization',
              'growth acceleration',
              'growth potential',
              'growth framework',
              'growth metrics',
              'growth innovation',
              'growth engine',
              'growth platform',
              'growth solution',
              'growth approach',
              'growth ecosystem',
          
              // Customer Success
              'success metrics',
              'customer satisfaction',
              'success framework',
              'customer experience',
              'success strategy',
              'customer journey',
              'success platform',
              'customer engagement',
              'success optimization',
              'customer success',
              'success innovation',
              'customer optimization',
              'success solution',
              'customer enhancement',
              'success ecosystem'
            ],

    // Structured patterns
    structuredPatterns: [
      /^\d+\.\s[A-Z]/m,  // Numbered sections
      /^Step \d+:/m,     // Step-by-step
      /^•\s/m,          // Bullet points
      /^-\s/m,          // Dashed lists
      /^First,|^Secondly,|^Finally,/m,  // Sequential markers
      /^Introduction:|^Conclusion:/m,    // Formal sections

          /^(\d+\.|[A-Z]\.|[a-z]\.|[IVXLCDM]+\.)\s[A-Z]/m,  // Matches different numbering styles
          /^Step\s+\d+[:.]/m,  // Step-based instructions
          /^Phase\s+\d+[:.]/m,  // Phase-based descriptions
          /^Stage\s+\d+[:.]/m,  // Stage-based content
          /^Level\s+\d+[:.]/m,  // Level-based organization
          /^Part\s+\d+[:.]/m,  // Part-based structure
          /^Section\s+\d+[:.]/m,  // Section-based division
          /^Chapter\s+\d+[:.]/m,  // Chapter-based organization
          /^Module\s+\d+[:.]/m,  // Module-based structure
          /^Unit\s+\d+[:.]/m,  // Unit-based division
      
   
              // Section headers and formatting
              /^[A-Z][a-zA-Z\s]+:$/m,  // Matches patterns like "Key Features:" or "Final Considerations:"
              /^\d+\.\s[A-Z]/m,        // Numbered sections
              /^Step\s+\d+[:.]/i,      // Step markers with number
              /^Phase\s+\d+[:.]/i,     // Phase markers with number
              /^Part\s+\d+[:.]/i,      // Part markers with number
              /^Chapter\s+\d+[:.]/i,   // Chapter markers
              /^Section\s+\d+[:.]/i,   // Section markers
              
              // List patterns
              /^[•●■○□▪▫◦∙◘]/m,       // Various bullet point styles
              /^[-–—]/m,               // Different dash styles for lists
              /^\*\s/m,                // Asterisk lists
              /^→\s/m,                 // Arrow lists
              /^\+\s/m,                // Plus sign lists
              /^>\s/m,                 // Greater than lists
              
              // Enumeration patterns
              /^(First|1st)[,:]|^(Second|2nd)[,:]|^(Third|3rd)[,:]/m,  // Ordinal markers
              /^(Firstly|Secondly|Thirdly|Finally)[,:]|^(Moreover|Furthermore)[,:]/m,  // Sequential markers
              /^(Initially|Subsequently|Ultimately)[,:]/m,  // Time-based sequence markers
              
              // Common AI formatting structures
              /^Benefits:/m,
              /^Advantages:/m,
              /^Disadvantages:/m,
              /^Challenges:/m,
              /^Solutions:/m,
              /^Overview:/m,
              /^Summary:/m,
              /^Conclusion:/m,
              /^Introduction:/m,
              /^Background:/m,
              /^Context:/m,
              /^Analysis:/m,
              /^Results:/m,
              /^Findings:/m,
              /^Discussion:/m,
              /^Implementation:/m,
              /^Methodology:/m,
              /^Recommendations:/m,
              
              // Complex header patterns
              /^[\d.]+\s+[A-Z]/m,      // Matches "1.1 Topic" or "2.3.1 Subtopic"
              /^[A-Z][\w\s-]+\(\d+\)/m,  // Matches "Topic (1)" pattern
              /^[IVX]+\.\s+[A-Z]/m,    // Roman numeral sections
              
              // Feature/Component lists
              /^Features:/m,
              /^Components:/m,
              /^Elements:/m,
              /^Factors:/m,
              /^Variables:/m,
              /^Parameters:/m,
              /^Requirements:/m,
              /^Specifications:/m,
              /^Characteristics:/m,
              
              // Technical documentation patterns
              /^Input:/m,
              /^Output:/m,
              /^Returns:/m,
              /^Parameters:/m,
              /^Example:/m,
              /^Usage:/m,
              /^Note:/m,
              /^Warning:/m,
              /^Important:/m,
              /^Tip:/m,
              
              // Process/Flow patterns
              /^Before:/m,
              /^After:/m,
              /^During:/m,
              /^Next:/m,
              /^Then:/m,
              /^Finally:/m,
              
              // Comparison patterns
              /^Pros:/m,
              /^Cons:/m,
              /^Before:/m,
              /^After:/m,
              /^Versus:/m,
              /^Compared to:/m,
              
              // Advanced structural patterns
              /^(?=.*:)(?=.*[A-Z])[^a-z\n]{3,50}$/m,  // Complex headers with colons
              /^\([a-z]\)\s/m,         // Alphabetical list markers
              /^\{\d+\}\s/m,           // Curly brace numbering
              /^\[\d+\]\s/m,           // Square bracket numbering
              /^<\d+>\s/m,             // Angle bracket numbering
              
              // Nested list patterns
              /^\s{2,4}[-•]/m,         // Indented sublists
              /^\s{2,4}\d+\./m,        // Indented numbered lists
              /^\s{2,4}[a-z]\)/m,      // Indented alphabetical lists
              
              // Technical section markers
              /^(?:Definition|Example|Note|Warning|Important|Tip|Remember|Key Point):\s/m,
          // List Patterns
          /^[•●○◆◇■□▪▫⚫⚪]\s/m,  // Various bullet point styles
          /^[-–—]\s/m,  // Different dash styles
          /^[\*\+]\s/m,  // Asterisk and plus markers
          /^>>\s/m,  // Arrow-style bullets
          /^⮚\s/m,  // Special arrow markers
          /^❖\s/m,  // Diamond bullets
          /^✦\s/m,  // Star bullets
          /^✓\s/m,  // Checkmark bullets
          /^⚡\s/m,  // Lightning bullets
          /^⬗\s/m,  // Triangle bullets
      
          // Sequential Markers
          /^(First|Firstly|First of all)[,:](\s|$)/im,
          /^(Second|Secondly|Second of all)[,:](\s|$)/im,
          /^(Third|Thirdly|Third of all)[,:](\s|$)/im,
          /^(Fourth|Fourthly|Fourth of all)[,:](\s|$)/im,
          /^(Fifth|Fifthly|Fifth of all)[,:](\s|$)/im,
          /^(Sixth|Sixthly)[,:](\s|$)/im,
          /^(Seventh|Seventhly)[,:](\s|$)/im,
          /^(Eighth|Eighthly)[,:](\s|$)/im,
          /^(Ninth|Ninthly)[,:](\s|$)/im,
          /^(Tenth|Tenthly)[,:](\s|$)/im,
          /^(Finally|Lastly|Last but not least)[,:](\s|$)/im,
      
          // Section Headers
          /^Introduction[:.]$/im,
          /^Background[:.]$/im,
          /^Methodology[:.]$/im,
          /^Results[:.]$/im,
          /^Discussion[:.]$/im,
          /^Conclusion[:.]$/im,
          /^Summary[:.]$/im,
          /^Overview[:.]$/im,
          /^Analysis[:.]$/im,
          /^Recommendations[:.]$/im,
      
          // Time-based Structure
          /^Before[:.](\s|$)/im,
          /^During[:.](\s|$)/im,
          /^After[:.](\s|$)/im,
          /^Meanwhile[:.](\s|$)/im,
          /^Subsequently[:.](\s|$)/im,
          /^Initially[:.](\s|$)/im,
          /^Eventually[:.](\s|$)/im,
          /^Previously[:.](\s|$)/im,
          /^Afterwards[:.](\s|$)/im,
          /^Simultaneously[:.](\s|$)/im,
      
          // Process Structure
          /^Planning Phase[:.](\s|$)/im,
          /^Implementation Phase[:.](\s|$)/im,
          /^Evaluation Phase[:.](\s|$)/im,
          /^Development Stage[:.](\s|$)/im,
          /^Testing Stage[:.](\s|$)/im,
          /^Review Stage[:.](\s|$)/im,
          /^Assessment Stage[:.](\s|$)/im,
          /^Monitoring Stage[:.](\s|$)/im,
          /^Control Stage[:.](\s|$)/im,
          /^Maintenance Stage[:.](\s|$)/im,
      
          // Comparative Structure
          /^Advantages[:.](\s|$)/im,
          /^Disadvantages[:.](\s|$)/im,
          /^Pros[:.](\s|$)/im,
          /^Cons[:.](\s|$)/im,
          /^Benefits[:.](\s|$)/im,
          /^Drawbacks[:.](\s|$)/im,
          /^Strengths[:.](\s|$)/im,
          /^Weaknesses[:.](\s|$)/im,
          /^Opportunities[:.](\s|$)/im,
          /^Threats[:.](\s|$)/im,
      
          // Analytical Structure
          /^Problem[:.](\s|$)/im,
          /^Solution[:.](\s|$)/im,
          /^Cause[:.](\s|$)/im,
          /^Effect[:.](\s|$)/im,
          /^Challenge[:.](\s|$)/im,
          /^Approach[:.](\s|$)/im,
          /^Impact[:.](\s|$)/im,
          /^Outcome[:.](\s|$)/im,
          /^Strategy[:.](\s|$)/im,
          /^Tactic[:.](\s|$)/im,

              /^Purpose[:.](\s|$)/im,
              /^Scope[:.](\s|$)/im,
              /^Definitions[:.](\s|$)/im,
              /^Procedures[:.](\s|$)/im,
              /^Prerequisites[:.](\s|$)/im,
              /^Requirements[:.](\s|$)/im,
              /^Dependencies[:.](\s|$)/im,
              /^Specifications[:.](\s|$)/im,
              /^Guidelines[:.](\s|$)/im,
              /^References[:.](\s|$)/im,
          
              // Technical Writing
              /^Version\s+\d+\.\d+/m,
              /^Release\s+Notes[:.](\s|$)/im,
              /^Technical\s+Details[:.](\s|$)/im,
              /^System\s+Requirements[:.](\s|$)/im,
              /^Configuration[:.](\s|$)/im,
              /^Installation[:.](\s|$)/im,
              /^Setup[:.](\s|$)/im,
              /^Deployment[:.](\s|$)/im,
              /^Maintenance[:.](\s|$)/im,
              /^Troubleshooting[:.](\s|$)/im,
          
              // Academic Formatting
              /^Abstract[:.](\s|$)/im,
              /^Literature\s+Review[:.](\s|$)/im,
              /^Research\s+Questions[:.](\s|$)/im,
              /^Hypothesis[:.](\s|$)/im,
              /^Methods[:.](\s|$)/im,
              /^Findings[:.](\s|$)/im,
              /^Data\s+Analysis[:.](\s|$)/im,
              /^Discussion\s+Points[:.](\s|$)/im,
              /^Future\s+Research[:.](\s|$)/im,
              /^Bibliography[:.](\s|$)/im,
          
              // Report Structures
              /^Executive\s+Summary[:.](\s|$)/im,
              /^Project\s+Overview[:.](\s|$)/im,
              /^Key\s+Findings[:.](\s|$)/im,
              /^Objectives[:.](\s|$)/im,
              /^Success\s+Metrics[:.](\s|$)/im,
              /^Risk\s+Assessment[:.](\s|$)/im,
              /^Budget\s+Analysis[:.](\s|$)/im,
              /^Timeline[:.](\s|$)/im,
              /^Milestones[:.](\s|$)/im,
              /^Recommendations[:.](\s|$)/im,
          
              // Presentation Formats
              /^Agenda[:.](\s|$)/im,
              /^Key\s+Points[:.](\s|$)/im,
              /^Main\s+Topics[:.](\s|$)/im,
              /^Highlights[:.](\s|$)/im,
              /^Action\s+Items[:.](\s|$)/im,
              /^Next\s+Steps[:.](\s|$)/im,
              /^Q&A[:.](\s|$)/im,
              /^Summary\s+Points[:.](\s|$)/im,
              /^Follow[\-\s]up[:.](\s|$)/im,
              /^Closing\s+Remarks[:.](\s|$)/im,
          
              // Tutorial Patterns
              /^Prerequisite\s+Knowledge[:.](\s|$)/im,
              /^Learning\s+Objectives[:.](\s|$)/im,
              /^Key\s+Concepts[:.](\s|$)/im,
              /^Practice\s+Exercise[:.](\s|$)/im,
              /^Review\s+Questions[:.](\s|$)/im,
              /^Common\s+Mistakes[:.](\s|$)/im,
              /^Tips\s+and\s+Tricks[:.](\s|$)/im,
              /^Additional\s+Resources[:.](\s|$)/im,
              /^Further\s+Reading[:.](\s|$)/im,
              /^Assessment[:.](\s|$)/im,
          
              // Guide Structures
              /^Quick\s+Start[:.](\s|$)/im,
              /^Basic\s+Usage[:.](\s|$)/im,
              /^Advanced\s+Features[:.](\s|$)/im,
              /^Best\s+Practices[:.](\s|$)/im,
              /^Frequently\s+Asked\s+Questions[:.](\s|$)/im,
              /^Known\s+Issues[:.](\s|$)/im,
              /^Workarounds[:.](\s|$)/im,
              /^Example\s+Usage[:.](\s|$)/im,
              /^Common\s+Scenarios[:.](\s|$)/im,
              /^Troubleshooting\s+Guide[:.](\s|$)/im,
          
              // Analysis Framework
              /^Problem\s+Statement[:.](\s|$)/im,
              /^Current\s+State[:.](\s|$)/im,
              /^Desired\s+State[:.](\s|$)/im,
              /^Gap\s+Analysis[:.](\s|$)/im,
              /^Root\s+Cause[:.](\s|$)/im,
              /^Impact\s+Assessment[:.](\s|$)/im,
              /^Cost\-Benefit\s+Analysis[:.](\s|$)/im,
              /^Implementation\s+Plan[:.](\s|$)/im,
              /^Success\s+Criteria[:.](\s|$)/im,
              /^Evaluation\s+Metrics[:.](\s|$)/im,
          
              // Complex List Patterns
              /^\(\d+\)\s[A-Z]/m,  // (1) Style
              /^\([a-z]\)\s[A-Z]/m,  // (a) Style
              /^\([A-Z]\)\s[A-Z]/m,  // (A) Style
              /^\{\d+\}\s[A-Z]/m,  // {1} Style
              /^\[\d+\]\s[A-Z]/m,  // [1] Style
              /^<<\d+>>\s[A-Z]/m,  // <<1>> Style
              /^§\d+\s[A-Z]/m,     // §1 Style
              /^¶\d+\s[A-Z]/m,     // ¶1 Style
              /^⁂\d+\s[A-Z]/m,     // ⁂1 Style
              /^‡\d+\s[A-Z]/m      // ‡1 Style
            ],



              roboticTransitions: [
                // Cause-Effect Transitions
                'as a direct result of',
                'stemming from this',
                'arising from this',
                'this leads to',
      
                'this gives rise to',
                'this brings about',
                'this generates',
                'this produces',
                'this yields',
                'this engenders',
                'this precipitates',
                'this triggers',
                'this initiates',
                'this catalyzes',
                'owing to this',
                'due to these factors',
                'on account of this',
                'this culminates in',
                'this eventuates in',
            
                // Evidence-Based Transitions
                'the evidence suggests',
                'according to the data',
                'based on observations',
                'the findings indicate',
                'research demonstrates',
   
                'analysis shows',
                'statistics confirm',
                'metrics indicate',
                'measurements show',
                'results demonstrate',
                'data points to',
                'empirical evidence suggests',
                'quantitative analysis reveals',
                'qualitative research indicates',
                'experimental results show',
                'survey data indicates',
                'case studies demonstrate',
                'historical data suggests',
                'documented evidence shows',
            
                // Emphasis Transitions
                'indeed',
                'certainly',
                'undoubtedly',
                'unquestionably',
                'without doubt',
                'decidedly',
                'unmistakably',
                'indisputably',
                'inevitably',
                'undeniably',
                'incontrovertibly',
                'manifestly',
                'patently',
                'evidently',
                'obviously',
                'clearly',
                'remarkably',
                'notably',
                'significantly',
                'markedly',
            
                // Process-Oriented Transitions
                'in the first phase',
                'during the next stage',
                'in the following step',
                'moving to the next phase',
                'proceeding to stage two',
                'advancing to the next level',
                'transitioning into phase three',
                'entering the final stage',
                'in the preliminary phase',
                'during the intermediate stage',
                'in the developmental phase',
                'moving through the process',
                'proceeding with implementation',
                'advancing toward completion',
                'transitioning between stages',
                'progressing through phases',
                'evolving through stages',
                'developing through steps',
                'advancing through tiers',
                'moving through sequences',
            
                // Qualification Transitions
                'to some extent',
                'up to a point',
                'within certain parameters',
                'under specific conditions',
                'with certain limitations',
                'to a certain degree',
                'in particular instances',
                'in specific cases',
                'under given circumstances',
                'within defined boundaries',
                'with notable exceptions',
                'subject to conditions',
                'barring certain factors',
                'excluding certain cases',
                'allowing for variations',
                'considering certain constraints',
                'acknowledging limitations',
                'recognizing boundaries',
                'accounting for variables',
                'factoring in conditions',
            
                // Analytical Transitions
                'upon closer examination',
                'through detailed analysis',
                'under careful scrutiny',
                'upon further investigation',
                'through systematic review',
                'under thorough evaluation',
                'upon critical assessment',
                'through comprehensive study',
                'under meticulous observation',
                'upon detailed inspection',
                'through careful consideration',
                'under precise examination',
                'upon methodical analysis',
                'through rigorous evaluation',
                'under systematic investigation',
                'upon analytical review',
                'through detailed assessment',
                'under comprehensive analysis',
                'upon thorough examination',
                'through careful evaluation',
    'first and foremost',
    'to begin with',
    'as a starting point',
    'moving forward',
    'proceeding onward',
    'transitioning to',
    'advancing to',
    'progressing to',
    'shifting our focus',
    'turning our attention',
    'in the next section',
    'subsequently',
    'following this',
    'thereafter',
    'hereinafter',

    // Logical Connectors
    'therefore it follows',
    'consequently',
    'as a result',
    'thus we can see',
    'hence it becomes',
    'accordingly',
    'in this manner',
    'as such',
    'it follows that',
    'for this reason',
    'on this basis',
    'in this regard',
    'on these grounds',
    'given these points',
    'under these circumstances',

    // Additive Transitions
    'furthermore',
    'moreover',
    'additionally',
    'in addition to this',
    'what is more',
    'adding to this',
    'on top of this',
    'coupled with',
    'along with this',
    'besides this',
    'equally important',
    'not to mention',
    'as well as',
    'together with',
    'in conjunction with',

    // Comparative Transitions
    'similarly',
    'in the same way',
    'by the same token',
    'in like manner',
    'correspondingly',
    'analogously',
    'equivalently',
    'in parallel',
    'by comparison',
    'in a similar fashion',
    'likewise',
    'in the same vein',
    'comparatively speaking',
    'drawing parallels',
    'following this pattern',

   

    // Summative Transitions
    'in conclusion',
    'to summarize',
    'to sum up',
    'in summary',
    'to conclude',
    'in closing',
    'ultimately',
    'finally',
    'in the final analysis',
    'on the whole',
    'altogether',
    'in essence',
    'to put it briefly',
    'in short',
    'in brief',

    // Explanatory Transitions
    'specifically',
    'in particular',

    'markedly',
  
    'expressly',
    'namely',

    'that is to say',
    'to put it another way',
    'to clarify',
    'to explain further',
    'to elaborate',

    // Temporal Transitions
    'meanwhile',
    'simultaneously',
    'concurrently',
    'at the same time',
    'during this period',
    'in the interim',
    'for the time being',
    'presently',
    'at this point',
    'at this juncture',
    'in the meantime',
    'in due course',
    'as time progresses',
    'throughout this period',

    // Illustrative Transitions
    'for instance',
    'for example',
    'to illustrate',
    'as an illustration',
    'as demonstrated by',
    'as evidenced by',
    'as shown by',
    'as exemplified by',
    'such as',
    'specifically speaking',
    'in particular',
    'case in point',
    'to demonstrate',
    'to exemplify',
    'as a case in point',

    // Conditional Transitions
    'in this case',
    'under these conditions',
    'in such circumstances',
    'given this scenario',
    'in this situation',
    'in this instance',
    'in this context',
    'under these circumstances',
    'in such cases',
    'in these instances',
    'when this occurs',
    'should this happen',
    'in the event that',
    'assuming this is true',
    'provided that this holds',
      'consequently',
      'thus',
      'hence',
      'in contrast',
      'similarly',
      'specifically',
      'subsequently',
      'conversely',
      'meanwhile',
      'essentially',
      'notably',
      'generally speaking',
      'in particular',
    ],



      impersonalConstructions: [
        // Time-related Constructions
        'it is presently observed that',
        'it is currently noted that',
        'it has historically been found that',
        'it was previously determined that',
        'it will subsequently be shown that',
        'it has traditionally been accepted that',
        'it is nowadays considered that',
        'it was formerly believed that',
        'it has recently been discovered that',
        'it is frequently observed that',
        'it was initially determined that',
        'it has gradually become apparent that',
        'it is periodically noted that',
        'it has consistently been shown that',
        'it was ultimately concluded that',
    
        // Process-related Constructions
        'it is being processed that',
        'it is being implemented that',
        'it is being developed that',
        'it is being established that',
        'it is being maintained that',
        'it is being integrated that',
        'it is being formulated that',
        'it is being structured that',
        'it is being organized that',
        'it is being arranged that',
        'it is being coordinated that',
        'it is being executed that',
        'it is being conducted that',
        'it is being performed that',
        'it is being managed that',
    
        // Decision-making Constructions
        'it has been decided that',
        'it was determined appropriate that',
        'it is concluded optimal that',
        'it has been resolved that',
        'it was agreed upon that',
        'it is considered beneficial that',
        'it has been established suitable that',
        'it was deemed necessary that',
        'it is found advantageous that',
        'it has been judged appropriate that',
        'it was selected optimal that',
        'it is chosen preferable that',
        'it has been opted that',
        'it was settled that',
        'it is determined favorable that',
    
        // Research-related Constructions
        'it has been researched that',
        'it was investigated that',
        'it is being studied that',
        'it has been examined that',
        'it was analyzed that',
        'it is being explored that',
        'it has been surveyed that',
        'it was assessed that',
        'it is being evaluated that',
        'it has been reviewed that',
        'it was scrutinized that',
        'it is being investigated that',
        'it has been probed that',
        'it was studied that',
        'it is being researched that',
    
        // Analysis-based Constructions
        'it can be analyzed that',
        'it must be interpreted that',
        'it should be assessed that',
        'it could be evaluated that',
        'it would be examined that',
        'it might be investigated that',
        'it shall be reviewed that',
        'it will be analyzed that',
        'it may be interpreted that',
        'it must be assessed that',
        'it should be evaluated that',
        'it could be examined that',
        'it would be investigated that',
        'it might be reviewed that',
        'it shall be analyzed that',
    
        // Outcome-related Constructions
        'it resulted in',
        'it led to the conclusion that',
        'it produced the outcome that',
        'it generated the result that',
        'it yielded the finding that',
        'it created the situation where',
        'it brought about the condition that',
        'it caused the effect that',
        'it induced the state where',
        'it prompted the circumstance that',
        'it effected the change where',
        'it manifested in the outcome that',
        'it culminated in the result that',
        'it terminated in the finding that',
        'it concluded with the determination that',
    
        // Development-related Constructions
        'it is in development that',
        'it is under construction that',
        'it is being formulated that',
        'it is in progress that',
        'it is under consideration that',
        'it is being designed that',
        'it is in formation that',
        'it is under preparation that',
        'it is being created that',
        'it is in production that',
        'it is under development that',
        'it is being constructed that',
        'it is in formulation that',
        'it is under progress that',
        'it is being considered that',
    
        // Implementation-related Constructions
        'it is to be implemented that',
        'it is to be executed that',
        'it is to be carried out that',
        'it is to be performed that',
        'it is to be conducted that',
        'it is to be accomplished that',
        'it is to be achieved that',
        'it is to be realized that',
        'it is to be fulfilled that',
        'it is to be completed that',
        'it is to be actioned that',
        'it is to be effected that',
        'it is to be instituted that',
        'it is to be established that',
        'it is to be enacted that',
        'it has been observed that',
        'it can be seen that',
        'it was determined that',
        'it has been found that',
        'it was discovered that',
        'it can be noted that',
        'it has been demonstrated that',
        'it was established that',
        'it can be determined that',
        'it has been shown that',
        'it was confirmed that',
        'it can be concluded that',
        'it has been verified that',
        'it was ascertained that',
        'it can be inferred that',
    
        // Necessity/Obligation
        'it is necessary to',
        'it is essential to',
        'it is required to',
        'it is imperative to',
        'it is crucial to',
        'it is vital to',
        'it is mandatory to',
        'it is important to',
        'it is fundamental to',
        'it is critical to',
        'it is indispensable to',
        'it is obligatory to',
        'it is compulsory to',
        'it is requisite to',
        'it is paramount to',
    
        // Suggestion/Recommendation
        'it is suggested that',
        'it is recommended that',
        'it is proposed that',
        'it is advised that',
        'it is indicated that',
        'it is implied that',
        'it is urged that',
        'it is advocated that',
        'it is proposed that',
        'it is endorsed that',
        'it is put forward that',
        'it is maintained that',
        'it is contended that',
        'it is posited that',
        'it is asserted that',
    
        // Possibility/Probability
        'it is possible that',
        'it is probable that',
        'it is likely that',
        'it is conceivable that',
        'it is feasible that',
        'it is plausible that',
        'it appears that',
        'it seems that',
        'it would appear that',
        'it would seem that',
        'it may be that',
        'it might be that',
        'it could be that',
        'it should be noted that',
        'it must be considered that',
    
        // Evidence/Observation
        'it is evident that',
        'it is apparent that',
        'it is obvious that',
        'it is clear that',
        'it is noticeable that',
        'it is observable that',
        'it is discernible that',
        'it is visible that',
        'it is manifest that',
        'it is perceivable that',
        'it is detectable that',
        'it is recognizable that',
        'it is distinguishable that',
        'it is identifiable that',
        'it is remarkable that',
    
        // Expectation/Anticipation
        'it is expected that',
        'it is anticipated that',
        'it is presumed that',
        'it is assumed that',
        'it is predicted that',
        'it is foreseen that',
        'it is projected that',
        'it is supposed that',
        'it is hypothesized that',
        'it is theorized that',
        'it is speculated that',
        'it is conjectured that',
        'it is postulated that',
        'it is envisioned that',
        'it is forecasted that',
    
        // Evaluation/Assessment
        'it is considered that',
        'it is deemed that',
        'it is judged that',
        'it is evaluated that',
        'it is assessed that',
        'it is estimated that',
        'it is measured that',
        'it is calculated that',
        'it is determined that',
        'it is appraised that',
        'it is rated that',
        'it is gauged that',
        'it is analyzed that',
        'it is reviewed that',
        'it is examined that',
    
        // Understanding/Comprehension
        'it is understood that',
        'it is comprehended that',
        'it is recognized that',
        'it is acknowledged that',
        'it is appreciated that',
        'it is known that',
        'it is realized that',
        'it is perceived that',
        'it is grasped that',
        'it is accepted that',
        'it is believed that',
        'it is conceived that',
        'it is interpreted that',
        'it is construed that',
        'it is discerned that',
    
        // Emphasis/Significance
        'it is emphasized that',
        'it is stressed that',
        'it is highlighted that',
        'it is underscored that',
        'it is accentuated that',
        'it is notable that',
        'it is significant that',
        'it is worthy of note that',
        'it is remarkable that',
        'it is of importance that',
        'it is of significance that',
        'it is of consequence that',
        'it is of relevance that',
        'it is of interest that',
        'it is of value that',
      'it should be noted',
      'it is important to',
      'it has been found',
      'it is suggested',
      'it is advised',
      'it is concluded',
      'it can be seen',
      'it is evident',
      'it is clear that',
      'it is worth noting',
      'it is crucial to',
      'it is necessary to',
      'it is beneficial to',
    ]
  };

  const detectAIPatterns = (text) => {
    if (!text.trim()) return {
      score: 0,
      patterns: [],
      highlightedParagraphs: [],
      wordCount: 0
    };

    const paragraphs = text.split(/\n/).filter(p => p.trim());
    const wordCount = text.split(/\s+/).filter(Boolean).length;
    let aiScore = 0;
    const detectedPatterns = [];

    // Check for formal consecutive phrases
    aiPatterns.formalPhrases.forEach(phrase => {
      if (text.toLowerCase().includes(phrase.toLowerCase())) {
        aiScore += 15;
        detectedPatterns.push({
          type: 'warning',
          text: `Formal AI phrase detected: "${phrase}"`
        });
      }
    });

    // Check for marketing buzzwords
    aiPatterns.marketingBuzz.forEach(word => {
      if (text.toLowerCase().includes(word.toLowerCase())) {
        aiScore += 10;
        detectedPatterns.push({
          type: 'warning',
          text: `Marketing buzzword detected: "${word}"`
        });
      }
    });

    // Check for structured patterns
    aiPatterns.structuredPatterns.forEach(pattern => {
      if (pattern.test(text)) {
        aiScore += 20;
        detectedPatterns.push({
          type: 'warning',
          text: 'Systematic/structured format detected (numbering, steps, bullets)'
        });
      }
    });

    // Check for robotic transitions
    aiPatterns.roboticTransitions.forEach(transition => {
      if (text.toLowerCase().includes(transition.toLowerCase())) {
        aiScore += 12;
        detectedPatterns.push({
          type: 'warning',
          text: `Robotic transition detected: "${transition}"`
        });
      }
    });

    // Check for impersonal constructions
    aiPatterns.impersonalConstructions.forEach(phrase => {
      if (text.toLowerCase().includes(phrase.toLowerCase())) {
        aiScore += 15;
        detectedPatterns.push({
          type: 'warning',
          text: `Impersonal construction detected: "${phrase}"`
        });
      }
    });

    // Check paragraph structures
    const highlightedParagraphs = paragraphs.map(paragraph => {
      let isAI = false;
      let aiPhraseCount = 0;

      // Check for multiple AI patterns in the same paragraph
      [...aiPatterns.formalPhrases, ...aiPatterns.marketingBuzz, ...aiPatterns.roboticTransitions]
        .forEach(phrase => {
          if (paragraph.toLowerCase().includes(phrase.toLowerCase())) {
            aiPhraseCount++;
          }
        });

      // If paragraph has multiple AI patterns, mark it
      isAI = aiPhraseCount >= 2;

      return {
        text: paragraph,
        isAI
      };
    });

    // Normalize score
    const normalizedScore = Math.min(Math.round(aiScore), 100);

    return {
      score: normalizedScore,
      patterns: detectedPatterns.slice(0, 5), // Show top 5 most relevant patterns
      highlightedParagraphs,
      wordCount
    };
  };

  const analyzeText = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const analysis = detectAIPatterns(text);
      setResult(analysis);
      setIsAnalyzing(false);
    }, 1500);
  }, [text]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        This analysis is based on common patterns found in AI-generated text. 
        Results are not definitive and should be used as a general guide only.
      </p>
    </div>
  ));
  
  InfoMessage.displayName = 'InfoMessage';

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to check for AI-generated content..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeText}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <Bot className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>AI Probability</span>
              <span>{result.score}%</span>
            </div>
            <div className="h-4 bg-gray-700 rounded-full overflow-hidden">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${result.score}%` }}
                className={`h-full rounded-full ${
                  result.score > 50 ? 'bg-red-500' : 'bg-green-500'
                }`}
              />
            </div>
          </div>

          
          <div className="space-y-4">
            <h4 className="font-medium">Content Analysis:</h4>
            {result.highlightedParagraphs.map((paragraph, index) => (
              <p
                key={index}
                className={`p-2 rounded ${
                  paragraph.isAI 
                    ? 'bg-red-500/10 border border-red-500/20' 
                    : ''
                }`}
              >
                {paragraph.text}
              </p>
            ))}
          </div>

          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              This analysis is based on common patterns found in AI-generated text. 
              Results are not definitive and should be used as a general guide only.
            </p>
          </div>
        </motion.div>
      )}
      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Bypass AI Detection?
              </h2>
              <p className="text-purple-100 mb-6">
                Unlock premium features like bypassing AI detection, writing human article, and real-time checking. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AIDetectionTool;