// src/components/PaymentForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Loader2, AlertCircle } from 'lucide-react';
import CoinPaymentsService from '../services/coinPaymentsService';

const PaymentForm = ({ plan, billingCycle = 'monthly' }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getPlanAmount = () => {
    return billingCycle === 'yearly' 
      ? plan.price.yearly 
      : plan.price.monthly;
  };

  const handlePayment = async () => {
    try {
      if (!user) {
        localStorage.setItem('selectedPlan', JSON.stringify({
          name: plan.name,
          price: getPlanAmount(),
          billingCycle
        }));
        navigate('/register');
        return;
      }

      setLoading(true);
      setError('');

      CoinPaymentsService.createPayment({
        planName: plan.name,
        amount: getPlanAmount(),
        billingCycle,
        email: user.email,
        userId: user._id // Make sure to use _id instead of id
      });

      // Keep button in loading state for a moment to show feedback
      setTimeout(() => setLoading(false), 1000);
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message || 'Failed to process payment');
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {error && (
        <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 rounded-lg flex items-center text-red-700 dark:text-red-400">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      <button
        onClick={handlePayment}
        disabled={loading}
        className={`w-full flex items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-lg ${
          plan.name === 'PRO'
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-white text-blue-600 border-blue-600 hover:bg-blue-50'
        } transition-colors ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {loading ? (
          <>
            <Loader2 className="animate-spin h-5 w-5 mr-2" />
            Processing...
          </>
        ) : (
          user ? `Subscribe ${billingCycle === 'yearly' ? 'Yearly' : 'Monthly'}` : 'Get Started'
        )}
      </button>
    </div>
  );
};

export default PaymentForm;