import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const calculateFleschKincaid = (text) => {
  if (!text) return 0;
  
  // Remove extra whitespace and split into sentences and words
  const cleanText = text.trim().replace(/\s+/g, ' ');
  const sentences = cleanText.split(/[.!?]+/).filter(Boolean);
  const words = cleanText.split(/\s+/).filter(Boolean);
  const syllables = words.reduce((count, word) => {
    return count + countSyllables(word);
  }, 0);

  if (words.length === 0 || sentences.length === 0) return 0;

  // Flesch-Kincaid formula
  const score = 206.835 - 1.015 * (words.length / sentences.length) - 84.6 * (syllables / words.length);
  return Math.min(Math.max(0, Math.round(score)), 100);
};

const countSyllables = (word) => {
  word = word.toLowerCase();
  if (word.length <= 3) return 1;

  // Remove common silent endings
  word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
  word = word.replace(/^y/, '');

  // Count vowel groups
  const syllableCount = word.match(/[aeiouy]{1,2}/g)?.length || 1;
  return syllableCount;
};

const calculateReadingTime = (text) => {
  const words = text.trim().split(/\s+/).filter(Boolean).length;
  const wordsPerMinute = 200;
  return Math.max(1, Math.ceil(words / wordsPerMinute));
};

const ReadabilityCalculator = () => {
  const [text, setText] = useState('');
  const [metrics, setMetrics] = useState({
    score: 0,
    readingTime: 0,
    characters: 0,
    words: 0,
    sentences: 0
  });

  useEffect(() => {
    const calculateMetrics = () => {
      const score = calculateFleschKincaid(text);
      const readingTime = calculateReadingTime(text);
      const characters = text.length;
      const words = text.trim().split(/\s+/).filter(Boolean).length;
      const sentences = text.split(/[.!?]+/).filter(Boolean).length;

      setMetrics({
        score,
        readingTime,
        characters,
        words,
        sentences
      });
    };

    calculateMetrics();
  }, [text]);

  const getScoreColor = (score) => {
    if (score >= 80) return 'text-green-400';
    if (score >= 60) return 'text-yellow-400';
    return 'text-red-400';
  };

  const getReadabilityLevel = (score) => {
    if (score >= 80) return 'Very Easy';
    if (score >= 70) return 'Easy';
    if (score >= 60) return 'Fairly Easy';
    if (score >= 50) return 'Standard';
    if (score >= 30) return 'Fairly Difficult';
    return 'Very Difficult';
  };

  return (
    <div className="bg-gray-800 rounded-2xl p-6 shadow-xl">
      <div className="mb-6">
        <label className="block text-gray-300 mb-2">Enter your text:</label>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="w-full h-32 px-4 py-2 bg-gray-700 text-white rounded-xl focus:ring-2 focus:ring-blue-500 resize-none"
          placeholder="Type or paste your text here..."
        />
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-gray-700/50 rounded-xl p-4">
          <div className={`text-2xl font-bold ${getScoreColor(metrics.score)}`}>
            {metrics.score}
          </div>
          <div className="text-sm text-gray-400">Readability Score</div>
          <div className="text-xs text-gray-500 mt-1">
            {getReadabilityLevel(metrics.score)}
          </div>
        </div>

        <div className="bg-gray-700/50 rounded-xl p-4">
          <div className="text-2xl font-bold text-blue-400">
            {metrics.readingTime} min
          </div>
          <div className="text-sm text-gray-400">Reading Time</div>
        </div>

        <div className="bg-gray-700/50 rounded-xl p-4">
          <div className="text-2xl font-bold text-purple-400">
            {metrics.words}
          </div>
          <div className="text-sm text-gray-400">Words</div>
        </div>

        <div className="bg-gray-700/50 rounded-xl p-4">
          <div className="text-2xl font-bold text-indigo-400">
            {metrics.sentences}
          </div>
          <div className="text-sm text-gray-400">Sentences</div>
        </div>
      </div>

      <Link 
        to="/register"
        className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-3 rounded-xl font-medium hover:from-blue-600 hover:to-purple-600 transition-all flex items-center justify-center group"
      >
        Improve This Text
        <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
      </Link>
    </div>
  );
};

export default ReadabilityCalculator;