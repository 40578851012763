import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { BookText, Link as LinkIcon, Copy, ArrowRight, Loader2, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const CitationGenerator = () => {
  const [formData, setFormData] = useState({
    title: '',
    authors: '',
    publicationDate: '',
    publisher: '',
    url: '',
    accessDate: new Date().toISOString().split('T')[0],
    sourceType: 'website'
  });

  const [citations, setCitations] = useState({
    apa: '',
    mla: '',
    chicago: ''
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [copiedStyle, setCopiedStyle] = useState('');

  const generateCitations = useCallback(() => {
    setIsGenerating(true);
    
    setTimeout(() => {
      const { title, authors, publicationDate, publisher, url, accessDate, sourceType } = formData;
      const authorList = authors.split(',').map(author => author.trim());
      
      // APA Citation
      const apaAuthors = authorList.map(author => {
        const names = author.split(' ');
        const lastName = names.pop();
        const initials = names.map(name => `${name[0]}.`).join(' ');
        return `${lastName}, ${initials}`;
      }).join(', ');
      
      const apaDate = new Date(publicationDate).getFullYear();
      const apaCitation = `${apaAuthors} (${apaDate}). ${title}. ${publisher}. ${url}`;
      
      // MLA Citation
      const mlaAuthors = authorList.map(author => {
        const names = author.split(' ');
        const lastName = names.pop();
        const firstName = names.join(' ');
        return `${lastName}, ${firstName}`;
      }).join(', ');
      
      const mlaDate = new Date(publicationDate).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      
      const mlaCitation = `${mlaAuthors}. "${title}." ${publisher}, ${mlaDate}, ${url}.`;
      
      // Chicago Citation
      const chicagoAuthors = authorList.map(author => {
        const names = author.split(' ');
        const lastName = names.pop();
        const firstName = names.join(' ');
        return `${lastName}, ${firstName}`;
      }).join(', ');
      
      const chicagoDate = new Date(publicationDate).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      
      const chicagoCitation = `${chicagoAuthors}. "${title}." ${publisher}, ${chicagoDate}. ${url}.`;
      
      setCitations({
        apa: apaCitation,
        mla: mlaCitation,
        chicago: chicagoCitation
      });
      
      setIsGenerating(false);
    }, 1000);
  }, [formData]);

  const copyToClipboard = (text, style) => {
    navigator.clipboard.writeText(text);
    setCopiedStyle(style);
    setTimeout(() => setCopiedStyle(''), 2000);
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Title"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Authors (separated by commas)"
              value={formData.authors}
              onChange={(e) => setFormData(prev => ({ ...prev, authors: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="date"
              value={formData.publicationDate}
              onChange={(e) => setFormData(prev => ({ ...prev, publicationDate: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Publisher"
              value={formData.publisher}
              onChange={(e) => setFormData(prev => ({ ...prev, publisher: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="url"
              placeholder="URL"
              value={formData.url}
              onChange={(e) => setFormData(prev => ({ ...prev, url: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={formData.sourceType}
              onChange={(e) => setFormData(prev => ({ ...prev, sourceType: e.target.value }))}
              className="w-full p-3 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="website">Website</option>
              <option value="article">Article</option>
              <option value="book">Book</option>
              <option value="journal">Journal</option>
            </select>
          </div>
        </div>
        
        <button
          onClick={generateCitations}
          disabled={!formData.title || !formData.authors || isGenerating}
          className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
        >
          {isGenerating ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Generating Citations...</span>
            </>
          ) : (
            <>
              <BookText className="h-4 w-4" />
              <span>Generate Citations</span>
            </>
          )}
        </button>
      </div>

      {citations.apa && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {Object.entries(citations).map(([style, citation]) => (
            <div key={style} className="bg-gray-800/50 border border-gray-700 rounded-xl p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium capitalize">{style.toUpperCase()} Format</h3>
                <button
                  onClick={() => copyToClipboard(citation, style)}
                  className="p-2 hover:bg-gray-700 rounded-lg transition-colors"
                >
                  {copiedStyle === style ? (
                    <span className="text-green-500 text-sm">Copied!</span>
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </button>
              </div>
              <p className="text-gray-300 text-sm">{citation}</p>
            </div>
          ))}
          
          <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
            <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              Always verify citations with your institution's specific requirements.
              Some details might need manual adjustment based on your style guide.
            </p>
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Need Professional Content Creation?
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to our AI-powered content generation tools, including academic writing, 
                research paper assistance, and automatic bibliography creation. Start with 1000 free 
                points today!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CitationGenerator;