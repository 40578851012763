import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Check, 
  Loader2, 
  Instagram, 
  Twitter, 
  Facebook, 
  Linkedin,
  RefreshCcw
} from 'lucide-react';

const SMLiveDemo = () => {
  const [selectedPlatform, setSelectedPlatform] = useState('instagram');
  const [topic, setTopic] = useState('');
  const [tone, setTone] = useState('professional');
  const [loading, setLoading] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');

  const platforms = {
    instagram: {
      icon: Instagram,
      name: "Instagram",
      placeholder: "e.g., New product launch, Behind the scenes..."
    },
    twitter: {
      icon: Twitter,
      name: "Twitter",
      placeholder: "e.g., Industry news, Quick tips..."
    },
    facebook: {
      icon: Facebook,
      name: "Facebook",
      placeholder: "e.g., Company update, Success story..."
    },
    linkedin: {
      icon: Linkedin,
      name: "LinkedIn",
      placeholder: "e.g., Thought leadership, Company milestone..."
    }
  };

  const tones = [
    { id: 'professional', label: 'Professional' },
    { id: 'casual', label: 'Casual' },
    { id: 'humorous', label: 'Humorous' }
  ];

  const generateContent = async () => {
    if (!topic) return;
    
    setLoading(true);
    setGeneratedContent('');

    try {
      // Simulating API call with platform-specific content generation
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      const contentTemplates = {
        instagram: {
          professional: `📱 Elevate your ${topic} game!\n\nDiscover how we're revolutionizing the industry with innovative solutions.\n\n#Innovation #Growth #Success`,
          casual: `✨ Hey fam! Check out our amazing ${topic}! We can't wait to share this journey with you 🚀\n\n#Excited #Community #Growth`,
          humorous: `🎉 Plot twist: ${topic} just got way cooler! \n\nSpoiler alert: You're gonna love this! 😎\n\n#FunTimes #Innovation #Trending`
        },
        twitter: {
          professional: `Announcing our latest developments in ${topic}. Stay tuned for groundbreaking innovations that will transform your experience. #Innovation #Growth`,
          casual: `Hey Twitter! 👋 We've got something exciting cooking with ${topic}! Can't wait to share more! #ComingSoon`,
          humorous: `Breaking news: ${topic} just got a glow-up! 💅 \nSpoiler: It's awesome! 🎉 #trending #mindblown`
        },
        facebook: {
          professional: `We're excited to announce our latest developments in ${topic}. Click below to learn how this will transform your business experience.`,
          casual: `Hey everyone! 👋 We've got some exciting news about ${topic}! Drop a ❤️ if you're curious to learn more!`,
          humorous: `🎯 When ${topic} meets awesome... you get this! Check out what we've been up to (warning: may cause excessive excitement!) 🎉`
        },
        linkedin: {
          professional: `Excited to announce our latest innovation in ${topic}. This development marks a significant milestone in our industry's evolution. #Innovation #Leadership`,
          casual: `We're thrilled to share our journey with ${topic}! This represents a new chapter in our growth story. #Growth #Innovation`,
          humorous: `Who said ${topic} couldn't be fun? 🎉 We're breaking the mold with our latest innovation! #GameChanger #Innovation`
        }
      };

      setGeneratedContent(contentTemplates[selectedPlatform][tone]);
    } catch (error) {
      console.error('Error generating content:', error);
    } finally {
      setLoading(false);
    }
  };

  const regenerateContent = () => {
    generateContent();
  };

  return (
    <div className="bg-black/50 p-6 rounded-xl space-y-6">
      {/* Platform Selection */}
      <div className="flex space-x-4 mb-6">
        {Object.entries(platforms).map(([key, platform]) => {
          const PlatformIcon = platform.icon;
          return (
            <button
              key={key}
              onClick={() => setSelectedPlatform(key)}
              className={`p-3 rounded-lg transition-all ${
                selectedPlatform === key 
                  ? 'bg-blue-500/20 text-blue-400' 
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <PlatformIcon className="h-6 w-6" />
            </button>
          );
        })}
      </div>

      {/* Topic Input */}
      <div className="space-y-4">
        <input
          type="text"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder={platforms[selectedPlatform].placeholder}
          className="w-full bg-black/30 border border-gray-700 rounded-lg px-4 py-3 text-gray-300 focus:outline-none focus:border-blue-500 placeholder-gray-500"
        />

        {/* Tone Selection */}
        <div className="flex flex-wrap gap-2">
          {tones.map(({ id, label }) => (
            <button
              key={id}
              onClick={() => setTone(id)}
              className={`px-4 py-2 rounded-lg transition-all ${
                tone === id
                  ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50'
                  : 'bg-black/30 text-gray-400 hover:bg-black/50 hover:text-gray-300'
              }`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      {/* Generate Button */}
      <button
        onClick={generateContent}
        disabled={loading || !topic}
        className={`w-full py-3 rounded-lg font-medium transition-all flex items-center justify-center space-x-2 
          ${!topic ? 'bg-gray-600 cursor-not-allowed' : 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600'}`}
      >
        {loading ? (
          <>
            <Loader2 className="h-5 w-5 animate-spin" />
            <span>Generating...</span>
          </>
        ) : (
          'Generate Content'
        )}
      </button>

      {/* Generated Content Display */}
      {generatedContent && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-black/30 p-4 rounded-lg border border-gray-700"
        >
          <div className="flex justify-between items-start mb-2">
            <h4 className="text-sm text-gray-400">Generated Content</h4>
            <button
              onClick={regenerateContent}
              className="p-1 text-gray-400 hover:text-blue-400 transition-colors"
              title="Regenerate"
            >
              <RefreshCcw className="h-4 w-4" />
            </button>
          </div>
          <p className="text-gray-300 whitespace-pre-line">{generatedContent}</p>
        </motion.div>
      )}
    </div>
  );
};

export default SMLiveDemo;