import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Type, Copy, Check, ArrowRight, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const TextCaseConverter = () => {
  const [text, setText] = useState('');
  const [convertedText, setConvertedText] = useState('');
  const [selectedConversion, setSelectedConversion] = useState('lowercase');
  const [copied, setCopied] = useState(false);
  const [isConverting, setIsConverting] = useState(false);

  const conversions = {
    lowercase: (text) => text.toLowerCase(),
    uppercase: (text) => text.toUpperCase(),
    capitalize: (text) => {
      return text.split('\n').map(paragraph => 
        paragraph.split(' ').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ')
      ).join('\n');
    },
    sentenceCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.replace(/(^\w|\.\s+\w)/g, char => char.toUpperCase())
      ).join('\n');
    },
    alternatingCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.split('').map((char, i) => 
          i % 2 === 0 ? char.toLowerCase() : char.toUpperCase()
        ).join('')
      ).join('\n');
    },
    titleCase: (text) => {
      const smallWords = new Set(['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'if', 'in', 'nor', 'of', 'on', 'or', 'so', 'the', 'to', 'up', 'yet']);
      return text.split('\n').map(paragraph =>
        paragraph.toLowerCase().split(' ').map((word, index) => {
          if (index === 0 || !smallWords.has(word)) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
          return word;
        }).join(' ')
      ).join('\n');
    },
    inverseCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.split('').map(char => 
          char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()
        ).join('')
      ).join('\n');
    },
    snakeCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '_')
      ).join('\n');
    },
    kebabCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')
      ).join('\n');
    },
    camelCase: (text) => {
      return text.split('\n').map(paragraph =>
        paragraph.toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' ')
          .map((word, index) => 
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join('')
      ).join('\n');
    }
  };

  const handleConvert = useCallback(() => {
    if (!text.trim()) return;
    
    setIsConverting(true);
    setTimeout(() => {
      const converted = conversions[selectedConversion](text);
      setConvertedText(converted);
      setIsConverting(false);
    }, 500);
  }, [text, selectedConversion]);

  const copyToClipboard = useCallback(() => {
    if (!convertedText) return;
    
    navigator.clipboard.writeText(convertedText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [convertedText]);

  const getStats = useCallback(() => {
    if (!text) return { chars: 0, words: 0, sentences: 0, paragraphs: 0 };
    
    const paragraphs = text.split('\n').filter(p => p.trim());
    return {
      chars: text.length,
      words: text.trim().split(/\s+/).filter(Boolean).length,
      sentences: text.split(/[.!?]+/).filter(Boolean).length,
      paragraphs: paragraphs.length
    };
  }, [text]);

  const stats = getStats();

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter your text here to convert..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="text-sm text-gray-400 space-x-4">
            <span>{stats.chars} characters</span>
            <span>{stats.words} words</span>
            <span>{stats.sentences} sentences</span>
            <span>{stats.paragraphs} paragraphs</span>
          </div>
          
          <select
            value={selectedConversion}
            onChange={(e) => setSelectedConversion(e.target.value)}
            className="px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="lowercase">lowercase</option>
            <option value="uppercase">UPPERCASE</option>
            <option value="capitalize">Capitalize Words</option>
            <option value="sentenceCase">Sentence case</option>
            <option value="alternatingCase">aLtErNaTiNg cAsE</option>
            <option value="titleCase">Title Case</option>
            <option value="inverseCase">InVeRsE CaSe</option>
            <option value="snakeCase">snake_case</option>
            <option value="kebabCase">kebab-case</option>
            <option value="camelCase">camelCase</option>
          </select>
        </div>

        <button
          onClick={handleConvert}
          disabled={!text.trim() || isConverting}
          className="w-full px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
        >
          {isConverting ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Converting...</span>
            </>
          ) : (
            <>
              <Type className="h-4 w-4" />
              <span>Convert Text</span>
            </>
          )}
        </button>
      </div>

      {convertedText && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative bg-gray-800/50 border border-gray-700 rounded-xl p-6"
        >
          <button
            onClick={copyToClipboard}
            className="absolute top-4 right-4 p-2 hover:bg-gray-700 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            {copied ? (
              <Check className="h-5 w-5 text-green-500" />
            ) : (
              <Copy className="h-5 w-5 text-gray-400" />
            )}
          </button>
          <div className="pr-12 whitespace-pre-wrap">
            {convertedText}
          </div>
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Want More Text Tools?
              </h2>
              <p className="text-purple-100 mb-6">
                Access premium features like bulk text conversion, custom case patterns, and save your frequently used conversions. Sign up now and get 1000 free points to try our premium tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Get 1000 Free Points
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TextCaseConverter;