import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Info, Loader2, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const GrammarPatternIdentifier = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const grammarPatterns = {
    sentenceStructures: [
      { pattern: /\b[A-Z][^.!?]*[.!?]\s*/g, type: 'Simple' },
      { pattern: /\b[A-Z][^.!?]*,[^.!?]*[.!?]\s*/g, type: 'Compound' },
      { pattern: /\b[A-Z][^.!?]*(?:because|although|if|when|while|unless)[^.!?]*[.!?]\s*/g, type: 'Complex' }
    ],
    
    styleElements: [
      { pattern: /\b(?:very|really|quite|extremely)\b/g, type: 'Intensifier', suggestion: 'Consider using stronger specific words' },
      { pattern: /\b(?:good|bad|nice|interesting)\b/g, type: 'Vague Adjective', suggestion: 'Use more specific descriptive words' },
      { pattern: /\b(?:things|stuff|something)\b/g, type: 'Vague Noun', suggestion: 'Be more specific' },
      { pattern: /(?:is|are|was|were)\s+(?:being|going)\s+to\b/g, type: 'Passive Construction', suggestion: 'Consider active voice' }
    ],
    
    transitions: [
      { pattern: /\b(?:however|therefore|furthermore|moreover|consequently)\b/g, type: 'Formal Transition' },
      { pattern: /\b(?:also|besides|plus|then)\b/g, type: 'Informal Transition' }
    ],
    
    readabilityIssues: [
      { pattern: /([.!?])\s*(?=[A-Z])/g, count: true, type: 'Sentence Count' },
      { pattern: /\b\w{12,}\b/g, type: 'Long Word', suggestion: 'Consider simpler alternatives' },
      { pattern: /([.!?])[^.!?]{100,}(?=[.!?])/g, type: 'Long Sentence', suggestion: 'Consider breaking into smaller sentences' }
    ]
  };

  const calculateReadability = (text) => {
    // Remove extra whitespace and split into sentences
    const cleanText = text.replace(/\s+/g, ' ').trim();
    const sentences = cleanText.split(/[.!?]+/).filter(Boolean);
    const words = cleanText.split(/\s+/).filter(Boolean);
    const syllables = words.reduce((count, word) => {
      return count + countSyllables(word);
    }, 0);

    // Calculate base metrics
    const averageWordsPerSentence = words.length / sentences.length;
    const averageSyllablesPerWord = syllables / words.length;
    const percentComplexWords = (words.filter(word => countSyllables(word) > 2).length / words.length) * 100;
    
    // Calculate Flesch Reading Ease score
    const fleschScore = 206.835 - (1.015 * averageWordsPerSentence) - (84.6 * averageSyllablesPerWord);
    
    // Calculate Gunning Fog Index
    const gunningFog = 0.4 * (averageWordsPerSentence + percentComplexWords);

    // Normalize scores to 0-100 range
    const normalizedScore = Math.max(0, Math.min(100, (
      // Weight different factors
      (fleschScore * 0.4) +                          // 40% Flesch score
      ((100 - gunningFog * 5) * 0.3) +              // 30% Gunning Fog (inverted)
      ((100 - percentComplexWords) * 0.2) +          // 20% Complex words (inverted)
      ((100 - Math.abs(15 - averageWordsPerSentence)) * 0.1)  // 10% Sentence length optimization
    )));

    return {
      score: Math.round(normalizedScore),
      metrics: {
        averageWordsPerSentence: averageWordsPerSentence.toFixed(1),
        averageSyllablesPerWord: averageSyllablesPerWord.toFixed(1),
        percentComplexWords: percentComplexWords.toFixed(1)
      }
    };
  };

  const countSyllables = (word) => {
    word = word.toLowerCase();
    word = word.replace(/(?:[^laeiouy]|ed|[^laeiouy]e)$/, '');
    word = word.replace(/^y/, '');
    const syllableMatches = word.match(/[aeiouy]{1,2}/g);
    return syllableMatches ? syllableMatches.length : 1;
  };

  const analyzeGrammar = (text) => {
    if (!text.trim()) return null;

    const analysis = {
      patterns: [],
      statistics: {
        sentenceCount: 0,
        averageWordLength: 0,
        readabilityMetrics: {},
        readabilityScore: 0
      },
      suggestions: []
    };

    // Calculate basic statistics
    const words = text.split(/\s+/).filter(Boolean);
    const totalCharacters = words.reduce((acc, word) => acc + word.length, 0);
    analysis.statistics.averageWordLength = (totalCharacters / words.length).toFixed(1);

    // Calculate readability
    const readability = calculateReadability(text);
    analysis.statistics.readabilityScore = readability.score;
    analysis.statistics.readabilityMetrics = readability.metrics;

    // Analyze patterns (same as before)
    grammarPatterns.sentenceStructures.forEach(({ pattern, type }) => {
      const matches = text.match(pattern) || [];
      if (matches.length > 0) {
        analysis.patterns.push({
          type: `${type} Sentences`,
          count: matches.length,
          examples: matches.slice(0, 2)
        });
      }
    });

    grammarPatterns.styleElements.forEach(({ pattern, type, suggestion }) => {
      const matches = text.match(pattern) || [];
      if (matches.length > 0) {
        analysis.patterns.push({
          type,
          count: matches.length,
          suggestion,
          examples: [...new Set(matches)].slice(0, 3)
        });
      }
    });

    grammarPatterns.transitions.forEach(({ pattern, type }) => {
      const matches = text.match(pattern) || [];
      if (matches.length > 0) {
        analysis.patterns.push({
          type,
          count: matches.length,
          examples: [...new Set(matches)].slice(0, 2)
        });
      }
    });

    grammarPatterns.readabilityIssues.forEach(({ pattern, type, suggestion }) => {
      const matches = text.match(pattern) || [];
      if (matches.length > 0) {
        analysis.patterns.push({
          type,
          count: matches.length,
          suggestion,
          examples: matches.slice(0, 2)
        });
      }
      if (type === 'Sentence Count') {
        analysis.statistics.sentenceCount = matches.length;
      }
    });

    return analysis;
  };

  const analyzeText = useCallback(() => {
    setIsAnalyzing(true);
    
    setTimeout(() => {
      const analysis = analyzeGrammar(text);
      setResult(analysis);
      setIsAnalyzing(false);
    }, 1000);
  }, [text]);

  const InfoMessage = memo(() => (
    <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
      <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
      <p>
        This tool analyzes writing patterns and suggests improvements. 
        The readability score combines multiple factors including sentence length, word complexity, and overall structure.
      </p>
    </div>
  ));

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here to analyze grammar patterns..."
          className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {text.split(/\s+/).filter(Boolean).length} words
          </span>
          <button
            onClick={analyzeText}
            disabled={!text.trim() || isAnalyzing}
            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Analyzing...</span>
              </>
            ) : (
              <>
                <BookOpen className="h-4 w-4" />
                <span>Analyze Text</span>
              </>
            )}
          </button>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6 space-y-6"
        >
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-800 p-4 rounded-lg">
                <div className="text-sm text-gray-400">Readability Score</div>
                <div className="text-2xl font-bold">{result.statistics.readabilityScore}%</div>
                <div className="text-xs text-gray-500 mt-1">
                  Based on multiple readability metrics
                </div>
              </div>
              <div className="bg-gray-800 p-4 rounded-lg">
                <div className="text-sm text-gray-400">Advanced Metrics</div>
                <div className="text-sm mt-2">
                  <div className="flex justify-between">
                    <span>Words per Sentence:</span>
                    <span>{result.statistics.readabilityMetrics.averageWordsPerSentence}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Complex Words:</span>
                    <span>{result.statistics.readabilityMetrics.percentComplexWords}%</span>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 p-4 rounded-lg">
                <div className="text-sm text-gray-400">Basic Stats</div>
                <div className="text-sm mt-2">
                  <div className="flex justify-between">
                    <span>Sentences:</span>
                    <span>{result.statistics.sentenceCount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Avg Word Length:</span>
                    <span>{result.statistics.averageWordLength}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="font-medium">Pattern Analysis:</h4>
            <div className="space-y-3">
              {result.patterns.map((pattern, index) => (
                <div key={index} className="bg-gray-800 p-4 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <div className="font-medium">{pattern.type}</div>
                    {pattern.count && (
                      <div className="text-sm text-gray-400">Count: {pattern.count}</div>
                    )}
                  </div>
                  {pattern.suggestion && (
                    <div className="text-yellow-500 text-sm mb-2">
                      Suggestion: {pattern.suggestion}
                    </div>
                  )}
                  {pattern.examples && pattern.examples.length > 0 && (
                    <div className="text-sm text-gray-400">
                      Examples: {pattern.examples.join(', ')}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <InfoMessage />
        </motion.div>
      )}

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Transform Your Writing with AI
              </h2>
              <p className="text-purple-100 mb-6">
                Take your content to the next level with our AI-powered writing tools. Get access to advanced grammar checking, style enhancement, and content generation features. Start with 1000 free credits!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Claim Your Free Credits
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default GrammarPatternIdentifier;