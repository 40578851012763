import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Globe, Info, Loader2, ArrowRight, Download, RefreshCcw } from 'lucide-react';
import { Link } from 'react-router-dom';

const XMLSitemapGenerator = () => {
  const [urls, setUrls] = useState('');
  const [result, setResult] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState({
    changefreq: 'weekly',
    priority: '0.8',
    lastmod: true,
  });

  const validateUrls = (urlList) => {
    const urlPattern = /^(https?:\/\/)[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;
    const lines = urlList.split('\n').filter(line => line.trim());
    const validUrls = [];
    const invalidUrls = [];

    lines.forEach(url => {
      if (urlPattern.test(url.trim())) {
        validUrls.push(url.trim());
      } else {
        invalidUrls.push(url.trim());
      }
    });

    return { validUrls, invalidUrls };
  };

  const generateSitemap = useCallback(() => {
    setIsGenerating(true);
    setError('');

    const { validUrls, invalidUrls } = validateUrls(urls);

    if (invalidUrls.length > 0) {
      setError(`Invalid URLs detected: ${invalidUrls.join(', ')}`);
      setIsGenerating(false);
      return;
    }

    const today = new Date().toISOString().split('T')[0];

    let sitemap = `<?xml version="1.0" encoding="UTF-8"?>\n`;
    sitemap += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

    validUrls.forEach(url => {
      sitemap += `  <url>\n`;
      sitemap += `    <loc>${url}</loc>\n`;
      if (settings.lastmod) {
        sitemap += `    <lastmod>${today}</lastmod>\n`;
      }
      sitemap += `    <changefreq>${settings.changefreq}</changefreq>\n`;
      sitemap += `    <priority>${settings.priority}</priority>\n`;
      sitemap += `  </url>\n`;
    });

    sitemap += `</urlset>`;

    setTimeout(() => {
      setResult(sitemap);
      setIsGenerating(false);
    }, 1000);
  }, [urls, settings]);

  const downloadSitemap = () => {
    const blob = new Blob([result], { type: 'text/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <textarea
            value={urls}
            onChange={(e) => setUrls(e.target.value)}
            placeholder="Enter URLs (one per line)&#10;Example:&#10;https://example.com&#10;https://example.com/about&#10;https://example.com/contact"
            className="w-full h-48 p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono text-sm"
          />
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <label className="text-sm text-gray-300">Change Frequency</label>
            <select
              value={settings.changefreq}
              onChange={(e) => setSettings(prev => ({ ...prev, changefreq: e.target.value }))}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="always">Always</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="never">Never</option>
            </select>
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-300">Priority</label>
            <select
              value={settings.priority}
              onChange={(e) => setSettings(prev => ({ ...prev, priority: e.target.value }))}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="1.0">1.0</option>
              <option value="0.9">0.9</option>
              <option value="0.8">0.8</option>
              <option value="0.7">0.7</option>
              <option value="0.6">0.6</option>
              <option value="0.5">0.5</option>
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="lastmod"
              checked={settings.lastmod}
              onChange={(e) => setSettings(prev => ({ ...prev, lastmod: e.target.checked }))}
              className="rounded bg-gray-800 border-gray-700 text-blue-500 focus:ring-blue-500"
            />
            <label htmlFor="lastmod" className="text-sm text-gray-300">
              Include Last Modified Date
            </label>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {urls.split('\n').filter(line => line.trim()).length} URLs
          </span>
          <div className="space-x-3">
            {result && (
              <button
                onClick={downloadSitemap}
                className="px-4 py-2 bg-gray-700 rounded-lg font-medium hover:bg-gray-600 transition-colors flex items-center space-x-2"
              >
                <Download className="h-4 w-4" />
                <span>Download XML</span>
              </button>
            )}
            <button
              onClick={generateSitemap}
              disabled={!urls.trim() || isGenerating}
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
            >
              {isGenerating ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Generating...</span>
                </>
              ) : (
                <>
                  <Globe className="h-4 w-4" />
                  <span>Generate Sitemap</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800/50 border border-gray-700 rounded-xl p-6"
        >
          <pre className="text-sm text-gray-300 overflow-x-auto">
            {result}
          </pre>
        </motion.div>
      )}

      <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
        <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
        <p>
          XML sitemaps help search engines better understand your website structure. 
          Add this file to your root directory and submit it to search engines to improve SEO.
        </p>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Ready to Supercharge Your Content Strategy?
              </h2>
              <p className="text-purple-100 mb-6">
                Generate SEO-optimized content, meta descriptions, and complete XML sitemaps automatically. Join now and get 1000 free points to explore our premium content creation tools!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default XMLSitemapGenerator;