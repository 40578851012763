import React, { useState, useEffect } from 'react';
  import { Link } from 'react-router-dom';
  import { motion } from 'framer-motion';
  import {
    Table,
    FileSpreadsheet,
    Brain,
    Zap,
    ArrowRight,
    Database,
    LineChart,
    BookOpen,
    Settings,
    Code,
    Globe,
    Lock,
    Sparkles,
    ChevronDown,
    Check,
    Star,
    Users,
    CheckCircle,
    Shield,
    Cpu,
    Server,
    X
  } from 'lucide-react';
  import SEOHead from '../../components/SEOHead';
  
  const AISpreadsheetLanding = () => {
    // Previous code remains the same...
  
  const [scrollY, setScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    setIsVisible(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Floating stats for real-time visualization
  const stats = {
    cellsProcessed: Math.floor(scrollY * 150),
    activeUsers: 2547,
    averageSpeed: '0.3s'
  };

  const features = [
    {
      icon: Brain,
      title: "AI-Powered Analysis",
      description: "Advanced machine learning algorithms analyze and understand your data in real-time",
      color: "blue"
    },
    {
      icon: Zap,
      title: "Lightning Fast Processing",
      description: "Process thousands of cells in seconds with our optimized engine",
      color: "yellow"
    },
    {
      icon: Database,
      title: "Smart Data Integration",
      description: "Seamlessly connect with existing databases and data sources",
      color: "purple"
    }
  ];

  
    // Demo section data
    const demoFeatures = {
      input: `A1: Revenue Data
  B1: 2023 Q1-Q4
  A2-A5: Q1, Q2, Q3, Q4
  B2-B5: Empty cells`,
      output: {
        analysis: "Analyzing quarterly revenue trends...",
        prediction: "Generating Q1 2024 forecast...",
        insights: "Identifying key growth patterns..."
      }
    };
  
    // Use cases with animations
    const useCases = [
      {
        title: "Financial Analysis",
        description: "Process financial data with AI-powered insights and forecasting",
        icon: LineChart,
        features: ["Revenue Prediction", "Cost Analysis", "Budget Planning"]
      },
      {
        title: "Data Processing",
        description: "Automate data cleaning, transformation, and analysis at scale",
        icon: Database,
        features: ["Data Cleaning", "Format Conversion", "Batch Processing"]
      },
      {
        title: "Report Generation",
        description: "Create automated reports with smart data visualization",
        icon: BookOpen,
        features: ["Custom Templates", "Auto-formatting", "Export Options"]
      }
    ];
  
    // Comparison data
    const comparisonFeatures = [
      {
        feature: "Processing Speed",
        traditional: "Manual processing",
        aiPowered: "Real-time analysis",
        advantage: "500x faster"
      },
      {
        feature: "Data Analysis",
        traditional: "Basic formulas",
        aiPowered: "Advanced AI insights",
        advantage: "Deep learning powered"
      },
      {
        feature: "Automation",
        traditional: "Limited macros",
        aiPowered: "Smart automation",
        advantage: "Self-learning"
      },
      {
        feature: "Error Handling",
        traditional: "Manual checking",
        aiPowered: "Auto-detection & fixing",
        advantage: "99.9% accuracy"
      }
    ];
  
    // Integration platforms
    const integrations = [
      {
        name: "Excel",
        icon: "/icons/excel.svg",
        features: ["Direct import/export", "Format preservation", "Formula conversion"]
      },
      {
        name: "Google Sheets",
        icon: "/icons/sheets.svg",
        features: ["Real-time sync", "Collaborative editing", "Version control"]
      },
      {
        name: "Databases",
        icon: "/icons/database.svg",
        features: ["SQL integration", "Auto-sync", "Data warehousing"]
      }
    ];
  
    const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const pricingPlans = [
    {
      name: "Starter",
      price: "29",
      description: "Perfect for individual analysts",
      features: [
        "10,000 cells per month",
        "Basic AI analysis",
        "Excel & CSV import/export",
        "Email support",
        "3 spreadsheet templates"
      ],
      highlight: false
    },
    {
      name: "Professional",
      price: "79",
      description: "Ideal for growing teams",
      features: [
        "100,000 cells per month",
        "Advanced AI insights",
        "All file formats supported",
        "Priority support",
        "Custom templates",
        "API access",
        "Collaboration tools"
      ],
      highlight: true
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "For large organizations",
      features: [
        "Unlimited cells",
        "Custom AI training",
        "Dedicated server",
        "24/7 support",
        "Full API access",
        "Custom integrations",
        "SLA guarantee",
        "Security compliance"
      ],
      highlight: false
    }
  ];

  const testimonials = [
    {
      name: "Michael Chen",
      role: "Data Analytics Lead",
      company: "TechCorp",
      image: "/testimonials/michael.jpg", // Using placeholder image API in actual render
      content: "The AI processing speed is incredible. What used to take our team hours now happens in seconds.",
      metrics: {
        improvement: "95%",
        metric: "Time Saved"
      }
    },
    {
      name: "Sarah Johnson",
      role: "Financial Controller",
      company: "Global Finance",
      image: "/testimonials/sarah.jpg",
      content: "The accuracy of AI-powered analysis has transformed how we handle financial forecasting.",
      metrics: {
        improvement: "99.9%",
        metric: "Accuracy Rate"
      }
    },
    {
      name: "David Rodriguez",
      role: "Operations Director",
      company: "LogisticsPro",
      image: "/testimonials/david.jpg",
      content: "Seamless integration with our existing tools and the automation capabilities are game-changing.",
      metrics: {
        improvement: "10x",
        metric: "Productivity"
      }
    }
  ];

  const techSpecs = [
    {
      icon: Brain,
      title: "AI Engine",
      specs: [
        "Neural network processing",
        "Machine learning optimization",
        "Natural language understanding",
        "Pattern recognition"
      ]
    },
    {
      icon: Cpu,
      title: "Processing Power",
      specs: [
        "GPU acceleration",
        "Parallel processing",
        "Real-time computation",
        "Low latency operations"
      ]
    },
    {
      icon: Shield,
      title: "Security",
      specs: [
        "End-to-end encryption",
        "SOC 2 compliance",
        "Data anonymization",
        "Regular security audits"
      ]
    },
    {
      icon: Server,
      title: "Infrastructure",
      specs: [
        "Cloud-native architecture",
        "Global CDN",
        "Auto-scaling",
        "99.99% uptime"
      ]
    }
  ];

  const faqs = [
    {
      question: "How does the AI spreadsheet processing work?",
      answer: "Our AI system uses advanced machine learning algorithms to understand your spreadsheet data, identify patterns, and automate analysis. It can process multiple formats, clean data automatically, and provide intelligent insights in real-time."
    },
    {
      question: "Can I import my existing Excel spreadsheets?",
      answer: "Yes! We support seamless import of Excel (.xlsx, .xls), CSV, and Google Sheets files. All your existing formulas, formatting, and data relationships will be preserved and enhanced with AI capabilities."
    },
    {
      question: "What about data privacy and security?",
      answer: "We maintain the highest security standards with end-to-end encryption, SOC 2 compliance, and regular security audits. Your data is processed in isolated environments and never shared or used for training."
    },
    {
      question: "Is there a limit to the spreadsheet size?",
      answer: "Free tier users can process spreadsheets up to 10,000 cells. Professional users get 100,000 cells, and Enterprise users have unlimited processing capacity. Custom limits can be arranged for specific needs."
    },
    {
      question: "Can I integrate it with my existing tools?",
      answer: "Yes, we offer robust API access and native integrations with popular platforms like Excel, Google Sheets, and various database systems. Custom integrations are available for Enterprise users."
    }
  ];

    return (
      <>
        {/* Previous sections remain the same... */}
  
      <SEOHead 
        title="AI Excel Spreadsheet Tool | Smart Excel Alternative | Automated Spreadsheet Analysis"
        description="Transform your spreadsheet workflow with AI-powered analysis, automation, and real-time processing. The smartest Excel alternative for modern businesses."
        keywords="AI spreadsheet, Excel alternative, automated spreadsheet, AI Excel tool, spreadsheet automation, business intelligence tool"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />
          
          {/* Animated Grid Background */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0" style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px), 
                               linear-gradient(90deg, rgba(0, 0, 0, 0.2) 1px, transparent 1px)`,
              backgroundSize: '20px 20px',
              backgroundPosition: 'center center',
              animation: 'gridMove 20s linear infinite'
            }} />
          </div>

          {/* Hero Content */}
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-7xl mx-auto px-4 text-center relative z-10"
          >
            <div className="flex justify-center mb-6">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, rotate: 360 }}
                transition={{ duration: 1 }}
                className="p-4 bg-blue-500/20 rounded-full"
              >
                <FileSpreadsheet className="h-16 w-16 text-blue-400" />
              </motion.div>
            </div>

            <motion.h1 
              className="text-6xl md:text-8xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Next-Gen AI
              <span className="block">Spreadsheet</span>
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Transform your data workflow with AI-powered analysis, automation, and real-time processing
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <Link 
                to="/spreadsheet"
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
              >
                Start Processing
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => {
                  document.querySelector('#demo')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
              >
                Watch Demo
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div 
              className="mt-12 grid grid-cols-3 gap-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">1M+</div>
                <div className="text-gray-400">Cells Processed</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">97%</div>
                <div className="text-gray-400">Accuracy Rate</div>
              </div>
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-400 mb-2">0.3s</div>
                <div className="text-gray-400">Processing Time</div>
              </div>
            </motion.div>
          </motion.div>
        </section>

        {/* Floating Statistics Panel */}
        <motion.div
          className="fixed bottom-8 right-8 bg-gray-800/90 backdrop-blur-sm rounded-2xl p-4 shadow-lg z-50"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 2 }}
        >
          <div className="flex items-center space-x-6">
            <div>
              <div className="text-sm text-gray-400">Cells Processed</div>
              <div className="text-xl font-bold text-blue-400">
                {stats.cellsProcessed.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Active Users</div>
              <div className="text-xl font-bold text-green-400">
                {stats.activeUsers.toLocaleString()}
              </div>
            </div>
          </div>
        </motion.div>
      
      {/* Interactive Demo Section */}
      <section id="demo" className="py-24 bg-gradient-to-b from-gray-900 to-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Watch AI Transform Your
              <span className="text-blue-400"> Spreadsheets</span>
            </h2>
            <p className="text-xl text-gray-400">
              Experience the power of AI-driven spreadsheet processing
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Input Side */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6"
            >
              <h3 className="text-xl font-bold mb-4">Input Spreadsheet</h3>
              <div className="font-mono text-sm bg-black/50 p-4 rounded-lg text-gray-300">
                {demoFeatures.input.split('\n').map((line, i) => (
                  <div key={i} className="mb-2">{line}</div>
                ))}
              </div>
            </motion.div>

            {/* Output Side */}
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6"
            >
              <h3 className="text-xl font-bold mb-4">AI Processing</h3>
              <div className="space-y-4">
                {Object.entries(demoFeatures.output).map(([key, value], index) => (
                  <motion.div
                    key={key}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-black/50 p-4 rounded-lg"
                  >
                    <div className="flex items-center text-blue-400 mb-2">
                      <Sparkles className="h-4 w-4 mr-2" />
                      <span className="capitalize">{key}</span>
                    </div>
                    <div className="text-gray-300">{value}</div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Use Cases Grid */}
      <section className="py-24 bg-black relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Endless Possibilities
            </h2>
            <p className="text-xl text-gray-400">
              Discover how AI transforms your spreadsheet workflows
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {useCases.map((useCase, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8 hover:bg-gray-800/70 transition-all group"
              >
                <useCase.icon className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">{useCase.title}</h3>
                <p className="text-gray-400 mb-6">{useCase.description}</p>
                <div className="space-y-2">
                  {useCase.features.map((feature, i) => (
                    <div key={i} className="flex items-center text-gray-300">
                      <Check className="h-4 w-4 text-blue-400 mr-2" />
                      {feature}
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Comparison Section */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Why Choose AI-Powered Spreadsheets?
            </h2>
            <p className="text-xl text-gray-400">
              See how we compare to traditional spreadsheet solutions
            </p>
          </motion.div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-800">
                  <th className="py-4 px-6 text-left">Feature</th>
                  <th className="py-4 px-6 text-left">Traditional</th>
                  <th className="py-4 px-6 text-left">AI-Powered</th>
                  <th className="py-4 px-6 text-left">Advantage</th>
                </tr>
              </thead>
              <tbody>
                {comparisonFeatures.map((item, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="border-b border-gray-800"
                  >
                    <td className="py-4 px-6 font-medium">{item.feature}</td>
                    <td className="py-4 px-6 text-gray-400">{item.traditional}</td>
                    <td className="py-4 px-6 text-blue-400">{item.aiPowered}</td>
                    <td className="py-4 px-6 text-green-400">{item.advantage}</td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* Integration Section */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Seamless Integrations
            </h2>
            <p className="text-xl text-gray-400">
              Connect with your favorite tools and platforms
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {integrations.map((integration, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-800/70 transition-all"
              >
                <img
                  src={integration.icon}
                  alt={integration.name}
                  className="h-12 w-12 mb-4"
                />
                <h3 className="text-xl font-bold mb-4">{integration.name}</h3>
                <ul className="space-y-2">
                  {integration.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-300">
                      <Check className="h-4 w-4 text-blue-400 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

       {/* Pricing Section */}
       <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/20 via-transparent to-transparent" />

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Simple, Transparent Pricing
            </h2>
            <p className="text-xl text-gray-400">
              Choose the perfect plan for your needs
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className={`rounded-2xl p-8 backdrop-blur-sm transform transition-all duration-300 hover:scale-105 ${
                  plan.highlight
                    ? 'bg-gradient-to-b from-blue-600/20 to-purple-600/20 border border-blue-500/20'
                    : 'bg-gray-800/50'
                }`}
              >
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <p className="text-gray-400 mb-4">{plan.description}</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold">
                    ${plan.price}
                  </span>
                  {plan.price !== "Custom" && <span className="text-gray-400">/month</span>}
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-300">
                      <CheckCircle className="h-5 w-5 text-blue-400 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link
                  to="/register"
                  className={`block text-center py-3 px-6 rounded-full transition-all ${
                    plan.highlight
                      ? 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white'
                      : 'bg-white/10 hover:bg-white/20 text-white'
                  }`}
                >
                  Get Started
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Trusted by Industry Leaders
            </h2>
            <p className="text-xl text-gray-400">
              See what our users have achieved
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-8"
              >
                <div className="flex items-center mb-6">
                  <div className="w-16 h-16 rounded-full bg-blue-500/20 flex items-center justify-center text-blue-400 text-xl font-bold mr-4">
                    {testimonial.name.split(' ').map(n => n[0]).join('')}
                  </div>
                  <div>
                    <h3 className="font-bold">{testimonial.name}</h3>
                    <p className="text-sm text-gray-400">{testimonial.role}</p>
                    <p className="text-sm text-gray-400">{testimonial.company}</p>
                  </div>
                </div>
                <p className="text-gray-300 italic mb-6">"{testimonial.content}"</p>
                <div className="bg-blue-500/10 rounded-lg p-4 text-center">
                  <div className="text-2xl font-bold text-blue-400">
                    {testimonial.metrics.improvement}
                  </div>
                  <div className="text-sm text-gray-400">
                    {testimonial.metrics.metric}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Technical Specifications */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Cutting-Edge Technology
            </h2>
            <p className="text-xl text-gray-400">
              Powered by advanced AI and cloud infrastructure
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {techSpecs.map((spec, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-6"
              >
                <spec.icon className="h-8 w-8 text-blue-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">{spec.title}</h3>
                <ul className="space-y-2">
                  {spec.specs.map((item, i) => (
                    <li key={i} className="flex items-center text-gray-300">
                      <div className="w-1.5 h-1.5 rounded-full bg-blue-400 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-24 bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />
        
        <div className="max-w-3xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Common Questions
            </h2>
            <p className="text-xl text-gray-400">
              Everything you need to know
            </p>
          </motion.div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden"
              >
                <button
                  onClick={() => toggleFaq(index)}
                  className="w-full p-6 text-left flex items-center justify-between hover:bg-gray-800/70 transition-all"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown 
                    className={`h-5 w-5 text-gray-400 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: openFaq === index ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  {openFaq === index && (
                    <div className="p-6 pt-0 text-gray-400">
                      {faq.answer}
                    </div>
                  )}
                </motion.div>
              </motion.div>
             ))}
             </div>
           </div>
         </section>
   
         {/* Stats Ticker */}
         <section className="py-12 bg-gradient-to-r from-blue-600/10 to-purple-600/10">
           <div className="max-w-7xl mx-auto overflow-hidden">
             <motion.div
               animate={{
                 x: [0, -1000],
                 transition: {
                   x: {
                     repeat: Infinity,
                     repeatType: "loop",
                     duration: 20,
                     ease: "linear",
                   },
                 },
               }}
               className="flex gap-8"
             >
               <div className="flex gap-8 items-center whitespace-nowrap">
                 <span className="text-gray-400">1M+ Cells Processed Daily</span>
                 <span className="text-blue-400">•</span>
                 <span className="text-gray-400">98% User Satisfaction</span>
                 <span className="text-blue-400">•</span>
                 <span className="text-gray-400">50+ Countries</span>
                 <span className="text-blue-400">•</span>
                 <span className="text-gray-400">24/7 Support</span>
                 <span className="text-blue-400">•</span>
                 <span className="text-gray-400">Enterprise-Grade Security</span>
                 <span className="text-blue-400">•</span>
                 <span className="text-gray-400">Real-time Processing</span>
               </div>
             </motion.div>
           </div>
         </section>
   
         {/* Final CTA Section */}
         <section className="py-24 bg-black relative overflow-hidden">
           <div className="absolute inset-0">
             <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse" />
             <motion.div
               animate={{
                 opacity: [0.5, 1, 0.5],
                 scale: [1, 1.2, 1],
               }}
               transition={{
                 duration: 10,
                 repeat: Infinity,
                 ease: "easeInOut",
               }}
               className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full filter blur-3xl"
             />
           </div>
   
           <div className="max-w-7xl mx-auto px-4 relative z-10">
             <motion.div
               initial={{ opacity: 0, y: 20 }}
               whileInView={{ opacity: 1, y: 0 }}
               viewport={{ once: true }}
               className="text-center"
             >
               <h2 className="text-4xl md:text-6xl font-bold mb-6">
                 Transform Your Spreadsheets
                 <span className="block mt-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                   With AI Power
                 </span>
               </h2>
               <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                 Join thousands of professionals using AI to revolutionize their data processing workflow
               </p>
               
               {/* Feature Highlights */}
               <div className="grid md:grid-cols-3 gap-8 max-w-3xl mx-auto mb-12">
                 <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                   <Zap className="h-8 w-8 text-blue-400 mb-4 mx-auto" />
                   <div className="text-lg font-semibold mb-2">Lightning Fast</div>
                   <div className="text-gray-400">Process thousands of cells in seconds</div>
                 </div>
                 <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                   <Brain className="h-8 w-8 text-purple-400 mb-4 mx-auto" />
                   <div className="text-lg font-semibold mb-2">AI-Powered</div>
                   <div className="text-gray-400">Smart analysis and automation</div>
                 </div>
                 <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6">
                   <Shield className="h-8 w-8 text-green-400 mb-4 mx-auto" />
                   <div className="text-lg font-semibold mb-2">Enterprise Secure</div>
                   <div className="text-gray-400">Bank-grade data protection</div>
                 </div>
               </div>
   
               <motion.div
                 initial={{ opacity: 0, y: 20 }}
                 whileInView={{ opacity: 1, y: 0 }}
                 viewport={{ once: true }}
                 transition={{ delay: 0.2 }}
                 className="flex flex-col sm:flex-row gap-4 justify-center"
               >
                 <Link
                   to="/register"
                   className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full font-medium hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 flex items-center justify-center group"
                 >
                   Start Free Trial
                   <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                 </Link>
                 <Link
                   to="/pricing"
                   className="px-8 py-4 bg-white/10 rounded-full font-medium hover:bg-white/20 transition-all"
                 >
                   Pricing
                 </Link>
               </motion.div>
   
               {/* Trust Badges */}
               <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-8 max-w-3xl mx-auto">
                 <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
                   <Shield className="h-6 w-6 text-blue-400 mx-auto mb-2" />
                   <div className="text-sm text-gray-400">SOC 2 Certified</div>
                 </div>
                 <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
                   <Server className="h-6 w-6 text-blue-400 mx-auto mb-2" />
                   <div className="text-sm text-gray-400">99.99% Uptime</div>
                 </div>
                 <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
                   <Users className="h-6 w-6 text-blue-400 mx-auto mb-2" />
                   <div className="text-sm text-gray-400">10k+ Users</div>
                 </div>
                 <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
                   <Star className="h-6 w-6 text-blue-400 mx-auto mb-2" />
                   <div className="text-sm text-gray-400">4.9/5 Rating</div>
                 </div>
               </div>
             </motion.div>
           </div>
         </section>
   
         </div>
    </>
  );
};

export default AISpreadsheetLanding;