import React, { useState, useCallback, memo } from 'react';
import { motion } from 'framer-motion';
import { FileCode, Loader2, ArrowRight, Copy, CheckCircle2, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

const HTMLToMarkdown = () => {
  const [input, setInput] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [copied, setCopied] = useState(false);

  const cleanupMarkdown = useCallback((text) => {
    // Handle headers
    let cleaned = text.replace(/^(#+)\s*(.+)$/gm, (match, hashes, content) => {
      return `${hashes} ${content.trim()}`;
    });

    // Handle paragraphs
    cleaned = cleaned.replace(/\n\s*\n/g, '\n\n');
    
    // Remove extra spaces at the end of lines
    cleaned = cleaned.replace(/[ \t]+$/gm, '');
    
    // Ensure consistent newlines
    cleaned = cleaned.replace(/\n{3,}/g, '\n\n');
    
    // Remove space before newlines
    cleaned = cleaned.replace(/[ \t]+\n/g, '\n');
    
    // Ensure space after headers
    cleaned = cleaned.replace(/^(#{1,6}[^#\n]+)\n([^#\n])/gm, '$1\n\n$2');

    return cleaned;
  }, []);

  const convertToMarkdown = useCallback((inputText) => {
    try {
      // First, check if input is already markdown-like
      const isMarkdownLike = /^#|^\*|^>|^-|^\d+\.|^```/.test(inputText);
      
      if (isMarkdownLike) {
        // If it's markdown-like, just clean it up
        return cleanupMarkdown(inputText);
      }
      
      // If it's HTML, convert it
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = inputText.trim();
      
      // Rest of your HTML to Markdown conversion logic here
      // ... (previous HTML conversion code)
      
      return cleanupMarkdown(inputText);
    } catch (error) {
      console.error('Conversion error:', error);
      return 'Error converting text. Please check your input.';
    }
  }, [cleanupMarkdown]);

  const handleConvert = useCallback(() => {
    if (!input.trim()) return;
    
    setIsConverting(true);
    setTimeout(() => {
      const result = convertToMarkdown(input);
      setMarkdown(result);
      setIsConverting(false);
    }, 800);
  }, [input, convertToMarkdown]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(markdown);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [markdown]);

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Input Section */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium">HTML Input</label>
            <span className="text-sm text-gray-400">
              {input.length} characters
            </span>
          </div>
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Paste your HTML or markdown here..."
            className="w-full h-[400px] p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono text-sm"
          />
        </div>

        {/* Output Section */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium">Markdown Output</label>
            <button
              onClick={handleCopy}
              disabled={!markdown}
              className="flex items-center space-x-1 text-sm text-gray-400 hover:text-white transition-colors disabled:opacity-50"
            >
              {copied ? (
                <CheckCircle2 className="h-4 w-4 text-green-500" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </button>
          </div>
          <textarea
            value={markdown}
            readOnly
            placeholder="Converted markdown will appear here..."
            className="w-full h-[400px] p-4 bg-gray-800 border border-gray-700 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono text-sm"
          />
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleConvert}
          disabled={!input.trim() || isConverting}
          className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
        >
          {isConverting ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Converting...</span>
            </>
          ) : (
            <>
              <FileCode className="h-4 w-4" />
              <span>Convert to Markdown</span>
            </>
          )}
        </button>
      </div>

      <div className="flex items-start space-x-2 text-sm text-gray-400 bg-gray-800 rounded-lg p-4">
        <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />
        <p>
          This tool converts HTML to Markdown while preserving formatting. 
          Support for tables, lists, code blocks, and more.
        </p>
      </div>

      {/* CTA Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden"
      >
        <div className="relative p-8 md:p-12">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white/10 via-transparent to-transparent" />
          <div className="relative z-10">
            <div className="max-w-xl">
              <h2 className="text-2xl font-bold text-white mb-4">
                Transform Your Content Creation
              </h2>
              <p className="text-purple-100 mb-6">
                Get access to our full suite of AI-powered content tools including advanced HTML conversion, SEO optimization, and bulk processing. Start creating better content today with 1000 free credits!
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors group"
              >
                Start Creating Better Content
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HTMLToMarkdown;