import { utils, writeFile } from 'xlsx';

export const downloadAnalysis = (results) => {
  // Create workbook and worksheet
  const wb = utils.book_new();
  
  // Create sheets for each section
  if (Object.keys(results.numerical).length > 0) {
    const numericalData = [];
    // Add header row
    numericalData.push(['Column', 'Metric', 'Value']);
    
    // Add data rows
    Object.entries(results.numerical).forEach(([column, stats]) => {
      numericalData.push([column, 'Mean', stats.mean]);
      numericalData.push([column, 'Median', stats.median]);
      numericalData.push([column, 'Mode', stats.mode || 'N/A']);
      numericalData.push([column, 'Minimum', stats.min]);
      numericalData.push([column, 'Maximum', stats.max]);
      numericalData.push([column, 'Standard Deviation', stats.stdDev]);
      numericalData.push([column, 'Quartiles', stats.quartiles.join(', ')]);
      numericalData.push([column, 'Outliers', stats.outliers.length > 0 ? stats.outliers.join(', ') : 'None']);
      numericalData.push([]); // Empty row for spacing
    });
    
    const numericalWs = utils.aoa_to_sheet(numericalData);
    utils.book_append_sheet(wb, numericalWs, 'Numerical Analysis');
  }

  if (Object.keys(results.categorical).length > 0) {
    const categoricalData = [];
    // Add header row
    categoricalData.push(['Column', 'Metric', 'Value']);
    
    // Add data rows
    Object.entries(results.categorical).forEach(([column, stats]) => {
      categoricalData.push([column, 'Unique Values', stats.uniqueCount]);
      categoricalData.push([column, 'Most Common', stats.mostCommon.value]);
      categoricalData.push([column, 'Least Common', stats.leastCommon.value]);
      categoricalData.push([column, 'Distribution', '']);
      Object.entries(stats.distribution).forEach(([category, percentage]) => {
        categoricalData.push(['', category, percentage]);
      });
      categoricalData.push([]); // Empty row for spacing
    });
    
    const categoricalWs = utils.aoa_to_sheet(categoricalData);
    utils.book_append_sheet(wb, categoricalWs, 'Categorical Analysis');
  }

  if (results.insights && results.insights.length > 0) {
    const insightsData = [
      ['Key Insights'],
      [''],
      ...results.insights.map((insight, index) => [`${index + 1}. ${insight}`])
    ];
    
    const insightsWs = utils.aoa_to_sheet(insightsData);
    utils.book_append_sheet(wb, insightsWs, 'Insights');
  }

  // Download the file
  writeFile(wb, 'Statistical_Analysis.xlsx');
};